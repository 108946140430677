import styled, { css } from 'styled-components';

import LinkedInIcon from '../../svgs/LinkedInIcon';
import FacebookIcon from '../../svgs/FacebookIcon';
import InstagramIcon from '../../svgs/InstagramIcon';
import TikTokIcon from '../../svgs/TiktokIcon';
import YouTubeIcon from '../../svgs/YouTubeIcon';
import XIcon from '../../svgs/XIcon';
import { observer } from 'mobx-react-lite';
import { SocialPost, SharedContent } from '../../types.ts/story';
import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';

type Props = {
  sharedContents: SharedContent[] | undefined;
  isImage?: boolean;
  showOnlyShared?: boolean;
  padding?: string;
};

const SocialShareStatus = observer((props: Props) => {
  const videoCreator = useVideoCreatorStore();
  const {
    sharedContents,
    isImage,
    showOnlyShared = false,
    padding = '10px 0',
  } = props;
  let linkedInStatus = false;
  let facebookStatus = false;
  let instagramStatus = false;
  let tiktokStatus = false;
  let youtubeStatus = false;
  let xStatus = false;

  sharedContents?.forEach((content) => {
    if (content && content.platform) {
      if (content.platform === 'linkedin') {
        linkedInStatus = true;
      } else if (content.platform === 'facebook') {
        facebookStatus = true;
      } else if (content.platform === 'instagram') {
        instagramStatus = true;
      } else if (content.platform === 'tiktok') {
        tiktokStatus = true;
      } else if (content.platform === 'youtube') {
        youtubeStatus = true;
      } else if (content.platform === 'x' || content.platform === 'twitter') {
        xStatus = true;
      }
    }
  });

  const hasNot = '#484848'; // TODO: Get final color
  const has = '#17C964'; // TODO: Get final color

  const show = (state: boolean) => {
    if (!showOnlyShared) return true;
    return state;
  };

  return (
    <>
      <Icons padding={padding}>
        {show(linkedInStatus) && (
          <LinkedInIcon fillColor={linkedInStatus ? has : hasNot} />
        )}
        {show(facebookStatus) && (
          <FacebookIcon fillColor={facebookStatus ? has : hasNot} />
        )}
        {show(instagramStatus) && (
          <InstagramIcon strokeColor={instagramStatus ? has : hasNot} />
        )}
        {show(xStatus) && (
          <XIcon
            fillColor={xStatus ? has : hasNot}
            style={{ marginRight: '-5px' }}
          />
        )}
        {!isImage && show(tiktokStatus) && (
          <TikTokIcon fillColor={tiktokStatus ? has : hasNot} />
        )}
        {!isImage && show(youtubeStatus) && (
          <YouTubeIcon fillColor={youtubeStatus ? has : hasNot} />
        )}
      </Icons>
    </>
  );
});

export default SocialShareStatus;

const Icons = styled.div<{ padding: string }>`
  display: flex;
  gap: 10px;
  padding: ${(props) => props.padding};
`;
