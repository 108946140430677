import { AIFlowStep } from './AIFlowStep';

export type AIFlowLog = {
  flow: string;
  time: Date;
  stepLabel: string;
  message: string;
};

export class AIFlow {
  title: string;
  steps: AIFlowStep[];
  currentStepIndex: number = 0;
  context: any;
  resultMapper?: (lastStepResult: any) => any;
  result: any;
  logEnabled: boolean;
  logs: AIFlowLog[] = [];
  onRunLogs?: (logs: AIFlowLog[]) => Promise<string | undefined>;

  constructor(params: {
    title: string;
    steps: AIFlowStep[];
    context: any;
    resultMapper?: (lastStepResult: any) => any;
    logEnabled: boolean;
    onRunLogs?: (logs: AIFlowLog[]) => Promise<string | undefined>;
  }) {
    this.title = params.title;
    this.steps = params.steps;
    this.context = params.context;
    this.resultMapper = params.resultMapper;
    this.logEnabled = params.logEnabled;
    this.onRunLogs = params.onRunLogs;
  }

  async run() {
    try {
      this.log({
        stepLabel: 'Before start',
        message:
          'Initial Flow Context set: \n' +
          JSON.stringify(this.context, null, 2),
      });
      this.currentStepIndex = 0;
      for (const step of this.steps) {
        let inputMessages;
        if (step.includeMessagesFromStepId) {
          const previousStep = this.steps.find(
            (step) => step.id === step.includeMessagesFromStepId,
          );
          inputMessages = previousStep?.inputMessages!;
          inputMessages.push({ role: 'assistant', content: step.lastResponse });
        }
        step.logger = this.logEnabled
          ? (message: string) => this.log({ stepLabel: step.label, message })
          : undefined;
        step.init(this.context, inputMessages);
        await step.run();
        this.context[step.id] = {
          ...(this.context[step.id] || {}),
          lastResult: step.lastResult,
        };
      }
      this.result = this.resultMapper
        ? this.resultMapper(this.steps[this.steps.length - 1].lastResult)
        : this.steps[this.steps.length - 1].lastResult;
    } catch (err) {
      console.error('Error running AI flow', err);
    } finally {
      if (this.logEnabled && this.onRunLogs) {
        await this.onRunLogs(this.logs);
      }
    }
  }

  log(element: { stepLabel: string; message: string }) {
    this.logs.push({
      flow: this.title,
      time: new Date(),
      ...element,
    });
  }
}
