import { Studio } from '../types.ts/general';
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { getCurrentStudio } from '../utility/general';
import VideosQuerySubscriptionComponent from './VideosQuerySubscriptionComponent';
import MyAudioQuerySubscriptionComponent from './MyAudioQuerySubscriptionComponent';
import StoryArtifactsAiTitleSubscription from './StoryArtifactAiTitleSubscription';
import OrganizationArtifactsAiTitleSubscription from './OrganizationArtifactAiTitleSubscription';

import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';

const GlobalQuerySubscriptionComponent = observer(() => {
  const videoCreator = useVideoCreatorStore();

  const { pathname } = useLocation();
  const currentStudio = getCurrentStudio(pathname);
  const generatingMyAudio =
    videoCreator.story?.myAudios?.filter(
      (o) => o.metadataStatus === 'generating' && !o.waveformUploadId,
    ) || [];
  const storyIdsWithWatchedArtifacts =
    videoCreator.statusUpdateManager.getStoryIdsWithWatchedArtifacts();
  const organizationIdsWithWatchedArtifacts =
    videoCreator.statusUpdateManager.getOrganizationIdsWithWatchedArtifacts();

  if (!currentStudio) return null;

  return (
    <>
      {(currentStudio === Studio.creator || currentStudio === Studio.content) &&
        !!videoCreator.renderingVideoIds.length && (
          <VideosQuerySubscriptionComponent
            key={videoCreator.renderingVideoIds.join('_')}
            videoIds={videoCreator.renderingVideoIds}
            onError={(error) => {
              console.error('VideosQuerySubscriptionComponent error', error);
            }}
            onDataReceived={async (allVideos) => {
              for (const video of allVideos) {
                await videoCreator.handleVideoRenderingStatusUpdate(video);
              }
            }}
          />
        )}

      {(currentStudio === Studio.creator || currentStudio === Studio.content) &&
        !!generatingMyAudio.length && (
          <MyAudioQuerySubscriptionComponent
            key={generatingMyAudio[0].id}
            audioIds={generatingMyAudio.map((audio) => audio.id)}
            onError={(error) => {
              console.error('MyAudioQuerySubscriptionComponent error', error);
            }}
            onDataReceived={async (audios) => {
              console.log('audio processed', audios);
              videoCreator.handleUpdateMyAudioMetadata(audios);
            }}
          />
        )}
      {!!storyIdsWithWatchedArtifacts.length && (
        <StoryArtifactsAiTitleSubscription
          storyIds={storyIdsWithWatchedArtifacts}
          onError={(error) => {
            console.error('StoryArtifactsAiTitleSubscription error', error);
          }}
          onDataReceived={(updates) => {
            videoCreator.statusUpdateManager.updateWatchedStoryArtifacts(
              updates,
            );
          }}
        />
      )}
      {!!organizationIdsWithWatchedArtifacts.length && (
        <OrganizationArtifactsAiTitleSubscription
          organizationIds={organizationIdsWithWatchedArtifacts}
          onError={(error) => {
            console.error(
              'OrganizationArtifactsAiTitleSubscription error',
              error,
            );
          }}
          onDataReceived={(updates) => {
            videoCreator.statusUpdateManager.updateWatchedOrganizationArtifacts(
              updates,
            );
          }}
        />
      )}
    </>
  );
});

export default GlobalQuerySubscriptionComponent;
