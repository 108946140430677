import { RequestDocument, gql } from 'graphql-request';

export const AI_FLOW_QUERY_FRAGMENT = gql`
  {
    id
    title
    steps {
      label
      systemMessageTemplate
      userPromptTemplate
      overrideMessagesFromStep
      maxAttempts
      iterateOver
      modelName
      temperature
      temperatureFunction
      retryConditionFunction
      filterFunction
      reducerFunction
    }
    resultMapperFunction
    logEnabled
  }
`;

export const AI_FLOW_BY_TITLE_ACTIVE_QUERY: RequestDocument = gql`
  query getAiFlow($title: String!) {
    aiFlow(
      filter: {
        title: { eq: $title }
        active: { eq: true }
      }
    ) ${AI_FLOW_QUERY_FRAGMENT}
  }
`;

export const AI_PROMPT_QUERY_FRAGMENT = gql`
  {
    id
    promptFields {
      id
      name
      description
    }
    functionDescription
    description
    title
    followUp
  }
`;

export const AI_PROMPT_QUERY: RequestDocument = gql`
  query {
    allAiPrompts(first: 100) ${AI_PROMPT_QUERY_FRAGMENT}
  }
`;

export const STORIES_ARTIFACT_AI_TITLE_QUERY: RequestDocument = gql`
  query getStories($ids: [ItemId]) {
    allStories(filter: { id: { in: $ids } }) {
      id
      storyArtifacts {
        id
        title
      }
    }
  }
`;

export const SHOWCASES_ARTIFACT_AI_TITLE_QUERY: RequestDocument = gql`
  query getShowcases($ids: [ItemId]) {
    allShowcases(filter: { id: { in: $ids } }) {
      id
      organizationArtifacts {
        id
        title
      }
    }
  }
`;

export const AI_PROMPT_SINGLE_BY_TITLE_QUERY: RequestDocument = gql`
  query getAipromptSingle($title: String!) {
    aiPromptSingle(
      filter: { title: { matches: { pattern: $title, caseSensitive: false } } }
    ) ${AI_PROMPT_QUERY_FRAGMENT}
  }
`;
