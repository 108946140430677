import { makeAutoObservable } from 'mobx';
import { UserIdentityStore } from './UserIdentityStore';
import { AnalyticsStore } from './AnalyticsStore';
import type { OrganizationResource } from '@clerk/types';
import type { DatoContext } from './types';
import { TemplatedPromptsStore } from './TemplatedPromptsStore';
import { Client } from '@datocms/cma-client-browser';
import { GraphQLClient } from 'graphql-request';
import ApiClient from '@src/apiClient/ApiClient';
import { Showcase } from '@src/types.ts/story';

interface InitializeParams {
  storyId: string | null | undefined;
  env: string;
  datoContext?: DatoContext;
  organization: OrganizationResource | undefined;
}

export class RootStore {
  // Child Stores
  userIdentityStore: UserIdentityStore;
  analyticsStore: AnalyticsStore;
  templatedPromptsStore: TemplatedPromptsStore;

  // Base State
  initialized: boolean = false;
  storyId: string | undefined | null = null;
  organization: OrganizationResource | undefined = undefined;

  // Data Access - Private so we can enforce error checking
  private _datoClient?: Client | ApiClient | undefined;
  private _gqlClient?: GraphQLClient | undefined;

  constructor() {
    this.userIdentityStore = new UserIdentityStore(this);
    this.analyticsStore = new AnalyticsStore(this);
    this.templatedPromptsStore = new TemplatedPromptsStore(this);

    makeAutoObservable(this, {
      userIdentityStore: false,
      analyticsStore: false,
      templatedPromptsStore: false,
    });
  }

  initialize({ storyId, env, datoContext }: InitializeParams) {
    this.storyId = storyId;
    this.userIdentityStore.setEnvironment(env);
    if (!this.initialized) {
      this.analyticsStore.initSmartlook();
    }

    if (datoContext) {
      this.userIdentityStore.setDatoContext(datoContext);
    }
    this.analyticsStore.identifyUser();

    this.initialized = true;
  }

  // We're ready if we're initialized and have either:
  // 1. Dato context
  // 2. Valid environment for external users
  // 3. API clients are ready
  get isReady(): boolean {
    return (
      this.initialized &&
      (this.userIdentityStore.hasDatoContext ||
        !!this.userIdentityStore.environment) &&
      this.apiReady
    );
  }

  // Helper method to get current auth type
  get authType(): 'dato' | 'clerk' | null {
    if (this.userIdentityStore.hasDatoContext) return 'dato';
    if (this.userIdentityStore.currentIdentity?.source === 'clerk')
      return 'clerk';
    return null;
  }

  // Helper to check if we have valid authentication
  get hasValidAuth(): boolean {
    return this.userIdentityStore.currentIdentity !== null;
  }
  // Helper to check if API clients are ready
  get apiReady(): boolean {
    return !!(this._datoClient && this._gqlClient);
  }

  // Client getters with error checking
  get datoClient(): Client | ApiClient {
    if (!this._datoClient) {
      throw new Error('Dato client not initialized');
    }
    return this._datoClient;
  }

  get gqlClient(): GraphQLClient {
    if (!this._gqlClient) {
      throw new Error('GraphQL client not initialized');
    }
    return this._gqlClient;
  }

  initializeApiClients(
    datoClient: Client | ApiClient | undefined,
    gqlClient: GraphQLClient | undefined,
  ) {
    this._datoClient = datoClient;
    this._gqlClient = gqlClient;
  }
}
