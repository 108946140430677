import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import styled, { css } from 'styled-components';

import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';

import { BrandingCustomFont, BrandingFontMetadata } from '../../types.ts/story';

import Modal from '../common/Modal';
import DeleteIcon from '../../svgs/DeleteIcon';
import useBrandKitFontUpload, {
  parseFontData,
  useFontDropzone,
} from './utils/useBrandKitFontUpload';
import ProgressBar from '../common/ProgressBar';
import SpinningLoading from '../SpinningLoading';

enum FontsDialogState {
  display,
  acknowledge,
  uploading,
  duplicate,
}

type FontData = {
  file: File;
  fontData: BrandingFontMetadata;
};

type Props = {
  onClose: () => void;
  fontsToUpload: File[] | null;
};

const BrandKitCustomFontsDialog = observer((props: Props) => {
  const videoCreator = useVideoCreatorStore();
  const { onClose, fontsToUpload: _fontsToUpload } = props;

  const branding = videoCreator.organization?.branding;
  const customFonts = filterAndSortFonts(branding?.customFonts);

  const [fontsToUpload, setFontsToUpload] = useState<File[] | null>(
    _fontsToUpload || null,
  );
  const {
    uploadFontsToBrandKit,
    uploading,
    archiveFontFromBrandKit,
    archiving,
  } = useBrandKitFontUpload(videoCreator);

  const [dialogState, setDialogState] = useState(
    fontsToUpload ? FontsDialogState.acknowledge : FontsDialogState.display,
  );
  const [fontToDelete, setFontToDelete] = useState<BrandingCustomFont | null>(
    null,
  );

  if (fontToDelete) {
    return (
      <Modal isOpen closeModal={() => setFontToDelete(null)}>
        <SDeleteContainer>
          <div>
            <h1>Delete font?</h1>
            <h2>This cannot be undone.</h2>
          </div>
          <p>
            You are about to delete <strong>“{fontToDelete.fullName}”</strong>{' '}
            from the current Brand Kit. You will need to reupload it if you want
            to use it again. Existing designs won’t be affected.
          </p>
          <SButton
            className="negative"
            disabled={archiving}
            onClick={async () => {
              await archiveFontFromBrandKit(fontToDelete);
              setFontToDelete(null);
            }}
          >
            Delete permanently
          </SButton>
        </SDeleteContainer>
      </Modal>
    );
  }

  return (
    <Modal isOpen closeModal={onClose}>
      <SContainer>
        <h2>Custom Fonts</h2>
        {dialogState === FontsDialogState.acknowledge ? (
          <FontAcknowledgeContent
            onClose={onClose}
            onUpload={async () => {
              if (!fontsToUpload?.length) return;
              // Check for duplicates
              const { duplicates } = await getFontDuplicates(
                fontsToUpload,
                customFonts,
              );
              if (duplicates.length) {
                setDialogState(FontsDialogState.duplicate);
                return;
              }
              // Proceed to upload
              setDialogState(FontsDialogState.uploading);
              uploadFontsToBrandKit(fontsToUpload);
            }}
          />
        ) : dialogState === FontsDialogState.duplicate ? (
          <FontDuplicatesContent
            customFonts={customFonts}
            fontsToUpload={fontsToUpload}
            onClose={onClose}
            onSkipDuplicates={async () => {
              if (!fontsToUpload?.length) return;
              // Skip duplicates and upload the rest
              const { rest } = await getFontDuplicates(
                fontsToUpload,
                customFonts,
              );
              setDialogState(FontsDialogState.uploading);
              uploadFontsToBrandKit(rest.map(({ file }) => file));
            }}
            onUploadAll={async () => {
              if (!fontsToUpload?.length) return;
              setDialogState(FontsDialogState.uploading);
              uploadFontsToBrandKit(fontsToUpload);
            }}
          />
        ) : dialogState === FontsDialogState.uploading ? (
          <FontAcknowledgeUploading uploading={uploading} onUpload={onClose} />
        ) : (
          <FontUploadContent
            customFonts={customFonts}
            onDrop={async (acceptedFiles) => {
              if (!acceptedFiles.length) return;
              setFontsToUpload(acceptedFiles);
              setDialogState(FontsDialogState.acknowledge);
            }}
            onDelete={(font) => setFontToDelete(font)}
          />
        )}
      </SContainer>
    </Modal>
  );
});

const filterAndSortFonts = (fonts: BrandingCustomFont[] | undefined) =>
  fonts
    ?.filter((font) => !font.archivedAt)
    .sort((a, b) => a.fullName.localeCompare(b.fullName));

const FontUploadContent = ({
  customFonts,
  onDrop,
  onDelete,
}: {
  customFonts?: BrandingCustomFont[];
  onDrop: (acceptedFiles: File[]) => void;
  onDelete: (font: BrandingCustomFont) => void;
}) => {
  const { getRootProps, getInputProps, open } = useFontDropzone({
    onDrop,
  });
  const uploadButton = (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <SButton onClick={open}>Upload</SButton>
    </div>
  );
  return (
    <SUploadContent>
      <SHeading>Uploaded Fonts</SHeading>
      {customFonts?.length ? (
        <SUploadFontList>
          {customFonts.map((font) => (
            <SUploadFontItem
              fontFamily={font.fontFamily}
              fontWeight={font.usWeightClass}
              fontStyle={font.style}
            >
              <div className="font-name">{font.fullName}</div>
              <div className="delete-font-btn" onClick={() => onDelete(font)}>
                <DeleteIcon strokeColor="#F3E9D7" width="24" height="24" />
              </div>
            </SUploadFontItem>
          ))}
        </SUploadFontList>
      ) : (
        <p className="muted">
          Click the button below to add your first custom font!
        </p>
      )}
      {uploadButton}
    </SUploadContent>
  );
};

const FontAcknowledgeContent = ({
  onClose,
  onUpload,
}: {
  onClose: () => void;
  onUpload: () => void;
}) => {
  return (
    <SUploadContent>
      <SHeading>Please confirm</SHeading>
      <p>
        By uploading custom fonts, I acknowledge that I am the owner or have the
        right to use these fonts for my intended purpose.
      </p>
      <SUploadActions>
        <SButton className="outline" onClick={onClose}>
          Cancel
        </SButton>
        <SButton onClick={onUpload}>Yes, upload</SButton>
      </SUploadActions>
    </SUploadContent>
  );
};

const getFontDuplicates = async (
  fontsToUpload: File[],
  customFonts: BrandingCustomFont[] | undefined,
) => {
  if (!customFonts) return { duplicates: [], rest: [] };
  const parsed = await Promise.all(
    fontsToUpload.map(async (file) => {
      const fontData = await parseFontData(file);
      return { file, fontData };
    }),
  );
  const fontDuplicatesData = parsed.reduce(
    (acc, item) => {
      const isDuplicate = customFonts.some(
        (font) => font.postScriptName === item.fontData.postScriptName,
      );
      if (isDuplicate) acc.duplicates.push(item);
      else acc.rest.push(item);
      return acc;
    },
    { duplicates: [], rest: [] } as {
      duplicates: FontData[];
      rest: FontData[];
    },
  );
  return fontDuplicatesData;
};

const FontDuplicatesContent = ({
  customFonts,
  fontsToUpload,
  onClose,
  onSkipDuplicates,
  onUploadAll,
}: {
  customFonts?: BrandingCustomFont[];
  fontsToUpload: File[] | null;
  onClose: () => void;
  onSkipDuplicates: () => void;
  onUploadAll: () => void;
}) => {
  const [fontsData, setFontsData] = useState<{
    duplicates: FontData[];
    rest: FontData[];
  } | null>(null);
  useEffect(() => {
    if (!fontsToUpload) return;
    getFontDuplicates(fontsToUpload, customFonts).then(setFontsData);
  }, [customFonts, fontsToUpload]);
  if (!fontsData)
    return (
      <SUploadContent center>
        <SpinningLoading text={null} customStyle={{ position: 'relative' }} />
      </SUploadContent>
    );
  return (
    <SUploadContent>
      <SHeading>Duplicate Fonts</SHeading>
      <p className="left">
        You're trying to upload fonts that have already been added to your brand
        kit. Please review:
      </p>
      <SUploadContentBlock>
        <div className="heading">Already in your kit:</div>
        {fontsData.duplicates.map(({ fontData }) => (
          <div className="row">{fontData.fullName}</div>
        ))}
      </SUploadContentBlock>
      <SUploadContentBlock>
        <div className="heading">Fonts you're trying to upload:</div>
        {fontsData.duplicates.map(({ fontData }) => (
          <div className="row">{fontData.fullName}</div>
        ))}
        {fontsData.rest.map(({ fontData }) => (
          <div className="row">{fontData.fullName}</div>
        ))}
      </SUploadContentBlock>
      <SUploadActions>
        <SButton className="outline" onClick={onClose}>
          Cancel
        </SButton>
        {fontsData.rest.length ? (
          <SButton className="outline" onClick={onSkipDuplicates}>
            Skip Duplicates
          </SButton>
        ) : null}
        <SButton onClick={onUploadAll}>Upload All</SButton>
      </SUploadActions>
    </SUploadContent>
  );
};

const FontAcknowledgeUploading = ({
  uploading,
  onUpload,
}: {
  uploading: boolean;
  onUpload: () => void;
}) => {
  useEffect(() => {
    if (!uploading) setTimeout(onUpload, 500);
  }, [uploading]);
  return (
    <SUploadContent center>
      <SHeading>Uploading fonts</SHeading>
      <p>Just a moment...</p>
      <ProgressBar auto width={320} finished={!uploading} />
    </SUploadContent>
  );
};

const SContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 440px;
  max-height: 70vh;
  overflow-y: auto;
  padding: 48px 24px;
  border-radius: 16px;
  border: 1px solid #484848;
  background-color: #03041a;
  box-shadow: 14px 8px 16px 0px rgba(0, 0, 0, 0.4);
  color: #f3e9d7;
  > h2 {
    color: #45d483;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1.92px;
    text-transform: uppercase;
  }
  > .fonts-dialog-content {
    display: flex;
    flex-direction: column;
  }
`;

const SDeleteContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 328px;
  padding: 24px 16px;
  border-radius: 16px;
  border: 1px solid #484848;
  background-color: #03041a;
  box-shadow: 8px 16px 8px 0px rgba(0, 0, 0, 0.4);
  color: #f3e9d7;
  h1 {
    margin: 0;
    color: #f3e9d7;
    text-align: center;
    font-size: 32px;
    font-weight: 700;
    line-height: 120%;
  }
  h2 {
    margin: 4px 0 0;
    color: #f3e9d7;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
  }
  p {
    margin: 0;
    color: #f3e9d7;
    font-size: 14px;
    font-weight: 400;
  }
`;

const SHeading = styled.h1`
  margin: 0;
  color: #f3e9d7;
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  line-height: 120%;
`;

const SUploadContent = styled.div<{ center?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 24px;
  ${({ center }) =>
    center &&
    css`
      align-items: center;
    `}
  > p {
    margin: 0;
    color: #f3e9d7;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    &.muted {
      color: #848484;
    }
    &.left {
      text-align: left;
    }
  }
`;
const SButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 48px;
  padding: 16px;
  border-radius: 8px;
  border: none;
  background: #17c964;
  color: #03041a;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
  transition: 0.2s;
  &:hover:not(:disabled) {
    filter: brightness(1.1);
  }
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
  &.outline {
    background: none;
    border: 1px solid #17c964;
    color: #17c964;
  }
  &.negative {
    background: #ef5da8;
  }
`;
const SUploadActions = styled.div`
  display: flex;
  gap: 8px;
`;
const SUploadFontList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
const SUploadFontItem = styled.div<{
  fontFamily: string;
  fontWeight: number;
  fontStyle: string;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  padding-top: 24px;
  border-top: 1px solid #484848;
  > .font-name {
    color: #f3e9d7;
    font-family: 'GT Super Display';
    font-size: 24px;
    line-height: 32px;
    font-weight: 400;
    font-family: ${({ fontFamily }) => fontFamily};
    font-weight: ${({ fontWeight }) => fontWeight};
    font-style: ${({ fontStyle }) => fontStyle};
  }
  > .delete-font-btn {
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 4px;
    transition: 0.2s;
    &:hover {
      background-color: rgba(72, 72, 72, 0.5);
    }
  }
`;
const SUploadContentBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  > .heading {
    color: #f3e9d7;
    font-size: 14px;
    font-weight: 700;
  }
  > .row {
    color: #f3e9d7;
    font-size: 14px;
    font-weight: 500;
  }
`;

export default BrandKitCustomFontsDialog;
