import { useState } from 'react';
import { Tooltip } from 'react-tooltip';
import styled, { css } from 'styled-components';

import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';
import { AIPrompt } from '@src/types.ts/ai_prompts';

import Modal from '../common/Modal';
import AutoResizeableMultilineInput from '../common/AutoResizeableMultilineInput';

import CheckIcon from '@src/svgs/CheckIcon';
import CheckIconAlt from '@src/svgs/CheckIconAlt';
import CreateIcon from '@src/svgs/CreateIcon';

type Props = {
  title: string;
  templatesHeading?: string;
  templatesSublabel?: string;
  templates: AIPrompt[];
  allowBrandVoice?: boolean;
  customInstructions?: string;
  allowCustomInstructions?: boolean;
  customInstructionsLabel?: string;
  onClose: () => void;
  onConfirm: (
    template: AIPrompt,
    brandVoice?: string,
    customInstructions?: string,
  ) => void;
  confirmButtonLabel?: string;
};

const AIProducerModal = (props: Props) => {
  const {
    title,
    templatesHeading,
    templatesSublabel,
    templates,
    allowBrandVoice = true,
    customInstructions: _customInstructions,
    allowCustomInstructions,
    customInstructionsLabel = 'Customize your theme',
    onClose,
    onConfirm,
    confirmButtonLabel = 'Generate',
  } = props;

  const videoCreator = useVideoCreatorStore();
  const brandVoice = videoCreator.organization?.branding?.brandVoice;
  const [selectedTemplate, setSelectedTemplate] = useState<AIPrompt | null>(
    null,
  );
  const handleSelectTemplate = (template: AIPrompt) => {
    setSelectedTemplate(template);
  };

  const [shouldUseBrandVoice, setShouldUseBrandVoice] = useState(!!brandVoice);
  const [customInstructions, setCustomInstructions] = useState(
    _customInstructions || '',
  );

  const handleConfirm = () => {
    onClose();
    onConfirm(
      selectedTemplate!,
      allowBrandVoice && shouldUseBrandVoice ? brandVoice : undefined,
      customInstructions || undefined,
    );
  };

  return (
    <Modal isOpen={true} closeModal={onClose}>
      <Wrapper>
        <Content>
          <Title>{title}</Title>
          <Section>
            {templatesHeading && (
              <SectionHeading>{templatesHeading}</SectionHeading>
            )}
            {templatesSublabel && (
              <SectionSublabel>{templatesSublabel}</SectionSublabel>
            )}
            <OptionList>
              {templates.map((template) => {
                const isSelected = selectedTemplate?.id === template.id;
                return (
                  <OptionItem
                    key={template.id}
                    selected={isSelected}
                    onClick={() => handleSelectTemplate(template)}
                  >
                    <div className="optionLabel">{template.title}</div>
                    {isSelected ? (
                      <div className="checkIcon">
                        <CheckIcon />
                      </div>
                    ) : (
                      <div className="checkEmpty" />
                    )}
                  </OptionItem>
                );
              })}
            </OptionList>
          </Section>

          {allowCustomInstructions && (
            <Section>
              {customInstructionsLabel && (
                <SectionSublabel>{customInstructionsLabel}</SectionSublabel>
              )}
              <AutoResizeableMultilineInput
                disableAutoResize
                width="480px"
                placeholder={customInstructionsLabel}
                value={customInstructions}
                getValue={setCustomInstructions}
              />
            </Section>
          )}

          {allowBrandVoice ? (
            <>
              <CheckBoxContainer
                onClick={
                  brandVoice
                    ? () => setShouldUseBrandVoice((cur) => !cur)
                    : undefined
                }
                disabled={!brandVoice}
                data-tooltip-id="brand-voice-tooltip"
                data-tooltip-content={
                  brandVoice
                    ? undefined
                    : 'Add your brand voice on the Brand Kit page'
                }
              >
                <CheckBox isChecked={shouldUseBrandVoice} role="button">
                  {shouldUseBrandVoice && <CheckIconAlt />}
                </CheckBox>
                <div className="checkbox-text">Use my Brand Voice</div>
              </CheckBoxContainer>
              <Tooltip
                id="brand-voice-tooltip"
                style={{
                  width: '150px',
                  color: '#f3e9d7',
                  textAlign: 'center',
                  padding: '8px',
                  borderRadius: '4px',
                  fontSize: '12px',
                  lineHeight: '120%',
                  zIndex: '10',
                }}
              />
            </>
          ) : null}

          <WriteButton disabled={!selectedTemplate} onClick={handleConfirm}>
            <CreateIcon width="16" height="16" />
            {confirmButtonLabel}
          </WriteButton>
        </Content>
      </Wrapper>
    </Modal>
  );
};

const Wrapper = styled.div`
  border-radius: 10px;
  background-color: #03041a;
  width: 942px;
  border: 1px solid #484848;
`;

const Content = styled.div`
  max-width: 480px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 75px 0;
  background-color: #03041a;
`;

const Title = styled.h2`
  margin: 0;
  color: #f3e9d7;
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  align-self: center;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const SectionHeading = styled.h3`
  margin: 0;
  color: #f3e9d7;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;
`;

const SectionSublabel = styled.div`
  margin-bottom: -8px;
  color: #f3e9d7;
  font-size: 12px;
  line-height: 140%;
`;

const OptionList = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
`;

const OptionItem = styled.div<{ selected: boolean }>`
  display: flex;
  height: 32px;
  padding: 0 8px;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  border-radius: 8px;
  border: 1px solid;
  border-color: ${({ selected }) => (selected ? '#F2D093' : '#484848')};
  cursor: pointer;
  .optionLabel {
    color: ${({ selected }) => (selected ? '#F2D093' : '#484848')};
    font-size: 10px;
    font-weight: 500;
    line-height: 150%;
  }
  .checkEmpty {
    display: flex;
    width: 14px;
    height: 14px;
    padding: 2px;
    justify-content: center;
    align-items: center;
    border-radius: 80px;
    border: 1px solid #484848;
  }
  .checkIcon {
    width: 14px;
    height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #4ad067;
    border-radius: 14px;
  }
`;

const CheckBoxContainer = styled.div<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  gap: 8px;
  cursor: pointer;
  &,
  * {
    box-sizing: border-box;
  }
  .checkbox-text {
    color: #f3e9d7;
    font-size: 12px;
    line-height: 140%;
  }
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: default;
    `}
`;
const CheckBox = styled.div<{ isChecked: boolean }>`
  border: 0;
  outline: 0;
  background-color: ${(props) => (props.isChecked ? '#4AD067' : 'transparent')};
  width: 16px;
  height: 16px;
  border-radius: 2px;
  overflow: hidden;
  border: ${(props) => !props.isChecked && '1px solid #848484'};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const WriteButton = styled.button`
  margin-top: 8px;
  display: flex;
  height: 48px;
  padding: 0 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background: #17c964;
  border-radius: 8px;
  border: none;
  outline: none;
  cursor: pointer;
  color: #03041a;
  font-size: 14px;
  font-weight: 700;
  transition: 0.15s;
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export default AIProducerModal;
