import styled from 'styled-components';

import ContentButton from './ContentButton';

type Props = {
  hasBeenGenerated?: boolean;
  renderCreateNew?: () => React.ReactNode;
  onGenerate?: () => void;
  onRegenerate?: () => void;
  onShare?: () => void;
  renderShare?: () => React.ReactNode;
  onCopy?: () => void;
  onSave?: () => void;
  saveDisabled?: boolean;
  saveButtonLabel?: string;
};

const ContentActions = (props: Props) => {
  const {
    hasBeenGenerated,
    renderCreateNew,
    onGenerate,
    onRegenerate,
    onShare,
    renderShare,
    onCopy,
    onSave,
    saveDisabled,
    saveButtonLabel = 'Save',
  } = props;
  return (
    <Container>
      {hasBeenGenerated &&
        (renderCreateNew
          ? renderCreateNew()
          : onGenerate && (
              <ContentButton
                onClick={onGenerate}
                icon="create"
                buttonLabel="Create New"
              />
            ))}
      <RightActions>
        {!hasBeenGenerated && onGenerate && (
          <ContentButton onClick={onGenerate} icon="generate" />
        )}
        {hasBeenGenerated && onRegenerate && (
          <ContentButton
            onClick={onRegenerate}
            variant="outlined"
            icon="regenerate"
            buttonLabel="Regenerate"
          />
        )}
        {renderShare
          ? renderShare()
          : onShare && (
              <ContentButton
                onClick={onShare}
                variant="outlined"
                icon="share"
                buttonLabel="Share"
              />
            )}
        {onCopy && (
          <ContentButton
            onClick={onCopy}
            variant="outlined"
            icon="copy"
            buttonLabel="Copy"
          />
        )}
        {onSave && (
          <ContentButton
            onClick={onSave}
            disabled={saveDisabled}
            variant="outlined"
            icon="save"
            buttonLabel={saveButtonLabel}
          />
        )}
      </RightActions>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  gap: 16px;
  justify-content: space-between;
`;

const RightActions = styled.div`
  display: flex;
  gap: 8px;
  margin-left: auto;
`;

export default ContentActions;
