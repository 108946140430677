import { FunctionComponent, memo, useEffect, useRef, useState } from 'react';
import { produceClips } from '../../services/AIClipProducer';

import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';
import {
  useStore,
  useTemplatedPromptsStore,
} from '@src/stores-v2/StoreContext';

type ClipProducerProps = {
  ClipProducerUI: FunctionComponent<ClipProducerUIProps>;
  useFinalOrOriginalVideo?: boolean;
  supportedPresets: { title: string; category: string }[] | null;
};

export type ClipProducerUIProps = {
  supportedPresets: { title: string; category: string }[];
  isProducing: boolean;
  retryCount: number;
  selectedDuration: number | null;
  setSelectedDuration: (duration: number | null) => void;
  selectedPresets: string[];
  setSelectedPresets: (presets: string[]) => void;
  onPresetClick: (preset: string) => void;
  customPresetText: string;
  setCustomPresetText: (text: string) => void;
  selectedSocials: string[];
  setSelectedSocials: (socials: string[]) => void;
  producedClips: any[] | null;
  showModal: boolean;
  isProducingDisable: boolean;
  onProduceButtonClick: () => void;
  closeProducingModal: () => void;
};

const AIClipProducer = memo(
  (props: ClipProducerProps) => {
    const videoCreator = useVideoCreatorStore();
    const rootStore = useStore();
    const { ClipProducerUI, useFinalOrOriginalVideo = false } = props;
    const [selectedPresets, setSelectedPresets] = useState<string[]>([]);
    const [selectedSocials, setSelectedSocials] = useState<string[]>([]);
    const [selectedDuration, setSelectedDuration] = useState<number | null>(
      null,
    );
    const [isProducing, setIsProducing] = useState(false);
    const [producedClips, setProducedClips] = useState<any[] | null>(null);
    const producerAbortController = useRef<{
      id: string;
      aborted: boolean;
    } | null>(null);
    const [showModal, setShowModal] = useState(false);

    const MAX_RETRIES = 3;

    const [presetInputValue, setPresetInputValue] = useState<string>('');

    const [retryCount, setRetryCount] = useState<number>(0);
    const retryCountRef = useRef(retryCount);
    retryCountRef.current = retryCount;

    const onPresetClick = (preset: string) => {
      if (selectedPresets.includes(preset)) {
        setSelectedPresets((prev) => prev.filter((p) => p !== preset));
      } else {
        setSelectedPresets((prev) => [preset]); //  [...prev, preset]);
        setPresetInputValue('');
      }
    };

    const closeProducingModal = () => {
      if (producerAbortController.current && isProducing) {
        producerAbortController.current.aborted = true;
      }
      setProducedClips(null);
      setIsProducing(false);
      setShowModal(false);
    };

    const isProducingDisabled = () => {
      return (
        !(selectedPresets.length || presetInputValue) ||
        !selectedSocials.length ||
        isProducing ||
        !videoCreator.story?.originalVideo
      );
    };

    const onProduceButtonClick = async () => {
      if (
        !(selectedPresets.length || presetInputValue) ||
        !selectedSocials.length
      ) {
        return;
      }
      setIsProducing(true);
      setShowModal(true);
      producerAbortController.current = {
        id: Date.now().toString(),
        aborted: false,
      };

      const finalVideo = videoCreator.story?.finalVideo;

      if (
        useFinalOrOriginalVideo &&
        finalVideo?.id &&
        finalVideo.videoFilePrimary?.video
      ) {
        await videoCreator.loadVideoWithoutRendering(finalVideo.id, true);
        videoCreator.duration = finalVideo.videoFilePrimary!.video.duration;
      } else if (useFinalOrOriginalVideo) {
        await videoCreator.createNewVideoFromSource();
        videoCreator.duration = parseFloat(videoCreator.originalVideoDuration);
      }

      while (retryCountRef.current < MAX_RETRIES) {
        try {
          const matchedParts = await produceClips({
            videoCreator: videoCreator,
            originalVideo:
              videoCreator.currentVideo?.videoFilePrimary ||
              videoCreator.story!.originalVideo!,
            transcriptionText:
              videoCreator.videoTranscriptionProcessor.getFinalTranscriptionText(),
            transcriptionElements:
              (videoCreator.currentVideo?.videoFilePrimary
                ? videoCreator.finalTranscriptionElements
                : videoCreator.originalTranscription?.elements) || [],
            originalDuration: videoCreator.duration,
            duration: selectedDuration,
            theme: selectedPresets[0] || presetInputValue,
            platform: selectedSocials[0],
            onClipsProduced: (clips) => {
              setProducedClips((prevClips) =>
                clips.length !== prevClips?.length ? clips : prevClips,
              );
            },
            abortToken: producerAbortController.current,
            rootStore: rootStore,
          });
          console.log('ALL PRODUCED CLIPS', matchedParts);
          if (!matchedParts?.length) {
            setRetryCount((prev) => prev + 1);
          } else {
            setProducedClips(matchedParts);
            break;
          }
        } catch (err: any) {
          console.error(err);
          if (err.message === 'Aborted') {
            return;
          }
          setRetryCount((prev) => prev + 1);
        }
      }
      producerAbortController.current = null;
      setRetryCount(0);
      setIsProducing(false);
    };

    return (
      <ClipProducerUI
        supportedPresets={props.supportedPresets || []}
        isProducing={isProducing}
        retryCount={retryCount}
        selectedDuration={selectedDuration}
        setSelectedDuration={setSelectedDuration}
        selectedPresets={selectedPresets}
        setSelectedPresets={setSelectedPresets}
        onPresetClick={onPresetClick}
        customPresetText={presetInputValue}
        setCustomPresetText={setPresetInputValue}
        selectedSocials={selectedSocials}
        setSelectedSocials={setSelectedSocials}
        producedClips={producedClips}
        showModal={showModal}
        isProducingDisable={isProducingDisabled()}
        onProduceButtonClick={onProduceButtonClick}
        closeProducingModal={closeProducingModal}
      />
    );
  },
  (oldProps, newProps) => {
    return (
      oldProps.supportedPresets?.length === newProps.supportedPresets?.length
    );
  },
);

export default AIClipProducer;
