import { makeAutoObservable, computed } from 'mobx';
import type { RootStore } from './RootStore';
import type { UserIdentity, AuthConfig, DatoContext } from './types';
import type { User, SsoUser, Account } from 'datocms-plugin-sdk';
import type {
  UserResource,
  SessionResource,
  OrganizationResource,
} from '@clerk/types';

export class UserIdentityStore {
  datoContext: DatoContext | null = null;
  environment: string = '';
  clerkUser: UserResource | null = null;
  clerkSession: SessionResource | null = null;
  clerkToken: string | null = null;
  organization: OrganizationResource | undefined = undefined;

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this, {
      currentEditor: computed,
      currentUserType: computed,
      hasDatoContext: computed,
      currentIdentity: computed,
    });
  }

  setClerkContext(
    user: UserResource | null,
    session: SessionResource | null,
    token: string | null,
  ) {
    this.clerkUser = user;
    this.clerkSession = session;
    this.clerkToken = token;
  }

  setDatoContext(context: DatoContext | null) {
    this.datoContext = context;
    if (context?.environment) {
      this.environment = context.environment;
    }
  }

  setClerkUser(user: UserResource | null) {
    this.clerkUser = user;
  }

  setEnvironment(env: string) {
    this.environment = env;
  }

  setOrganization(org: OrganizationResource) {
    this.organization = org;
  }

  get hasDatoContext(): boolean {
    return !!this.datoContext;
  }

  getUserIdentity(): UserIdentity | null {
    // Handle Clerk user first
    if (this.clerkUser && this.clerkToken) {
      return {
        id: this.clerkUser.id,
        name: this.currentEditor,
        email: this.clerkUser.primaryEmailAddress?.emailAddress,
        type: 'external',
        token: this.clerkToken,
        source: 'clerk',
        organization: this.organization,
      };
    }

    // Handle Dato user
    if (this.datoContext?.currentUser) {
      const user = this.datoContext.currentUser;
      return {
        id: user.id,
        name: this.currentEditor,
        email: (user as User)?.attributes?.email,
        type: 'internal',
        token: this.datoContext.currentUserAccessToken,
        source: 'dato',
        role: this.datoContext.currentRole?.attributes?.name,
        organization: undefined,
      };
    }

    return null;
  }

  get currentIdentity(): UserIdentity | null {
    return this.getUserIdentity();
  }

  get currentEditor(): string {
    // Try Clerk first
    if (this.clerkUser) {
      const { fullName, firstName, lastName } = this.clerkUser;
      if (fullName) {
        return fullName;
      } else if (firstName) {
        return [firstName, lastName].filter(Boolean).join(' ');
      }
    }

    // Fall back to Dato
    const currentUser = this.datoContext?.currentUser;
    if (!currentUser) return 'Unknown';

    if ((currentUser as User)?.attributes?.full_name) {
      return (currentUser as User).attributes.full_name;
    }

    if ((currentUser as SsoUser | Account)?.attributes?.first_name) {
      return [
        (currentUser as SsoUser | Account)?.attributes?.first_name,
        (currentUser as SsoUser | Account)?.attributes?.last_name,
      ]
        .filter(Boolean)
        .join(' ');
    }

    return 'Unknown';
  }

  get currentUserType(): 'internal' | 'external' {
    if (this.datoContext?.currentUserAccessToken) {
      return 'internal';
    }

    if (!this.clerkUser && !this.datoContext) {
      console.error('UserIdentityStore: no user at all?');
    }

    return 'external';
  }

  isInternalUser(): boolean {
    return this.currentUserType === 'internal';
  }

  isExternalUser(): boolean {
    return this.currentUserType === 'external';
  }
}
