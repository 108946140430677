import { useEffect, useMemo, useState } from 'react';
import { useObserver } from 'mobx-react-lite';
import { uniqBy } from 'lodash';

import VideoCreatorStore from '../../../stores/VideoCreatorStore';
import { BrandingCustomFont } from '../../../types.ts/story';
import { proximaNovaFonts } from '../../../utility/general';

const LOADED_FONTS = new Set<string>();

const loadFontFace = async (font: BrandingCustomFont, fontFace: FontFace) => {
  const loadedFont = await fontFace.load();
  LOADED_FONTS.add(font.postScriptName);
  document.fonts.add(loadedFont);
};

const useLoadBrandKitFonts = (videoCreator: VideoCreatorStore) => {
  const renderer = useObserver(() => videoCreator.renderer);
  const rendererReady = useObserver(() => videoCreator.renderer?.ready);
  const customFonts = useObserver(() =>
    uniqBy(videoCreator.organization?.branding?.customFonts, 'postScriptName'),
  );

  const fontsToLoad = useMemo(
    () =>
      customFonts
        ?.filter((font) => !LOADED_FONTS.has(font.postScriptName))
        .map((font) => ({
          font,
          fontFace: new FontFace(font.fontFamily, `url(${font.source})`, {
            style: font.style,
            weight: font.usWeightClass.toString(),
          }),
        })),
    [customFonts],
  );

  // Load fonts to the browser
  useEffect(() => {
    if (!fontsToLoad?.length) return;
    fontsToLoad?.forEach(({ font, fontFace }) => {
      loadFontFace(font, fontFace);
    });
  }, [fontsToLoad]);

  // Load fonts to video source
  const [loadedToSource, setLoadedToSource] = useState(false);
  useEffect(() => {
    if (!renderer || !rendererReady || loadedToSource) return;
    const fn = async () => {
      let fontsConfig: {
        family: string;
        weight: number;
        style: string;
        source: string;
      }[] = proximaNovaFonts;

      if (customFonts && customFonts.length) {
        fontsConfig = [
          ...fontsConfig,
          ...customFonts.map((font) => ({
            family: font.fontFamily,
            weight: font.usWeightClass,
            style: font.style,
            source: font.source,
          })),
        ];
      }

      const source = renderer.getSource();
      if (source) {
        await renderer.setSource({
          ...source,
          fonts: fontsConfig,
        });
        setLoadedToSource(true);
      }
    };
    fn();
  }, [renderer, rendererReady, loadedToSource, customFonts]);
};

export default useLoadBrandKitFonts;
