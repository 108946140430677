import { observer } from 'mobx-react-lite';
import { FC, useState, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import styled from 'styled-components';

import { Artifact } from '../../types.ts/story';
import SpinningLoading from '../SpinningLoading';
import QuoteCard from './QuoteCard';

import { useFlagsCombination } from '../../utility/useFlagsCombination';
import { initializeContentStudioContent } from '../../utility/general';
import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';
import { useTemplatedPromptsStore } from '@src/stores-v2/StoreContext';
import { AIPrompt } from '@src/types.ts/ai_prompts';
import { analytics } from '@src/utility/analytics';
import ChatGPTService from '../../services/ChatGPTService';
import AIProducerModal from './AIProducerModal';
import ContentActions from './ContentActions';
import BrandVoiceRevisionModal from './BrandVoiceRevisionModal';

const PullQuotes: FC<{}> = observer(() => {
  const videoCreator = useVideoCreatorStore();
  const gptService = new ChatGPTService(videoCreator);
  const [isLoading, setIsLoading] = useState(false);
  const { story, unsavedShareableImages } = videoCreator;
  const { enableShareableImageRegeneration } = useFlagsCombination(
    videoCreator.datoContext.currentRole,
  );

  useEffect(() => {
    initializeContentStudioContent(videoCreator, story?.id, 'Quotes');
  }, [story?.id]);

  const contentGenerated = videoCreator.contentStudioGeneratedContent;

  const [loadingStock, setLoadingStock] = useState(false);

  let generatedContent = contentGenerated?.Quotes?.content?.response;
  const hasBeenGenerated = contentGenerated?.Quotes?.hasBeenGenerated;

  const artifacts = (
    story?.storyArtifacts?.map((artifact) => {
      if (artifact.responsiveImage) {
        return artifact;
      }
    }) || []
  ).filter(Boolean);
  const organizationArtifacts =
    story?._allReferencingShowcases?.flatMap((album) =>
      (album.organizationArtifacts || []).filter((a) => a.responsiveImage),
    ) || [];
  const assets = (
    story?.storyAssets?.map((asset) => {
      if (asset.responsiveImage) {
        return asset;
      }
    }) || []
  ).filter(Boolean);

  useEffect(() => {
    let quoteList = generatedContent as string[];
    if (typeof generatedContent === 'string') {
      quoteList = (generatedContent as string)
        ?.split('\n')
        .filter((q) => q.trim()) as string[];
    }

    let saved = story?.shareableImages || [];

    let unsaved: any = [];
    const stillImages = [...artifacts, ...assets, ...organizationArtifacts];
    for (let [index, quote] of (quoteList || []).entries()) {
      const image = (
        stillImages.length ? stillImages[index % stillImages.length] : null
      ) as Artifact | null;

      const isInCard = saved.some(
        (q) =>
          q.quote?.trim() === quote.trim() &&
          q.imagefile?.url === (image?.url || ''),
      );

      if (isInCard) continue;

      unsaved.push({
        id: uuid(),
        quote: quote?.replace(/^["'\s-]+|["'\s-]+$/g, '')?.trim(),
        storytellerName: story?.storyTeller?.name,
        ...(image && {
          imagefile: {
            ...image,
          },
        }),
      });
    }

    videoCreator.unsavedShareableImages = unsaved;
  }, [generatedContent]);

  const getLoadingText = () => {
    if (loadingStock) {
      return 'Loading stock photo...';
    }
    if (hasBeenGenerated) {
      return 'Regenerating shareable images...';
    }
    return 'Generating shareable images...';
  };

  const { aiShareableImagesTemplates } = useTemplatedPromptsStore();
  const [aiProducerOpen, setAiProducerOpen] = useState(false);
  const [promptTemplate, setPromptTemplate] = useState<AIPrompt | null>(null);
  const handleGenerate = async (
    template: AIPrompt,
    _brandVoice?: string,
    _customInstructions?: string,
  ) => {
    setIsLoading(true);
    analytics.track('generate_quotes', {
      storyId: story?.id,
      storyTitle: videoCreator.story?.title,
      brandVoice: _brandVoice,
      brandText: _customInstructions,
      template,
    });
    gptService.regenerateStreamResponse({
      key: 'Quotes',
      template,
      brandVoice: _brandVoice,
      brandText: _customInstructions,
      setLoading: setIsLoading,
    });
    setPromptTemplate(template);
  };

  const [revisionModalOpen, setRevisionModalOpen] = useState(false);
  const handleRegenerate = async (revision: string) => {
    setIsLoading(true);
    setRevisionModalOpen(false);
    analytics.track('regenerate_quotes', {
      storyId: story?.id,
      storyTitle: videoCreator.story?.title,
      template: promptTemplate,
      revision,
    });
    gptService.regenerateStreamResponse({
      key: 'Quotes',
      setLoading: setIsLoading,
      brandText: revision,
      template: promptTemplate!,
    });
  };

  return (
    <Main>
      {(isLoading || loadingStock) && (
        <SpinningLoading
          customStyle={{
            top: 0,
            position: 'fixed',
            alignItems: 'center',
          }}
          text={getLoadingText()}
        />
      )}
      {enableShareableImageRegeneration && (
        <ContentActions
          hasBeenGenerated={hasBeenGenerated}
          onGenerate={() => setAiProducerOpen(true)}
          onRegenerate={() => setRevisionModalOpen(true)}
        />
      )}
      <Quotes>
        {story?.shareableImages?.map((content) => (
          <QuoteCard
            key={content.id}
            id={content.id}
            text={content.quote}
            isSaved={true}
            imageFile={content.imagefile}
            storytellerName={content.storytellerName}
            setLoadingStock={setLoadingStock}
            allReferencingCaptions={content._allReferencingCaptions}
            allReferencingSharedContents={content._allReferencingSharedContents}
          />
        ))}
        {hasBeenGenerated &&
          unsavedShareableImages?.map((content, index) => (
            <QuoteCard
              key={index}
              text={content.quote}
              id={content.id}
              isSaved={false}
              imageFile={content.imagefile}
              storytellerName={content.storytellerName}
              setLoadingStock={setLoadingStock}
            />
          ))}
      </Quotes>

      {aiProducerOpen ? (
        <AIProducerModal
          title="Let’s find some quotes"
          templatesSublabel="Theme"
          templates={aiShareableImagesTemplates || []}
          onClose={() => setAiProducerOpen(false)}
          onConfirm={handleGenerate}
          allowBrandVoice={false}
          allowCustomInstructions
        />
      ) : null}

      {revisionModalOpen ? (
        <BrandVoiceRevisionModal
          title="Regenerate quotes"
          onClose={() => setRevisionModalOpen(false)}
          handleGenerate={handleRegenerate}
        />
      ) : null}
    </Main>
  );
});

export default PullQuotes;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  gap: 20px;
  .buttons {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
  }
`;

const Quotes = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin: 0 auto;
  @media only screen and (min-width: 1800px) {
    gap: 50px;
  }
`;
