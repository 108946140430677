import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import styled from 'styled-components';
import PaperPlaneIcon from '../../svgs/PaperPlaneIcon';
import type { VideoClip } from '../../types.ts/story';
import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';
import { useStore } from '@src/stores-v2/StoreContext';

type Props = {
  video: VideoClip;
};

const ClientReadyFlag = observer(({ video }: Props) => {
  const userIdentityStore = useStore().userIdentityStore;
  const videoCreator = useVideoCreatorStore();
  const [isApproved, setIsApproved] = useState(video.isClientReady);

  useEffect(() => {
    setIsApproved(video.isClientReady);
  }, [video.isClientReady]);

  const toggleApprove = () => {
    setIsApproved(!video.isClientReady);
    videoCreator.updateIsClientReady(!video.isClientReady, video.id);
  };

  if (userIdentityStore.currentUserType === 'external') {
    return null;
  }

  return (
    <Container onClick={toggleApprove} isApproved={isApproved}>
      <PaperPlaneIcon
        width="14"
        height="14"
        strokeColor={isApproved ? '#03041A' : '#484848'}
      />
      <Status isApproved={isApproved}>APPROVED</Status>
    </Container>
  );
});

const Container = styled.div<{
  isApproved?: boolean;
}>`
  background: #f2d093;
  border: 1px solid #f2d093;
  padding: 4px 8px 3px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  ${(props) =>
    !props.isApproved &&
    `
    background: inherit;
    border-color: #484848;
  `}
`;

const Status = styled.div<{
  isApproved?: boolean;
}>`
  font-size: 10px;
  font-family: Inter;
  font-weight: 700;
  line-height: 10px;
  padding-left: 8px;
  color: #03041a;
  ${(props) =>
    !props.isApproved &&
    `
    color:#484848;
    text-decoration: line-through;
  `}
`;

ClientReadyFlag.displayName = 'ClientReadyFlag';

export default ClientReadyFlag;
