import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';
import { Studio } from '@src/types.ts/general';
import { AssociatedVideo, VideoClip } from '@src/types.ts/story';
import { useNavigate, useSearchParams } from 'react-router-dom';

type ReturnValue = (video: VideoClip | AssociatedVideo) => void;

export function useNavigateToCreatorStudio(): ReturnValue {
  const videoCreator = useVideoCreatorStore();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const handleNavigate: ReturnValue = (video) => {
    searchParams.set('videoId', video.id!);
    videoCreator.videoClipPreview = null;
    videoCreator.setDuration(video.clipJson?.duration);
    // to prevent race condition where previous currentVideo possibly gets selected
    if (video.id !== videoCreator.currentVideo?.id) {
      videoCreator.currentVideo = undefined;
    }
    navigate({
      pathname: `/${Studio.creator}`,
      search: searchParams.toString(),
    });
  };

  return handleNavigate;
}
