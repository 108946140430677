import { gql, RequestDocument } from 'graphql-request';
import {
  AI_FLOW_QUERY_FRAGMENT,
  AI_PROMPT_QUERY_FRAGMENT,
} from './ai-prompts-gql';

export const PERSON_BY_EMAIL_QUERY: RequestDocument = `query getPersonByEmail($email: String!) {
  allPeople(filter: {email: {eq: $email}}) {
    id
    name
  }
}`;

export const PERSON_BY_NAME_QUERY: RequestDocument = `query getPersonByName($name: String!) {
  allPeople(filter: {name: {matches: {pattern: $name}}}) {
    id
    title
  }
}`;

export const TRANSCRIPTION_QUERY_FRAGMENT = `
{
  jobStatus
  audio {
    url
  }
  elementsJson {
    id
    url
  }
  waveformData {
    id
    url
  }
}`;

export const CUSTOMER_TYPE_QUERY_FRAGMENT = gql`
{
  clipProducerTemplates ${AI_FLOW_QUERY_FRAGMENT}
  blogTemplates ${AI_PROMPT_QUERY_FRAGMENT}
  emailTemplates ${AI_PROMPT_QUERY_FRAGMENT}
  shareableImagesTemplates ${AI_PROMPT_QUERY_FRAGMENT}
  talkingPointsTemplate ${AI_PROMPT_QUERY_FRAGMENT}
}
`;

export const CUSTOMER_TYPE_DEFAULT_TEMPLATES_QUERY = gql`
  query getDefaultCustomerType {
    customerType(filter: { title: { eq: "Default" } })
      ${CUSTOMER_TYPE_QUERY_FRAGMENT}
  }
`;
