import { makeAutoObservable } from 'mobx';
import { RootStore } from './RootStore';
import type { CustomerType, Showcase, Story } from '@src/types.ts/story';
import { STORY_QUERY } from '@src/gql/story-gql';
import {
  AIFlowQueryResult,
  AIPrompt,
  ClipProducerPreset,
} from '@src/types.ts/ai_prompts';
import { CUSTOMER_TYPE_DEFAULT_TEMPLATES_QUERY } from '@src/gql/misc-gql';

export class TemplatedPromptsStore {
  private rootStore: RootStore;

  clipProducerPresets: ClipProducerPreset[] = [];
  aiBlogTemplates: AIPrompt[] = [];
  aiEmailTemplates: AIPrompt[] = [];
  aiShareableImagesTemplates: AIPrompt[] = [];
  aiTalkingPointsTemplate: AIPrompt | null = null;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setClipProducerPresets(presets: ClipProducerPreset[]) {
    this.clipProducerPresets = presets;
  }

  setAiBlogTemplates(templates: AIPrompt[]) {
    this.aiBlogTemplates = templates;
  }

  setAiEmailTemplates(templates: AIPrompt[]) {
    this.aiEmailTemplates = templates;
  }

  setAiShareableImagesTemplates(templates: AIPrompt[]) {
    this.aiShareableImagesTemplates = templates;
  }

  setAiTalkingPointsTemplate(template: AIPrompt | null) {
    this.aiTalkingPointsTemplate = template;
  }

  async fetchTemplates(storyId: string) {
    const storyResponse = await this.rootStore.gqlClient.request<{
      story: Story;
    }>(STORY_QUERY, { id: storyId });

    const { customerType } = await this.rootStore.gqlClient.request<{
      customerType: CustomerType;
    }>(CUSTOMER_TYPE_DEFAULT_TEMPLATES_QUERY);
    const defaultTemplates = customerType;

    let clipProducerTemplates: AIFlowQueryResult[] =
      defaultTemplates.clipProducerTemplates || [];
    let aiBlogTemplates: AIPrompt[] = defaultTemplates.blogTemplates || [];
    let aiEmailTemplates: AIPrompt[] = defaultTemplates.emailTemplates || [];
    let aiShareableImagesTemplates: AIPrompt[] =
      defaultTemplates.shareableImagesTemplates || [];
    let aiTalkingPointsTemplate: AIPrompt | null =
      defaultTemplates.talkingPointsTemplate || null;

    const showcase = storyResponse.story._allReferencingShowcases[0];
    if (showcase?.customerType) {
      clipProducerTemplates =
        showcase.customerType?.clipProducerTemplates || clipProducerTemplates;
      aiBlogTemplates = showcase.customerType?.blogTemplates || aiBlogTemplates;
      aiEmailTemplates =
        showcase.customerType?.emailTemplates || aiEmailTemplates;
      aiShareableImagesTemplates =
        showcase.customerType?.shareableImagesTemplates ||
        aiShareableImagesTemplates;
      aiTalkingPointsTemplate =
        showcase.customerType?.talkingPointsTemplate || aiTalkingPointsTemplate;
    }

    const clipProducerPresets = clipProducerTemplates.map((flow) => ({
      title: flow.title,
      category: flow.category,
    }));

    this.setClipProducerPresets(clipProducerPresets);
    this.setAiBlogTemplates(aiBlogTemplates);
    this.setAiEmailTemplates(aiEmailTemplates);
    this.setAiShareableImagesTemplates(aiShareableImagesTemplates);
    this.setAiTalkingPointsTemplate(aiTalkingPointsTemplate);
  }
}
