import { LogLevel, buildClient } from '@datocms/cma-client-browser';
import { request } from '../utility/dato';
import VideoCreatorStore, {
  KARAOKE_TRACK_NUMBER,
} from '../stores/VideoCreatorStore';
import { ElementState } from '../renderer/ElementState';
import { Story, Subtitles } from '../types.ts/story';
import {
  TranscriptData,
  TranscriptPunctElement,
  TranscriptTextElement,
  TranscriptElement,
  TranscriptClipboard,
  TranscriptChange,
  TranscriptChangelog,
} from '../types.ts/transcript';

import { KaraokeConfig } from './types/karaokeTypes';
import { deepClone } from '../utility/deepClone';

const TRANSCRIPT_UPLOAD_QUERY = `query getTranscript($id: UploadId) {
    allUploads(filter: {id: {eq: $id}}) {
      id
      url
    }
  }`;

const UPLOAD_BY_TITLE_QUERY = `query getUpload($title: String!) {
    allUploads(filter: {title: {matches: {pattern: $title, caseSensitive: "false"}}}) {
      id
      url
      customData
    }
  }`;

const STORY_TRANSCRIPT_CHANGES_QUERY = `query getStory($id: ItemId) {
    story(filter: {id: {eq: $id}}) {
      transcriptionChanges
    }
  }`;

const ORIGINAL_VIDEO_TRANSCRIPTION_QUERY = `query getStory($id: ItemId) {
  story(filter: {id: {eq: $id}}) {
    id
    transcription {
      jobStatus
      elementsJson {
        id
        url
      }
    }
  }
}`;

export async function requestTranscriptionFromDato(
  videoCreator: VideoCreatorStore,
  storyId: string,
) {
  const queryResult: {
    story: Pick<Story, 'transcription' | 'id'>;
  } = await videoCreator.gqlClient!.request(
    ORIGINAL_VIDEO_TRANSCRIPTION_QUERY,
    {
      id: storyId,
    },
  );
  return queryResult.story.transcription;
}

export async function fetchTranscriptFromUrl(transcriptUrl: string) {
  const jsonResponse = await fetch(transcriptUrl);
  const transcriptData = (await jsonResponse.json()) as TranscriptData;
  if (!transcriptData.language) {
    transcriptData.language = 'en';
  }
  return transcriptData;
}

export async function generateSubtitles(
  transcriptionElements: TranscriptElement[],
  language: string,
) {
  const fetchUrl = `${process.env.REACT_APP_API_URL}/api/captions/get-translated`;
  const response = await fetch(fetchUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      transcriptionElements,
      language,
    }),
  });
  if (!response.ok) {
    console.error('Failed to fetch subtitles', response.json());
    return null;
  }
  const subtitlesLines = (await response.json()).translatedCaptions;
  return { lines: subtitlesLines } as Subtitles;
}

export async function fetchWaveformDataFromUrl(
  waveformUrl: string,
  environment: string,
) {
  const jsonResponse = await fetch(waveformUrl);
  return jsonResponse.json();
}

export async function fetchWaveformData(uploadId: string, environment: string) {
  const response: any = await request({
    query: TRANSCRIPT_UPLOAD_QUERY,
    variables: {
      id: uploadId,
    },
    environment: environment,
  });
  const waveformFile = response.allUploads[0];
  if (!waveformFile) {
    throw Error(`Waveform file not found`);
  }
  const jsonResponse = await fetch(waveformFile.url);
  return jsonResponse.json();
}

export async function fetchWaveformDataForAudioWithTitle(
  title: string,
  environment: string,
) {
  const response: any = await request({
    query: UPLOAD_BY_TITLE_QUERY,
    variables: {
      title,
    },
    environment: environment,
  });
  const audio = response.allUploads[0];
  const waveformFileId = audio?.customData?.waveformDataUploadId;
  const duration = parseFloat(audio?.customData?.duration) || 0;
  if (!waveformFileId) {
    throw Error(`Waveform file Id not found`);
  }
  return {
    waveformJson: await fetchWaveformData(waveformFileId, environment),
    duration,
  };
}

export function getClosestNotRemovedTextIndexToRight(
  startIdx: number,
  elements: TranscriptElement[],
  includeMuted: boolean = true,
) {
  let textIndexToRight = -1;
  while (startIdx < elements.length) {
    // console.log('check for text', startIdx);
    if (
      elements[startIdx].state !== 'removed' &&
      elements[startIdx].state !== 'cut' &&
      elements[startIdx].type === 'text' &&
      (includeMuted || elements[startIdx].state !== 'muted')
    ) {
      textIndexToRight = startIdx;
      break;
    }
    startIdx++;
  }

  return textIndexToRight;
}

export function getTimeBufferBefore(
  position: number,
  originalElements: TranscriptElement[],
) {
  if (originalElements[position].type !== 'text') {
    throw Error('Not a text element');
  }
  return (
    (originalElements[position] as TranscriptTextElement).buffer_before_ts || 0
  );
}

export function getTimeBufferAfter(
  position: number,
  originalElements: TranscriptElement[],
) {
  if (originalElements[position].type !== 'text') {
    throw Error('Not a text element');
  }
  return (
    (originalElements[position] as TranscriptTextElement).buffer_after_ts || 0
  );
}

export function getClosestNotRemovedElementIndexToLeft(
  startIdx: number,
  elements: TranscriptElement[],
  includeMuted: boolean = true,
  includeEmpty: boolean = false,
) {
  let textIndexToLeft = -1;
  while (startIdx >= 0) {
    if (
      elements[startIdx].state !== 'removed' &&
      elements[startIdx].state !== 'cut' &&
      (includeEmpty || elements[startIdx].value) &&
      (includeMuted || elements[startIdx].state !== 'muted')
    ) {
      textIndexToLeft = startIdx;
      break;
    }
    startIdx--;
  }

  return textIndexToLeft;
}

export function getClosestNotRemovedElementIndexToRight(
  startIdx: number,
  elements: TranscriptElement[],
  includeMuted: boolean = true,
  includeEmpty: boolean = false,
) {
  let textIndexToLeft = -1;
  while (startIdx < elements.length) {
    if (
      elements[startIdx].state !== 'removed' &&
      elements[startIdx].state !== 'cut' &&
      (includeEmpty || elements[startIdx].value) &&
      (includeMuted || elements[startIdx].state !== 'muted')
    ) {
      textIndexToLeft = startIdx;
      break;
    }
    startIdx++;
  }

  return textIndexToLeft;
}

export function getClosestElementIndexToLeftByFilter(
  startIdx: number,
  elements: TranscriptElement[],
  filter: (el: TranscriptElement, index: number) => boolean,
) {
  let textIndexToLeft = -1;
  while (startIdx >= 0) {
    if (filter(elements[startIdx], startIdx)) {
      textIndexToLeft = startIdx;
      break;
    }
    startIdx--;
  }

  return textIndexToLeft;
}

export function getClosestElementIndexToRightByFilter(
  startIdx: number,
  elements: TranscriptElement[],
  filter: (el: TranscriptElement, index: number) => boolean,
) {
  let textIndexToRight = -1;
  while (startIdx < elements.length) {
    if (filter(elements[startIdx], startIdx)) {
      textIndexToRight = startIdx;
      break;
    }
    startIdx++;
  }

  return textIndexToRight;
}

export function getClosestNotRemovedNotWhiteSpaceElementIndexToLeft(
  startIdx: number,
  elements: TranscriptElement[],
) {
  let textIndexToLeft = -1;
  while (startIdx >= 0) {
    if (
      elements[startIdx].state !== 'removed' &&
      elements[startIdx].state !== 'cut' &&
      elements[startIdx].value !== ' ' &&
      elements[startIdx].value !== ''
    ) {
      textIndexToLeft = startIdx;
      break;
    }
    startIdx--;
  }

  return textIndexToLeft;
}

export function getClosestNotRemovedNotWhiteSpaceElementToRight(
  startIdx: number,
  elements: TranscriptElement[],
) {
  let textIndexToLeft = -1;
  while (startIdx < elements.length) {
    if (
      elements[startIdx].state !== 'removed' &&
      elements[startIdx].state !== 'cut' &&
      elements[startIdx].value !== ' ' &&
      elements[startIdx].value !== ''
    ) {
      textIndexToLeft = startIdx;
      break;
    }
    startIdx++;
  }

  return textIndexToLeft;
}

export function getClosestNotRemovedTextIndexToLeft(
  startIdx: number,
  elements: TranscriptElement[],
) {
  let textIndexToLeft = -1;
  if (startIdx > elements.length) {
    startIdx = elements.length - 1;
  }
  while (startIdx >= 0) {
    // console.log('check for text (left)', startIdx);
    if (
      elements[startIdx].state !== 'removed' &&
      elements[startIdx].state !== 'cut' &&
      elements[startIdx].type === 'text'
    ) {
      textIndexToLeft = startIdx;
      break;
    }
    startIdx--;
  }

  return textIndexToLeft;
}

export function getClosestTextIndexToRight(
  startIdx: number,
  elements: TranscriptElement[],
  includeMuted: boolean = true,
) {
  let textIndexToRight = -1;
  while (startIdx < elements.length) {
    // console.log('check for text', startIdx);
    if (
      elements[startIdx].type === 'text' &&
      (includeMuted || elements[startIdx].state !== 'muted')
    ) {
      textIndexToRight = startIdx;
      break;
    }
    startIdx++;
  }

  return textIndexToRight;
}

export function getClosestTextIndexToLeft(
  startIdx: number,
  elements: TranscriptElement[],
) {
  let textIndexToLeft = -1;
  while (startIdx >= 0) {
    // console.log('check for text (left)', startIdx);
    if (elements[startIdx].type === 'text') {
      textIndexToLeft = startIdx;
      break;
    }
    startIdx--;
  }

  return textIndexToLeft;
}

export function getClosestRemovedIndexToLeft(
  startIdx: number,
  elements: TranscriptElement[],
) {
  let textIndexToLeft = -1;
  while (startIdx >= 0) {
    //todo better condition
    if (
      elements[startIdx].state === 'removed' ||
      elements[startIdx].state === 'cut' ||
      elements[startIdx].state === 'muted' ||
      !elements[startIdx].value
    ) {
      textIndexToLeft = startIdx;
      break;
    }
    startIdx--;
  }

  return textIndexToLeft;
}

export function getClosestRemovedIndexToRight(
  startIdx: number,
  elements: TranscriptElement[],
) {
  let textIndexToLeft = -1;
  while (startIdx < elements.length) {
    //todo better condition
    if (
      elements[startIdx].state === 'removed' ||
      elements[startIdx].state === 'cut' ||
      elements[startIdx].state === 'muted' ||
      !elements[startIdx].value
    ) {
      textIndexToLeft = startIdx;
      break;
    }
    startIdx++;
  }

  return textIndexToLeft;
}

function timeToFrame(time: number) {
  let seconds = Math.floor(time);
  let partial = time - seconds;
  let frames = Math.round(partial * 24);
  let minutes = Math.floor(seconds / 60);
  let final_seconds = Math.round(seconds - minutes * 60);
  return (
    ('00' + minutes).slice(-2) +
    ':' +
    ('00' + final_seconds).slice(-2) +
    ':' +
    ('00' + frames).slice(-2)
  );
}

export function getCutTimeBetweenTwoWords(
  firstElement?: TranscriptElement,
  nextElement?: TranscriptElement,
): number {
  if (firstElement && firstElement.type !== 'text')
    throw Error(`Not a word element ${firstElement}`);
  if (nextElement && nextElement.type !== 'text')
    throw Error(`Not a word element ${firstElement}`);
  if (!firstElement && !nextElement)
    throw Error(`At least one element required to calculate cut time`);
  if (!nextElement) return firstElement!.end_ts!;
  if (!firstElement) {
    return Math.max(0, nextElement.ts! - FIRST_WORD_CUT_BUFFER_SECONDS);
  }

  return firstElement.end_ts + (firstElement.buffer_after_ts || 0);
}

// TODO: Move to TranscriptManager
export function applyDeletionToTranscript(
  fromTs: number,
  toTs: number,
  inPlace: boolean,
  elements: TranscriptElement[],
): TranscriptChange | null {
  // if (fromTs > toTs) throw Error('toTs must be greater than fromTs');
  let fromIndex, toIndex;
  const fromWordIndex = elements.findIndex(
    (el) =>
      el.state !== 'removed' &&
      el.state !== 'cut' &&
      el.end_ts &&
      el.end_ts > fromTs &&
      el.ts < toTs,
  );
  const toWordIndex = elements.findIndex(
    (el) =>
      el.state !== 'removed' &&
      el.state !== 'cut' &&
      el.end_ts &&
      el.end_ts > toTs,
  );

  // todo: if fromWordIndex === toWordIndex
  if (fromWordIndex === -1) return null;

  // simple case, word is fully in the [fromTs; toTs] interval
  if (elements[fromWordIndex]?.ts! > fromTs) {
    fromIndex = fromWordIndex;
  } else {
    // todo choose between current and prev word
    const isCloserToEnd =
      fromTs - elements[fromWordIndex]?.ts! >
      elements[fromWordIndex]?.end_ts! - fromTs;
    fromIndex = isCloserToEnd
      ? getClosestNotRemovedTextIndexToRight(fromWordIndex + 1, elements)
      : fromWordIndex;
  }

  if (fromIndex === -1) return null;

  if (toWordIndex === -1) {
    toIndex = elements.length - 1; // till last word
  } else if (elements[toWordIndex]?.ts! > toTs) {
    toIndex = getClosestNotRemovedTextIndexToLeft(toWordIndex - 1, elements);
  } else {
    const isCloserToEnd =
      toTs - elements[toWordIndex]?.ts! > elements[toWordIndex]?.end_ts! - toTs;
    toIndex = isCloserToEnd
      ? toWordIndex
      : getClosestNotRemovedTextIndexToLeft(toWordIndex - 1, elements);
  }

  if (toIndex < fromIndex) {
    toIndex = fromIndex;
  }

  const timeBufferBefore = +(elements[fromIndex]?.ts! - fromTs).toFixed(3);
  let timeBufferAfter = +(toTs - elements[toIndex]?.end_ts!).toFixed(3);
  if (fromIndex === toIndex + 1) {
    timeBufferAfter = +(toTs - elements[fromIndex]?.ts!).toFixed(3);
  }

  return {
    version: 2,
    type: 'remove',
    index: fromIndex,
    count: toIndex - fromIndex + 1,
    oldValue: elements
      .slice(fromIndex, toIndex + 1)
      .map((el) => el.value || '')
      .join(''),
    newValue: null,
    timeBufferBefore,
    timeBufferAfter,
    inPlace,
    datetime: new Date().toISOString(),
  };
}

// TODO: Move to TranscriptManager
export function applyShiftingToTranscript(
  fromTs: number,
  toTs: number,
  intoTs: number,
  elements: TranscriptElement[],
): TranscriptChange | null {
  const filterRemoved = (el: TranscriptElement) =>
    el.state !== 'removed' && el.state !== 'cut';

  let fromIndex, toIndex, intoIndex;
  const fromWordIndex = elements.findIndex(
    (el) => filterRemoved(el) && el.end_ts && el.end_ts > fromTs,
  );
  const toWordIndex = elements.findIndex(
    (el) => filterRemoved(el) && el.end_ts && el.end_ts > toTs,
  );
  const afterWordIndex = elements.findIndex(
    (el) => filterRemoved(el) && el.end_ts && el.end_ts > intoTs,
  );

  if (fromWordIndex === -1) {
    return null;
    // todo empty shift
  }

  // simple case, word is fully in the [fromTs; toTs] interval
  if (elements[fromWordIndex]?.ts! >= fromTs) {
    fromIndex = fromWordIndex;
  } else {
    // todo choose between current and prev word
    const isCloserToEnd =
      fromTs - elements[fromWordIndex]?.ts! >
      elements[fromWordIndex]?.end_ts! - fromTs;
    fromIndex = isCloserToEnd
      ? getClosestNotRemovedTextIndexToRight(fromWordIndex + 1, elements)
      : fromWordIndex;
  }

  if (fromIndex === -1) return null;

  if (toWordIndex === -1) {
    toIndex = getClosestNotRemovedElementIndexToLeft(
      elements.length - 1,
      elements,
    ); // till last word
  } else if (elements[toWordIndex]?.ts! >= toTs) {
    toIndex = getClosestNotRemovedElementIndexToLeft(toWordIndex - 1, elements);
  } else {
    const isCloserToEnd =
      toTs - elements[toWordIndex]?.ts! > elements[toWordIndex]?.end_ts! - toTs;
    toIndex = isCloserToEnd
      ? toWordIndex
      : getClosestNotRemovedElementIndexToLeft(toWordIndex - 1, elements);
  }
  const closestBreakOrTextIndex = getClosestElementIndexToRightByFilter(
    Math.min(toIndex + 1, elements.length - 1),
    elements,
    (el) => filterRemoved(el) && (el.karaoke_break || el.type === 'text'),
  );
  toIndex = elements[closestBreakOrTextIndex]?.karaoke_break
    ? closestBreakOrTextIndex
    : toIndex;

  if (afterWordIndex === -1) {
    // into the end
    intoIndex = elements.length - 1;
  } else if (afterWordIndex >= fromIndex && afterWordIndex <= toIndex) {
    // todo check
    intoIndex = fromIndex;
  } else if (elements[afterWordIndex]?.ts! > intoTs) {
    intoIndex = afterWordIndex;
  } else {
    const isCloserToEnd =
      intoTs - elements[afterWordIndex]?.ts! >
      elements[afterWordIndex]?.end_ts! - intoTs;
    intoIndex = isCloserToEnd
      ? getClosestNotRemovedTextIndexToRight(afterWordIndex + 1, elements)
      : afterWordIndex;
  }

  return {
    type: 'shift',
    index: fromIndex,
    count: toIndex - fromIndex + 1,
    newIndex: intoIndex,
    timeShift: intoTs - fromTs,
    datetime: new Date().toISOString(),
  };
}

export const getRoundedTo = (time: number, precision: number) => {
  return Math.round(time / precision) / (1 / precision);
};

export function test_injectTranscriptionElementsInTimeline(
  transcriptionElements: TranscriptElement[],
) {
  const elements = [];
  // check if top track is free
  let track = KARAOKE_TRACK_NUMBER; //fix karaoke on top track
  for (let i = 0; i < transcriptionElements.length; i++) {
    const element = transcriptionElements[i];
    if (
      element.type !== 'text' ||
      element.state === 'removed' ||
      element.state === 'cut'
    )
      continue;
    const duration =
      element.end_ts - element.ts > 0 ? element.end_ts - element.ts : 0; //MIN DURATION
    elements.push({
      id: 'transcription-elemenet-' + i,
      track: track,
      globalTime: element.ts,
      localTime: element.ts,
      time: element.ts,
      duration,
      exitDuration: 0,
      type: 'text',
      text: element.value,
      source: {
        type: 'text',
        track: track,
        text: element.value,
      },
    });
  }
  return elements as unknown as ElementState[];
}

export const convertToPixels = (
  value: number,
  units: string,
  dimensions: { width: number; height: number },
) => {
  if (units === 'vh') {
    return (dimensions.height * value) / 100;
  }
  if (units === 'vw') {
    return (dimensions.width * value) / 100;
  }
  return value;
};

export const convertFromPixels = (
  value: number,
  toUnits: string,
  dimensions: { width: number; height: number },
) => {
  if (toUnits === 'vh') {
    return (100 * value) / dimensions.height;
  }
  if (toUnits === 'vw') {
    return (100 * value) / dimensions.width;
  }
  return value;
};

const FIRST_WORD_CUT_BUFFER_SECONDS = 0.2;

export const getMinMaxYPosition = (
  sourceHeight: number | undefined,
  selectedFontSize: string,
  lines?: 1 | 2 | 3 | 4 | 5 | 6 | 7,
  karaokeElement?: KaraokeConfig,
) => {
  let min = 0;
  let max = 100;
  let height = sourceHeight || 720;
  const fontSizeUnits = selectedFontSize.match(/[a-z]+/)?.[0] || 'px';
  const fontSize = parseFloat(selectedFontSize);
  if (karaokeElement?.instagramEffect && lines) {
    const smallFontSize = 1.1 * 0.8 * fontSize; // 1.25 - line height multiplier, 0.8 - multiplier for small line
    const largeFontSize = 1.1 * 1.25 * fontSize; // 1.25 - line height multiplier, 1.25 - multiplier for large line
    min =
      fontSizeUnits === 'px'
        ? convertFromPixels(largeFontSize / 2, 'vh', { height, width: 0 })
        : largeFontSize / 2;
    const largeLines = Math.floor((2 * lines) / 5);
    const smallLines = lines - largeLines;
    max =
      102 -
      (fontSizeUnits === 'px'
        ? convertFromPixels(
            largeLines * largeFontSize + smallLines * smallFontSize,
            'vh',
            { height, width: 0 },
          )
        : largeLines * largeFontSize + smallLines * smallFontSize);
  } else {
    min =
      fontSizeUnits === 'px'
        ? convertFromPixels(fontSize, 'vh', { height, width: 0 })
        : fontSize;
    max =
      100 -
      (fontSizeUnits === 'px'
        ? convertFromPixels(2 * fontSize, 'vh', { height, width: 0 })
        : 2 * fontSize);
  }
  return [min, max];
};

export function isSentenceEnd(value: string | null): boolean {
  return !!value?.match(/[\.\!\?]\s*$/);
}

export function capitalize(value: string) {
  return value.charAt(0).toLocaleUpperCase() + value.slice(1);
}

export function decapitalize(value: string) {
  return value.charAt(0).toLocaleLowerCase() + value.slice(1);
}

export function mapToElementState(el: any) {
  return {
    source: {
      ...el,
      elements: undefined,
    },
    elements: el.elements?.map(mapToElementState),
    duration: parseFloat(el.duration),
    globalTime: el.time,
    trimStart: parseFloat(el.trim_start || 0),
  };
}

export function mapToSource(state: Record<string, any>): Record<string, any> {
  if (!state) {
    return {};
  } else if (state.elements) {
    return {
      ...state.source,
      elements: state.elements.map((element: any) => mapToSource(element)),
    };
  } else {
    return state.source;
  }
}

export function adjustTrackNumbersToStartFromOne(source: any) {
  const newSource = deepClone(source);
  const minTrackNumber = Math.min(
    ...newSource.elements.map((el: any) => el.track),
  );
  if (minTrackNumber > 1) {
    newSource.elements.forEach((el: any) => {
      if (el.track !== KARAOKE_TRACK_NUMBER) {
        el.track -= minTrackNumber - 1;
      }
    });
  }
  return newSource;
}
