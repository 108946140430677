import { useRef, useState } from 'react';
import { VideoClip } from '../../types.ts/story';
import { getFormattedDurationForClip } from '../../utility/timeFormat';

import styled from 'styled-components';
import { ActionsWrapper, ActionWrapperRow } from '../../styles/mainStyle';
import EllipsisIcon from '../../svgs/EllipsisIcon';
import { useFlagsCombination } from '../../utility/useFlagsCombination';
import { EditIcon } from '../common/icons';
import ShareIcon from '../../svgs/ShareIcon';
import PencilIcon from '../../svgs/PencilIcon';
import { useOutsideAlerter } from '../transcript/useClickOutside';
import Modal from '../common/Modal';
import CopyContentModal from '../stage/CopyContentModal';
import SpinningLoading from '../SpinningLoading';
import CirclePlayIcon from '../../svgs/CirclePlayIcon';
import SocialShareStatus from './SocialShareStatus';
import SocialStats from './SocialStats';
import { observer } from 'mobx-react-lite';
import RemoveIcon from '../../svgs/RemoveIcon';
import {
  getAssociatedVideoWithStoryVideoField,
  getThumbnailTime,
} from '../../utility/videoClip';
import ClientReadyFlag from '../common/ClientReadyFlag';
import {
  getMuxHostedThumbnailUrl,
  getSelfHostedThumbnailUrl,
} from '../../utility/story';
import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';
import { useNavigateToCreatorStudio } from '@src/hooks/useNavigateToCreatorStudio';

type VideoContent = VideoClip & { autoPlay?: boolean };
type Props = {
  video: VideoContent;
};

const VideoClipCard = observer((props: Props) => {
  const videoCreator = useVideoCreatorStore();
  const [openRenameDropdown, toggleRenameDropdown] = useState<boolean>(false);
  const [contentToEdit, toggleContentToEditModal] = useState<{
    type: 'rename';
  } | null>(null);
  const [loadingSpinnerText, setLoadingSpinnerText] = useState<
    string | undefined
  >();

  const renameRef = useRef<HTMLDivElement>(null);
  const { enableVideoClipEdit, enableSharing } = useFlagsCombination(
    videoCreator.datoContext.currentRole,
  );
  const navigate = useNavigateToCreatorStudio();

  useOutsideAlerter(renameRef, () => {
    toggleRenameDropdown(false);
  });

  const { video } = props;
  const duration = getFormattedDurationForClip(video);
  const currentStory = videoCreator.story;
  const clips = [video, ...(video.associatedVideos || [])];
  const shareableClip = clips.find((clip) => clip.shareableImageId);

  let aspectRatio = shareableClip?.aspectRatio || video.aspectRatio;
  const getThumbnailUrl = () => {
    if (shareableClip) {
      if (video.shareableImageId) return video.thumbnail?.url;
      return shareableClip.thumbnail?.url;
    }

    let result =
      video.thumbnail?.url || video.videoFilePrimary?.video?.thumbnailUrl;

    if (!result) {
      const associatedVideo = getAssociatedVideoWithStoryVideoField(
        video,
        'thumbnailUrl',
      );
      if (associatedVideo) {
        result = associatedVideo?.videoFilePrimary?.video?.thumbnailUrl;
        aspectRatio = associatedVideo.aspectRatio;
      }
    }

    if (!result && currentStory!.useAws && video.clipJson) {
      result = getSelfHostedThumbnailUrl(
        currentStory?.originalVideo!,
        getThumbnailTime(video.clipJson),
      );
    } else if (!result && video.clipJson) {
      result = getMuxHostedThumbnailUrl(
        video.clipJson?.muxPlaybackId ||
          currentStory!.originalVideo.video.muxPlaybackId!,
        getThumbnailTime(video.clipJson),
      );
    }

    if (!result) {
      result = currentStory?.thumbnail?.url;
    }
    if (!result) {
      result = currentStory?.originalVideo?.video?.thumbnailUrl;
    }
    return result || '';
  };

  const thumbnailUrl = getThumbnailUrl();
  const description = videoCreator.story?.description || '';

  const shouldFillImage = () => {
    if (!shareableClip) return true;
    return !!video.shareableImageId;
  };
  const fillImage = shouldFillImage();

  async function loadVideoModal(autoPlay = false) {
    video.autoPlay = autoPlay;
    videoCreator.videoClipPreview = video;

    if (video.id) {
      videoCreator.videoLoaded = false;
      await videoCreator.loadVideo(video.id);
      videoCreator.videoLoaded = true;
    }
  }

  return (
    <>
      <Card>
        <KebabContainer>
          <Kebab
            ref={renameRef}
            onClick={() => toggleRenameDropdown(!openRenameDropdown)}
          >
            <EllipsisIcon />
            <ActionsWrapper isVisible={openRenameDropdown}>
              <RenameRow
                role="button"
                onClick={() => toggleContentToEditModal({ type: 'rename' })}
              >
                <span>Rename</span> <PencilIcon strokeColor="currentColor" />
              </RenameRow>
              <RemoveRow
                role="button"
                onClick={async () => {
                  setLoadingSpinnerText('Removing video');
                  await videoCreator.hideVideo(video.id);
                  setLoadingSpinnerText(undefined);
                }}
              >
                <span>Remove Clip</span>{' '}
                <RemoveIcon
                  width={'17'}
                  height={'18'}
                  strokeColor="currentColor"
                />
              </RemoveRow>
            </ActionsWrapper>
          </Kebab>
        </KebabContainer>
        <Icons>
          <DurationBadge>{duration.formattedDuration}</DurationBadge>
          <ClientReadyFlagContainer>
            <ClientReadyFlag video={video} />
          </ClientReadyFlagContainer>
          {enableSharing && (
            <SocialShareStatus
              sharedContents={video._allReferencingSharedContents}
              padding="0"
            ></SocialShareStatus>
          )}
        </Icons>
        <TitleContainer>
          <Title title={video.title}>{video.title}</Title>
        </TitleContainer>
        <ThumbnailWrapper onClick={() => loadVideoModal(true)}>
          <Thumbnail
            src={thumbnailUrl}
            alt="thumbnail"
            fill={fillImage}
            objectFit={aspectRatio === '9:16' ? 'contain' : 'cover'}
          />
          <div className="overlay"></div>
          <HoverPlayButton className="play-icon" type="button">
            <CirclePlayIcon />
          </HoverPlayButton>
        </ThumbnailWrapper>
        {enableSharing && (
          <SocialStats
            type="video"
            id={video.id}
            allReferencingSharedContents={video._allReferencingSharedContents}
            layout="wide"
          ></SocialStats>
        )}
        <Description>{description}</Description>
        <Buttons>
          {enableVideoClipEdit && (
            <EditButton
              onClick={() => {
                navigate(video);
              }}
            >
              <EditIcon />
              Edit
            </EditButton>
          )}

          <ShareButton onClick={() => loadVideoModal(false)}>
            <ShareIcon /> Preview
          </ShareButton>
        </Buttons>
      </Card>
      {contentToEdit && (
        <Modal
          isOpen={contentToEdit !== null}
          closeModal={() => toggleContentToEditModal(null)}
        >
          <CopyContentModal
            name={video.title! ?? ''}
            tag="Story"
            type={contentToEdit.type}
            onCancel={() => toggleContentToEditModal(null)}
            onSave={async (title) => {
              if (contentToEdit?.type === 'rename') {
                toggleContentToEditModal(null);
                setLoadingSpinnerText('Renaming video');
                await videoCreator.renameVideo(video.id!, title, false);
                setLoadingSpinnerText(undefined);
              }
            }}
          />
        </Modal>
      )}
      {loadingSpinnerText && (
        <SpinningLoading
          customStyle={{
            top: 0,
            position: 'fixed',
            alignItems: 'center',
          }}
          text={loadingSpinnerText}
        />
      )}
    </>
  );
});

export default VideoClipCard;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 392px;
  width: 392px;
  box-sizing: border-box;
  padding: 24px;
  border-radius: 10px;
  border: 1px solid #484848;
  position: relative;

  @media only screen and (max-width: 1000px) {
    width: 380px;
  }

  @media only screen and (max-width: 900px) {
    width: 360px;
  }

  @media only screen and (max-width: 450px) {
    width: 350px;
  }
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin: 5px 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ClientReadyFlagContainer = styled.div`
  margin-right: auto;
`;

const KebabContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 15.5px;
`;

const Kebab = styled.div`
  cursor: pointer;
  margin-left: 3px;
`;

const DurationBadge = styled.div`
  padding: 4px 8px;
  background-color: #f178b6;
  color: black;
  width: fit-content;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  margin: 0 3px 0 0;
`;

const Icons = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 8.5px 0;
`;

const Description = styled.div`
  font-size: 14px;
  text-align: center;
  margin: 5px 0;
`;

const ThumbnailWrapper = styled.div`
  margin: 5px 0;
  position: relative;
  height: 187px;
  display: flex;
  cursor: pointer;

  &:hover .overlay {
    background: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  &:hover .play-icon {
    display: block;
    cursor: pointer;
  }
`;

const HoverPlayButton = styled.button`
  position: absolute;
  top: 40%;
  left: 40%;
  display: none;
  background-color: transparent;
  outline: none;
  border: none;
`;

const Thumbnail = styled.img<{
  fill: boolean;
  objectFit: 'cover' | 'contain';
}>`
  width: ${(props) => (props.fill ? '100%' : 'auto')};
  margin: 0 auto;
  height: 187px;
  object-fit: ${(props) => props.objectFit};
  border-radius: 10px;
`;

const Buttons = styled.div`
  display: flex;
  gap: 8px;
  margin: 10px 0;
`;

const EditButton = styled.button`
  display: flex;
  height: 48px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid #484848;
  background: none;
  cursor: pointer;

  color: #f3e9d7;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
`;

const ShareButton = styled(EditButton)`
  background: #f2d093;
  color: black;
`;

const RenameRow = styled(ActionWrapperRow)`
  display: flex;
  gap: 25px;
  justify-content: space-between;
`;

const RemoveRow = styled(RenameRow)`
  &:hover {
    background-color: #ef5d6f;
  }
`;
