import { DatoContext } from '@src/stores-v2/types';

export class DatoContextService {
  static loadDatoContext(): DatoContext | undefined {
    try {
      const ctx =
        window.opener?.ctx ||
        JSON.parse(window.sessionStorage.getItem('ctx') || 'null');

      if (ctx?.currentUserAccessToken) {
        // Store Dato context in session storage
        window.sessionStorage.setItem(
          'ctx',
          JSON.stringify({
            currentUserAccessToken: ctx.currentUserAccessToken,
            currentRole: ctx.currentRole,
            currentUser: ctx.currentUser,
            environment: ctx.environment,
          }),
        );
        return ctx;
      }
      return undefined;
    } catch (error) {
      console.error('Error loading Dato context:', error);
      return undefined;
    }
  }
}
