import styled from 'styled-components';
import Modal from '../common/Modal';
import {
  OptionItem as OptionItemBase,
  OptionList as OptionListBase,
} from '../common/OptionList';
import { useState } from 'react';

type PhotoHuntSource = 'story' | 'org' | 'stock';

const SOURCE_OPTIONS: { text: string; value: PhotoHuntSource }[] = [
  {
    text: 'Story',
    value: 'story',
  },
  {
    text: 'Organizational',
    value: 'org',
  },
  {
    text: 'Stock',
    value: 'stock',
  },
];

export default function FeelingLuckyModal({
  closeModal,
  renderActions,
}: {
  closeModal: () => void;
  renderActions: (args: {
    sources: PhotoHuntSource[];
    disabled: boolean;
  }) => React.ReactNode;
}) {
  const [sources, setSources] = useState<PhotoHuntSource[]>([]);

  const toggleSource = (value: PhotoHuntSource) => {
    if (sources.includes(value)) {
      setSources(sources.filter((s) => s !== value));
    } else {
      setSources([...sources, value]);
    }
  };

  return (
    <Modal isOpen closeModal={closeModal}>
      <Wrapper>
        <Content>
          <Header>
            <ModalName>Feeling lucky</ModalName>
            <Title>Select photos</Title>
            <Hint>Select photo sources you’d like to use</Hint>
          </Header>
          <OptionList>
            {SOURCE_OPTIONS.map((o) => (
              <OptionItem
                isCurrent={sources.includes(o.value)}
                onClick={() => toggleSource(o.value)}
                key={o.value}
              >
                {o.text}
              </OptionItem>
            ))}
          </OptionList>
          <ActionsWrapper>
            {renderActions({ sources, disabled: sources.length === 0 })}
          </ActionsWrapper>
        </Content>
      </Wrapper>
    </Modal>
  );
}

const Wrapper = styled.div`
  display: inline-flex;
  padding: 48px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 48px;
  border-radius: 16px;
  border: 1px solid #484848;
  background: #03041a;
  /* Drop Shadow */
  box-shadow: 14px 8px 16px 0px rgba(0, 0, 0, 0.4);
`;

const Content = styled.div`
  display: flex;
  width: 440px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: 100%;
`;

const ModalName = styled.h1`
  width: 182px;
  height: 15px;
  color: #45d483;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.92px;
  text-transform: uppercase;
  margin: 0;
`;

const Title = styled.h2`
  align-self: stretch;
  color: #f3e9d7;
  text-align: center;
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 38.4px */
  margin: 0;
`;

const Hint = styled.h3`
  align-self: stretch;
  color: #f3e9d7;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 16.8px */
  margin: 0;
`;

const OptionList = styled(OptionListBase)`
  flex-wrap: wrap;
`;

const OptionItem = styled(OptionItemBase)`
  max-width: 216px;
  min-width: 216px;
`;

const ActionsWrapper = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
`;

export const ProceedButton = styled.button.attrs(
  (props: { disabled?: boolean }) => props,
)`
  flex: 1;
  display: flex;
  height: 48px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  border: none;
  border-radius: 8px;
  background: ${(props) => (props.disabled ? '#848484' : '#17C964')};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  color: #03041a;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
