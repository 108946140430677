import styled from 'styled-components';
import FeelingLuckyIcon from '../svgs/FeelingLuckyIcon';
import React from 'react';

type Props = {
  text?: string | React.ReactNode;
};

const SimpleLoadingSpinner = ({ text = 'Loading...' }: Props) => {
  return (
    <Content>
      <SpinnerWrapper>
        <Icon>
          <FeelingLuckyIcon strokeColor="#17c964" />
        </Icon>
        <Spinner></Spinner>
      </SpinnerWrapper>
      {text && <Text>{text}</Text>}
    </Content>
  );
};

export default SimpleLoadingSpinner;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const Text = styled.p`
  color: #f3e9d7;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
`;

const SpinnerWrapper = styled.div`
  width: 72px;
  height: 72px;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const Spinner = styled.div`
  width: 100%;
  height: 100%;
  border: 5px solid #484848;
  border-top: 5px solid #17c964;
  border-left: 5px solid #17c964;
  border-right: 5px solid #17c964;
  border-radius: 50%;
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Icon = styled.div`
  width: fit-content;
  position: absolute;
  top: calc(50% - 6px);
  left: calc(50% - 6px);
`;
