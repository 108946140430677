// @ts-nocheck
import React, { Fragment, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import styled, { css } from 'styled-components';
import { ElementState } from '../../renderer/ElementState';
import { KARAOKE_TRACK_NUMBER } from '../../stores/VideoCreatorStore';
import { Draggable } from '../Draggable';
import DebugModal from '../debug/DebugModal';
import { ResizeHandle } from './ResizeHandle';
import { TimelineAudioElement } from './TimelineAudioElement';
import { TimelineTextElement } from './TimelineTextElement';
import { Story } from '../../types.ts/story';
import TimelineBrollElement from './TimelineBrollElement';
import { runInAction } from 'mobx';
import TimelineStore from '@src/stores/TimelineStore';
import applyImageOptimizations from '../../utility/applyImageOptimizations';
import { inDebugMode } from '../../utility/debug';
import { getVolumeKeyPointsOf } from '../../utility/volumeKeyPoints';
import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';

interface TimelineElementProps {
  element: ElementState;
  allElements: ElementState[];
  story?: Story;
  type?: string;
  onContextMenu?: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    el: ElementState,
  ) => void;
  onElementCrtlClick: (element: ElementState) => void;
}

export const PADDING_LEFT_COMPENSATION = 14;

export const TimelineElement: React.FC<TimelineElementProps> = observer(
  (props) => {
    const videoCreator = useVideoCreatorStore();
    const timelineStore = videoCreator.timelineStore;
    const { element, story, type, allElements } = props;
    const overridenState =
      timelineStore.overrideTimelineElementsState[element.source.id];
    const active =
      videoCreator.activeElementIds.includes(element.source.id) ||
      element.track === videoCreator.selectedTrack;
    const timelineScale = videoCreator.timelineScale;
    const [isDragging, setIsDragging] = useState(false);
    const [isShiftDragging, setIsShiftDragging] = useState(false);
    const [thumbnailUrl, setThumbnailUrl] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedElement, setSelectedElement] = useState<ElementState | null>(
      null,
    );

    const nextElement = React.useMemo(
      () => allElements.find((el) => el.globalTime > element.globalTime),
      [allElements],
    );

    //@ts-ignore
    const prevElement = React.useMemo(
      () => allElements.findLast((el) => el.globalTime < element.globalTime),
      [allElements],
    );

    const volumeKeyPoints = getVolumeKeyPointsOf(videoCreator, element);

    useEffect(() => {
      if (element.source.type !== 'video') return;
      if (videoCreator.isOriginalVideoElement(element.source)) {
        setThumbnailUrl(story?.originalVideo?.video?.thumbnailUrl);
      } else {
        const storyVideo = story?.storyArtifactsVideo?.find(
          (s) => s.url === element.source.source,
        );
        setThumbnailUrl(storyVideo?.video?.thumbnailUrl || '');
      }
    }, []);

    const placement = { ...element, ...overridenState };
    const [volumeKeyPointsLocal, setVolumeKeyPointsLocal] =
      useState(volumeKeyPoints);

    useEffect(() => {
      setVolumeKeyPointsLocal(volumeKeyPoints);
    }, [volumeKeyPoints]);

    const applyPlacementAndKeyPoints = async (shiftDragging: boolean) => {
      timelineStore.applyPlacements();
    };

    const onTimeDurationChange = (time, trim_start, duration) => {
      // if time is shorter, get rid of any keypoints, and make sure last one is at the end
      // if time is longer, set last keypoint to be at the end
      const originalDuration = element.duration;
      const originalVolumeKeyPoints = volumeKeyPoints;
      // if (
      //   videoCreator.isOriginalVideoElement(element.source) &&
      //   (prevElement?.trimStart || 0) < trim_start &&
      //   (prevElement?.trimStart || 0) + (prevElement?.duration || 0) >
      //     trim_start
      // ) {
      //   const minTrimStart =
      //     (prevElement?.trimStart || 0) + (prevElement?.duration || 0);
      //   time = time + (minTrimStart - trim_start);
      //   duration = duration - (minTrimStart - trim_start);
      //   trim_start = minTrimStart;
      //   console.log('1', duration);
      //   // todo videoCreator.snapTime may create a gap?
      // } else if (
      //   videoCreator.isOriginalVideoElement(element.source) &&
      //   nextElement &&
      //   nextElement.trimStart > trim_start &&
      //   nextElement.trimStart < trim_start + duration
      // ) {
      //   const maxDuration = nextElement.trimStart - trim_start;
      //   duration = maxDuration;
      //   console.log('2', duration);
      // } else
      if (
        (videoCreator.isOriginalVideoElement(element.source) ||
          element.source.type === 'audio') &&
        element.mediaDuration &&
        element.mediaDuration + element.trimStart < duration + trim_start
      ) {
        duration = element.mediaDuration;
      }

      if (prevElement && prevElement.globalTime + prevElement.duration > time) {
        // todo videoCreator.snapTime
        trim_start += prevElement.globalTime + prevElement.duration - time;
        duration -= prevElement.globalTime + prevElement.duration - time;
        time = prevElement.globalTime + prevElement.duration;
      }

      if (nextElement && nextElement.globalTime < time + duration) {
        duration = nextElement.globalTime - time;
      }

      timelineStore.applyPlacementOverrideToElement(element.source.id, {
        globalTime: time,
        trimStart: trim_start,
        duration,
      });

      if (videoCreator.isImageElement(element)) {
        videoCreator.handleResetPhotoHighlight(element, time, duration);
      }

      if (
        (element.source.type === 'audio' || element.source.type === 'video') &&
        originalVolumeKeyPoints
      ) {
        let newVolumeKeyPoints = JSON.parse(
          JSON.stringify(originalVolumeKeyPoints),
        );
        let trimStartDiff = 0;
        // shift all key points to the right or left if trim_start changed
        if (trim_start !== element.source.trim_start) {
          trimStartDiff =
            trim_start - (parseFloat(element.source.trim_start) || 0);

          newVolumeKeyPoints = newVolumeKeyPoints.map((keyPoint: any) => {
            return {
              time: `${parseFloat(keyPoint.time) - trimStartDiff} s`,
              value: keyPoint.value,
            };
          });

          // filter out all but one key point that has a negative time and make that the first key point
          // unless there is already a keypoint at exactly 0
          newVolumeKeyPoints = newVolumeKeyPoints.filter(
            (keyPoint: any) => parseFloat(keyPoint.time) >= 0,
          );
          const zeroKeyPoint = newVolumeKeyPoints.find(
            (keyPoint: any) => parseFloat(keyPoint.time) === 0,
          );
          if (!zeroKeyPoint) {
            newVolumeKeyPoints.unshift({
              time: '0 s',
              value: originalVolumeKeyPoints[0].value,
            });
          }
        }

        // if duration is shorter, get rid of any keypoints, and make sure last one is at the end
        if (
          duration < originalDuration &&
          Math.abs(originalDuration - duration - trimStartDiff) > 0.0001
        ) {
          newVolumeKeyPoints = newVolumeKeyPoints.filter((keyPoint: any) => {
            return parseFloat(keyPoint.time) <= parseFloat(duration);
          });

          newVolumeKeyPoints.push({
            time: `${duration} s`,
            value:
              originalVolumeKeyPoints[originalVolumeKeyPoints.length - 1].value,
          });
        } else {
          newVolumeKeyPoints[newVolumeKeyPoints.length - 1] = {
            time: `${duration} s`,
            value:
              originalVolumeKeyPoints[originalVolumeKeyPoints.length - 1].value,
          };
        }

        setVolumeKeyPointsLocal(newVolumeKeyPoints);
      }
    };

    const onDrag = (e, data, context) => {
      e.preventDefault();
      const timeOffset = (data.x - context.startX) / timelineScale;
      if (e.type === 'mousemove') setIsDragging(true);
      if (e.shiftKey) setIsShiftDragging(true);
      if (!videoCreator.activeElementIds.includes(element.source.id)) {
        videoCreator.setActiveElement(element);
        timelineStore.applyPlacementOffsetToElements([element], timeOffset);
      } else {
        timelineStore.applyPlacementOffsetToActiveElements(timeOffset);
      }
    };

    let color = '#3f4043';
    switch (element.source.type) {
      case 'audio':
        color = `#7828C8`;
        break;
      case 'video':
        color = `#006FEE`;
        break;
      case 'image':
        color = videoCreator.isLogoElement(element) ? '#EFA65D' : `#17C964`;
        break;
      case 'text':
        color = '#CBD7F0';
      case 'composition':
        if (videoCreator.isImageElementComposition(element)) color = `#17C964`;
      default:
        break;
    }
    if (element.source.track === KARAOKE_TRACK_NUMBER) color = '#CBD7F0';

    const timelineElementWidth =
      (placement.duration - element.exitDuration) * timelineScale;

    const isBroll = !videoCreator.isOriginalVideoElement(element.source);

    return (
      <Fragment>
        {inDebugMode() && isModalOpen && (
          <DebugModal
            title="Timeline Element Debug"
            content={selectedElement}
            onClose={() => {
              setIsModalOpen(false);
            }}
          />
        )}
        <Draggable
          cancel={'.volume-key-point'}
          onStart={(e, data) => {
            e.preventDefault();
            return { startX: data.x };
          }}
          onDrag={onDrag}
          onStop={(e, data, context) => {
            e.preventDefault();
            videoCreator.openPhotoElementReplacementModal = false;
            if (!context) return;
            const timeOffset = (data.x - context.startX) / timelineScale;
            const time = Math.max(element.localTime + timeOffset, 0);
            if (videoCreator.isImageElement(element)) {
              videoCreator.handleResetPhotoHighlight(element, time);
            }

            applyPlacementAndKeyPoints(isShiftDragging);
            if (e.type === 'mouseup') {
              setTimeout(() => {
                setIsDragging(false);
                setIsShiftDragging(false);
              }, 100);
            }
          }}
        >
          {(ref) => (
            <Bar
              ref={ref}
              active={active}
              color={color}
              audio={element.source.type === 'audio'}
              linkedAudio={type === 'linked-audio'}
              isKaraoke={element.source.track === KARAOKE_TRACK_NUMBER}
              linkedVideo={
                element.source.type === 'video' &&
                type !== 'linked-audio' &&
                videoCreator.isOriginalVideoElement(element.source)
              }
              isText={
                element.source.type === 'text' ||
                element.source.track === KARAOKE_TRACK_NUMBER
              }
              isLogo={videoCreator.isLogoElement(element)}
              style={{
                left:
                  placement.globalTime * timelineScale +
                  PADDING_LEFT_COMPENSATION,
                width: timelineElementWidth,
              }}
              onClick={(e) => {
                if (isDragging) return;
                if (e.ctrlKey) {
                  props.onElementCrtlClick(element);
                  setSelectedElement(element);
                }
                videoCreator.setActiveElement(
                  element,
                  e.shiftKey,
                  e.detail > 1,
                );
              }}
            >
              {/* <Name>
                {element.source.name ??
                  element.source.type[0].toUpperCase() +
                    element.source.type.slice(1)}
              </Name> */}
              <ElementContainer
                imageUrl={
                  type !== 'linked-audio'
                    ? thumbnailUrl ||
                      applyImageOptimizations(
                        videoCreator.getImageElement(element)?.source?.source,
                        {
                          height: videoCreator.isLogoElement(element) ? 30 : 70,
                        },
                      )
                    : ''
                }
                audio={element.source.type === 'audio'}
                linkedAudio={type === 'linked-audio'}
                linkedVideo={
                  element.source.type === 'video' && type !== 'linked-audio'
                }
              >
                {element.source.type === 'audio' ? (
                  <TimelineAudioElement
                    element={element}
                    onContextMenu={props.onContextMenu}
                  />
                ) : element.source.type === 'video' ? (
                  isBroll ? (
                    <TimelineBrollElement
                      element={element}
                      thumbnail={thumbnailUrl}
                    />
                  ) : (
                    <>
                      {type === 'linked-audio' ? (
                        <TimelineAudioElement element={element} />
                      ) : null}
                      {!thumbnailUrl ? (
                        <VideoPlayer src={element.source.source} />
                      ) : null}
                    </>
                  )
                ) : element.source.type === 'text' ||
                  element.source.track === KARAOKE_TRACK_NUMBER ? (
                  <TimelineTextElement element={element} />
                ) : null}
              </ElementContainer>
            </Bar>
          )}
        </Draggable>
        {type !== 'linked-audio' ? (
          <>
            <ResizeHandle
              element={element}
              side="start"
              time={element.localTime}
              onChange={onTimeDurationChange}
              onComplete={applyPlacementAndKeyPoints}
            />
            <ResizeHandle
              element={element}
              side="end"
              time={element.localTime + element.duration}
              onChange={onTimeDurationChange}
              onComplete={applyPlacementAndKeyPoints}
            />
          </>
        ) : null}
      </Fragment>
    );
  },
);

const Bar = styled.div<{
  color: string;
  audio: boolean;
  linkedAudio: boolean;
  linkedVideo: boolean;
  isText: boolean;
  isLogo: boolean;
  isKaraoke: boolean;
  active: boolean;
}>`
  position: absolute;
  z-index: unset;
  height: ${(props) => {
    if (props.linkedAudio) return '36px';
    if (props.isText || props.isLogo) return '30px';
    if (props.linkedVideo) return '55px';
    return '70px';
  }};
  -webkit-box-align: center;
  align-items: center;
  ${(props) =>
    props.isText &&
    css`
      background: ${(props) => (props.active ? '#FFF' : props.color)};
    `}

  ${(props) =>
    props.isKaraoke &&
    css`
      background: ${(props) => (props.active ? '#17C964' : props.color)};
    `}

      ${(props) =>
    (!props.isKaraoke || !props.isText) &&
    css`
      background: ${(props) =>
        props.linkedVideo ? 'transparent' : props.color};
    `}

  border-top-left-radius: ${(props) => (!props.linkedAudio ? '6px' : '0px')};
  border-top-right-radius: ${(props) => (!props.linkedAudio ? '6px' : '0px')};
  border-bottom-left-radius: ${(props) => (!props.linkedVideo ? '5px' : '0px')};
  border-bottom-right-radius: ${(props) =>
    !props.linkedVideo ? '5px' : '0px'};
  display: flex;
  box-sizing: border-box;
  overflow: hidden;

  ${(props) =>
    props.active && !props.isKaraoke && !props.linkedAudio
      ? css`
          &::before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 20px;
            height: 20px;
            background-color: #fff;
            clip-path: polygon(0 0, 100% 0, 100% 100%);
            z-index: 1;
          }
        `
      : null}
  ${(props) =>
    props.active && !props.isKaraoke
      ? css`
          &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            border: 2px solid #fff;
            border-top-left-radius: ${!props.linkedAudio ? '6px' : '0px'};
            border-top-right-radius: ${!props.linkedAudio ? '6px' : '0px'};
            border-bottom-left-radius: ${!props.linkedVideo ? '5px' : '0px'};
            border-bottom-right-radius: ${!props.linkedVideo ? '5px' : '0px'};
            border-top-width: ${!props.linkedAudio ? '2px' : '0px'};
            border-bottom-width: ${!props.linkedVideo ? '2px' : '0px'};
            pointer-events: none;
          }
        `
      : null}
`;

const Name = styled.div`
  line-height: 30px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  pointer-events: none;
`;

const ElementContainer = styled.div<{
  audio: boolean;
  imageUrl?: string;
  linkedAudio: boolean;
  linkedVideo: boolean;
}>`
  pointer-events: none;
  height: 100%;
  width: ${(props) => (props.audio ? 'calc(100% + 4px)' : '100%')};
  background-image: ${(props) => `url("${props.imageUrl}")`};
  background-repeat: repeat-x;
  background-size: auto 100%;
  border-top-left-radius: ${(props) => (!props.linkedAudio ? '4px' : '0px')};
  border-top-right-radius: ${(props) => (!props.linkedAudio ? '4px' : '0px')};
  border-bottom-left-radius: ${(props) => (!props.linkedVideo ? '5px' : '0px')};
  border-bottom-right-radius: ${(props) =>
    !props.linkedVideo ? '5px' : '0px'};
  position: relative;
`;

const VideoPlayer = styled.video`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const Image = styled.img`
  margin-top: 7px;
  height: 80%;
  width: 100%;
  object-fit: cover;
`;
