import { makeAutoObservable } from 'mobx';
import type { RootStore } from './RootStore';
import { analytics } from '@src/utility/analytics';
import smartlook from 'smartlook-client';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';

export class AnalyticsStore {
  constructor(private rootStore: RootStore) {
    makeAutoObservable(this, {
      // rootStore: false,
    });
  }

  identifyUser() {
    const { userIdentityStore } = this.rootStore;
    const identity = userIdentityStore.currentIdentity;

    // TODO: Better env checlking, should be out of store too.
    if (process.env.NODE_ENV === 'production') {
      if (!identity) {
        console.warn('Attempting to identify analytics without user identity');
        return;
      }

      const userData = {
        id: identity.id,
        email: identity.email,
        name: identity.name,
        type: identity.type,
        source: identity.source,
      };

      console.log('Identifying user:', userData);

      // Datadog
      datadogRum.setUser(userData);
      datadogLogs.setUser(userData);

      // Segment
      analytics.identify(identity.id, userData);

      // Smartlook
      if (process.env.NODE_ENV === 'production') {
        // @ts-ignore
        smartlook.identify(identity.id, userData);
      }
    }
  }

  setContext(storyId: string, orgSlug: string | undefined) {
    // Datadog context
    datadogRum.setGlobalContextProperty('organization', orgSlug);
    datadogLogs.setGlobalContextProperty('organization', orgSlug);
    datadogRum.setGlobalContextProperty('storyId', storyId);
    datadogLogs.setGlobalContextProperty('storyId', storyId);

    // Segment page view
    analytics.page();
  }

  initSmartlook() {
    if (process.env.NODE_ENV === 'production') {
      smartlook.init(
        process.env.REACT_APP_SMARTLOOK_CONTENT_STUDIO_PROJECT_KEY as string,
      );
    }
  }
}
