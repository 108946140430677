import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { withClerkProvider } from './withClerkProvider';
import {
  RedirectToSignIn,
  useAuth,
  useClerk,
  useSession,
  useUser,
} from '@clerk/clerk-react';

// Stores and Hooks
import { useStore } from '@src/stores-v2/StoreContext';
import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';
import { useStoreInitialization } from '@src/hooks/useStoreInitialization';
import { useLaunchDarklyInit } from '@src/hooks/useLaunchDarklyInit';

// Components
import { VideoCreator } from '../components/VideoCreator';
import ContentStudio from '../components/ContentStudio';
import BrandKit from '../components/BrandKit';
import RootLayout from './RootLayout';
import Dashboard from '../components/Dashboard/';
import BrollPage from '../components/BrollPage';

// Services
import { DatoContextService } from '@src/services/DatoContextService';
import { RenderModalCtx } from 'datocms-plugin-sdk';
import SimpleLoadingSpinner from '@src/components/SimpleLoadingSpinner';
import { useAuthInitialization } from '@src/hooks/useAuthInitialization';

const EditorEntry = observer(() => {
  const rootStore = useStore();
  const videoCreator = useVideoCreatorStore();
  const { analyticsStore } = rootStore;

  // Clerk hooks
  const clerk = useClerk();
  const { session, isLoaded: isSessionLoaded } = useSession();
  const { user, isLoaded: isUserLoaded } = useUser();
  const { orgRole, orgSlug } = useAuth();
  console.debug('Clerk Provider State:', {
    isSessionLoaded,
    isUserLoaded,
    sessionStatus: session?.status,
    hasUser: !!user,
    clerkLoaded: clerk.loaded,
  });

  // External services
  const ldClient = useLDClient();

  // URL Parameters
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const { storyId, videoId, env } = params;

  // Load Dato context
  const datoCtx = React.useMemo(() => DatoContextService.loadDatoContext(), []);

  const { isAuthReady, needsRedirect, hasValidAuth } = useAuthInitialization({
    datoCtx,
    clerkUser: user,
    clerkSession: session,
    isClerkLoaded: isSessionLoaded && isUserLoaded,
  });

  // Initialize stores
  const { isReady, userIdentity } = useStoreInitialization({
    storyId,
    env,
    datoCtx,
    clerkUser: user,
    clerkSession: session,
    isClerkLoaded: isSessionLoaded && isUserLoaded,
    hasValidAuth,
  });

  // Initialize LaunchDarkly
  useLaunchDarklyInit({
    ldClient,
    storyId,
    clerkUser: user,
    isClerkLoaded: isSessionLoaded,
    orgRole,
    orgSlug,
  });

  // Set analytics context
  useEffect(() => {
    if (isReady && storyId) {
      const org = orgSlug || 'unknown';
      analyticsStore.setContext(storyId, org);
    }
  }, [isReady, storyId, orgSlug]);

  // Link videoCreatorStore
  // TODO: Refactor when we remove linkFunctions from videoCreatorStore
  useEffect(() => {
    const linkAndFetch = async () => {
      if (datoCtx) {
        // Internal user flow (Dato)
        videoCreator.linkFunctions({
          environment: env,
          currentUserAccessToken: datoCtx.currentUserAccessToken,
          currentUser: datoCtx.currentUser,
          currentRole: datoCtx.currentRole,
        } as RenderModalCtx);

        rootStore.initializeApiClients(
          videoCreator.datoClient,
          videoCreator.gqlClient,
        );
        rootStore.templatedPromptsStore.fetchTemplates(storyId);
      } else if (isSessionLoaded && session?.status === 'active') {
        // External user flow (Clerk)
        const token = await session.getToken();
        if (token) {
          videoCreator.linkFunctions({
            environment: env,
            currentUserAccessToken: undefined,
            clerkUserSessionToken: token,
            currentUser: session as any,
            currentRole: null as any,
          } as any as RenderModalCtx);

          rootStore.initializeApiClients(
            videoCreator.datoClient,
            videoCreator.gqlClient,
          );
          rootStore.templatedPromptsStore.fetchTemplates(storyId);
        }
      }
    };

    linkAndFetch();
  }, [
    datoCtx,
    isSessionLoaded,
    session,
    env,
    storyId,
    rootStore,
    videoCreator,
  ]);

  // Router configuration
  const videoCreatorElement = (
    <RootLayout>
      <VideoCreator />
    </RootLayout>
  );

  const router = createBrowserRouter([
    {
      path: '/',
      element: videoCreatorElement,
    },
    {
      path: '/creator',
      element: videoCreatorElement,
    },
    {
      path: '/content',
      element: (
        <RootLayout>
          <ContentStudio params={params} />
        </RootLayout>
      ),
    },
    {
      path: '/story-manager',
      element: (
        <RootLayout>
          <Dashboard params={params} />
        </RootLayout>
      ),
    },
    {
      path: '/broll',
      element: <BrollPage />,
    },
    {
      path: '/brand-kit',
      element: (
        <RootLayout>
          <BrandKit params={params} />
        </RootLayout>
      ),
    },
  ]);

  if (clerk.loaded && needsRedirect) {
    return (
      <>
        <RedirectToSignIn />
      </>
    );
  }

  if (!isReady) {
    return (
      <>
        <LoadingScreen>
          <SimpleLoadingSpinner text="Loading..." />
        </LoadingScreen>
      </>
    );
  }

  return <RouterProvider router={router} />;
});

const LoadingScreen = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: #03041a;
`;

// Provider composition
export default EditorEntry;
