import React, { Fragment } from 'react';
import { ElementState } from '../../renderer/ElementState';
import { VideoPreset } from './VideoPreset';
import { PropertyCaption } from './PropertyCaption';
import { PropertySelect } from './PropertySelect';
import { PropertyNumericalInput } from './PropertyNumericalInput';

import styled from 'styled-components';
import { ElementAnimationSettings } from './ElementAnimationSettings';
import { numericalInputStyles } from '../../styles/mainStyle';
import { GenericAnimationSettings } from './GenericAnimationSettings';
import FadeProducer from '../../fadeEffectProcessor/FadeProducer';
import { FIT_DEFAULT } from '../../config/videoSettings';
import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';

interface VideoSettingsProps {
  activeElement: ElementState;
}

export const VideoSettings: React.FC<VideoSettingsProps> = (props) => {
  const videoCreator = useVideoCreatorStore();
  const isBroll = !videoCreator.isOriginalVideoElement(
    props.activeElement.source,
  );
  const fadeProducer = new FadeProducer(videoCreator, props.activeElement);

  return (
    <Fragment>
      <VideoPreset
        activeElement={props.activeElement}
        url={props.activeElement.source.source}
      />

      <ElementAnimationSettings activeElement={props.activeElement} />

      <GroupContent>
        <Item>
          <PropertyCaption>Start</PropertyCaption>
          <PropertyNumericalInput
            activeElement={props.activeElement}
            propertyName="time"
            defaultValue="auto"
            unit="s"
            customStyles={numericalInputStyles}
            getInputValue={async (property: string, value: string) => {
              const newVideoOverlays = await fadeProducer.resetCrossfadeOnVideo(
                parseFloat(value),
                props.activeElement.duration,
              );
              await videoCreator.applyVideoStateModifications(
                {
                  ...newVideoOverlays,
                  [`${props.activeElement?.source.id}.${property}`]: value,
                },
                true,
                'changing video placement',
              );
            }}
          />
        </Item>

        <Item>
          <PropertyCaption>Duration</PropertyCaption>
          <PropertyNumericalInput
            activeElement={props.activeElement}
            propertyName="duration"
            defaultValue="auto"
            unit="s"
            customStyles={numericalInputStyles}
            getInputValue={async (property: string, value: string) => {
              const newVideoOverlays = await fadeProducer.resetCrossfadeOnVideo(
                props.activeElement.source.time,
                parseFloat(value),
              );
              await videoCreator.applyVideoStateModifications(
                {
                  ...newVideoOverlays,
                  [`${props.activeElement?.source.id}.${property}`]: value,
                },
                true,
                'changing video placement',
              );
            }}
          />
        </Item>

        <Item>
          <PropertyCaption>Fit</PropertyCaption>
          <PropertySelect
            activeElement={props.activeElement}
            propertyName="fit"
            defaultValue={FIT_DEFAULT}
            options={[
              { caption: 'Cover', value: 'cover' },
              { caption: 'Contain', value: 'contain' },
              { caption: 'Fill', value: 'fill' },
            ]}
          />
        </Item>
        {isBroll && (
          <Item>
            <PropertyCaption>Volume</PropertyCaption>
            <PropertyNumericalInput
              activeElement={props.activeElement}
              propertyName="volume"
              defaultValue="100"
              unit="%"
              customStyles={numericalInputStyles}
            />
          </Item>
        )}
      </GroupContent>

      <GenericAnimationSettings activeElement={props.activeElement} />

      <GroupContent>
        <Item>
          <PropertyCaption>Audio Fade In</PropertyCaption>
          <PropertyNumericalInput
            activeElement={props.activeElement}
            propertyName="audio_fade_in"
            defaultValue="0"
            unit="s"
            customStyles={numericalInputStyles}
          />
        </Item>
        <Item>
          <PropertyCaption>Audio Fade Out</PropertyCaption>
          <PropertyNumericalInput
            activeElement={props.activeElement}
            propertyName="audio_fade_out"
            defaultValue="0"
            unit="s"
            customStyles={numericalInputStyles}
          />
        </Item>
      </GroupContent>
    </Fragment>
  );
};

const GroupContent = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 10px;
`;

const Item = styled.div``;
