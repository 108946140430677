import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { observer } from 'mobx-react-lite';
import RepublishOnSaveModal from './RepublishOnSaveModal';
import RetrySavingModal from './RetrySavingModal';
import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';
import { analytics } from '@src/utility/analytics';

export const SaveButton: React.FC = observer(() => {
  const videoCreator = useVideoCreatorStore();
  const isSaving = videoCreator.isSaving;
  const isEnabled = videoCreator.isVideoCreatorReady;
  const savingError = videoCreator.savingError;
  const [showRepublishModal, setShowRepublishModal] = useState(false);

  const handleSaveData = async (asFinal: boolean) => {
    if (isSaving) return;

    analytics.track('save_video', {
      storyId: videoCreator.story?.id,
      storyTitle: videoCreator.story?.title,
      videoId: videoCreator.currentVideo?.id,
      videoTitle: videoCreator.currentVideo?.title,
      asFinal,
    });

    videoCreator.currentVideo!.punchList =
      videoCreator.punchListManager.punchListItems!;
    const resetTimeline = false;
    const withRenderer = true;
    let showRepublishModal = false;
    if (
      videoCreator.renderingStatus === 'rendering' ||
      videoCreator.renderQueueing
    ) {
      showRepublishModal = true;
    }
    await videoCreator.saveStoryAndVideo(asFinal, withRenderer, resetTimeline);
    if (showRepublishModal) {
      setShowRepublishModal(true);
    }
  };

  return (
    <>
      <Main
        disabled={!isEnabled}
        active={isSaving}
        onClick={async () => {
          await handleSaveData(false);
        }}
      >
        {isSaving ? 'Saving' : 'Save'}
      </Main>
      {showRepublishModal && (
        <RepublishOnSaveModal closeModal={() => setShowRepublishModal(false)} />
      )}
      {savingError && (
        <RetrySavingModal
          closeModal={() => (videoCreator.savingError = undefined)}
        />
      )}
    </>
  );
});

const Main = styled.div.attrs(
  (props: { active: boolean; disabled: boolean }) => props,
)`
  box-sizing: border-box;
  height: 32px;
  min-width: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #848484;
  color: #848484;
  font-size: 10px;
  font-weight: 700;
  cursor: pointer;
  padding: 8px;
  transition: 150ms;
  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}
  &:hover {
    border-color: #f2d093;
    color: #f2d093;
  }
  ${(props) =>
    props.active &&
    css`
      border-color: #f2d093;
      color: #f2d093;
    `}
`;
