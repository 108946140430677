import { toPng } from 'html-to-image';

import { BlogOrEmailContent, MediaSubType, Studio } from '../types.ts/general';
import {
  Artifact,
  Caption,
  ContentViewData,
  FileData,
  PhotoArtifactTab,
  PhotoAsset,
  PhotoTab,
  Story,
  VideoClip,
} from '../types.ts/story';
import TurndownService from 'turndown';
import { v4 as uuid } from 'uuid';
import { AssetRepository } from '../repositories/AssetRepository';
import { SimpleSchemaTypes } from '@datocms/cma-client-browser';
import { AspectRatio } from '../types.ts/video';
import VideoCreatorStore from '@src/stores/VideoCreatorStore';

const turndownService = new TurndownService();

export function delay(t: number) {
  return new Promise(function (resolve) {
    setTimeout(resolve, t);
  });
}

export function retry<T>(
  fn: () => Promise<T>,
  retries = 3,
  delayTime = 2000,
  err?: Error,
): Promise<T> {
  if (!retries) {
    return Promise.reject(err);
  }

  return delay(delayTime).then(() =>
    fn().catch((err: Error) => {
      return retry(fn, retries - 1, delayTime * 2, err);
    }),
  );
}

export function chunkArray<T>(array: T[], chunkSize: number): T[][] {
  return Array.from({ length: Math.ceil(array.length / chunkSize) }, (_, i) =>
    array.slice(i * chunkSize, i * chunkSize + chunkSize),
  );
}

export function randomString(length: number) {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export const handleCopyToClipboard = async (text: string, format = 'plain') => {
  try {
    const clipboardItem = new ClipboardItem({
      [`text/${format}`]: new Blob([text], { type: `text/${format}` }),
    });

    await navigator.clipboard.write([clipboardItem]);
  } catch (err) {}
};

export function getRandomFileName(inputString: string) {
  if (!inputString) return '';
  const words = inputString.split(/\s+/);
  const filteredWords = words.filter((word) => word.length > 4);

  const numberOfWords = filteredWords.length >= 3 ? 3 : 2;

  if (filteredWords.length < numberOfWords) {
    return inputString;
  }

  const randomIndices: number[] = [];

  while (randomIndices.length < numberOfWords) {
    const randomIndex = Math.floor(Math.random() * filteredWords.length);

    if (!randomIndices.includes(randomIndex)) {
      randomIndices.push(randomIndex);
    }
  }

  const randomWords = randomIndices.map((index) => filteredWords[index]);
  const randomNumber = Math.floor(Math.random() * 1000);

  return `${randomWords.join('_')}_${randomNumber}`;
}

export async function handleDownloadMedia<
  T extends { url: string; fileName?: string; id?: string },
  E extends HTMLElement,
>(e: React.MouseEvent<E, MouseEvent>, media: T) {
  e.preventDefault();
  e.stopPropagation();
  try {
    const url = media.url;
    const fileName = media.fileName;
    const link = document.createElement('a');
    let blob = await fetch(url).then((r) => r.blob());

    link.href = globalThis.URL.createObjectURL(blob);
    link.download = fileName || media?.id || '';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.log(error);
  }
}

export const getPosterUrl = (
  url: string,
  width: number,
  height: number,
  fit = true,
) => {
  if (!url) return;

  const imgixParts = {
    w: width,
    h: height,
    ...(fit && { fit: 'crop' }),
    q: 95,
    // fit: 'fill',
    // fill: 'blur',
  };
  return (
    url +
    '?' +
    Object.entries(imgixParts)
      .map(([k, v]) => `${k}=${v}`)
      .join('&')
  );
};

export const shouldWhitelist = (
  flags: (boolean | string)[],
  enterprisePlatform: string[],
) => {
  //const showcases = videoCreator.story?._allReferencingShowcases;

  //const showcaseIds = showcases?.map((s) => s.id) || [];
  return flags?.some((s) => s) || enterprisePlatform?.some((s) => s) || false;
};
export async function handleRemoveMedia<
  T extends PhotoArtifactTab | Omit<MediaSubType, MediaSubType.all>,
>(
  videoCreator: VideoCreatorStore,
  mediaId: string,
  type: T,
  toggleDropdown = (e: boolean) => {},
) {
  try {
    let artifacts = videoCreator.story?.storyArtifacts || [];
    let assets = videoCreator.story?.storyAssets || [];
    let aiPhotos = videoCreator.story?.aiPhotos || [];
    let videoArtifacts = videoCreator.story?.storyArtifactsVideo || [];
    let albumIndex;

    let resource;

    const narrowedType = type as unknown as PhotoArtifactTab | MediaSubType;

    switch (narrowedType) {
      case PhotoArtifactTab.story:
      case MediaSubType.artifact:
        artifacts = artifacts.filter((artifact) => artifact.id !== mediaId);
        resource = artifacts;
        break;
      case PhotoArtifactTab.album:
        const albumIdx =
          videoCreator.story?._allReferencingShowcases?.findIndex(
            (a) => a.organizationArtifacts?.some((v) => v.id === mediaId),
          );
        if (albumIdx !== undefined && albumIdx > -1) {
          videoCreator.story!._allReferencingShowcases[
            albumIdx
          ].organizationArtifacts =
            videoCreator.story!._allReferencingShowcases[
              albumIdx
            ].organizationArtifacts?.filter((a) => a.id !== mediaId) || [];
          albumIndex = albumIdx;
        }
        resource =
          videoCreator?.story?._allReferencingShowcases?.flatMap(
            (album) => album.organizationArtifacts || [],
          ) || [];
        break;
      case PhotoArtifactTab.logo: {
        const albumIdx =
          videoCreator.story?._allReferencingShowcases?.findIndex(
            (a) => a.organizationLogos?.some((v) => v.id === mediaId),
          );
        if (albumIdx !== undefined && albumIdx > -1) {
          videoCreator.story!._allReferencingShowcases[
            albumIdx
          ].organizationLogos =
            videoCreator.story!._allReferencingShowcases[
              albumIdx
            ].organizationLogos?.filter((a) => a.id !== mediaId) || [];
          albumIndex = albumIdx;
        }
        resource =
          videoCreator?.story?._allReferencingShowcases?.flatMap(
            (album) => album.organizationLogos || [],
          ) || [];
        break;
      }
      case PhotoArtifactTab.stock:
      case MediaSubType.stock:
        assets = assets.filter((asset) => asset.id !== mediaId);
        resource = assets;
        break;
      case MediaSubType.ai:
      case PhotoArtifactTab.ai:
        aiPhotos = aiPhotos.filter((ai) => ai.id !== mediaId);
        resource = aiPhotos;
        break;
      case MediaSubType.video:
        videoArtifacts = videoArtifacts.filter((vi) => vi.id !== mediaId);
        resource = videoArtifacts;
        break;
      default:
        return;
    }

    videoCreator.story! = {
      ...videoCreator.story!,
      storyArtifacts: artifacts,
      storyAssets: assets,
      storyArtifactsVideo: videoArtifacts,
      aiPhotos,
    };

    const photoResource = resource
      ?.filter((a) => a.responsiveImage)
      .map((a) => ({
        id: a.id,
        title: a.title,
        src: a.responsiveImage?.src,
        type: 'artifact',
        ...a.customData,
      })) as PhotoAsset[];

    toggleDropdown(false);
    videoCreator.selectedPhotoAssets = {
      tab: type as PhotoArtifactTab,
      resource: photoResource,
      lastSelectedStock: videoCreator.selectedPhotoAssets.lastSelectedStock,
      lastSelectedAi: videoCreator.selectedPhotoAssets.lastSelectedAi,
      selectedId: videoCreator.selectedPhotoAssets.selectedId,
    };
    if (type === PhotoArtifactTab.album || type === PhotoArtifactTab.logo) {
      if (albumIndex !== undefined && albumIndex > -1) {
        await videoCreator.albumRepository?.update(
          videoCreator.story!._allReferencingShowcases[albumIndex]!,
        );
      }
    } else {
      await videoCreator.updateStory(videoCreator.story!);
    }
  } catch (error) {
  } finally {
    toggleDropdown(false);
  }
}

export const handleLoadBlogContent = (
  videoCreator: VideoCreatorStore,
  content: BlogOrEmailContent,
  setCurrentContent: (content: BlogOrEmailContent | null) => void,
) => {
  setCurrentContent(content);
  let markdownContent = turndownService.turndown(content.content);
  const text = markdownContent
    .replace(/Replace image\n\nDelete image\n\n/g, '')
    .replace(/Add Photo\n\nDelete\n\n/g, '');

  const generatedContent = videoCreator.contentStudioGeneratedContent;
  videoCreator.contentStudioGeneratedContent = {
    ...(generatedContent || {}),
    Blog: {
      ...(generatedContent?.Blog || {}),
      content: {
        ...(generatedContent?.Blog?.content || {}),
        response: text,
      },
    },
  } as ContentViewData;

  videoCreator.selectedBlogContent = {
    id: content.id.toString(),
    content: content.title,
    type: 'saved',
  };
};

export async function handleDeleteBlogContent(
  videoCreator: VideoCreatorStore,
  contentId: number,
  currentContentId: number | undefined,
  setOptionsModal: (e: null) => void,
  setDropdownOpen: (e: boolean) => void,
  setCurrentContent: (e: BlogOrEmailContent | null) => void,
  type: 'savedBlog' | 'savedEmail' = 'savedBlog',
  saveType: 'saved_blog' | 'saved_email' = 'saved_blog',
) {
  setOptionsModal(null);
  setDropdownOpen(false);
  videoCreator.selectedBlogContent = null;

  const content = videoCreator.story?.[type];
  if (!content?.hasOwnProperty(contentId)) return;
  delete videoCreator.story?.savedBlog?.[contentId];
  if (contentId === currentContentId) {
    videoCreator.selectedBlogContent = null;
    setCurrentContent(null);
  }
  await videoCreator.storyRepository?.handleSaveAiGeneratedContent(
    videoCreator.story!.id,
    videoCreator.story?.[type]!,
    saveType,
  );
}

export async function handleRenameBlogContent(
  videoCreator: VideoCreatorStore,
  contentId: number,
  title: string,
  type: 'savedBlog' | 'savedEmail',
  saveType: 'saved_blog' | 'saved_email',
  toggleContentToEditModal: (e: null) => void,
  setOptionsModal: (e: null) => void,
  setDropdownOpen: (e: boolean) => void,
  setCurrentContent: (e: BlogOrEmailContent | null) => void,
  loadContent: boolean = true,
) {
  toggleContentToEditModal(null);
  setOptionsModal(null);
  setDropdownOpen(false);

  const data = videoCreator.story![type];
  if (!data || !Object.keys(data).length) return;

  if (data.hasOwnProperty(contentId)) {
    videoCreator.story![type]![contentId].title = title;
  } else {
    videoCreator.story![type] = {
      ...data,
      [contentId]: {
        ...(data[contentId] || {}),
        title,
      },
    };
  }

  if (loadContent) {
    handleLoadBlogContent(
      videoCreator,
      {
        id: contentId,
        title: title,
        content: data[contentId].content,
        username: data[contentId].username,
      },
      setCurrentContent,
    );
  }

  await videoCreator.storyRepository?.handleSaveAiGeneratedContent(
    videoCreator.story!.id,
    videoCreator.story?.[type]!,
    saveType,
  );
}

export const getCurrentStudio = (pathname: string) => {
  switch (pathname.replaceAll('/', '')) {
    case Studio.content:
      return Studio.content;
    case Studio.creator:
      return Studio.creator;
    case Studio.dashboard:
      return Studio.dashboard;
    case Studio.brandKit:
      return Studio.brandKit;
    default:
      return null;
  }
};

export const initializeContentStudioContent = (
  videoCreator: VideoCreatorStore,
  storyId: string | undefined,
  title: 'Blog' | 'Quotes' | 'Email',
) => {
  if (!storyId) return;
  const contentGenerated = videoCreator.contentStudioGeneratedContent;
  if (contentGenerated?.[title]?.storyId === storyId) return;
  videoCreator.contentStudioGeneratedContent = {
    ...(contentGenerated || {}),
    [title]: {
      storyId,
      hasBeenGenerated: false,
      content: { title, response: '' },
    },
  } as ContentViewData;
};

export const getAvailableClipFormats = (video: VideoClip) => {
  const formats: string[] =
    [video, ...(video.associatedVideos || [])]
      .filter((v) => v.videoFilePrimary)
      .map((v) => (v.aspectRatio || '') as string) || [];

  const availableFormats = [...new Set(formats)];
  availableFormats.sort();
  return availableFormats;
};

// function that will give two significant figures and K for thousands and M for millions to numbers
export function formatAnalyticsNumber(num: number) {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(2).replace(/\.0$/, '') + 'M';
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(2).replace(/\.0$/, '') + 'K';
  }
  return num;
}

export const createImageElement = async (
  baseElement: HTMLElement,
  backgroundImage: string,
  quote: string,
  cb: () => void,
  aspectRatio = AspectRatio.AR_16_9,
) => {
  const placeholderContainer = document.createElement('div');
  const container = document.createElement('div');
  const imageContainer = document.createElement('div');

  placeholderContainer.id = 'placeholder-shareable-image';
  const computedStyles = getComputedStyle(baseElement);
  const heightTags = ['min-block-size', 'min-height', 'height'];
  const widthTags = ['max-inline-size', 'max-width', 'width'];

  const dimension = getShareableImageDimension(aspectRatio);
  const width = `${dimension.width / 2}px`;
  const height = `${dimension.height / 2}px`;

  for (let i = 0; i < computedStyles.length; i++) {
    const styleName = computedStyles[i];
    let value = computedStyles.getPropertyValue(styleName);
    // if (widthTags.includes(styleName)) value = width;
    if (heightTags.includes(styleName)) value = height;
    if (styleName.includes('color')) continue;
    if (styleName === 'background-image') {
      value = `linear-gradient(0deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%), url("${backgroundImage}")`;
    }

    if (styleName === 'background-size') {
      value = 'cover';
    }

    if (styleName === 'background-position') {
      value = 'top';
    }

    if (styleName === 'background-repeat') {
      value = 'no-repeat';
    }

    if (styleName === 'background-color') {
      value = 'rgba(0, 0, 0, 0)';
    }
    imageContainer.style.setProperty(styleName, value);
  }

  imageContainer.style.borderRadius = '0px';
  imageContainer.style.aspectRatio = `${aspectRatio.split(':').join('/')}`;

  // placeholderContainer.style.width = width;
  // placeholderContainer.style.height = height;
  placeholderContainer.style.display = 'flex';
  placeholderContainer.style.justifyContent = 'center';
  placeholderContainer.style.alignItems = 'center';
  placeholderContainer.style.width = '666px';
  placeholderContainer.style.height = '500px';

  // container.style.width = width;
  // container.style.height = height;

  placeholderContainer.style.position = 'fixed';
  placeholderContainer.style.top = '50%';
  placeholderContainer.style.left = '50%';
  placeholderContainer.style.transform = 'translate(-50%, -50%)';

  document.body.appendChild(placeholderContainer);
  placeholderContainer.appendChild(container);
  container.appendChild(imageContainer);

  imageContainer.innerHTML = baseElement.innerHTML;

  const quoteElement = imageContainer.querySelector(
    '.quote-element',
  ) as HTMLElement;
  if (quoteElement) {
    quoteElement.innerHTML = quote;
    quoteElement.style.color = '#fff';
  }

  const svgElement = imageContainer.querySelector('svg');
  if (svgElement) {
    const pathElements = svgElement.querySelectorAll('path');
    pathElements.forEach((path) => {
      path.style.fill = '#fff';
    });
  }

  // Just logs
  const rect = container.getBoundingClientRect();
  console.log('rect', rect, '-- aspect ratio --', rect.width / rect.height);

  cb();
  const blobUrl = await toPng(container);
  placeholderContainer.remove();
  return blobUrl;
};

export const getShareableImageDimension = (currDimension: string) => {
  const width = 1060;
  const height = 1000;

  switch (currDimension) {
    case AspectRatio.AR_1_1:
      return { width, height };
    case AspectRatio.AR_9_16:
      return { width: (width * 9) / 16, height };
    case AspectRatio.AR_16_9:
      return { width: (width * 16) / 9, height };
    default:
      return { width, height };
  }
};

export const addUploadToStory = async (
  videoCreator: VideoCreatorStore,
  newPhotoData: FileData & { fileName: string },
) => {
  const upload = await videoCreator.assetRepository?.uploadFile(newPhotoData);
  if (!upload) return newPhotoData.url!;
  const assetKey = newPhotoData.type === 'stock' ? 'storyAssets' : 'aiPhotos';
  const assets = [
    ...(videoCreator.story?.[assetKey] || []),
    {
      id: upload.id,
      title: newPhotoData.title,
      customData: {},
      format: upload.format,
      mimeType: upload.mime_type,
      url: upload.url,
      responsiveImage: {
        srcSet: upload.url,
        src: upload.url,
        alt: newPhotoData.alt,
        title: newPhotoData.title,
      },
      video: null,
      _createdAt: new Date(),
    },
  ] as Artifact[];

  videoCreator.story![assetKey] = assets;
  await videoCreator.updateStory(videoCreator.story!);
  return upload.url;
};

export const saveAssetToDato = async (
  videoCreator: VideoCreatorStore,
  selectedImage: {
    url: string;
    type: 'stock' | 'ai' | 'artifact' | 'quotes';
    description?: string;
  },
) => {
  let url = selectedImage.url;
  const isInDato = selectedImage.url.startsWith(
    'https://www.datocms-assets.com',
  );

  if (!isInDato) {
    videoCreator.savingStockPhoto = true;
    const fileName = uuid();

    const newPhotoData: FileData & { fileName: string } = {
      type: selectedImage.type,
      url: selectedImage.url,
      fileName,
      alt: selectedImage.description || '',
      title: selectedImage.description || '',
    };
    const imageUrl = await addUploadToStory(videoCreator, newPhotoData);
    url = imageUrl;
    videoCreator.savingStockPhoto = false;
  }
  return url;
};

export const getDocumentHeight = () =>
  Math.max(
    document.body.scrollHeight,
    document.documentElement.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.offsetHeight,
    document.body.clientHeight,
    document.documentElement.clientHeight,
  );

export const uploadFilesAndAsset = async (
  videoCreator: VideoCreatorStore,
  files: (File & {
    preview?: string;
    id?: string;
    author?: string;
  })[],
  contributor: string,
  story: Story,
  cb: (
    file: File & { preview: string; id: string; description: string },
    upload: SimpleSchemaTypes.Upload,
  ) => void = () => {},
) => {
  const uploadResults = await Promise.all(
    files.map(async (file) => {
      const namesplit = file?.name?.split('.');
      const fileName = namesplit?.length
        ? namesplit[0]
        : window.crypto.randomUUID();
      const newFileData = {
        type: 'artifact' as 'artifact',
        file,
        fileName,
        author: file.author,
        alt: '',
        title: '',
        tags: contributor ? [contributor] : undefined,
        customData: { contributor },
      };
      const newUpload =
        await videoCreator.assetRepository?.uploadFile(newFileData);
      cb(
        file as File & { preview: string; id: string; description: string },
        newUpload!,
      );
      return newUpload;
    }),
  );

  const photoArtifactsIds = story?.storyArtifacts?.map((a) => a.id) || [];
  const videoArtifactsIds = story?.storyArtifactsVideo?.map((a) => a.id) || [];

  uploadResults.forEach((upload: any) => {
    if (upload) {
      if (upload.is_image) {
        photoArtifactsIds.push(upload.id);
      } else {
        videoArtifactsIds.push(upload.id);
      }
    }
  });
  console.log('videoArtifactsIds', videoArtifactsIds);

  story.storyArtifacts = photoArtifactsIds as unknown as Artifact[];
  story.storyArtifactsVideo = videoArtifactsIds as unknown as Artifact[];

  return story;
};

export const formatUploadToArtifact = (
  upload: SimpleSchemaTypes.Upload,
  data: { title?: string; alt?: string },
) => {
  return {
    id: upload.id,
    title: data.title || upload.default_field_metadata.en.title,
    customData: upload.default_field_metadata.en.custom_data,
    format: upload.format,
    mimeType: upload.mime_type,
    width: upload.width?.toString(),
    height: upload.height?.toString(),
    url: upload.url,
    ...(upload.is_image
      ? {
          responsiveImage: {
            srcSet: upload.url,
            src: upload.url,
            alt: data.alt || upload.default_field_metadata.en.alt,
            title: data.title || upload.default_field_metadata.en.title,
            base64: '',
            sizes: '',
          },
        }
      : null),
    video: null,
    _createdAt: new Date().toDateString(),
  } as Artifact;
};

export const FONTS = [
  'Abel',
  'Anton',
  'Archivo Black',
  'Arimo',
  'Barlow',
  'Bebas Neue',
  'Bitter',
  'Cabin',
  'Caveat',
  'Chakra Petch',
  'Cinzel',
  'Courgette',
  'Dancing Script',
  'DM Sans',
  'Dosis',
  'EB Garamond',
  'Fira Sans',
  'Great Vibes',
  'Hind Siliguri',
  'IBM Plex Sans',
  'Inconsolata',
  'Indie Flower',
  'Inter',
  'Josefin Sans',
  'Jost',
  'Kalam',
  'Kanit',
  'Karla',
  'Lato',
  'Libre Baskerville',
  'Libre Franklin',
  'Lobster',
  'Lora',
  'Manrope',
  'Merienda',
  'Merriweather',
  'Montserrat',
  'Mukta',
  'Mulish',
  'Nanum Gothic',
  'Neuton',
  'Noto Sans',
  'Nunito',
  'Nunito Sans',
  'Open Sans',
  'Oswald',
  'Outfit',
  'Pacifico',
  'Permanent Marker',
  'Playfair Display',
  'Poppins',
  'Proxima Nova',
  'PT Sans',
  'PT Sans Narrow',
  'PT Serif',
  'Quicksand',
  'Raleway',
  'Roboto',
  'Roboto Condensed',
  'Roboto Mono',
  'Roboto Slab',
  'Rubik',
  'Sacramento',
  'Satisfy',
  'Shadows Into Light',
  'Slabo',
  'Source Code Pro',
  'Space Grotesk',
  'Titillium Web',
  'Ubuntu',
  'Work Sans',
];

export const FONT_SIZE_VALUES = [
  12, 14, 16, 18, 20, 22, 24, 28, 30, 32, 36, 40, 48, 60, 72, 96,
];

export const checkWordsInString = (str: string, search: string) => {
  const lowercaseStr = str.toLowerCase();
  const wordsInSearch = (search.toLowerCase().match(/\w+/g) || []) as string[];
  // const wordsInStr = (lowercaseStr.match(/\w+/g) || []) as string[];

  for (let word of wordsInSearch) {
    if (!lowercaseStr.includes(word)) return false;
  }
  return true;
};

export const scrollIfNotInView = (
  dropdown: Element,
  container: Element,
  extraScroll = 100,
) => {
  const containerRect = container.getBoundingClientRect();
  const elementRect = dropdown.getBoundingClientRect();
  const intoView =
    container.scrollTop + elementRect.bottom - containerRect.bottom;

  if (elementRect.bottom > containerRect.bottom - extraScroll) {
    container.scrollTo({
      left: 0,
      top: intoView + extraScroll,
      behavior: 'smooth',
    });
  }
};

export const proximaNovaFonts = [
  {
    family: 'Proxima Nova',
    weight: 400,
    style: 'normal',
    source:
      'https://www.datocms-assets.com/99106/1723563406-proximanova_light.otf',
  },
  {
    family: 'Proxima Nova',
    weight: 500,
    style: 'normal',
    source:
      'https://www.datocms-assets.com/99106/1723563409-proximanova_regular.ttf',
  },
  {
    family: 'Proxima Nova',
    weight: 600,
    style: 'normal',
    source:
      'https://www.datocms-assets.com/99106/1723562352-proximanova_bold.otf',
  },
  {
    family: 'Proxima Nova',
    weight: 600,
    style: 'italic',
    source:
      'https://www.datocms-assets.com/99106/1723562359-proximanova_boldit.otf',
  },
  {
    family: 'Proxima Nova',
    weight: 700,
    style: 'normal',
    source:
      'https://www.datocms-assets.com/99106/1723563234-proximanova_extrabold.otf',
  },
];
