import { observer } from 'mobx-react-lite';
import { FC, useState } from 'react';
import { Story } from '../../types.ts/story';
import styled from 'styled-components';

import { ProduceIcon } from '../common/icons';
import SocialClipShareModal from './SocialClipShareModal';
import VideoClipCard from './VideoClipCard';
import { useFlagsCombination } from '../../utility/useFlagsCombination';
import AIClipProducer from '../ClipProducer/AIClipProducer';
import ClipProducerModalUI from './ClipProducer/ClipProducerModalUI';
import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';
import { useTemplatedPromptsStore } from '@src/stores-v2/StoreContext';

type Props = {
  aiGeneratedContent?: Story['aiGeneratedContent'];
};
const SocialClipsView: FC<Props> = observer((props) => {
  const videoCreator = useVideoCreatorStore();
  const templatedPrompts = useTemplatedPromptsStore();
  const [showProducerModal, toggleProducerModal] = useState<boolean>(false);
  const clipProducerPresets = templatedPrompts?.clipProducerPresets;
  const { contentStudioEnableClipProducer } = useFlagsCombination(
    videoCreator.datoContext.currentRole,
  );

  return (
    <Main>
      {contentStudioEnableClipProducer && (
        <ProducerClipButton
          disabled={!clipProducerPresets?.length}
          onClick={() => {
            if (clipProducerPresets?.length) {
              toggleProducerModal(true);
            }
          }}
        >
          <ProduceIcon /> <span>Produce Clips</span>
        </ProducerClipButton>
      )}
      {videoCreator.videoClipPreview && (
        <SocialClipShareModal
          clip={videoCreator.videoClipPreview}
          onClose={() => (videoCreator.videoClipPreview = null)}
        />
      )}

      <Content>
        {videoCreator.story?.otherVideos
          ?.filter(
            (video) =>
              !video.isHidden &&
              (video.videoFilePrimary ||
                video.associatedVideos.find((v) => v.videoFilePrimary) ||
                video.sourcePlatform === 'content-studio'),
          )
          .map((video, index) => (
            <VideoClipCard
              key={video.id || index}
              // toggleShare={toggleShare}
              video={video}
            />
          ))}
      </Content>

      {contentStudioEnableClipProducer && showProducerModal && (
        <AIClipProducer
          useFinalOrOriginalVideo={true}
          supportedPresets={templatedPrompts?.clipProducerPresets || null}
          ClipProducerUI={(props) => (
            <ClipProducerModalUI
              {...props}
              closeModal={() => toggleProducerModal(false)}
            />
          )}
        />
      )}
    </Main>
  );
});

const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Content = styled.div`
  margin-top: 10px;
  row-gap: 30px;
  column-gap: 10px;
  place-items: center;
  display: grid;
  grid-template-columns: repeat(5, 1fr);

  @media only screen and (max-width: 3000px) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media only screen and (max-width: 2500px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media only screen and (max-width: 2000px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media only screen and (max-width: 1300px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (max-width: 800px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const EditButton = styled.button.attrs((props: { disabled: boolean }) => props)`
  display: flex;
  height: 48px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid #484848;
  background: none;

  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
  cursor: ${(props) => (props.disabled ? 'wait' : 'pointer')};

  color: #f3e9d7;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
`;

const ProducerClipButton = styled(EditButton)`
  background: #17c964;
  color: #03041a;
  margin-left: auto;
  width: 240px;
`;
export default SocialClipsView;
