type Props = {
  width?: string;
  height?: string;
  viewBox?: string;
  strokeColor?: string;
  fillColor?: string;
};
const LinkIcon = (props: Props) => {
  const {
    width = '17',
    height = '16',
    viewBox = '0 0 17 16',
    strokeColor = '#F2D093',
    fillColor = 'none',
  } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={viewBox}
      fill={fillColor}
    >
      <path
        d="M9.16602 3.99935L10.4993 2.66602C11.166 1.99935 12.4993 1.99935 13.166 2.66602L13.8327 3.33268C14.4993 3.99935 14.4993 5.33268 13.8327 5.99935L10.4993 9.33268C9.83268 9.99935 8.49935 9.99935 7.83268 9.33268M7.83268 11.9993L6.49935 13.3327C5.83268 13.9993 4.49935 13.9993 3.83268 13.3327L3.16602 12.666C2.49935 11.9993 2.49935 10.666 3.16602 9.99935L6.49935 6.66602C7.16602 5.99935 8.49935 5.99935 9.16602 6.66602"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LinkIcon;
