import { Client } from '@datocms/cma-client-browser';
import { GraphQLClient } from 'graphql-request';
import {
  DatoShowcase,
  Showcase,
  ShowcaseAccess,
  ShowcaseBranding,
} from '../types.ts/story';
import ApiClient from '../apiClient/ApiClient';
import { pickBy } from 'lodash';
import { populateShowcaseStoriesWithOriginalVideo } from '../utility/story';
import { ALBUM_QUERY, SHOWCASE_QUERY } from '../gql/album-gql';
import { STORY_REFERENCING_ALBUMS_ACCESS_QUERY } from '../gql/story-gql';

export class AlbumRepository {
  private dClient: Client | ApiClient;
  private gqlClient: GraphQLClient;
  // private dClient: Client;

  constructor(dClient: Client | ApiClient, gqlClient: GraphQLClient) {
    this.gqlClient = gqlClient;
    this.dClient = dClient;
  }

  async findAllWithStoryAccess(storyId: string): Promise<ShowcaseAccess[]> {
    const response = (await this.gqlClient.request(
      STORY_REFERENCING_ALBUMS_ACCESS_QUERY,
      {
        id: storyId,
      },
    )) as { story: { albums: ShowcaseAccess[] } } | null;

    return response?.story.albums || [];
  }

  async findOneBySlug(slug: string): Promise<Showcase | null> {
    const response = (await this.gqlClient.request(SHOWCASE_QUERY, {
      slug,
    })) as { showcase?: DatoShowcase };
    if (!response.showcase) return null;

    const fullShowcase = await populateShowcaseStoriesWithOriginalVideo(
      response.showcase,
    );

    return fullShowcase;
  }

  async findOne(id: string): Promise<Showcase | null> {
    const response = (await this.gqlClient.request(ALBUM_QUERY, {
      id,
    })) as { showcase?: DatoShowcase };
    if (!response.showcase) return null;

    const fullShowcase = await populateShowcaseStoriesWithOriginalVideo(
      response.showcase,
    );

    return fullShowcase;
  }

  async update(showcase: Partial<Showcase> & { id: string }): Promise<void> {
    if (!showcase.id) {
      throw new Error('Organization id is required');
    }

    let brandingId = showcase.branding?.id;
    if (showcase.branding) {
      if (showcase.branding.id) await this.updateBranding(showcase.branding);
      else {
        const branding = await this.createBranding(showcase.branding);
        brandingId = branding.id;
      }
    }

    await this.dClient.items.update(showcase.id, {
      ...(showcase.title && {
        title: { en: showcase.title },
      }),

      ...(showcase.organizationArtifacts && {
        organization_artifacts: showcase.organizationArtifacts.map((photo) => ({
          upload_id: photo.id,
          ...(photo.title && { title: photo.title }),
        })),
      }),

      ...(showcase.organizationLogos && {
        organization_logos: showcase.organizationLogos.map((photo) => ({
          upload_id: photo.id,
          ...(photo.title && { title: photo.title }),
        })),
      }),

      ...(showcase.organizationArtifactsVideo && {
        organization_artifacts_video: showcase.organizationArtifactsVideo.map(
          (video) => ({
            upload_id: video.id,
            ...(video.title && { title: video.title }),
          }),
        ),
      }),

      ...(showcase.contributors && {
        contributors: showcase.contributors.map((c) => c.id),
      }),

      ...(showcase.lowerThirdTextBranding && {
        lower_third_text_branding: showcase.lowerThirdTextBranding.map(
          (b) => b.id,
        ),
      }),

      ...(showcase.karaokeTextBranding && {
        karaoke_text_branding: showcase.karaokeTextBranding.map((b) => b.id),
      }),

      ...(showcase.lastUsedTemplates && {
        last_used_templates: JSON.stringify(
          showcase.lastUsedTemplates,
          null,
          2,
        ),
      }),

      ...(showcase.branding && { branding: brandingId }),
    });

    await this.dClient.items.publish(showcase.id);
  }

  async createBranding(branding: Partial<ShowcaseBranding>) {
    const itemType = await this.dClient.itemTypes.find('branding');
    const createdItem = await this.dClient.items.create({
      item_type: { type: 'item_type', id: itemType.id },
      title: branding.title || '',
      primary_colors: branding.primaryColors || '',
      secondary_colors: branding.secondaryColors || '',
      brand_fonts: JSON.stringify(branding.brandFonts || []),
      custom_fonts: branding.customFonts
        ? JSON.stringify(branding.customFonts)
        : null,
      custom_font_files:
        branding.customFontFiles?.map((font) => ({
          upload_id: font.id,
          ...(font.title && { title: font.title }),
        })) || null,
      design_elements:
        branding.designElements?.map((designElement) => ({
          upload_id: designElement.id,
          ...(designElement.title && { title: designElement.title }),
        })) || [],
      brand_voice: branding.brandVoice || '',
      avoid_words: branding.avoidWords || '',
      mission_statement: branding.missionStatement || '',
      key_metrics: branding.keyMetrics || '',
      company_reports:
        branding.companyReports?.map((impactReport) => ({
          upload_id: impactReport.id,
          ...(impactReport.title && { title: impactReport.title }),
        })) || [],
      organization_website: branding.organizationWebsite || '',
      external_links: branding.externalLinks || '',
    });
    return createdItem;
  }

  async updateBranding(branding: Partial<ShowcaseBranding>) {
    if (!branding.id) {
      throw new Error('Branding id is required');
    }

    this.dClient.items.update(
      branding.id,
      pickBy(
        {
          title: branding.title,
          primary_colors: branding.primaryColors,
          secondary_colors: branding.secondaryColors,
          brand_fonts: JSON.stringify(branding.brandFonts || []),
          custom_fonts: branding.customFonts
            ? JSON.stringify(branding.customFonts)
            : null,
          custom_font_files:
            branding.customFontFiles?.map((font) => ({
              upload_id: font.id,
              ...(font.title && { title: font.title }),
            })) || null,
          design_elements: branding.designElements?.map((designElement) => ({
            upload_id: designElement.id,
            ...(designElement.title && { title: designElement.title }),
          })),
          brand_voice: branding.brandVoice,
          avoid_words: branding.avoidWords,
          mission_statement: branding.missionStatement,
          key_metrics: branding.keyMetrics,
          company_reports: branding.companyReports?.map((impactReport) => ({
            upload_id: impactReport.id,
            ...(impactReport.title && { title: impactReport.title }),
          })),
          organization_website: branding.organizationWebsite,
          external_links: branding.externalLinks,
        },
        (value) => value !== undefined,
      ),
    );
  }
}
