import { observer } from 'mobx-react-lite';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { useOutsideAlerter } from '../transcript/useClickOutside';
import BoldIcon from '../../svgs/BoldIcon';
import { KaraokeConfig } from '../../videoTranscriptionProcessor/types/karaokeTypes';
import ItalicIcon from '../../svgs/ItalicIcon';
import CapitalizeIcon from '../../svgs/CapitalizationIcon';
import LeftAlignIcon from '../../svgs/LeftAlignIcon';
import RightAlignIcon from '../../svgs/RightAlignIcon';
import MiddleAlignIcon from '../../svgs/MiddleAlignIcon';
import styled from 'styled-components';
import { scrollIfNotInView } from '../../utility/general';
import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';

export enum Action {
  bold = 'Bold',
  italic = 'Italic',
  capitalize = 'Capitalize',
  align = 'Align',
}

export enum Align {
  leftAlign = 'Left Align',
  midAlign = 'Middle Align',
  rightAlign = 'Right Align',
}

export enum Bold {
  normal = 'Normal',
  semiBold = 'Semi-Bold',
  bold = 'Bold',
  extraBold = 'Extra-Bold',
}

export const BoldValues = {
  [Bold.normal]: 400,
  [Bold.semiBold]: 600,
  [Bold.bold]: 700,
  [Bold.extraBold]: 800,
};

export const AlignValues = {
  [Align.leftAlign]: '0%',
  [Align.midAlign]: '50%',
  [Align.rightAlign]: '100%',
};

type Props = {
  selectedActions: Action[];
  alignType: Align;
  selectedBold: Bold | null;
  handleToggleBold: (bold: Bold) => void;
  handleToggleAction: (
    action: Action,
    propertyName: keyof KaraokeConfig,
    normalState: string,
    requiredState: string,
  ) => void;
  toggleAlign: (align: Align) => void;
  hasStickyBottom: boolean;
};

const TextBoldAlignFormatting = observer(
  forwardRef((props: Props, ref) => {
    const videoCreator = useVideoCreatorStore();
    const {
      selectedActions,
      alignType,
      selectedBold,
      hasStickyBottom,
      handleToggleBold,
      handleToggleAction,
      toggleAlign,
    } = props;
    const [openBold, toggleBold] = useState<boolean>(false);
    const boldRef = useRef<HTMLButtonElement>(null);
    const boldDropdownRef = useRef<HTMLDivElement>(null);
    const template = videoCreator.textBrandingService.selectedBrandingTemplate;

    useOutsideAlerter(boldRef, () => {
      toggleBold(false);
    });

    useEffect(() => {
      if (boldDropdownRef.current && ref) {
        const container = (ref as React.RefObject<HTMLDivElement>)
          .current as Element;

        const stickyContainerHeight = hasStickyBottom ? 100 : 0;

        scrollIfNotInView(
          boldDropdownRef.current,
          container,
          stickyContainerHeight,
        );
      }
    }, [openBold, template, hasStickyBottom]);

    const getActionColor = (action: Action) => {
      if (
        (action === Action.bold &&
          selectedBold &&
          selectedBold !== Bold.normal) ||
        selectedActions.includes(action)
      )
        return '#03041A';
      return '#848484';
    };

    const getAlignColor = (align: Align) => {
      if (alignType === align) return '#03041A';
      return '#848484';
    };

    const renderAction = (action: Action | Align) => {
      switch (action) {
        case Action.bold:
          const isSelected = selectedBold !== Bold.normal && !!selectedBold;
          return (
            <BoldAction
              ref={boldRef}
              isSelected={isSelected || !!openBold}
              onClick={() => {
                toggleBold(!openBold);
              }}
            >
              <BoldIcon fillColor={getActionColor(Action.bold)} />
              {openBold && (
                <Dropdown ref={boldDropdownRef}>
                  {Object.values(Bold).map((bold) => (
                    <DropdownItem
                      isSelected={selectedBold === bold}
                      boldValue={BoldValues[bold]}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleToggleBold(bold);
                        toggleBold(false);
                      }}
                    >
                      {bold}
                    </DropdownItem>
                  ))}
                </Dropdown>
              )}
            </BoldAction>
          );
        case Action.italic:
          return (
            <ActionButton
              isSelected={selectedActions.includes(Action.italic)}
              onClick={() =>
                handleToggleAction(
                  Action.italic,
                  'font_style',
                  'normal',
                  'italic',
                )
              }
            >
              <ItalicIcon fillColor={getActionColor(Action.italic)} />
            </ActionButton>
          );

        case Action.capitalize:
          return (
            <ActionButton
              isSelected={selectedActions.includes(Action.capitalize)}
              onClick={() =>
                handleToggleAction(
                  Action.capitalize,
                  'text_transform',
                  'none',
                  'uppercase',
                )
              }
            >
              <CapitalizeIcon fillColor={getActionColor(Action.capitalize)} />
            </ActionButton>
          );

        case Action.align:
          return renderAlignOptions();

        default:
          return null;
      }
    };

    const renderAlignOptions = () => {
      return (
        <>
          {(Object.keys(Align) as Array<keyof typeof Align>).map((alignKey) => (
            <ActionButton
              isSelected={alignType === Align[alignKey]}
              onClick={() => {
                toggleAlign(Align[alignKey]);
              }}
            >
              {
                {
                  [Align.leftAlign]: (
                    <LeftAlignIcon fillColor={getAlignColor(Align[alignKey])} />
                  ),
                  [Align.midAlign]: (
                    <MiddleAlignIcon
                      strokeColor={getAlignColor(Align[alignKey])}
                    />
                  ),
                  [Align.rightAlign]: (
                    <RightAlignIcon
                      fillColor={getAlignColor(Align[alignKey])}
                    />
                  ),
                }[Align[alignKey]]
              }
            </ActionButton>
          ))}
        </>
      );
    };

    return (
      <>
        {Object.values(Action).map((action) => (
          <>{renderAction(action)}</>
        ))}
      </>
    );
  }),
);

export default TextBoldAlignFormatting;

const ActionButton = styled.button<{ isSelected: boolean }>`
  outline: 0;
  border: 1px solid ${(props) => (props.isSelected ? '#F2D093' : '#484848')};
  // width: 32px;
  width: 12%;
  aspect-ratio: 1/1;
  // height: 32px;
  background-color: ${(props) => (props.isSelected ? '#F2D093' : '#03041A')};
  border-radius: 4px;
  color: ${(props) => (props.isSelected ? '#03041A' : '#484848')};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  span.filler {
    font-weight: 400;
    font-size: 11px;
    line-height: 9.68px;
    color: ${(props) => (props.isSelected ? '#03041A' : 'white')};
  }
`;

const BoldAction = styled(ActionButton)`
  position: relative;
`;

const Dropdown = styled.div<{ width?: string }>`
  display: flex;
  flex-direction: column;
  border: 1px solid #484848;
  border-radius: 8px;
  background-color: #03041a;
  box-shadow: 8px 16px 8px 0px rgba(0, 0, 0, 0.4);
  // padding: 0 10px;
  text-align: left;
  width: 100px;
  overflow: auto;
  position: absolute;
  top: 40px;
  left: 0;
  cursor: pointer;
  z-index: 1;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
`;

const DropdownItem = styled.span<{ isSelected: boolean; boldValue: number }>`
  padding: 12px;
  background-color: ${(props) => props.isSelected && '#f2d093'};
  &:not(:last-child) {
    border-bottom: 1px solid #484848;
  }
  font-weight: ${(props) => props.isSelected && props.boldValue};
  color: ${(props) => (props.isSelected ? '#030419' : '#f3e9d7')};
  &:hover {
    color: ${(props) => (props.isSelected ? '#030419' : '#f2d093')};
    font-weight: ${(props) => props.boldValue};
  }
`;
