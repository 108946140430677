import { observer } from 'mobx-react-lite';
import { Dispatch, FC, SetStateAction } from 'react';
import styled, { css } from 'styled-components';
import { KARAOKE_TRACK_NUMBER } from '../../stores/VideoCreatorStore';
import MediaIcon from '../../svgs/MediaIcon';
import AiProducerIcon from '../../svgs/AiProducerIcon';
import {
  AIProducerCard,
  SidebarOption,
  MediaCard,
} from '../../types.ts/general';
import { HistoryIcon, TextIcon } from '../common/icons';
import KaraokeIcon from '../../svgs/KaraokeIcon';
import { useFlagsCombination } from '../../utility/useFlagsCombination';
import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';

const SidePanelMenu: FC<{}> = observer(() => {
  const videoCreator = useVideoCreatorStore();
  const { enableAiStoryProducer } = useFlagsCombination(
    videoCreator.datoContext.currentRole,
  );
  const { sidebarOptions, aiProducerSubMenu, mediaSubMenu } = videoCreator;
  const isProducer = sidebarOptions === SidebarOption.aiProducer;
  const isMedia = sidebarOptions === SidebarOption.media;
  const isHistory = sidebarOptions === SidebarOption.history;
  const isKaraoke = sidebarOptions === SidebarOption.karaoke;
  const activeElement = videoCreator.getActiveElement();

  const handleSetOption = (state: SidebarOption) => {
    videoCreator.sidebarOptions = state;
    videoCreator.setActiveElements();
  };

  const handleSetAICard = (state: AIProducerCard) => {
    videoCreator.aiProducerSubMenu = state;
    videoCreator.setActiveElements();
  };

  const handleSetMediaCard = (state: MediaCard) => {
    videoCreator.mediaSubMenu = state;
    videoCreator.setActiveElements();
  };

  return (
    <Main>
      <TopMenu>
        <Icon
          onClick={() => {
            handleSetOption(SidebarOption.aiProducer);
            handleSetAICard(AIProducerCard.clip);
          }}
        >
          <AiProducerIcon strokeColor={isProducer ? '#f2d093' : '#484848'} />
          <Text isSelected={isProducer}>AI Producer</Text>
        </Icon>
        <Icon
          onClick={() => {
            handleSetOption(SidebarOption.karaoke);
            videoCreator.setShowSubtitles(true);
          }}
        >
          <KaraokeIcon strokeColor={isKaraoke ? '#f2d093' : '#484848'} />
          <Text isSelected={isKaraoke}>Karaoke</Text>
        </Icon>
        <Icon
          onClick={() => {
            handleSetOption(SidebarOption.media);
            handleSetMediaCard(MediaCard.photo);
          }}
        >
          <MediaIcon strokeColor={isMedia ? '#f2d093' : '#484848'} />
          <Text isSelected={isMedia}>Media</Text>
        </Icon>

        <Icon
          onClick={async () => {
            if (
              videoCreator.sidebarOptions !== SidebarOption.text &&
              (activeElement?.source.type !== 'text' ||
                activeElement?.source.track === KARAOKE_TRACK_NUMBER)
            ) {
              videoCreator.textProducer.setDefaultTextConfig();
            }

            videoCreator.sidebarOptions = SidebarOption.text;
          }}
        >
          <TextIcon
            height="20"
            width="20"
            fillColor={
              videoCreator.sidebarOptions === SidebarOption.text
                ? '#f2d093'
                : '#484848'
            }
          />
          <Text isSelected={videoCreator.sidebarOptions === SidebarOption.text}>
            Design
          </Text>
        </Icon>

        <Icon
          onClick={() => {
            handleSetOption(SidebarOption.history);
          }}
        >
          <HistoryIcon
            height="20"
            width="20"
            strokeColor={isHistory ? '#f2d093' : '#484848'}
          />

          <Text isSelected={isHistory}>History</Text>
        </Icon>
      </TopMenu>
      {isProducer && (
        <Cards>
          {enableAiStoryProducer && (
            <Card
              onClick={() => handleSetAICard(AIProducerCard.story)}
              isSelected={aiProducerSubMenu === AIProducerCard.story}
            >
              Story
            </Card>
          )}
          <Card
            onClick={() => handleSetAICard(AIProducerCard.clip)}
            isSelected={aiProducerSubMenu === AIProducerCard.clip}
          >
            Clip
          </Card>
          <Card
            onClick={() => handleSetAICard(AIProducerCard.music)}
            isSelected={aiProducerSubMenu === AIProducerCard.music}
          >
            Music
          </Card>
          <Card
            onClick={() => handleSetAICard(AIProducerCard.photo)}
            isSelected={aiProducerSubMenu === AIProducerCard.photo}
          >
            Photo
          </Card>
        </Cards>
      )}
      {isMedia && (
        <Cards>
          <Card
            onClick={() => handleSetMediaCard(MediaCard.photo)}
            isSelected={mediaSubMenu === MediaCard.photo}
          >
            Photo
          </Card>
          <Card
            onClick={() => handleSetMediaCard(MediaCard.music)}
            isSelected={mediaSubMenu === MediaCard.music}
          >
            Music
          </Card>
          <Card
            onClick={() => handleSetMediaCard(MediaCard.video)}
            isSelected={mediaSubMenu === MediaCard.video}
          >
            Video
          </Card>
        </Cards>
      )}
      <HorizontalLine height="1" fill="none">
        <path d="M0 1L312 1.00002" stroke="#484848" />
      </HorizontalLine>
    </Main>
  );
});
export default SidePanelMenu;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px;
  gap: 20px;
`;

const TopMenu = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
  align-items: flex-end;
`;

const Icon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  cursor: pointer;
  border-radius: 8px;
  span {
    font-size: 11px;
  }
`;

const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-self: start;
`;

const Card = styled.div<{ isSelected?: boolean }>`
  border: 1px solid ${(props) => (props.isSelected ? '#f2d093' : '#484848')};
  border-radius: 4px;
  font-size: 12px;
  padding: 8px 10px;
  text-align: center;
  color: ${(props) => (props.isSelected ? '#030419' : '#484848')};
  background-color: ${(props) => (props.isSelected ? '#f2d093' : '')};
  cursor: pointer;
  ${(props) =>
    !props.isSelected &&
    css`
      &:hover {
        color: #f3e9d7;
        border-color: #f3e9d7;
      }
    `}
`;

const Text = styled.span<{ isSelected: boolean }>`
  color: ${(props) => (props.isSelected ? '#f2d093' : '#484848')};
`;

const HorizontalLine = styled.svg`
  width: 100%;
`;
