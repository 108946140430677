import React from 'react';
import styled from 'styled-components';
import { ElementState } from '../../renderer/ElementState';
import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';

interface VideoPresetProps {
  activeElement: ElementState;
  url: string;
}

export const VideoPreset: React.FC<VideoPresetProps> = (props) => {
  const videoCreator = useVideoCreatorStore();
  const getElement = () => {
    if (videoCreator.isOriginalVideoElement(props.activeElement.source)) {
      const video = videoCreator.currentVideo;
      return {
        id: video?.id,
        title: video?.title,
        thumbnail: video?.thumbnail?.url,
      };
    }
    const video = videoCreator.story?.storyArtifactsVideo?.find(
      (p) => p.url === props.url,
    );
    return {
      id: video?.id,
      title: video?.title,
      thumbnail: video?.video?.thumbnailUrl,
    };
  };

  const getThumbnail = () => {
    const element = getElement();
    return element?.thumbnail;
  };

  const videoElement = getElement();
  // need to replace click event with a modal that allows user to select a new video file
  return (
    <Main
    //onClick={() => uploadFromGallery()}
    >
      {videoElement?.thumbnail ? (
        <Image src={videoElement.thumbnail} alt="image present" />
      ) : (
        <Video src={props.url} />
      )}
      <Description>{videoElement?.title}</Description>
    </Main>
  );
};

const Main = styled.div`
  position: relative;
  margin: 15px 0;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  gap: 10px;
  align-items: center;
`;

const Video = styled.video`
  width: 107px;
  height: 70px;
  border-radius: 8px;
`;

const Image = styled.img`
  width: 107px;
  height: 70px;
  border-radius: 8px;
  background-size: cover;
  object-fit: cover;
`;

const Description = styled.span`
  font-color: #d7d7e1;
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;
`;
