import React, { useCallback } from 'react';
import styled from 'styled-components';
import CutIcon from '../../svgs/CutIcon';
import TrackUpIcon from '../../svgs/TrackUpIcon';
import TrackDownIcon from '../../svgs/TrackDownIcon';
import DeleteIcon from '../../svgs/DeleteIcon';
import { timeFormat } from '../../utility/timeFormat';
import { KARAOKE_TRACK_NUMBER } from '../../stores/VideoCreatorStore';
import VerticalLine from '../../svgs/VerticalLine';
import { observer } from 'mobx-react-lite';
import { runInAction } from 'mobx';
import { SidebarOption, MediaCard } from '../../types.ts/general';
import PlainSlider from '../common/PlainSlider';
import { getDocumentHeight } from '../../utility/general';
import { throttle } from 'lodash';
import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';

const MinusIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
    <circle cx="8" cy="8" r="7" stroke="#484848" />
    <path d="M5 8H11" stroke="#484848" />
  </svg>
);

const PlusIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
    <circle cx="8" cy="8" r="7" stroke="#484848" />
    <path d="M5 8H11" stroke="#484848" />
    <path d="M8 5V11" stroke="#484848" />
  </svg>
);

const TimelineControls = observer(() => {
  const videoCreator = useVideoCreatorStore();
  const timelineHeight = videoCreator.timelineHeight;
  const percentToPixel =
    parseFloat(timelineHeight || '0%') * 0.01 * getDocumentHeight();

  const toggleTimeline = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    runInAction(() => {
      const percentHeight = (36 * 100) / getDocumentHeight();
      if (percentToPixel > 40) {
        videoCreator.timelineHeight = `${percentHeight}%`;
      } else {
        videoCreator.timelineHeight = '30%';
      }
    });
  };

  const onZoomChange = useCallback(
    throttle((value: number) => {
      runInAction(() => {
        videoCreator.timelineScale = value;
      });
    }, 1000),
    [],
  );
  const TIMELINE_INC =
    (videoCreator.maxTimelineScale - videoCreator.defaultTimelineScale) / 4;
  const minScale = videoCreator.defaultTimelineScale * 0.95;

  return (
    <Main
      onMouseDown={(e) => {
        e.stopPropagation();
      }}
    >
      <LeftActions>
        <ZoomControl>
          <Action
            onClick={() => {
              if (videoCreator.timelineScale < minScale) return;
              onZoomChange(Math.max(videoCreator.timelineScale / 2, minScale));
            }}
          >
            <MinusIcon />
          </Action>
          <PlainSlider
            max={videoCreator.maxTimelineScale}
            min={minScale}
            currValue={videoCreator.timelineScale}
            getValue={(value: number) => onZoomChange(value)}
          />

          <Action
            onClick={() =>
              onZoomChange(
                Math.min(
                  videoCreator.timelineScale * 2,
                  videoCreator.maxTimelineScale,
                ),
              )
            }
          >
            <PlusIcon />
          </Action>
        </ZoomControl>
        <Action
          aria-label="cut-track"
          disabled={!videoCreator.activeElementIds.length}
          onClick={(e) => {
            e.stopPropagation();
            videoCreator.cutCurrentTrack();
          }}
        >
          <CutIcon />
        </Action>
        <Action
          disabled={videoCreator.activeElementIds.length === 0}
          onClick={async (e) => {
            e.stopPropagation();
            const activeElement = videoCreator.getActiveElement();
            if (activeElement) {
              await videoCreator.trackManager.rearrangeTracks(
                activeElement.track,
                'up',
              );
            }
          }}
        >
          <TrackUpIcon />
        </Action>
        <Action
          disabled={videoCreator.activeElementIds.length === 0}
          onClick={async (e) => {
            e.stopPropagation();
            const activeElement = videoCreator.getActiveElement();
            if (activeElement) {
              await videoCreator.trackManager.rearrangeTracks(
                activeElement.track,
                'down',
              );
            }
          }}
        >
          <TrackDownIcon />
        </Action>
        <Action
          disabled={
            videoCreator.activeElementIds.length === 0 &&
            videoCreator.selectedTrack !== KARAOKE_TRACK_NUMBER
          }
          onKeyDown={(e) => {
            if (e.code === 'Space') e.preventDefault();
          }}
          onClick={async (e) => {
            e.stopPropagation();
            const activeElement = videoCreator.getActiveElement();
            if (activeElement && videoCreator.selectedVolumeKeyPoint) {
              videoCreator.deleteSelectedVolumeKeyPoint(
                activeElement.source.id,
              );
              return;
            } else if (activeElement) {
              await videoCreator.deleteElementWithTranscription(
                activeElement.source.id,
              );
              delete videoCreator.currentVideo!.extraElementData[
                `logo_el_${activeElement.source.id}`
              ];
              if (activeElement.source.type === 'video') {
                videoCreator.setTime(activeElement.globalTime, true);
              }
            } else if (videoCreator.selectedTrack === KARAOKE_TRACK_NUMBER) {
              videoCreator.deleteKaraokeElements();
            }
            // Default to the next element when previous element is deleted
            videoCreator.sidebarOptions = SidebarOption.media;
            videoCreator.mediaSubMenu = MediaCard.photo;
            const elements = videoCreator.state?.elements;
            if (elements?.length) {
              const videoElement = elements.find(
                (el) => el.source.type === 'video',
              );
              if (videoElement?.source.id) {
                videoCreator.setActiveElements(videoElement.source.id);
              }
            }
          }}
        >
          <DeleteIcon />
        </Action>
      </LeftActions>

      <ToggleHide>
        <Action onClick={toggleTimeline}>
          <VisibilityStatus>
            {' '}
            {percentToPixel > 40 ? 'Hide Timeline' : 'Show Timeline'}
          </VisibilityStatus>
          <ArrowDown onClick={toggleTimeline} rotate={percentToPixel > 40}>
            <ArrowDownIcon />
          </ArrowDown>
        </Action>
      </ToggleHide>
    </Main>
  );
});

export default TimelineControls;

const Main = styled.div`
  height: 36px;
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 30px;
`;

const ArrowDownIcon = () => (
  <svg width="10" height="6" viewBox="0 0 10 6" fill="none">
    <path d="M9 5.5L5 1.5L1 5.5" stroke="#F2D093" strokeWidth="2" />
  </svg>
);

const ArrowDown = styled.div<{ rotate: boolean }>`
  transform: ${(props) => props.rotate && 'rotate(180deg)'};
  display: flex;
  align-item: center;
`;

const LeftActions = styled.div`
  display: flex;
  gap: 8px;
  display: flex;
  align-items: center;
`;

const Action = styled.button<{ disabled?: boolean }>`
  background-color: transparent;
  outline: 0;
  border: 0;
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  svg {
    opacity: ${(props) => (props.disabled ? '40%' : 'auto')};
  }
`;

const Time = styled.div`
  display: flex;
  gap: 3px;
  font-size: 12px;
  margin-top: -3px;
  align-items: center;
  pointer-events: none;
  .current {
    opacity: 0.8;
    color: #f2d093;
    width: 60px;
    display: flex;
    justify-content: center;
  }
  .end {
    opacity: 0.4;
    width: 60px;
    display: flex;
    justify-content: center;
  }
`;

const ToggleHide = styled.div``;

const VisibilityStatus = styled.span`
  font-size: 12px;
  line-height: 14.52px;
  font-weight: 500;
  color: #484848;
  pointer-events: none;
`;

const ZoomControl = styled.div`
  display: flex;
  align-items: center;

  //     width: 68px;
  // height: 10px;
`;
