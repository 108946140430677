import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { get, set } from 'lodash';

import { formatUploadToArtifact } from '../../utility/general';
import { getFileNameFromURL } from '../../utility/files';
import { Artifact, FileData } from '../../types.ts/story';

import Accordion from './Accordion';
import Dropzone, { DropzoneAccept } from './Dropzone';
import AssetItem from './AssetItem';
import AddTile from './AddTile';

import DocumentIcon from '../../svgs/DocumentIcon';
import { updateStoryShowcase } from '../../utility/story';

import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';

type Props = {
  property:
    | 'organizationLogos'
    | 'organizationArtifacts'
    | 'organizationArtifactsVideo'
    | 'branding.designElements'
    | 'branding.companyReports';
};

const BrandKitMediaSection = observer((props: Props) => {
  const videoCreator = useVideoCreatorStore();
  const { property } = props;
  const [isUploading, setIsUploading] = useState(false);
  const items = get(videoCreator.organization, property);
  const { id, title, message, accept, gridSize } =
    getPropsFromProperty(property);
  return (
    <Accordion id={id} title={`${title}${items ? ` (${items.length})` : ''}`}>
      {(property.includes('branding')
        ? videoCreator.organization?.branding
        : items) !== undefined ? (
        <Dropzone
          isEmpty={!items?.length}
          message={message}
          accept={accept as DropzoneAccept}
          isUploading={isUploading}
          onDrop={async (files) => {
            if (!files.length) return;
            setIsUploading(true);
            const results = await Promise.all(
              files.map(async (file) => {
                const fileName = file.name.split('.')[0];
                const fileData: FileData & { fileName: string } = {
                  type: 'artifact',
                  file,
                  fileName,
                  alt: '',
                  title: property === 'organizationArtifacts' ? '' : fileName,
                };
                const newUpload =
                  await videoCreator.assetRepository?.uploadFile(fileData);
                return formatUploadToArtifact(newUpload!, {});
              }),
            );
            const organization = videoCreator.organization;
            set(organization!, property, [
              ...(get(organization, property) || []),
              ...results,
            ]);
            await videoCreator.albumRepository?.update(organization!);
            if (videoCreator.story) {
              updateStoryShowcase(videoCreator.story, organization!);
            }
            if (property === 'organizationArtifacts') {
              videoCreator.statusUpdateManager.addOrganizationArtifactsToWatch(
                organization!.id,
                results,
              );
            }
            setIsUploading(false);
          }}
          key={items?.map((i) => `${i.id}_${i.title}`).join(',')}
        >
          {({ openDialog }) => {
            return (
              <Container gridSize={gridSize}>
                {items?.length ? (
                  <>
                    {items.map((item) => (
                      <AssetItem
                        key={item.id}
                        asset={
                          property !== 'organizationArtifactsVideo'
                            ? item
                            : {
                                ...item,
                                title:
                                  item.title ||
                                  getFileNameFromURL(item.url, {
                                    stripPrefixId: true,
                                  }) ||
                                  'Untitled',
                                url: (item as Artifact).video?.thumbnailUrl,
                                downloadUrl: item.url,
                              }
                        }
                        onDelete={async (asset) => {
                          const organization = videoCreator.organization!;
                          const updated = get(organization, property)?.filter(
                            (i) => i.id !== asset.id,
                          );
                          set(videoCreator.organization!, property, updated);
                          set(organization, property, updated);
                          await videoCreator.albumRepository?.update(
                            organization,
                          );
                        }}
                        renderImage={
                          property === 'branding.companyReports'
                            ? () => (
                                <Document>
                                  <DocumentIcon width="40" height="45" />
                                </Document>
                              )
                            : undefined
                        }
                      />
                    ))}
                    <AddTile className="add-tile" onClick={openDialog} />
                  </>
                ) : null}
              </Container>
            );
          }}
        </Dropzone>
      ) : null}
    </Accordion>
  );
});

const getPropsFromProperty = (property: string) => {
  switch (property) {
    case 'organizationLogos':
      return {
        id: 'logos',
        title: 'Logos',
        message: 'Add your logo(s)',
        accept: 'images',
        gridSize: 140,
      };
    case 'organizationArtifacts':
      return {
        id: 'photos',
        title: 'Photos',
        message: 'Add your photo(s)',
        accept: 'images',
        gridSize: 160,
      };
    case 'organizationArtifactsVideo':
      return {
        id: 'videos',
        title: 'Videos',
        message: 'Add your video(s)',
        accept: 'videos',
        gridSize: 160,
      };
    case 'branding.designElements':
      return {
        id: 'design-elements',
        title: 'Design Elements',
        message: 'Add your design element(s)',
        accept: 'images',
        gridSize: 140,
      };
    case 'branding.companyReports':
      return {
        id: 'company-reports',
        title: 'Company Reports',
        message: 'Add your company report(s)',
        accept: 'documents',
        gridSize: 140,
      };
    default:
      return {
        id: '',
        title: '',
        message: '',
        accept: undefined,
        gridSize: 140,
      };
  }
};

const Container = styled.div<{ gridSize: number }>`
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(${({ gridSize }) => gridSize}px, 1fr)
  );
  gap: 24px;

  > .add-tile {
    align-self: center;
  }
`;

const Document = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  width: 100%;
  aspect-ratio: 1;
  border-radius: 8px;
  background: #484848;
  color: #f2d093;
`;

export default BrandKitMediaSection;
