import { useState, useEffect } from 'react';
import styled from 'styled-components';
import Modal from '../common/Modal';

export interface EditTimingModalProps {
  currentText: string;
  discard: () => void;
  currentTimestamp?: number;
  currentEndTimestamp?: number;
  changeTimestamp?: (ts: number, end_ts: number) => void;
  startBound: { ts: number; end_ts: number };
  endBound: { ts: number; end_ts: number };
  fps?: number; // Frames per second
}

export const EditTimingModal: React.FC<EditTimingModalProps> = ({
  currentText,
  currentTimestamp = 0,
  currentEndTimestamp = 0,
  changeTimestamp,
  discard,
  startBound,
  endBound,
  fps = 30, // Default to 30fps if not specified
}) => {
  const FRAME_TIMING_DECIMALS = 3;

  // Calculate frame duration in seconds
  const frameDuration = 1 / fps;

  // Convert initial times to frame numbers
  const initialStartFrame = Math.round(currentTimestamp * fps);
  const initialEndFrame = Math.round(currentEndTimestamp * fps);
  const initialDuration = initialEndFrame - initialStartFrame;

  const initialStartTime = currentTimestamp.toFixed(FRAME_TIMING_DECIMALS);
  const initialEndTime = currentEndTimestamp.toFixed(FRAME_TIMING_DECIMALS);

  const [startFrame, setStartFrame] = useState<number>(initialStartFrame);
  const [endFrame, setEndFrame] = useState<number>(initialEndFrame);

  const [error, setError] = useState<string | null>(null);
  const getBoundaryErrorMessage = (
    type: 'Start' | 'End',
    bound: { ts: number; end_ts: number },
  ): string => {
    return `${type} time must be between ${bound.ts.toFixed(
      FRAME_TIMING_DECIMALS,
    )}s and ${bound.end_ts.toFixed(
      FRAME_TIMING_DECIMALS,
    )}s to avoid conflicts with other elements.`;
  };

  const validateFrames = (start: number, end: number): string | null => {
    if (
      start < Math.round(startBound.ts * fps) ||
      start > Math.round(startBound.end_ts * fps)
    ) {
      return getBoundaryErrorMessage('Start', startBound);
    }
    if (
      end < Math.round(endBound.ts * fps) ||
      end > Math.round(endBound.end_ts * fps)
    ) {
      return getBoundaryErrorMessage('End', endBound);
    }
    if (start >= end) {
      return `Start time must be less than end time.`;
    }
    return null;
  };

  const validateAndSetFrames = (start: number, end: number) => {
    const validationError = validateFrames(start, end);
    setError(validationError);
    if (!validationError) {
      setStartFrame(start);
      setEndFrame(end);
    }
  };

  // Validate frames on initial load
  useEffect(() => {
    setError(validateFrames(startFrame, endFrame));
  }, [startFrame, endFrame]);

  const handleSave = () => {
    if (changeTimestamp && !error) {
      // Save only if there is no error
      // Convert frames back to time in seconds
      const startTime = startFrame / fps;
      const endTime = endFrame / fps;
      changeTimestamp(startTime, endTime);
      discard();
    }
  };

  const handleStartFrameChange = (value: string) => {
    const time = parseFloat(value);
    if (!isNaN(time)) {
      const frame = Math.round(time * fps);
      validateAndSetFrames(frame, endFrame);
    }
  };

  const handleEndFrameChange = (value: string) => {
    const time = parseFloat(value);
    if (!isNaN(time)) {
      const frame = Math.round(time * fps);
      validateAndSetFrames(startFrame, frame);
    }
  };

  const incrementStartTime = () => {
    validateAndSetFrames(startFrame + 1, endFrame);
  };

  const decrementStartTime = () => {
    validateAndSetFrames(startFrame - 1, endFrame);
  };

  const incrementEndTime = () => {
    validateAndSetFrames(startFrame, endFrame + 1);
  };

  const decrementEndTime = () => {
    validateAndSetFrames(startFrame, endFrame - 1);
  };

  return (
    <Modal isOpen={true} closeModal={discard}>
      <Wrapper>
        <Heading>Edit Timing ({fps} FPS) - BETA </Heading>
        <FieldSet>
          <FieldName>Editing Text:</FieldName>
          <CurrentText>"{currentText}"</CurrentText>

          <TimingContainer>
            <TimingData>
              <TimingLabel>Initial Start:</TimingLabel>
              <span>
                {initialStartTime}s (Frame {initialStartFrame})
              </span>
            </TimingData>
            <TimingData>
              <TimingLabel>Initial End:</TimingLabel>
              <span>
                {initialEndTime}s (Frame {initialEndFrame})
              </span>
            </TimingData>
            <TimingData>
              <TimingLabel>Initial Duration:</TimingLabel>
              <span>
                {(initialDuration / fps).toFixed(FRAME_TIMING_DECIMALS)}s
                (Frames {initialDuration})
              </span>
            </TimingData>
            <TimingData>
              <TimingLabel>Current Start:</TimingLabel>
              <span>
                {(startFrame / fps).toFixed(FRAME_TIMING_DECIMALS)}s (Frame{' '}
                {startFrame})
              </span>
            </TimingData>
            <TimingData>
              <TimingLabel>Current End:</TimingLabel>
              <span>
                {(endFrame / fps).toFixed(FRAME_TIMING_DECIMALS)}s (Frame{' '}
                {endFrame})
              </span>
            </TimingData>
          </TimingContainer>

          <FieldName>Adjust Timing:</FieldName>
          <InlineInputs>
            <InputLabel>
              Start Time (s):
              <InputContainer>
                <CustomButton onClick={decrementStartTime}>-</CustomButton>
                <TimeInput
                  type="number"
                  step={frameDuration}
                  value={(startFrame / fps).toFixed(FRAME_TIMING_DECIMALS)}
                  onChange={(e) => handleStartFrameChange(e.target.value)}
                />
                <CustomButton onClick={incrementStartTime}>+</CustomButton>
              </InputContainer>
            </InputLabel>
            <InputLabel>
              End Time (s):
              <InputContainer>
                <CustomButton onClick={decrementEndTime}>-</CustomButton>
                <TimeInput
                  type="number"
                  step={frameDuration}
                  value={(endFrame / fps).toFixed(FRAME_TIMING_DECIMALS)}
                  onChange={(e) => handleEndFrameChange(e.target.value)}
                />
                <CustomButton onClick={incrementEndTime}>+</CustomButton>
              </InputContainer>
            </InputLabel>
          </InlineInputs>
          {error && <ErrorMessage>{error}</ErrorMessage>}
        </FieldSet>
        <Buttons>
          <CancelButton onClick={discard}>Cancel</CancelButton>
          <SubmitButton onClick={handleSave} disabled={!!error}>
            Save
          </SubmitButton>
        </Buttons>
      </Wrapper>
    </Modal>
  );
};

const Wrapper = styled.div`
  width: 550px;
  min-height: 400px;
  border-radius: 16px;
  border: 1px solid #484848;
  padding: 24px;
  box-sizing: border-box;
  background: #03041a;
`;

const Heading = styled.div`
  color: #45d483;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.92px;
  text-transform: uppercase;
  margin-bottom: 16px;
`;

const FieldSet = styled.div`
  margin: 24px 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const InlineInputs = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
`;

const InputLabel = styled.label`
  display: flex;
  flex-direction: column;
  color: #f3e9d7;
  font-size: 12px;
  font-weight: 700;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4px;
`;

const TimeInput = styled.input`
  border: 1px solid #484848;
  padding: 8px;
  width: 100px;
  text-align: center;

  color: #f3e9d7;
  font-size: 12px;
  font-weight: 400;
  background: #03041a;
`;

const CustomButton = styled.button`
  border: 1px solid #484848;
  background: #1a1a1a;
  color: #f3e9d7;
  font-size: 16px;
  padding: 4px 8px;
  cursor: pointer;

  &:disabled {
    color: #555;
    cursor: not-allowed;
  }
`;

const FieldName = styled.div`
  color: #f3e9d7;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
`;

const CurrentText = styled.div`
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  background-color: #1a1a1a;
  padding: 8px;
  border-radius: 8px;
  margin-top: 8px;
`;

const TimingContainer = styled.div`
  padding: 12px;
  background-color: #f9f9f9;
  border-radius: 6px;
  font-size: 13px;
  color: #333;
`;

const TimingLabel = styled.span`
  font-weight: bold;
`;

const TimingData = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
`;

const Buttons = styled.div`
  display: flex;
  height: 48px;
  gap: 8px;
  cursor: pointer;
`;

const CancelButton = styled.div`
  box-sizing: border-box;
  flex: 1;
  display: flex;
  height: 48px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #17c964;

  font-size: 14px;
  font-weight: 700;

  color: #17c964;

  &:hover {
    font-size: 15px;
  }
`;

const SubmitButton = styled(CancelButton)<{ disabled: boolean }>`
  background: ${(props) => (props.disabled ? 'gray' : '#17c964')};
  color: ${(props) => (props.disabled ? '#808080' : '#03041a')};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
`;

const ErrorMessage = styled.div`
  color: #ff4d4d;
  font-size: 12px;
  margin-top: 8px;
`;

export default EditTimingModal;
