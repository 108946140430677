import { observer } from 'mobx-react-lite';

import styled, { css } from 'styled-components';
import {
  convertTimeToMMSS,
  getFormattedDurationForClip,
} from '../../utility/timeFormat';
import { useEffect, useRef, useState } from 'react';
import Modal from '../common/Modal';
import CopyContentModal from './CopyContentModal';
import CheckIcon from '../../svgs/CheckIcon';
import EllipsisIcon from '../../svgs/EllipsisIcon';
import { useOutsideAlerter } from '../transcript/useClickOutside';
import { VideoAction, AssociatedVideo, StoryVideo } from '../../types.ts/story';
import SpinningLoading from '../SpinningLoading';
import { XmlUpload } from '../xmlparser/XmlUpload';
import _uniqBy from 'lodash/uniqBy';
import _sortBy from 'lodash/sortBy';
import { useFlagsCombination } from '../../utility/useFlagsCombination';
import LastVideoAction from './LastVideoAction';
import PaperPlaneIcon from '../../svgs/PaperPlaneIcon';
import { AspectRatio, VideoResolution } from '../../types.ts/video';
import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';
import { useStore } from '@src/stores-v2/StoreContext';

type DropdownOption = {
  id: string;
  title: string;
  duration?: number | null;
  videoFilePrimary?: StoryVideo;
  associatedVideos?: AssociatedVideo[];
  aspectRatio?: AspectRatio;
  isHidden?: boolean;
  lastAction?: VideoAction;
  isClientReady?: boolean;
};

export const VideoSelector: React.FC = observer(() => {
  const userIdentityStore = useStore().userIdentityStore;
  const videoCreator = useVideoCreatorStore();
  const { enableImportXml } = useFlagsCombination(
    videoCreator.datoContext.currentRole,
  );
  const currentParentVideo =
    videoCreator.currentVideo?.parentVideo || videoCreator.currentVideo;
  const currentVideoOption = currentParentVideo
    ? ({
        id: currentParentVideo.id,
        title: currentParentVideo.title,
        duration: getFormattedDurationForClip(currentParentVideo).duration,
        videoFilePrimary: currentParentVideo.videoFilePrimary,
        associatedVideos: currentParentVideo.associatedVideos,
        aspectRatio: currentParentVideo.aspectRatio,
        isHidden: currentParentVideo.isHidden,
        lastAction: currentParentVideo.lastActionJson,
        isClientReady: currentParentVideo.isClientReady,
      } as DropdownOption)
    : null;
  const storyFinalVideo = videoCreator.story?.finalVideo;

  const originalStoryOption = {
    id: 'original_story',
    title: videoCreator.story?.title,
    duration: videoCreator.story?.originalVideo?.video.duration,
  } as DropdownOption;

  let allVideoOptions: DropdownOption[] = [];
  if (currentVideoOption && !currentVideoOption.id) {
    allVideoOptions.push(currentVideoOption);
  }
  if (videoCreator.story?.otherVideos?.length) {
    allVideoOptions = allVideoOptions.concat(
      videoCreator.story?.otherVideos.map((video) => ({
        id: video.id!,
        title: video.title,
        duration: getFormattedDurationForClip(video).duration,
        videoFilePrimary: video.videoFilePrimary,
        associatedVideos: video.associatedVideos,
        aspectRatio: video.aspectRatio,
        isHidden: video.isHidden,
        lastAction: video.lastActionJson,
        isClientReady: video.isClientReady,
      })),
    );
  }

  allVideoOptions.push(originalStoryOption);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [newVideoModal, setNewVideoModal] = useState<{
    type: 'save_as' | 'rename';
    video: DropdownOption | null;
  } | null>(null);
  const [actionProgress, setActionProgress] = useState<string>('');
  const [editingCurrentVideo, setEditingCurrentVideo] =
    useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [currentVideoTitle, setCurrentVideoTitle] = useState<string>('');

  const [optionsModal, setOptionsModal] = useState<{
    position: number;
    video: DropdownOption | null;
  } | null>(null);
  const buttonRef = useRef<HTMLDivElement>(null);
  const [isMouseDown, setIsMouseDown] = useState<boolean>(false);
  const clickTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const dropdownOpenRef = useRef<boolean>(false);
  const editingCurrentVideoRef = useRef<boolean>(false);

  useOutsideAlerter(buttonRef, () => {
    setOptionsModal(null);
  });

  async function copyVideo(video: DropdownOption, newTitle: string) {
    if (video.id !== 'original_story') {
      await videoCreator.copyVideo(video.id, newTitle, 'creator-studio');
    } else {
      await videoCreator.createNewVideoFromSource(newTitle);
    }
    videoCreator.addUnsaveVideoAction();
  }

  async function deleteVideo(videoId?: string) {
    if (videoId === 'original_story') return;

    const idx = allVideoOptions.findIndex((data) => data.id === videoId);
    let nextVideoIdx = idx + 1;
    if (nextVideoIdx === allVideoOptions.length - 1) {
      nextVideoIdx = idx - 1;
    }

    await videoCreator.removeStoryVideo(videoId);

    // Load the next video
    if (videoId === videoCreator.currentVideo!.id) {
      selectVideo(
        nextVideoIdx >= 0 ? allVideoOptions[nextVideoIdx].id : 'original_story',
      );
    }
    setOptionsModal(null);
  }

  async function makeFeatureVideo(videoId: string) {
    try {
      setOptionsModal(null);

      if (videoId === 'original_story') {
        await videoCreator.createNewVideoFromSource();
      } else if (videoId && videoId !== videoCreator.currentVideo?.id) {
        await videoCreator.loadVideo(videoId);
      }

      await videoCreator.saveStoryAndVideo(true);
      await videoCreator.finishVideo(VideoResolution.High);
    } catch (error) {
      console.error(error);
      videoCreator.renderingStatus = 'error';
    }
  }

  function selectVideo(videoId: string) {
    // todo confirm saving or discard changes.
    if (videoId === currentVideoOption?.id) {
      return;
    } else if (videoId === 'original_story') {
      videoCreator.createNewVideoFromSource();
      videoCreator.addUnsaveVideoAction();
    } else {
      videoCreator.loadVideo(videoId);
    }
  }

  function getColor(video: DropdownOption) {
    let videoId = video.id;
    if (!videoId && currentVideoOption?.id) return '#484848';
    if (
      videoId === 'original_story' ||
      (video.videoFilePrimary && !video.isHidden) ||
      videoId === currentVideoOption?.id
    ) {
      return '#17C964';
    }
    return '#484848';
  }

  function getClipType(video: DropdownOption) {
    let videoId = video.id;
    if (!videoId) return 'Social Clip';

    if (videoId === 'original_story') {
      return 'Original';
    }

    if (videoId === storyFinalVideo?.id) {
      return 'Feature';
    }
    return 'Social Clip';
  }

  function renderVideoDimensions(video: DropdownOption) {
    if (!video.id && !video.associatedVideos?.length) return null;
    const data: { id: string; format: AspectRatio }[] = [];
    if (video.id && video.id !== 'original_story') {
      data.push({
        id: video.id,
        format:
          video.videoFilePrimary?.customData?.aspectRatio || video?.aspectRatio,
      });
    }

    if (video.associatedVideos?.length) {
      const associatedVideos =
        video.associatedVideos.map((v) => ({
          id: v.id!,
          format: v.videoFilePrimary?.customData.aspectRatio || v.aspectRatio,
        })) || [];

      data.push(...associatedVideos);
    }

    const videoData = _sortBy(_uniqBy(data, 'format'), 'format');

    return (
      <>
        {videoData.map((v) => (
          <VideoAspectRatio
            key={v.id}
            isSelected={video.id === currentVideoOption?.id}
            role="button"
            onClick={async (e) => {
              e.stopPropagation();
              setDropdownOpen(false);
              await videoCreator.loadVideo(v.id);
            }}
          >
            {v.format}
          </VideoAspectRatio>
        ))}
      </>
    );
  }

  const renderDropdownItem = (video: DropdownOption) => (
    <DropdownItem
      key={video.id}
      isSelected={video.id === currentVideoOption?.id}
      onClick={() => {
        setDropdownOpen(false);
        selectVideo(video.id);
      }}
    >
      <DropdownItemRow>
        <Title isSelected={currentVideoOption?.id === video.id}>
          <ClipType>
            <Check color={getColor(video)}>
              <CheckIcon fillColor="#03041A" />
            </Check>

            {getClipType(video)}
          </ClipType>
          <p>{video.title}</p>
        </Title>
        <VideoIconsAndAspectRatios>
          {video.isClientReady && userIdentityStore.isInternalUser() && (
            <PaperPlaneIconContainer>
              <PaperPlaneIcon strokeColor="#f2d093" width="14" height="14" />
            </PaperPlaneIconContainer>
          )}
          {renderVideoDimensions(video)}
        </VideoIconsAndAspectRatios>
        <TimeAndEllipsis>
          <Duration>
            {video.duration ? convertTimeToMMSS(video.duration) : '--:--'}
          </Duration>

          <EllipsisButton
            onClick={(e) => {
              const rect = e.currentTarget.getBoundingClientRect();
              setOptionsModal({
                position: rect.top,
                video,
              });
              e.stopPropagation();
            }}
          >
            <EllipsisIcon fillColor="currentColor" width="10" />
          </EllipsisButton>
        </TimeAndEllipsis>
      </DropdownItemRow>
      {video.lastAction && (
        <DropdownItemRow>
          <LastVideoAction lastAction={video.lastAction} />
        </DropdownItemRow>
      )}
    </DropdownItem>
  );

  const renderActionDropdown = (
    video: DropdownOption | null,
    top: number | null = null,
  ) => (
    <Actions ref={buttonRef} isVisible={true} top={top}>
      <RenameButton
        onClick={(e) => {
          setNewVideoModal({ type: 'rename', video });
          e.stopPropagation();
        }}
      >
        <span>Rename</span>
      </RenameButton>
      <SaveAsButton
        onClick={(e) => {
          setNewVideoModal({ type: 'save_as', video });
          e.stopPropagation();
        }}
      >
        <span>Save As</span>
      </SaveAsButton>
      <FeatureStoryButton onClick={() => makeFeatureVideo(video?.id!)}>
        <span>Make Feature Story</span>
      </FeatureStoryButton>
      <DeleteButton
        onClick={async () => {
          setActionProgress('Deleting video...');
          await deleteVideo(video?.id);
          setActionProgress('');
        }}
      >
        <span>Delete</span>
      </DeleteButton>
    </Actions>
  );

  useEffect(() => {
    if (!editingCurrentVideoRef.current) return;
    setCurrentVideoTitle(currentVideoOption?.title || '');
    inputRef.current?.focus();
    inputRef.current?.setSelectionRange(
      inputRef.current.value.length,
      inputRef.current.value.length,
    );
  }, [editingCurrentVideoRef.current, inputRef.current]);

  const handleClick = () => {
    clickTimeoutRef.current = setTimeout(() => {
      dropdownOpenRef.current = true;
      if (!editingCurrentVideoRef.current) setDropdownOpen((open) => !open);
    }, 200);
  };

  const handleShowEditInput = () => {
    editingCurrentVideoRef.current = true;
    setEditingCurrentVideo(true);
    setDropdownOpen(false);
  };

  const toggleEditingInput = () => {
    if (isMouseDown) return;
    setEditingCurrentVideo(false);
    editingCurrentVideoRef.current = false;
  };

  const handleDoubleClick = () => {
    if (clickTimeoutRef.current) {
      clearTimeout(clickTimeoutRef.current);
    }

    handleShowEditInput();
  };

  const handleRenameCurrentVideo = async () => {
    const title = currentVideoTitle || '';
    if (title !== currentVideoOption?.title) {
      setActionProgress('Renaming video...');
      await videoCreator.renameVideo(currentVideoOption?.id!, title);
      setActionProgress('');
    }
  };

  const handleToggleEditingVideo = async (event: MouseEvent) => {
    if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
      if (!isMouseDown) toggleEditingInput();
    }
  };

  const handleMouseDown = () => {
    setIsMouseDown(true);
  };

  const handleMouseUp = (event: MouseEvent) => {
    if (
      inputRef.current &&
      !inputRef.current.contains(event.target as Node) &&
      !window.getSelection()?.toString()
    ) {
      setIsMouseDown(false);
    }
  };

  useEffect(() => {
    window.addEventListener('click', handleToggleEditingVideo);
    window.addEventListener('mousedown', handleMouseDown);
    window.addEventListener('mouseup', handleMouseUp);
    return () => {
      window.removeEventListener('click', handleToggleEditingVideo);
      window.removeEventListener('mousedown', handleMouseDown);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isMouseDown]);

  return (
    <Wrapper>
      <Item
        onClick={handleClick}
        onDoubleClick={handleDoubleClick}
        onBlur={handleRenameCurrentVideo}
        isEditing={editingCurrentVideo}
        ref={containerRef}
      >
        {editingCurrentVideoRef.current ? (
          <>
            <PrimaryVideoInput
              ref={inputRef}
              value={currentVideoTitle}
              placeholder="Enter clip name"
              onChange={(e) => setCurrentVideoTitle(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleRenameCurrentVideo();
                  setEditingCurrentVideo(false);
                  editingCurrentVideoRef.current = false;
                }
              }}
            />
          </>
        ) : (
          <>
            <WrapperItemContent onDoubleClick={handleShowEditInput}>
              <MainTitle
                active={dropdownOpen}
                onDoubleClick={handleShowEditInput}
              >
                {currentVideoOption?.title}
              </MainTitle>
              <StorytellerName
                active={dropdownOpen}
                onDoubleClick={handleShowEditInput}
              >
                {videoCreator.story?.storyTeller.name}
              </StorytellerName>
            </WrapperItemContent>
            <DropdownIcon isOpen={dropdownOpen}>
              <ArrowIcon />
            </DropdownIcon>
          </>
        )}
      </Item>

      {editingCurrentVideoRef.current && (
        <DropdownBackground
          onClick={() => {
            toggleEditingInput();
            setDropdownOpen(false);
          }}
        />
      )}

      {dropdownOpen && (
        <>
          <DropdownBackground
            onClick={() => {
              setDropdownOpen(false);
            }}
          ></DropdownBackground>
          <Dropdown>
            {!!actionProgress && <SpinningLoading text={actionProgress} />}
            <DropdownScrollableArea>
              {allVideoOptions.map((video) => renderDropdownItem(video))}
              {enableImportXml && (
                <DropdownItem
                  onClick={() => {
                    // setDropdownOpen(false);
                  }}
                >
                  <XmlUpload
                    onUpload={() => {
                      setDropdownOpen(false);
                    }}
                  />
                </DropdownItem>
              )}
            </DropdownScrollableArea>
          </Dropdown>
          {optionsModal?.video &&
            renderActionDropdown(optionsModal.video, optionsModal.position)}
        </>
      )}

      {newVideoModal && (
        <Modal
          isOpen={newVideoModal !== null}
          closeModal={() => setNewVideoModal(null)}
        >
          <CopyContentModal
            name={
              (newVideoModal.video?.id === 'original_video' ? 'Copy of ' : '') +
              newVideoModal.video?.title
            }
            tag="Story"
            type={newVideoModal.type}
            onCancel={() => setNewVideoModal(null)}
            onSave={async (storyName) => {
              setNewVideoModal(null);
              if (newVideoModal?.type === 'rename') {
                setActionProgress('Renaming video...');
                await videoCreator.renameVideo(
                  newVideoModal.video?.id!,
                  storyName,
                );
              } else {
                setActionProgress('Copying video data...');
                await copyVideo(newVideoModal.video!, storyName);
              }
              setActionProgress('');

              setDropdownOpen(false);
            }}
          />
        </Modal>
      )}
    </Wrapper>
  );
});

const ArrowIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="6"
    viewBox="0 0 10 6"
    fill="none"
  >
    <path d="M9 5.5L5 1.5L1 5.5" stroke="currentColor" strokeWidth="2" />
  </svg>
);

const WrapperItemContent = styled.div`
  flex: 1;
`;

const DropdownIcon = styled.div<{ isOpen: boolean }>`
  width: 10px;
  height: 6px;
  margin-left: auto;
  display: flex;
  color: #f2d093;

  ${(props) => !props.isOpen && `rotate: 180deg;`}
`;

const Wrapper = styled.div`
  font-family: Inter, sans-serif;
  position: relative;
  min-width: 500px;
  height: fit-content;
`;

const CopyButton = styled.div`
  background: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  padding: 0 6px;
  margin-right: -6px;

  &:hover {
    color: #f2d093;
    scale: 1.1;
  }
`;

const EllipsisButton = styled.div`
  background: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  padding: 0 6px;
  margin-right: -6px;

  &:hover {
    color: #f2d093;
    scale: 1.1;
  }
`;

const MainTitle = styled.div<{ active: boolean }>`
  font-size: 14px;
  font-weight: 700;
  color: ${(props) => props.active && '#17c964'};
`;

const StorytellerName = styled.div<{ active: boolean }>`
  color: ${(props) => (props.active ? '#17c964' : '#a9a9a9')};
  font-size: 10px;
  font-weight: 400;
`;

const Title = styled.div<{ isSelected: boolean }>`
  color: #f3e9d7;
  font-size: 12px;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  gap: 4px;
  p {
    margin: 0;
  }
`;

const PaperPlaneIconContainer = styled.div`
  display: flex;
  align-self: center;
  margin: 0 8px;
`;

const Duration = styled.div`
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  min-width: 28px;
  text-align: right;
  // white-space: nowrap;
`;

const TimeAndEllipsis = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const Item = styled.div<{ isEditing: boolean }>`
  width: 100%;
  border: 1px solid #484848;
  border-radius: 8px;
  padding: ${(props) => (props.isEditing ? '0' : '8px 16px')};
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
`;

const Dropdown = styled.div`
  position: absolute;
  z-index: 1000;
  top: 60px;
  width: 100%;
  border: 1px solid #484848;
  border-radius: 8px;
  background-color: #030419;
  padding: 8px 0;
  box-sizing: border-box;
`;

const DropdownScrollableArea = styled.div`
  max-height: 500px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  @media only screen and (max-height: 950px) {
    max-height: 450px;
  }
  @media only screen and (max-height: 810px) {
    max-height: 400px;
  }
  @media only screen and (max-height: 700px) {
    max-height: 350px;
  }
  @media only screen and (max-height: 600px) {
    max-height: 300px;
  }
  @media only screen and (max-height: 500px) {
    max-height: 250px;
  }
  @media only screen and (max-height: 430px) {
    max-height: 200px;
  }
  @media only screen and (max-height: 344px) {
    max-height: 150px;
  }
`;

const DropdownBackground = styled.div`
  position: fixed;
  z-index: 999;
  inset: 0;
  box-sizing: border-box;
`;

const DropdownItemRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

const DropdownItem = styled.div<{ isSelected?: boolean }>`
  color: #484848;
  font-size: 12px;
  font-weight: 200;
  line-height: normal;
  padding: 8px 16px;
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;

  &:hover {
    background-color: #484848;
    color: ${(props) => (props.isSelected ? '#17c964' : '#f2d093')};
    p {
      color: ${(props) => (props.isSelected ? '#17c964' : '#f2d093')};
      font-weight: 700;
    }
    * {
      border-color: ${(props) => (props.isSelected ? '#17c964' : '#f2d093')};
    }
  }

  &:not(:hover):not(:last-child)::after {
    content: '';
    position: absolute;
    height: 1px;
    width: calc(100% - 32px);
    left: 16px;
    background-color: #484848;
    bottom: -1px;
  }

  ${(props) =>
    props.isSelected &&
    css`
      * {
        color: #17c964;
        border-color: #17c964;
      }
    `}
`;

const Check = styled.div<{ color: string }>`
  display: flex;
  width: 10px;
  height: 10px;
  padding: 2px;
  justify-content: center;
  align-items: center;
  border-radius: 80px;
  background: ${(props) => props.color};
`;

const ClipType = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  font-style: italic;
  font-size: 10px;
  font-weight: 400;
`;

const Actions = styled.div<{ isVisible: boolean; top: number | null }>`
  display: ${(props) => (props.isVisible ? 'flex' : 'none')};
  position: absolute;
  top: ${(props) => (props.top ? props.top + 10 : '20')}px;
  right: ${(props) => props.top && '-80'}px;
  left: ${(props) => !props.top && '0'}px;
  z-index: 2000;
  flex-direction: column;

  background: #03041a;
  border: 1px solid #484848;
  border-radius: 8px;
  font-size: 12px;
`;

const Action = styled.div`
  cursor: pointer;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  align-items: center;

  span {
    white-space: nowrap;
  }
  .icon {
    margin-left: auto;
    display: flex;
  }
  &:not(:first-child) {
    border-top: 1px solid #d9d9d9;
  }

  &:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  &:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &:hover {
    background-color: #17c964;
    color: #03041a;
  }
`;

const RenameButton = styled(Action)``;

const SaveAsButton = styled(Action)``;
const FeatureStoryButton = styled(Action)``;

const DeleteButton = styled(Action)`
  &:hover {
    background-color: #ef5d6f;
  }
`;

const VideoIconsAndAspectRatios = styled.div`
  display: flex;
  gap: 5px;
  margin-left: auto;
`;

const VideoAspectRatio = styled.div<{ isSelected?: boolean }>`
  width: 37px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  border: 1px solid ${(props) => (props.isSelected ? '#17c964' : '#484848')};
  border-radius: 4px;

  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  &:hover {
    font-size: 12px;
  }
`;

const PrimaryVideoInput = styled.input`
  width: 100%;
  height: 100%;
  min-height: 50px;
  color: #f3e9d7;
  background-color: transparent;
  padding: 0 16px;

  outline: none;
  border: 2px solid #17c964;
  border-radius: 8px;
  z-index: 1000;
`;
