import { useCallback, useMemo, useState } from 'react';

/**
 * Custom hook to manage the state of a request.
 *
 * This hook provides a way to handle common states such as saving,
 * saved, and error when executing asynchronous requests.
 */
const useRequestState = () => {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean | null>(null);

  const request = useCallback(async (cb: () => Promise<void>) => {
    setIsSaving(true);
    setIsError(null);
    try {
      await cb();
      setIsError(false);
    } catch (err) {
      console.error('Request failed', err);
      setIsError(true);
    } finally {
      setIsSaving(false);
    }
  }, []);

  const state = useMemo(
    () => ({
      isSaving: !!isSaving,
      isError: !!isError,
      isSaved: !isSaving && isError === false,
      request,
    }),
    [isSaving, isError, request],
  );

  return state;
};

export default useRequestState;
