import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';

import Accordion from './Accordion';

const BrandKitWebsite = observer(() => {
  const videoCreator = useVideoCreatorStore();
  const branding = videoCreator.organization?.branding;
  const website = branding?.organizationWebsite;
  const [value, setValue] = useState('');

  useEffect(() => {
    if (website) setValue(website);
  }, [Boolean(website)]);

  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const handleSave = async () => {
    setIsSaving(true);
    setIsSaved(false);
    const organization = videoCreator.organization;
    organization!.branding = {
      ...organization!.branding!,
      organizationWebsite: value.trim(),
    };
    await videoCreator.albumRepository?.update(organization!);
    setIsSaving(false);
    setIsSaved(true);
  };

  const isChanged = value !== website;

  return (
    <Accordion id="org-website" title="Organization Website" isSaved={isSaved}>
      <Container isSaving={isSaving}>
        <div className="input-wrapper">
          <input
            type="url"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            placeholder="Enter your organization's website"
          />
        </div>
        <div className="actions">
          <button disabled={isSaving || !isChanged} onClick={handleSave}>
            {isSaving ? 'Saving...' : 'Save'}
          </button>
        </div>
      </Container>
    </Accordion>
  );
});

const Container = styled.div<{ isSaving?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 24px;

  .input-wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    border: 1px solid #484848;
    border-radius: 8px;

    &:focus-within {
      border-color: #f3e9d7;
    }

    > input {
      color: #f3e9d7;
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      background: transparent;
      outline: none;
      border: none;
      resize: none;
      padding: 24px;
      &::placeholder {
        color: #848484;
      }
    }
  }

  .actions {
    align-self: flex-end;

    > button {
      display: block;
      height: 48px;
      padding: 12px 24px;
      border-radius: 8px;
      background: #17c964;
      color: #03041a;
      font-size: 14px;
      font-weight: 700;
      border: none;
      transition: 0.2s;
      opacity: ${({ isSaving }) => (isSaving ? 0.6 : 1)};
      cursor: ${({ isSaving }) => (isSaving ? 'not-allowed' : 'pointer')};
      &:disabled {
        color: #848484;
        border: 1px solid #848484;
        background: transparent;
        cursor: not-allowed;
      }
      &:hover:not(:disabled) {
        filter: brightness(1.2) saturate(1.2);
      }
    }
  }
`;

export default BrandKitWebsite;
