/* eslint-disable react/jsx-no-target-blank */
import React, { Fragment } from 'react';
import { ElementState } from '../../renderer/ElementState';
import { AudioPreset } from './AudioPreset';
import { PropertyCaption } from './PropertyCaption';
import { PropertyNumericalInput } from './PropertyNumericalInput';

import { observer } from 'mobx-react-lite';
import { ExtraElementData, VolumeKeyPoint } from '../../types.ts/story';
import { numericalInputStyles } from '../../styles/mainStyle';
import styled from 'styled-components';
import { runInAction } from 'mobx';
import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';

interface AudioSettingsProps {
  activeElement: ElementState;
}

export const AudioSettings: React.FC<AudioSettingsProps> = observer((props) => {
  const videoCreator = useVideoCreatorStore();
  const volumeKeyPoints = (
    videoCreator.currentVideo?.extraElementData[
      props.activeElement.source?.id
    ] as ExtraElementData | null
  )?.volumeKeyPoints;

  const toFixed = (value: unknown, precision = 2): string => {
    const number = parseFloat(String(value));
    return String(Number(number.toFixed(precision)));
  };

  const setKeypointVolume = async (propertyName: string, value: any) => {
    if (volumeKeyPoints && volumeKeyPoints.length > 0) {
      if (propertyName === 'start') {
        volumeKeyPoints[0].value = value;
      } else {
        volumeKeyPoints[volumeKeyPoints.length - 1].value = value;
      }

      videoCreator.applyVolumeKeyPoints(
        props.activeElement.source.id,
        volumeKeyPoints,
      );
    }
  };

  const selectedVolumeKeyPointIndex = (volumeKeyPoints || []).findIndex((p) =>
    videoCreator.isSelectedVolumeKeyPoint(p),
  );
  const selectedVolumeKeyPoint =
    selectedVolumeKeyPointIndex === -1
      ? null
      : volumeKeyPoints![selectedVolumeKeyPointIndex];

  const updateSelectedKeyPoint = (propertyName: string, value: any) => {
    if (!selectedVolumeKeyPoint || !volumeKeyPoints) {
      return;
    }
    const keyPointField = propertyName.split('=')[1] as keyof VolumeKeyPoint;

    runInAction(() => {
      volumeKeyPoints[selectedVolumeKeyPointIndex][keyPointField] = value;
      videoCreator.applyVolumeKeyPoints(
        props.activeElement.source.id,
        volumeKeyPoints,
      );

      videoCreator.selectVolumeKeyPoint({
        ...selectedVolumeKeyPoint,
        [keyPointField]: value,
      });
    });
  };

  return (
    <Fragment>
      <AudioPreset
        activeElement={props.activeElement}
        url={props.activeElement.source.source}
      />

      <div>
        <div>
          <PropertyCaption>Start</PropertyCaption>
          <PropertyNumericalInput
            activeElement={props.activeElement}
            propertyName="time"
            defaultValue="auto"
            unit="s"
            customStyles={numericalInputStyles}
          ></PropertyNumericalInput>
        </div>

        <div>
          <PropertyCaption>Duration</PropertyCaption>
          <PropertyNumericalInput
            activeElement={props.activeElement}
            propertyName="duration"
            defaultValue="auto"
            unit="s"
            customStyles={numericalInputStyles}
          ></PropertyNumericalInput>
        </div>

        {volumeKeyPoints && volumeKeyPoints?.length > 0 ? (
          <>
            <div>
              <PropertyCaption>Start Volume</PropertyCaption>
              <PropertyNumericalInput
                activeElement={props.activeElement}
                getInputValue={setKeypointVolume}
                propertyName="start"
                defaultValue={toFixed(volumeKeyPoints[0].value)}
                unit="%"
                customStyles={numericalInputStyles}
              ></PropertyNumericalInput>
            </div>
            <div>
              <PropertyCaption>End Volume</PropertyCaption>
              <PropertyNumericalInput
                activeElement={props.activeElement}
                getInputValue={setKeypointVolume}
                propertyName="end"
                defaultValue={toFixed(
                  volumeKeyPoints[volumeKeyPoints.length - 1].value,
                )}
                unit="%"
                customStyles={numericalInputStyles}
              ></PropertyNumericalInput>
            </div>
            {selectedVolumeKeyPoint && (
              <>
                <div>
                  <PropertyCaption>Key Frame Volume</PropertyCaption>
                  <PropertyNumericalInput
                    activeElement={props.activeElement}
                    getInputValue={updateSelectedKeyPoint}
                    propertyName="key-frame=value"
                    defaultValue={toFixed(selectedVolumeKeyPoint.value)}
                    unit="%"
                    customStyles={numericalInputStyles}
                  ></PropertyNumericalInput>
                </div>
                <div>
                  <PropertyCaption>Key Frame Time</PropertyCaption>
                  <PropertyNumericalInput
                    activeElement={props.activeElement}
                    getInputValue={updateSelectedKeyPoint}
                    propertyName="key-frame=time"
                    defaultValue={toFixed(selectedVolumeKeyPoint.time)}
                    unit="s"
                    customStyles={numericalInputStyles}
                  ></PropertyNumericalInput>
                </div>
              </>
            )}
          </>
        ) : (
          <div>
            <PropertyCaption>Volume</PropertyCaption>
            <PropertyNumericalInput
              activeElement={props.activeElement}
              propertyName="volume"
              defaultValue="100"
              unit="%"
              customStyles={numericalInputStyles}
            ></PropertyNumericalInput>
          </div>
        )}

        <div>
          <PropertyCaption>Fade In</PropertyCaption>
          <PropertyNumericalInput
            activeElement={props.activeElement}
            propertyName="audio_fade_in"
            defaultValue="0"
            unit="s"
            customStyles={numericalInputStyles}
          ></PropertyNumericalInput>
        </div>

        <div>
          <PropertyCaption>Fade Out</PropertyCaption>
          <PropertyNumericalInput
            activeElement={props.activeElement}
            propertyName="audio_fade_out"
            defaultValue="0"
            unit="s"
            customStyles={numericalInputStyles}
          ></PropertyNumericalInput>
        </div>
      </div>
    </Fragment>
  );
});

// const Information = styled.div`
//   margin-top: 20px;
//   color: #a3a5a5;

//   a {
//     color: #a3a5a5;
//   }
// `;

const GroupContent = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 10px;
`;
