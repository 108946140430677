import { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import Content from './Content';
import { observer } from 'mobx-react-lite';
import { runInAction } from 'mobx';
import SpinningLoading from '../../SpinningLoading';
import { TalkingPointContent } from '../../../types.ts/general';
import { useFlagsCombination } from '../../../utility/useFlagsCombination';
import { handleCopyToClipboard } from '../../../utility/general';
import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';
import ContentActions from '../ContentActions';
import { useTemplatedPromptsStore } from '@src/stores-v2/StoreContext';
import { analytics } from '@src/utility/analytics';
import ChatGPTService from '@src/services/ChatGPTService';
import BrandVoiceRevisionModal from '../BrandVoiceRevisionModal';

type Revision = (keyof TalkingPointContent & 'all') | null;

const applyH2Style = (h2: HTMLHeadingElement | null) => {
  if (h2) {
    h2.style.fontSize = '32px';
    h2.style.fontWeight = '700';
  }
};

const applyTitleStyles = (e: Element | null) => {
  if (e) {
    const title = e as HTMLElement;
    title.style.fontSize = '24px';
    title.style.fontWeight = '700';
    title.style.marginBottom = '0';
    title.style.marginTop = '0';
  }
};
const applyStoryTellerStyles = (e: Element | null) => {
  if (e) {
    const storyTeller = e as HTMLElement;
    storyTeller.style.fontSize = '16px';
    storyTeller.style.fontWeight = '400';
    storyTeller.style.lineHeight = '22.4px';
  }
};

const applyStoryInfoStyle = (e: Element | null) => {
  if (e) {
    const storyInfo = e as HTMLElement;
    storyInfo.style.marginBottom = '15px';
    storyInfo.style.gap = '5px';
  }
};

const modifyContentBlockBeforeCopy = (clonedContent: HTMLElement) => {
  const regenerateActions = clonedContent?.querySelectorAll(
    '.regenerate-action-button',
  );
  regenerateActions?.forEach((regenerateAction) => {
    regenerateAction.remove();
  });

  // Apply all styles to copied content
  applyH2Style(clonedContent?.querySelector('h2'));
  applyTitleStyles(clonedContent?.querySelector('.story-title'));
  applyStoryInfoStyle(clonedContent?.querySelector('.story-info'));
  applyStoryTellerStyles(clonedContent?.querySelector('.story-teller'));

  const titles = clonedContent?.querySelectorAll('.title');
  titles?.forEach((title) => {
    applyTitleStyles(title);
  });

  const strongs = clonedContent?.querySelectorAll('p strong');
  strongs?.forEach((strong) => {
    (strong as HTMLElement).style.fontWeight = '800';
  });

  const blockquotes = clonedContent?.querySelectorAll('blockquote');
  blockquotes?.forEach((blockquote) => {
    blockquote.style.fontStyle = 'italic';
  });

  const uls = clonedContent?.querySelectorAll('ul');
  uls?.forEach((ul) => {
    ul.style.listStyleType = 'disc';
  });

  const ols = clonedContent?.querySelectorAll('ol');
  ols?.forEach((ol) => {
    ol.style.listStyleType = 'none';
  });

  const lis = clonedContent?.querySelectorAll('li');
  lis?.forEach((li) => {
    li.style.fontSize = '16px';
    li.style.lineHeight = '1.6';
    li.style.marginBottom = '20px';
  });

  const liOl = clonedContent?.querySelectorAll('ol > li');
  liOl?.forEach((liElement) => {
    const li = liElement as HTMLElement;
    li.style.fontStyle = 'italic';
    li.style.marginBottom = '20px';
  });
};

const TalkingPointStudio = observer(() => {
  const videoCreator = useVideoCreatorStore();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const [revision, toggleRevisionModal] = useState<{
    isOpen: boolean;
    value: (keyof TalkingPointContent & 'all') | null;
  }>({ isOpen: false, value: null });

  useEffect(() => {
    const savedContent = videoCreator.story?.savedTalkingPointContent;
    if (!videoCreator.talkingPointContent && savedContent) {
      const ids = Object.keys(savedContent) as unknown as number[];
      const id = Math.max(...ids);
      const lastSaved = savedContent[id];
      videoCreator.talkingPointContent = lastSaved.content;
    }
  }, []);

  const content = videoCreator.talkingPointContent;
  const contentIsEmpty =
    !content || (content && !Object.values(content).length);

  const {
    enableTalkingPointsStudioSave,
    enableTalkingPointsStudioRegeneration,
  } = useFlagsCombination(videoCreator.datoContext.currentRole);

  const handleSaveContent = async () => {
    if (contentIsEmpty || !contentRef.current) return;
    try {
      setIsSaving(true);

      const keys = Object.keys(
        videoCreator.talkingPointContent!,
      ) as (keyof TalkingPointContent)[];

      const content = {} as Record<
        keyof TalkingPointContent,
        Record<'title' | 'content' | 'prompt', string>
      >;

      for (let key of keys) {
        const field = contentRef.current.querySelector(`#talkingpoint-${key}`);
        if (!field) continue;
        const title = field.querySelector('.title')?.innerHTML;
        const fieldContent = field.querySelector('.content')?.innerHTML;
        let prompt = '';
        if (videoCreator.talkingPointContent) {
          prompt = videoCreator.talkingPointContent[key].prompt;
        }
        content[key] = { title: title!, content: fieldContent!, prompt };
      }

      const title = 'Fundraising Talking Points';
      const updatedTalkingPointContent =
        await videoCreator.storyRepository?.saveTalkingPointContent(
          videoCreator.story!,
          content,
          title,
        );

      runInAction(
        () =>
          (videoCreator.story!.savedTalkingPointContent =
            updatedTalkingPointContent),
      );
    } catch (error) {
      console.log('Error occurred: ', error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleCopyTalkingPoint = () => {
    const contentElement = contentRef?.current;
    const clonedContent = contentElement?.cloneNode(true) as HTMLElement;
    modifyContentBlockBeforeCopy(clonedContent);
    const updatedContent = clonedContent?.innerHTML || '';
    handleCopyToClipboard(updatedContent, 'html');
  };

  const [isLoading, setIsLoading] = useState(false);
  const { aiTalkingPointsTemplate } = useTemplatedPromptsStore();
  const gptService = new ChatGPTService(videoCreator);
  const handleGenerate = async () => {
    setIsLoading(true);
    analytics.track('generate_talking_points', {
      storyId: videoCreator.story?.id,
      storyTitle: videoCreator.story?.title,
      template: aiTalkingPointsTemplate,
    });
    await gptService.generateTalkingPoint({
      setLoading: setIsLoading,
      template: aiTalkingPointsTemplate,
    });
  };

  const [revisionModalOpen, setRevisionModalOpen] = useState(false);
  const handleRegenerate = async (revision: string) => {
    setIsLoading(true);
    setRevisionModalOpen(false);
    analytics.track('regenerate_talking_points', {
      storyId: videoCreator.story?.id,
      storyTitle: videoCreator.story?.title,
      template: aiTalkingPointsTemplate,
      revision,
    });
    await gptService.generateTalkingPoint({
      setLoading: setIsLoading,
      template: aiTalkingPointsTemplate,
      brandText: revision,
    });
  };

  return (
    <Main>
      {isLoading && (
        <SpinningLoading
          customStyle={{
            top: 0,
            position: 'fixed',
            alignItems: 'center',
          }}
          text="Generating talking points"
        />
      )}

      {isSaving && (
        <SpinningLoading
          customStyle={{
            top: 0,
            position: 'fixed',
            alignItems: 'center',
          }}
          text="Saving talking point content"
        />
      )}

      <ContentActions
        hasBeenGenerated={!contentIsEmpty}
        onGenerate={handleGenerate}
        onRegenerate={
          enableTalkingPointsStudioRegeneration
            ? () => setRevisionModalOpen(true)
            : undefined
        }
        onCopy={!contentIsEmpty ? handleCopyTalkingPoint : undefined}
        onSave={
          !contentIsEmpty && enableTalkingPointsStudioSave
            ? handleSaveContent
            : undefined
        }
      />

      {revisionModalOpen ? (
        <BrandVoiceRevisionModal
          title="Regenerate Talking Points"
          onClose={() => setRevisionModalOpen(false)}
          handleGenerate={handleRegenerate}
        />
      ) : null}

      <Content ref={contentRef} />
    </Main>
  );
});

export default TalkingPointStudio;

const Main = styled.div`
  display: flex;
  flex-direction: column;
`;
