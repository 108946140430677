import VideoCreatorStore from '@src/stores/VideoCreatorStore';
import {
  ShareableImagePreviewType,
  ShareableImageType,
  VideoClip,
} from '../types.ts/story';
import ChatGPTService from './ChatGPTService';
import { logChatGptOutput } from '@src/utility/datadog';

type CaptionData<T> = {
  text: string;
  label: T;
  platform: Lowercase<Platform>;
  clipId?: string;
  contentId?: string;
  id?: string;
  shareable?: ShareableImagePreviewType;
};

type Platform =
  | 'Twitter'
  | 'Facebook'
  | 'LinkedIn'
  | 'Instagram'
  | 'YouTube'
  | 'TikTok';

type Label = 'otherVideos' | 'shareableImages';

export default class CaptionService extends ChatGPTService {
  constructor(videoCreator: VideoCreatorStore) {
    super(videoCreator);
  }

  public getExistingCaptionData<T extends Label>(
    data: Pick<CaptionData<T>, 'platform' | 'contentId' | 'label'>,
  ) {
    const content = (
      this.videoCreator.story?.[data.label] as (
        | VideoClip
        | ShareableImageType
      )[]
    )?.find((c) => {
      return c.id === data.contentId;
    });

    const platformData = content?._allReferencingCaptions?.find(
      (r) => r.platform === data.platform,
    );
    return { platformData, content };
  }

  public getExistingCaptionText<T extends Label>(
    data: Pick<CaptionData<T>, 'platform' | 'contentId' | 'label'>,
  ) {
    const { platformData } = this.getExistingCaptionData(data);
    return platformData?.caption || '';
  }

  private getPromptLabel(label: Label) {
    if (label === 'otherVideos') {
      return 'Social Posts';
    }
    return 'Sharable Image Caption';
  }

  private getRequestInputData = async <T extends Label>(
    brandText: string | null,
    data: CaptionData<T>,
  ) => {
    const transcript = this.videoCreator.finalTranscriptionElements
      ?.map((e) => e.value || '')
      .join('');
    let promptKey = this.getPromptLabel(data.label);
    await this.fetchAiPrompts(promptKey);
    if (!this.aiPrompt || !transcript || !this.videoCreator.storyId) return;

    const field = this.aiPrompt.promptFields.find(
      (f) => f.name.toLowerCase() === data.platform,
    );

    let prevQuestion = this.aiPrompt.description + field?.description || '';

    let currQuestion =
      (this.aiPrompt.followUp || this.aiPrompt.description) +
      field?.description +
      '';

    if (brandText) {
      currQuestion += `. ${brandText}`;
    }

    return {
      prevQuestion,
      currQuestion,
      transcript,
    };
  };

  private async createCaption<T extends Label>(data: CaptionData<T>) {
    const story = this.videoCreator?.story!;
    const platform = data.platform.toLowerCase();

    const itemType =
      await this.videoCreator.datoClient?.itemTypes.find('caption');
    const savedCaption = await this.videoCreator.datoClient?.items.create({
      item_type: { type: 'item_type', id: itemType!.id },
      title: `${story.title} - ${platform}`,
      platform,
      caption: data.text,
      story: story.id,
      shareable: data.contentId,
    });
    this.updateCaptionInStore({ ...data, id: savedCaption!.id });
    return savedCaption!.id;
  }

  public async updateCaption<T extends Label>(data: CaptionData<T>) {
    await this.videoCreator.datoClient?.items.update(data.id!, {
      caption: data.text,
      shareable: data.contentId,
    });
    this.updateCaptionInStore({ ...data, text: data.text });
  }

  public async saveCaptionsToDato<T extends Label>(data: CaptionData<T>) {
    const storyId = this.videoCreator?.story?.id;
    if (!storyId) return;
    const text = this.getExistingCaptionText(data);
    if (!data.id) {
      return await this.createCaption({ ...data, text });
    } else {
      await this.updateCaption({ ...data, text });
    }
  }

  public async regenerateCaptionStreamResponse<T extends Label>(
    brandText: string | null = null,
    toggleLoading: (e: boolean) => void,
    data: CaptionData<T>,
    callback: (savedId: string) => void,
    inputData: Record<
      'transcript' | 'prevQuestion' | 'currQuestion',
      string
    > | null = null,
  ) {
    const controller = new AbortController();
    const signal = controller.signal;

    const items =
      inputData || (await this.getRequestInputData(brandText, data));
    if (!items) return;
    const { transcript, prevQuestion, currQuestion } = items;

    const { platformData, content } = this.getExistingCaptionData(data);

    const existingResponse = platformData?.caption || '';

    const storyId = this.videoCreator.story?.id!;

    try {
      const resPromise = fetch(
        `${process.env.REACT_APP_API_URL}/api/aiprompts/regenerate-gpt-response-stream`,
        {
          signal,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            transcript,
            existingResponse,
            storyId,
            prevQuestion,
            currQuestion,
          }),
        },
      );

      this.updateCaptionInStore({ ...data, text: '' });
      this.streamProcessDone = false;
      const res = await resPromise;
      if (!res.body) return;

      const reader = res.body.getReader();
      const decoder = new TextDecoder();

      while (!this.streamProcessDone) {
        const { value, done: doneReading } = await reader.read();
        this.streamProcessDone = doneReading;
        let chunk = decoder.decode(value);
        toggleLoading(false);

        const existingResponse = this.getExistingCaptionText(data);
        let text = existingResponse;
        if (
          existingResponse.endsWith('.') &&
          chunk &&
          (!chunk.startsWith(' ') || !chunk.startsWith('\n'))
        ) {
          text += ` ${chunk}`;
        } else {
          text += chunk;
        }
        if (doneReading) {
          logChatGptOutput({ 'regenerate-gpt-response-stream': text });
        }

        this.updateCaptionInStore({ ...data, text });
      }

      if (this.streamProcessDone) {
        if (!content && data.label === 'shareableImages') {
          const savedId = await this.saveShareableImage(data.shareable!);
          data.contentId = savedId;
          callback(savedId!);
        }
        await this.saveCaptionsToDato(data);
      }
    } catch (error) {
      console.log('Error: ', error);
    } finally {
      toggleLoading(false);
    }
  }

  public updateCaptionInStore = <T extends Label>(data: CaptionData<T>) => {
    const { id, contentId, label, platform, text } = data;
    const storyId = this.videoCreator.storyId!;
    let idx = this.videoCreator?.story?.[label].findIndex(
      (content) => content.id === contentId,
    );

    if (!contentId) return;

    if ((idx === undefined || idx <= -1) && label === 'shareableImages') {
      this.videoCreator.story!.shareableImages = [
        ...(this.videoCreator.story!.shareableImages || []),
        {
          id: contentId!,
          imagefile: data.shareable?.imageFile,
          quote: data.shareable?.quote,
          storytellerName: this.videoCreator.story?.storyTeller?.name || '',
        },
      ] as ShareableImageType[];
      idx = this.videoCreator.story!.shareableImages.length - 1;
    }

    const isNew = !this.videoCreator!.story![label][
      idx!
    ]._allReferencingCaptions?.find((c) => c.platform === platform);

    if (isNew) {
      this.videoCreator!.story![label][idx!]._allReferencingCaptions = [
        ...(this.videoCreator!.story![label][idx!]._allReferencingCaptions ||
          []),
        {
          id,
          platform,
          story: {
            id: storyId!,
          },
          shareable: {
            __typename:
              label === 'otherVideos' ? 'VideoRecord' : 'ShareableImageRecord',
          },
          caption: text.replace(/^["'\s-]+|["'\s-]+$/g, '')?.trim(),
        },
      ];
    } else {
      this.videoCreator!.story![label][idx!]._allReferencingCaptions =
        this.videoCreator!.story![label][idx!]._allReferencingCaptions?.map(
          (caption) => {
            if (caption.platform === platform) {
              return {
                ...caption,
                id,
                shareable: {
                  __typename:
                    label === 'otherVideos'
                      ? 'VideoRecord'
                      : 'ShareableImageRecord',
                },
                caption: text.replace(/^["'\s-]+|["'\s-]+$/g, '')?.trim(),
              };
            }
            return caption;
          },
        );
    }
  };

  public async saveShareableImage(shareable: ShareableImagePreviewType) {
    const unsavedIdx = this.videoCreator.story!.shareableImages.length - 1;
    const unsaved = this.videoCreator.story!.shareableImages[unsavedIdx];

    const savedId =
      await this.videoCreator.storyRepository?.createShareableImage(
        this.videoCreator.story!,
        shareable.quote,
        shareable.imageFile,
      );

    this.videoCreator.unsavedShareableImages =
      this.videoCreator.unsavedShareableImages?.filter(
        (o) => o.id !== unsaved.id,
      ) || [];

    this.videoCreator!.story!.shareableImages[unsavedIdx].id = savedId!;
    await this.videoCreator.updateStory(this.videoCreator.story!);

    return savedId;
  }

  public async generateAllCaptions(contentId: string, key = 'Social Posts') {
    const transcript = this.videoCreator.finalTranscriptionElements
      ?.map((e) => e.value || '')
      .join('');
    await this.fetchAiPrompts(key);
    if (!this.aiPrompt || !transcript || !this.videoCreator.storyId) return;

    let prevQuestion = this.aiPrompt.description || '';
    let currQuestion =
      (this.aiPrompt.followUp || this.aiPrompt.description) + '';

    await Promise.all(
      this.aiPrompt.promptFields.map(async (f) => {
        const platform = f.name.toLowerCase() as Lowercase<Platform>;
        const label =
          key === 'Social Posts' ? 'otherVideos' : ('shareableImages' as Label);

        const captionData = this.getExistingCaptionData({
          platform,
          label,
          contentId,
        });
        if (!captionData.platformData?.caption) {
          const data = {
            platform: f.name.toLowerCase() as Lowercase<Platform>,
            text: '',
            label:
              key === 'Social Posts'
                ? 'otherVideos'
                : ('shareableImages' as Label),
            contentId,
            id: captionData.platformData?.id,
          };

          const captionService = new CaptionService(this.videoCreator);
          await captionService.regenerateCaptionStreamResponse(
            '',
            () => {},
            data,
            () => {},
            { transcript, prevQuestion, currQuestion },
          );
        }
      }),
    );
  }
}
