import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { observer } from 'mobx-react-lite';
import styled, { css } from 'styled-components';

import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';

import WaveformData from 'waveform-data';

interface WaveFormProps {
  id: string;
  url: string;
  type: string;
  trim_start: string;
  duration: string;
  active?: boolean;
  height: number;
  isInMusicProducer?: boolean;
  range?: number;
  customColor?: string;
  defaultCustomBrightness?: string;
  customResampleWaveform?: WaveformData | null;
}

const MAX_VALUE = 128;
export const MAX_CANVAS_WIDTH = 16384;

export const WaveForm: React.FC<WaveFormProps> = observer((props) => {
  const {
    isInMusicProducer = false,
    range = 128,
    customResampleWaveform,
  } = props;

  const videoCreator = useVideoCreatorStore();

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [isVisible, setIsVisible] = useState(false);

  const isOriginalVideo = useMemo(
    () => props.url === videoCreator.originalVideoAudioUrl,
    [props.url],
  );

  // const resampledWaveform: WaveformData | null | undefined =
  //   customResampleWaveform ??
  //   (isOriginalVideo
  //     ? videoCreator.resampledOriginalWaveForm
  //     : // @ts-ignore
  //       videoCreator.audioTracksData[props.url]?.resampledWaveform);

  const resampledWaveform = useMemo(() => {
    return (
      customResampleWaveform ??
      (isOriginalVideo
        ? videoCreator.resampledOriginalWaveForm
        : videoCreator.audioTracksData[props.url] !== 'loading'
          ? // @ts-ignore
            videoCreator.audioTracksData[props.url]?.resampledWaveform
          : undefined)
    );
  }, [
    customResampleWaveform,
    isOriginalVideo,
    props.url,
    videoCreator.resampledOriginalWaveForm,
    videoCreator.audioTracksData,
    // @ts-ignore
    videoCreator.audioTracksData[props.url]?.resampledWaveform,
  ]);

  const scaleY = useCallback(
    (amplitude: number, height: number) => {
      const padding_top = props.type === 'video' ? 0 : 14;
      const maxHeight = height - padding_top;
      return maxHeight - (amplitude * maxHeight) / range + padding_top;
    },
    [props.type, range],
  );

  const drawWaveform = useCallback(
    (
      waveform: WaveformData,
      startTime: number,
      endTime: number,
      factor = 1,
      step = 1,
    ) => {
      if (!canvasRef.current) return;
      const ctx = canvasRef.current.getContext('2d');

      if (!ctx) return;

      const width = canvasRef.current.clientWidth;
      const height = canvasRef.current.clientHeight;
      ctx.canvas.width = width;
      ctx.canvas.height = height;

      ctx.clearRect(0, 0, width, height);

      const color =
        props.customColor || (isOriginalVideo ? '#004493' : '#9f66d8');
      const channel = waveform.channel(0);
      const maxSamples = channel.max_array();
      const maxSample = isOriginalVideo
        ? videoCreator.maxOriginalWaveformSample
        : 0.8 * MAX_VALUE;
      const scaleValueY = props.type === 'video' ? MAX_VALUE / maxSample : 1;

      ctx.strokeStyle = color;
      ctx.fillStyle = color;

      if (props.defaultCustomBrightness) {
        ctx.filter = `brightness(${props.defaultCustomBrightness})`;
      }

      try {
        const startIndex = waveform.at_time(startTime);
        const endIndex = Math.min(
          waveform.at_time(endTime),
          waveform.length - 1,
        );

        for (let i = startIndex; i <= endIndex; i += step) {
          const vals = [maxSamples[i]];
          if (step >= 2 && i + 1 <= endIndex) {
            vals.push(maxSamples[i + 1]);
          }
          if (step === 3 && i + 2 <= endIndex) {
            vals.push(maxSamples[i + 2]);
          }

          const val = vals.reduce((sum, v) => sum + v, 0) / vals.length;

          ctx.rect(
            (i - startIndex + 1) * 2 * factor,
            scaleY(val * scaleValueY, height),
            0.5,
            scaleY(0, height),
          );
        }
      } catch (e) {
        console.error('draw wave form error', e);
      }

      ctx.closePath();
      ctx.stroke();
    },
    [
      canvasRef,
      isOriginalVideo,
      props.customColor,
      props.defaultCustomBrightness,
      props.type,
      scaleY,
    ],
  );

  const generateWaveform = useCallback(async () => {
    if (!resampledWaveform || !canvasRef.current) {
      console.log(
        'No resampledWaveform or canvasRef.current',
        resampledWaveform,
        canvasRef.current,
      );
      return;
    }

    const originalDuration =
      videoCreator.audioTracksData[props.url] !== 'loading'
        ? // @ts-ignore
          videoCreator.audioTracksData[props.url]?.originalTrackDuration
        : undefined;

    const elementDuration = parseFloat(
      props.duration || originalDuration || '0',
    );
    const startTime = parseFloat(props.trim_start || '0');
    const endTime = startTime + elementDuration;

    let factor = 0.5;
    let step = 1;

    if (!isInMusicProducer) {
      const timelineScale = videoCreator.timelineScale;
      const newFactor =
        resampledWaveform.sample_rate / timelineScale / resampledWaveform.scale;
      if (newFactor < 0.99) {
        factor = 0.5 / newFactor;
      }
    }

    drawWaveform(resampledWaveform, startTime, endTime, factor, step);
  }, [
    resampledWaveform,
    canvasRef,
    props.duration,
    props.trim_start,
    props.url,
    isInMusicProducer,
    drawWaveform,
  ]);

  useEffect(() => {
    if (isVisible) {
      generateWaveform();
    }
  }, [
    isVisible,
    generateWaveform,
    props.active,
    props.trim_start,
    props.defaultCustomBrightness,
    resampledWaveform,
  ]);

  useEffect(() => {
    if (!canvasRef.current) return;
    const intersectObserver = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        root: null,
        threshold: 0.01,
      },
    );

    intersectObserver.observe(canvasRef.current);

    return () => {
      intersectObserver.disconnect();
    };
  }, [canvasRef]);

  return (
    <div style={{ position: 'relative', flex: '1', height: '100%' }}>
      <Wave
        isInMusicProducer={isInMusicProducer}
        id={props.id}
        ref={canvasRef}
      />
    </div>
  );
});

const Wave = styled.canvas<{ isInMusicProducer: boolean }>`
  height: 100%;
  width: 100%;
  ${(props) =>
    !props.isInMusicProducer &&
    css`
      position: absolute;
      top: 2px;
      left: 0;
    `}
`;
