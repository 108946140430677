import { useEffect, useState } from 'react';
import { PunchListItem } from '../../types.ts/story';
import Modal from './Modal';
import GeneratedPunchListModalItems from '../sidepanel/GeneratedPunchListModalItems';
import {
  PunchListItemReplacement,
  SidebarOption,
} from '../../types.ts/general';
import { observer } from 'mobx-react-lite';

import { runInAction } from 'mobx';

import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';

export type ModalItem = {
  item: PunchListItem | null;
  archive: PunchListItem | null;
};

type Props = {
  modalItem: ModalItem;
  toggleModal: (modalItem: ModalItem) => void;
  afterReplacement?: () => void;
};

const GeneratedPunchListModal = observer(
  ({ modalItem, toggleModal, afterReplacement }: Props) => {
    const videoCreator = useVideoCreatorStore();
    const punchList = videoCreator.punchListManager.punchListItems;

    const [replacement, setReplacement] =
      useState<PunchListItemReplacement | null>(null);

    useEffect(() => {
      if (!replacement?.id || !punchList) return;
      const { id, url, type } = replacement;
      const idx = punchList.findIndex((p: any) => p.id === id);
      punchList[idx] = {
        ...punchList[idx],
        type,
        artifactSrc: url,
        artifactAspectRatio: replacement.aspectRatio,
      };
      let time = punchList[idx].startTime || null;

      runInAction(async () => {
        videoCreator.punchListManager.punchListItems = punchList;
        await videoCreator.findOrReplaceInTimeline(
          id,
          url,
          null,
          time,
          replacement.aspectRatio,
        );
        await videoCreator.setActiveElements(id);
        videoCreator.sidebarOptions = SidebarOption.editing;
        if (afterReplacement) afterReplacement();
      });
    }, [replacement]);

    if (!modalItem?.item) {
      return null;
    }

    return (
      <Modal
        isOpen={!!modalItem}
        closeModal={() => toggleModal({ ...modalItem, item: null })}
      >
        <GeneratedPunchListModalItems
          modalItem={modalItem.item}
          setReplacement={setReplacement}
          onClose={() => toggleModal({ ...modalItem, item: null })}
          openPrevModal={() =>
            toggleModal({
              ...(modalItem || {}),
              item: (modalItem || {}).archive,
            })
          }
        />
      </Modal>
    );
  },
);

export default GeneratedPunchListModal;
