import { useCallback, useEffect } from 'react';
import { useAuth, useOrganizationList, useUser } from '@clerk/clerk-react';
import { useSearchParams } from 'react-router-dom';
import { ShowcaseAccess } from '../../types.ts/story';
import VideoCreatorStore from '@src/stores/VideoCreatorStore';

const getRedirectToSignInLink = (orgSlug: string) => {
  return `${process.env.REACT_APP_API_URL}/showcase/${orgSlug}`;
};

const hasAccessToStory = (
  albums: ShowcaseAccess[] | undefined,
  memberships: ReturnType<
    typeof useOrganizationList
  >['userMemberships']['data'],
  clerkUserPrimaryEmail: string,
) => {
  return !!albums?.find(
    (album) =>
      memberships?.find(
        (memb) =>
          (album.organization && memb.organization.id === album.organization) ||
          (album.slug && memb.organization.slug === album.slug) ||
          album.adminList?.find(
            (admin) => admin.email === clerkUserPrimaryEmail,
          ) ||
          album.allowList?.find(
            (editor) => editor.email === clerkUserPrimaryEmail,
          ),
      ),
  );
};

const useInitializeDatoUser = (videoCreator: VideoCreatorStore) => {
  const auth = useAuth();
  const clerkUser = useUser();
  const isDatoUser = Boolean(videoCreator.datoContext.currentRole);
  const isDatoContextSet = Boolean(videoCreator.datoContext.currentUser);
  const orgList = useOrganizationList({
    userMemberships: {
      pageSize: 50,
      infinite: true,
    },
  });
  const [urlSearchParams] = useSearchParams();

  const { storyId, videoId, showcase } =
    Object.fromEntries(urlSearchParams.entries()) || {};

  const story = videoCreator.story;
  const organization = videoCreator.organization;

  const isLoaded =
    auth.isLoaded &&
    ((orgList.isLoaded && !orgList.userMemberships.isLoading) ||
      !auth.isSignedIn);

  let orgSlug: string | undefined | null;
  if (showcase) {
    if (auth.orgSlug && auth.orgSlug === showcase) {
      orgSlug = showcase;
    } else {
      orgSlug = orgList.userMemberships.data?.find(
        (memb) => memb.organization.slug === showcase,
      )?.organization.slug;
    }
  } else {
    orgSlug =
      auth.orgSlug || orgList.userMemberships.data?.at(0)?.organization.slug;
  }

  const userHasAccessOrRedirect = useCallback(
    async (
      storyId: string | undefined,
      showcaseSlug: string | undefined | null,
    ) => {
      if (isDatoUser) {
        console.log('Is Dato User - access granted');
        return { hasAccess: true };
      }
      if (!clerkUser.user) {
        console.log('User is not authenticated - access denied');
        return { hasAccess: false };
      }

      if (
        clerkUser.user.emailAddresses.find((e) =>
          e.emailAddress.endsWith('@tryarbor.com'),
        )
      ) {
        console.log('access granted');
        return { hasAccess: true };
      }

      let hasOrgAccess;
      if (showcaseSlug) {
        hasOrgAccess = !!orgList.userMemberships.data?.find(
          (memb) => memb.organization.slug === showcaseSlug,
        );
        if (!hasOrgAccess) {
          console.log('No org membership - access denied');
          return {
            hasAccess: false,
            redirectTo: getRedirectToSignInLink(showcaseSlug),
          };
        }
      }

      if (storyId) {
        const albums =
          await videoCreator.albumRepository?.findAllWithStoryAccess(storyId);

        const hasStoryAccess = hasAccessToStory(
          albums,
          orgList.userMemberships.data,
          clerkUser.user.primaryEmailAddress!.emailAddress,
        );
        if (!hasStoryAccess) {
          console.log('No membership in story album - access denied');
          return {
            hasAccess: false,
            redirectTo: getRedirectToSignInLink(albums?.[0]?.slug || ''),
          };
        } else {
          console.log('story access granted');
          return { hasAccess: true };
        }
      } else if (hasOrgAccess) {
        console.log('showcase access granted');
        return { hasAccess: true };
      }

      console.log('access denied');
      return { hasAccess: false };
    },
    [clerkUser, isDatoUser, orgList, isLoaded],
  );

  useEffect(() => {
    const initializeOrRedirect = async () => {
      if (
        isDatoContextSet &&
        isLoaded &&
        ((!story && !organization) ||
          (storyId && story?.id !== storyId) ||
          (videoId && videoCreator.currentVideo?.id !== videoId))
      ) {
        const hasAccessOrRedirect = await userHasAccessOrRedirect(
          storyId,
          showcase,
        );
        console.log('hasAccessOrRedirect', hasAccessOrRedirect);
        if (hasAccessOrRedirect.hasAccess) {
          videoCreator.initializeData({
            storyId,
            videoId,
            showcaseSlug: isDatoUser ? null : showcase,
          });
        } else if (hasAccessOrRedirect.redirectTo) {
          window.location.href = hasAccessOrRedirect.redirectTo;
        } else {
          throw new Error(
            'User does not have access and cannot be redirected to sign in',
          );
        }
      }
    };
    initializeOrRedirect();
  }, [isLoaded, isDatoContextSet, storyId, videoId]);
};

export default useInitializeDatoUser;
