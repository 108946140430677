import React from 'react';
import { observer } from 'mobx-react-lite';
import { ElementState } from '../../renderer/ElementState';

import PropertyDropdown from '../common/PropertyDropdown';
import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';

interface PropertySelectProps {
  activeElement: ElementState;
  propertyName: string;
  defaultValue: any;
  options: Array<{ caption: string; value: any }>;
}

export const PropertySelect: React.FC<PropertySelectProps> = observer(
  (props) => {
    const videoCreator = useVideoCreatorStore();

    return (
      <PropertyDropdown
        value={
          props.activeElement.source[props.propertyName] ?? props.defaultValue
        }
        onChange={async (value) => {
          await videoCreator.applyVideoStateModifications(
            {
              [`${props.activeElement.source.id}.${props.propertyName}`]: value,
            },
            true,
            `modifying ${props.activeElement.source.type} element`,
          );
        }}
        defaultValue={
          props.activeElement.source[props.propertyName] ?? props.defaultValue
        }
        values={props.options.map(({ value, caption }) => ({ value, caption }))}
      />
    );
  },
);
