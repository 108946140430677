import { useEffect } from 'react';
import type { LDClient } from 'launchdarkly-js-client-sdk';
import type { UserResource } from '@clerk/types';
import { useStore } from '../stores-v2/StoreContext';

interface LaunchDarklyInitParams {
  ldClient?: LDClient;
  storyId?: string;
  clerkUser?: UserResource | null;
  isClerkLoaded?: boolean;
  orgRole?: string | null | undefined;
  orgSlug?: string | null | undefined;
}

export function useLaunchDarklyInit({
  ldClient,
  storyId,
  clerkUser,
  isClerkLoaded,
  orgRole,
  orgSlug,
}: LaunchDarklyInitParams) {
  const { userIdentityStore } = useStore();

  useEffect(() => {
    if (!ldClient) return;

    const identity = userIdentityStore.currentIdentity;

    if (identity?.source === 'dato') {
      ldClient
        .identify({
          kind: 'user',
          key: `dato-user-${identity.id}-${storyId}`,
          id: identity.id,
          datoRole: identity.role,
        })
        .catch(console.error);
    } else if (isClerkLoaded && clerkUser) {
      ldClient
        .identify({
          kind: 'user',
          key: `user-content-studio-${clerkUser.id}-${storyId}`,
          id: clerkUser.id,
          emailAddress: {
            email: clerkUser.primaryEmailAddress?.emailAddress,
            linkedTo: clerkUser.primaryEmailAddress?.linkedTo,
          },
          datoRole: 'viewer',
          role: orgRole,
          showcase: orgSlug,
          storyId: storyId,
        })
        .catch(console.error);
    }
  }, [ldClient, isClerkLoaded, clerkUser, storyId, orgRole, orgSlug]);
}
