import { useState } from 'react';
import styled, { css } from 'styled-components';

import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';

import { ShareableImageType } from '../../types.ts/story';
import {
  getPosterUrl,
  getShareableImageDimension,
} from '../../utility/general';

import Modal from '../Modal';
import QuoteStartIcon from '../../svgs/QuoteStartIcon';

type Props = {
  data: Pick<
    ShareableImageType,
    'id' | 'quote' | 'storytellerName' | 'imagefile'
  >;
  onClose: () => void;
  storyteller?: string;
  isSaved: boolean;
};

const EditSharableImageModal = (props: Props) => {
  const videoCreator = useVideoCreatorStore();
  const { data, onClose, storyteller: storytellerFromStory, isSaved } = props;

  const [quote, setQuote] = useState(data.quote);
  const [storyteller, setStoryteller] = useState(
    data.storytellerName || storytellerFromStory,
  );
  const [currDimension, setCurrDimension] = useState<string>('1:1');

  const dimension = getShareableImageDimension(currDimension);
  const background = getPosterUrl(
    props.data.imagefile?.url || '',
    dimension.width!,
    dimension.height!,
    false,
  );

  const [isSaving, setIsSaving] = useState(false);
  const saveShareableImage = async () => {
    try {
      setIsSaving(true);
      if (!isSaved) {
        const savedId =
          await videoCreator.storyRepository?.createShareableImage(
            videoCreator?.story!,
            quote,
            data.imagefile,
            storyteller,
          );
        videoCreator.story!.shareableImages = [
          ...(videoCreator.story!.shareableImages || []),
          {
            quote,
            storytellerName: storyteller,
            imagefile: data.imagefile,
            id: savedId as string,
          },
        ] as ShareableImageType[];
        videoCreator.unsavedShareableImages =
          videoCreator.unsavedShareableImages?.filter(
            (o) => o.id !== data.id,
          ) || [];
      } else {
        await videoCreator.storyRepository?.updateShareableImage(
          data.id,
          quote,
          data.imagefile,
          storyteller,
        );
        videoCreator.story!.shareableImages =
          videoCreator.story!.shareableImages.map((s) =>
            s.id === data.id
              ? { ...s, quote, storytellerName: storyteller }
              : s,
          ) || [];
      }
      await videoCreator.updateStory(videoCreator.story!);
      onClose();
    } catch (error) {
      console.log('Error occurred in saving sharable image: ', error);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Modal isOpen onClose={onClose} customWidth="min(80%, 860px)">
      <Main>
        <h2>Edit your post</h2>

        <Content>
          <LeftPane>
            <ImageContent
              background={background}
              aspectRatio={currDimension.split(':').join('/')}
            >
              <Quote>
                <QuoteIcon>
                  <QuoteStartIcon
                    fillColor={background ? '#ffffff' : '#03041a'}
                  />
                </QuoteIcon>
                <QuoteText
                  ref={(e) => {
                    if (e) {
                      const computedStyle = window.getComputedStyle(e);
                      const lineHeight = parseFloat(computedStyle.lineHeight);
                      const lines = Math.round(e.clientHeight / lineHeight);
                      if (lines > 4 && lines < 6) {
                        e.style.fontSize = '21px';
                      } else if (lines >= 6) {
                        e.style.fontSize = '18px';
                      }
                    }
                  }}
                  background={background}
                >
                  {quote}
                </QuoteText>
                {storyteller && (
                  <StoryTeller background={background}>
                    {storyteller}
                  </StoryTeller>
                )}
              </Quote>
            </ImageContent>
          </LeftPane>

          <RightPane isSaving={isSaving}>
            <label>
              <strong>Quote</strong>
              <textarea
                value={quote}
                onChange={(e) => setQuote(e.target.value)}
              />
            </label>
            <label>
              <strong>Storyteller</strong>
              <input
                value={storyteller}
                onChange={(e) => setStoryteller(e.target.value)}
              />
            </label>
            <button disabled={isSaving} onClick={saveShareableImage}>
              {isSaving ? 'Saving...' : 'Save'}
            </button>
          </RightPane>
        </Content>
      </Main>
    </Modal>
  );
};

const Main = styled.div`
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  text-align: initial;

  h2 {
    color: #45d483;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
  }
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: 500px auto;
  gap: 32px;
  margin-top: 32px;
`;

const LeftPane = styled.div``;

const ImageContent = styled.div<{ background?: string; aspectRatio?: string }>`
  display: flex;
  flex-direction: column;
  padding: 32px;
  gap: 12px;
  border-radius: 8px;
  background-color: #fff;
  ${(props) =>
    props.background &&
    css`
      background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.9) 0%,
          rgba(0, 0, 0, 0) 100%
        ),
        url(${props.background});
      background-size: cover;
      background-position: center center;
    `}
  ${(props) =>
    props.aspectRatio &&
    css`
      aspect-ratio: ${props.aspectRatio};
    `}
`;

const Quote = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
  margin-top: auto;
  word-wrap: break-word;
`;

const QuoteIcon = styled.div``;

const QuoteText = styled.div<{ background?: string }>`
  color: ${(props) => (props.background ? '#ffffff' : '#03041a')};
  font-size: 24px;
  font-weight: 800;
  line-height: 29.4px;
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  overflow-y: hidden;
`;

const StoryTeller = styled.div<{ background?: string }>`
  padding: 4px 8px;
  border-radius: 4px;
  background-color: ${({ background }) => (background ? '#ffffff' : '#03041a')};
  color: ${({ background }) => (background ? '#03041a' : '#ffffff')};
  width: fit-content;
  font-size: 12px;
  font-weight: 700;
  line-height: 16.8px;
`;

const RightPane = styled.div<{ isSaving: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 16px;

  label {
    display: flex;
    flex-direction: column;
    gap: 8px;

    strong {
      display: block;
      font-size: 10px;
      font-weight: 500;
      color: #848484;
    }

    input {
      padding: 16px;
      border: 1px solid #484848;
      color: #dbdbdb;
      font-size: 16px;
      border-radius: 8px;
      background-color: #03041a;
      font-family: Inter, sans-serif;
      outline: none;
    }

    textarea {
      padding: 16px;
      border: 1px solid #484848;
      color: #dbdbdb;
      font-size: 16px;
      border-radius: 8px;
      background-color: #03041a;
      font-family: Inter, sans-serif;
      resize: none;
      outline: none;
      min-height: 120px;
    }
  }

  button {
    display: flex;
    height: 48px;
    padding: 16px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: #17c964;
    outline: 0;
    border: 0;
    font-size: 14px;
    font-weight: 700;
    color: #03041a;
    cursor: pointer;
    opacity: ${(props) => (props.isSaving ? 0.6 : 1)};
  }
`;

export default EditSharableImageModal;
