import { observer } from 'mobx-react-lite';
import React from 'react';
import { AlbumQueryResult } from '../../types.ts/story';
import { convertTimeToMMSS } from '../../utility/timeFormat';
import styled from 'styled-components';
import SocialStats from '../content-components/SocialStats';
import SocialShareStatus from '../content-components/SocialShareStatus';
import { Link } from 'react-router-dom';
import { Studio } from '../../types.ts/general';
import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';

type Props = {
  stories: AlbumQueryResult['stories'];
  resetStoryStates(storyId: string): void;
  buildTargetUrl(
    params: URLSearchParams,
    storyId: string,
    playbackId: string | undefined,
  ): string;
};

type ClipType = AlbumQueryResult['stories'][0]['otherVideos'][0] & {
  parentStory: string;
  parentStoryId: string;
  storyTeller: string;
  totalVideoCount: number;
  currentVideoIndex: number;
};

const ClipDashboardContent = observer((props: Props) => {
  const videoCreator = useVideoCreatorStore();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const getAllClips = () => {
    return props.stories.flatMap((s) => {
      const filteredVideos = s.otherVideos.filter(
        (v) =>
          !v.isHidden &&
          (v.videoFilePrimary ||
            v.associatedVideos.find((v) => v.videoFilePrimary)),
      );
      return (
        filteredVideos?.map(
          (o, index) =>
            ({
              ...o,
              parentStory: s.title,
              parentStoryId: s.id,
              storyTeller: s.storyTeller?.name,
              totalVideoCount: filteredVideos?.length,
              currentVideoIndex: index + 1,
            }) as ClipType,
        ) || []
      );
    });
  };

  const getClipThumbnailUrl = (clip: ClipType) => {
    if (clip.thumbnail?.url) return clip.thumbnail?.url;

    if (clip?.videoFilePrimary?.video?.thumbnailUrl) {
      return clip?.videoFilePrimary?.video?.thumbnailUrl;
    }
    return null;
  };

  const clipDuration = (clip: ClipType) => {
    const duration = clip.videoFilePrimary?.video?.duration;
    if (duration) {
      return convertTimeToMMSS(duration);
    }
  };

  const renderClipTableHeader = () => (
    <>
      <RowHeader>
        <ThumbnailHeader>
          <span>Clips</span>
        </ThumbnailHeader>
        <ParentStoryHeader>
          <span>Parent Story</span>
        </ParentStoryHeader>
        <PlatformHeader>
          <span>Platforms</span>
        </PlatformHeader>
        <AnalyticsHeader>
          <span>Analytics</span>
        </AnalyticsHeader>

        <DateHeader>
          <span>Published</span>
        </DateHeader>
      </RowHeader>
    </>
  );

  const renderClipTableContent = (clip: ClipType) => {
    return (
      <>
        <HorizontalLine />

        <Row>
          <RowLink
            to={`/${Studio.content}${props.buildTargetUrl(
              urlSearchParams,
              clip.parentStoryId,
              clip.videoFilePrimary?.video.muxPlaybackId,
            )}`}
            onClick={async () => {
              props.resetStoryStates(clip.parentStoryId);
              videoCreator.videoClipPreview = clip;
              await videoCreator.loadVideo(clip.id!);
            }}
          >
            <ThumbnailColumn>
              <ThumbContainer>
                {/* <ViewClip className="view-clip">View Clip</ViewClip> */}
                <Thumb src={getClipThumbnailUrl(clip) || ''} />
              </ThumbContainer>

              <Info>
                <span className="story-title">{clip.title}</span>
                <span className="teller-name">{clip.storyTeller}</span>
                <span className="duration">{clipDuration(clip)}</span>
              </Info>
            </ThumbnailColumn>

            <ParentStory>
              <span className="parent-story">{clip.parentStory}</span>
              <span className="clip-number">
                {clip.currentVideoIndex} of {clip.totalVideoCount}
              </span>
            </ParentStory>

            <Platform>
              <SocialShareStatus
                showOnlyShared={true}
                sharedContents={clip?._allReferencingSharedContents}
              />
            </Platform>
            <Analytics>
              <SocialStats
                type="video"
                id={clip.id}
                allReferencingSharedContents={
                  clip?._allReferencingSharedContents
                }
                layout="dashboard"
              />
            </Analytics>

            <PublishedDate>
              {clip._publishedAt &&
                new Date(clip._publishedAt).toLocaleDateString()}
            </PublishedDate>
          </RowLink>
        </Row>
      </>
    );
  };

  const clipHeader = {} as ClipType;
  const clipsData = [clipHeader, ...getAllClips()];

  return (
    <>
      {clipsData?.map((clip, index) => {
        return (
          <div key={clip.id}>
            {index === 0
              ? renderClipTableHeader()
              : renderClipTableContent(clip)}
          </div>
        );
      })}
    </>
  );
});

export default ClipDashboardContent;

const HorizontalLine = styled.div`
  min-width: 1190px;
  width: 100%;
  height: 1px;
  background-color: #848484;
`;
const Row = styled.div`
  display: flex;
  // border-top: 1px solid #848484;
  height: 90px;
  gap: 30px;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
`;

const RowHeader = styled(Row)`
  border-top: unset;
  height: unset;
  margin-top: 40px;
  padding: 0 5px;
  padding-bottom: 10px;
  text-align: left;
`;
const Column = styled.div`
  height: 90px;
  display: flex;
  align-items: center;
  gap: 10px;
  min-width: 183px;
  width: 183px;
`;

const ThumbnailColumn = styled(Column)`
  gap: 15px;
  min-width: 380px;
  width: 380px;
`;

const ThumbnailHeader = styled(ThumbnailColumn)`
  gap: 8px;
  font-size: 12px;
  font-weight: 600;
  color: #848484;
  align-items: center;
  width: 350px;
  height: 100%;
`;
const ColumnHeader = styled(Column)`
  text-align: left;
  min-width: 200px;
  width: 200px;
  font-size: 12px;
  font-weight: 600;
  color: #848484;
  height: 100%;
`;

const ParentStoryHeader = styled(ColumnHeader)`
  width: 250px;
  min-width: 250px;
  height: 100%;
`;

const Thumb = styled.img`
  width: 140px;
  height: 90px;
  border-radius: 8px;
  object-fit: cover;
  object-position: top;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1,
  overflow: hidden;
  text-overflow: ellipsis;
  gap: 2px;
  .story-title {
    font-weight: 700;
    font-size: 14px;
    color: #f3e9d7;
    text-overflow: ellipsis;
  }
  .teller-name,
  .duration {
    font-weight: 500;
    font-size: 12px;
    color: #848484;
  }
`;

const ParentStory = styled(Column)`
  width: 250px;
  min-width: 250px;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  flex-direction: column;
  text-align: start;
  justify-content: center;
  align-items: flex-start;
  gap: 3px;
  .parent-story {
    color: #f3e9d7;
    font-weight: 400;
  }
  .clip-number {
    font-weight: 500;
    color: #848484;
  }
`;

const Platform = styled(Column)`
  width: 170px;
  min-width: 170px;
  font-weight: 500;
  font-size: 12px;
  color: #848484;
`;

const PlatformHeader = styled(Platform)`
  height: 100%;
`;

const Analytics = styled(Column)`
  width: 100px;
  min-width: 100px;
  // font-weight: 500;
`;

const AnalyticsHeader = styled(Analytics)`
  font-size: 12px;
  color: #848484;
  height: 100%;
`;

const PublishedDate = styled(Column)`
  font-weight: 400;
  text-align: right;
  color: #f3e9d7;
  margin-left: 50px;
  justify-content: flex-end;
  width: 80px;
  min-width: 80px;
`;

const DateHeader = styled(PublishedDate)`
  font-size: 12px;
  font-weight: 600;
  color: #848484;
  height: 100%;
`;

const RowLink = styled(Link)`
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  padding: 0 5px;
  align-items: center;
  min-width: 1190px;
  width: 100%;
  height: 100px;
  cursor: pointer;
  &:hover {
    background-color: #48484866;
    border-radius: 8px;
    .view-clip {
      display: flex;
    }
  }
`;

const ViewClip = styled.div`
  background-color: #17c964;
  text-decoration: none;
  width: 61px;
  height: 28px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 10px;
  line-height: 12.1px;
  text-align: center;
  color: #03041a;
  font-family: 'Inter', sans-serif;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: calc(50% - 30.5px);
  top: calc(50% - 14px);
  display: none;
`;

const ThumbContainer = styled.div`
  height: 100%;
  position: relative;
`;
