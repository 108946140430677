import { useState, useEffect } from 'react';
import type { UserResource, SessionResource } from '@clerk/types';
import type { DatoContext } from '../stores-v2/types';

interface AuthInitParams {
  datoCtx?: DatoContext;
  clerkUser?: UserResource | null;
  clerkSession?: SessionResource | null;
  isClerkLoaded?: boolean;
}

export function useAuthInitialization({
  datoCtx,
  clerkUser,
  clerkSession,
  isClerkLoaded,
}: AuthInitParams) {
  const [authState, setAuthState] = useState<{
    isAuthReady: boolean;
    needsRedirect: boolean;
    hasValidAuth: boolean;
  }>({
    isAuthReady: false,
    needsRedirect: false,
    hasValidAuth: false,
  });

  useEffect(() => {
    // Wait for Clerk to finish loading if we don't have Dato auth
    if (!datoCtx?.currentUserAccessToken && !isClerkLoaded) {
      return;
    }

    const hasClerkAuth = !!clerkSession;
    const hasDatoAuth = !!datoCtx?.currentUserAccessToken;

    // Add more detailed debugging
    console.debug('Detailed Auth Status:', {
      hasClerkAuth,
      hasDatoAuth,
      isClerkLoaded,
      clerkSessionStatus: clerkSession?.status,
      clerkUserId: clerkUser?.id,
      clerkSessionId: clerkSession?.id,
      datoToken: !!datoCtx?.currentUserAccessToken,
    });

    // Only redirect if we're sure auth has loaded and we have no valid auth
    if (!hasClerkAuth && !hasDatoAuth && isClerkLoaded) {
      console.debug('Triggering redirect because:', {
        noClerkAuth: !hasClerkAuth,
        noDatoAuth: !hasDatoAuth,
        isClerkLoaded,
      });
      setAuthState({
        isAuthReady: true,
        needsRedirect: true,
        hasValidAuth: false,
      });
      return;
    }

    setAuthState({
      isAuthReady: true,
      needsRedirect: false,
      hasValidAuth: hasClerkAuth || hasDatoAuth,
    });
  }, [datoCtx, clerkUser, clerkSession, isClerkLoaded]);

  return authState;
}
