import React from 'react';
import styled from 'styled-components';

import { observer } from 'mobx-react-lite';
import Modal from '../common/Modal';
import InfoIcon from '../../svgs/InfoIcon';
import { ApiError } from '@datocms/cma-client-browser';
import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';

type Props = {
  closeModal: () => void;
};

const RetrySavingModal: React.FC<Props> = observer((props: Props) => {
  const videoCreator = useVideoCreatorStore();
  const { closeModal } = props;
  const error = videoCreator.savingError!;
  const handleRetrySaving = async () => {
    const resetTimeline = false;
    const withRenderer = true;
    await videoCreator.saveStoryAndVideo(false, withRenderer, resetTimeline);
  };

  const errorMessage =
    'humanMessage' in error ? (error.humanMessage as string) : 'Unknown error';

  return (
    <Modal isOpen closeModal={closeModal}>
      <Wrapper>
        <Heading>
          <InfoIcon /> <span>Saving failed</span>
        </Heading>
        <Message>{errorMessage}</Message>
        <Buttons>
          <CancelButton onClick={closeModal}>Cancel</CancelButton>
          <SaveButton onClick={handleRetrySaving}>Retry</SaveButton>
        </Buttons>
      </Wrapper>
    </Modal>
  );
});

export default RetrySavingModal;

const Wrapper = styled.div`
  width: 459px;
  height: 260px;
  border-radius: 16px;
  border: 1px solid #484848;
  padding: 48px 24px;
  box-sizing: border-box;
  background: #03041a;
`;

const Heading = styled.div`
  color: #ef5da8;

  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.92px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
`;

const Message = styled.div`
  color: #f3e9d7;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.92px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
`;

const Buttons = styled.div`
  margin-top: 32px;
  display: flex;
  height: 48px;
  gap: 8px;
`;

const CancelButton = styled.div`
  box-sizing: border-box;
  flex: 1;
  display: flex;
  height: 48px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #ef5da8;
  cursor: pointer;

  font-size: 14px;
  font-weight: 700;

  color: #ef5da8;
`;

const SaveButton = styled(CancelButton)`
  background: #ef5da8;
  color: #03041a;
`;
