type Props = {
  width?: string;
  height?: string;
  viewBox?: string;
  fillColor?: string;
};

const EarthIcon = (props: Props) => {
  const {
    width = '12',
    height = '12',
    viewBox = '0 0 12 12',
    fillColor = '#848484',
  } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
    >
      <path
        d="M6 12C5.17 12 4.39 11.8424 3.66 11.5272C2.93 11.212 2.295 10.7846 1.755 10.245C1.215 9.7054 0.787601 9.0704 0.472801 8.34C0.158001 7.6096 0.000400759 6.8296 7.59493e-07 6C-0.00039924 5.1704 0.157201 4.3904 0.472801 3.66C0.788401 2.9296 1.2158 2.2946 1.755 1.755C2.2942 1.2154 2.9292 0.788 3.66 0.4728C4.3908 0.1576 5.1708 0 6 0C6.8292 0 7.6092 0.1576 8.34 0.4728C9.0708 0.788 9.7058 1.2154 10.245 1.755C10.7842 2.2946 11.2118 2.9296 11.5278 3.66C11.8438 4.3904 12.0012 5.1704 12 6C11.9988 6.8296 11.8412 7.6096 11.5272 8.34C11.2132 9.0704 10.7858 9.7054 10.245 10.245C9.7042 10.7846 9.0692 11.2122 8.34 11.5278C7.6108 11.8434 6.8308 12.0008 6 12ZM6 10.8C7.34 10.8 8.475 10.335 9.405 9.405C10.335 8.475 10.8 7.34 10.8 6C10.8 5.93 10.7976 5.8574 10.7928 5.7822C10.788 5.707 10.7854 5.6446 10.785 5.595C10.735 5.885 10.6 6.125 10.38 6.315C10.16 6.505 9.9 6.6 9.6 6.6H8.4C8.07 6.6 7.7876 6.4826 7.5528 6.2478C7.318 6.013 7.2004 5.7304 7.2 5.4V4.8H4.8V3.6C4.8 3.27 4.9176 2.9876 5.1528 2.7528C5.388 2.518 5.6704 2.4004 6 2.4H6.6C6.6 2.17 6.6626 1.9676 6.7878 1.7928C6.913 1.618 7.0654 1.4754 7.245 1.365C7.045 1.315 6.8426 1.275 6.6378 1.245C6.433 1.215 6.2204 1.2 6 1.2C4.66 1.2 3.525 1.665 2.595 2.595C1.665 3.525 1.2 4.66 1.2 6H4.2C4.86 6 5.425 6.235 5.895 6.705C6.365 7.175 6.6 7.74 6.6 8.4V9H4.8V10.65C5 10.7 5.1976 10.7376 5.3928 10.7628C5.588 10.788 5.7904 10.8004 6 10.8Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default EarthIcon;
