import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { convertTimeToMMSS } from '../../utility/timeFormat';
import lodash from 'lodash';

import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';

interface TickMarksProps {
  maxWidth: number;
}

export const Tickmarks: React.FC<TickMarksProps> = observer((props) => {
  const videoCreator = useVideoCreatorStore();
  const timelineScale = videoCreator.timelineScale;

  let duration =
    videoCreator.duration || parseFloat(videoCreator.originalVideoDuration);

  const maxWidth = props.maxWidth;

  const { timeMarks, ticks } = useMemo(() => {
    const timeDiff = Math.max(
      0,
      maxWidth - duration * videoCreator.timelineScale,
    );
    const extraTime = timeDiff / videoCreator.timelineScale;

    // figure out interval between major ticks
    let interval = 5;
    if (timelineScale >= 200) {
      interval = 1;
    } else if (timelineScale >= 130) {
      interval = 2;
    } else if (timelineScale >= 90) {
      interval = 3;
    } else if (timelineScale >= 60) {
      interval = 4;
    } else if (timelineScale >= 40) {
      interval = 5;
    } else if (timelineScale >= 20) {
      interval = 10;
    } else if (timelineScale >= 5) {
      interval = 15;
    } else if (timelineScale >= 4) {
      interval = 30;
    } else if (timelineScale >= 3) {
      interval = 60;
    } else if (timelineScale >= 1) {
      interval = 120;
    } else if (timelineScale >= 0.1) {
      interval = 300;
    } else {
      interval = 600;
    }

    let minorTicks = 5;

    if (interval === 1) {
      minorTicks = 24; // Assumes 24 frames per second
    }
    const minorInterval = interval / minorTicks;

    let timeMarks = [];
    let ticks = [];
    for (let i = 0; i <= duration + extraTime; i += interval) {
      const minutes = Math.floor(i / 60);
      const seconds = i - minutes * 60;
      let parts = [];
      if (minutes > 0) {
        parts.push(`${minutes} m`);
      }
      if (seconds > 0) {
        parts.push(`${seconds} s`);
      }

      if (parts.length === 0) {
        parts.push(`${seconds} s`);
      }

      timeMarks.push({ name: parts.join(' '), time: i });
      ticks.push({ type: 'major', time: i });
      for (let j = 1; j < minorTicks; j++) {
        ticks.push({ type: 'minor', time: i + j * minorInterval });
      }
    }
    return { timeMarks, ticks };
  }, [duration, timelineScale, maxWidth]);

  if (videoCreator.isLoading || !timelineScale) return <></>;

  return (
    <Main>
      <TimeMarkWrapper
        style={{ width: Math.max(duration * timelineScale, maxWidth) }}
      >
        {timeMarks.map((timeMark) => {
          return (
            <TimeMark
              style={{
                left: timeMark.time * timelineScale,
              }}
              key={timeMark.time}
            >
              {/* {timeMark.name} */}
              {convertTimeToMMSS(timeMark.time)}
            </TimeMark>
          );
        })}
      </TimeMarkWrapper>
      <TickWrapper>
        {ticks.map((tick) => {
          if (tick.type === 'major') {
            return (
              <Major
                style={{
                  left: tick.time * timelineScale,
                }}
                key={tick.time}
              ></Major>
            );
          } else if (tick.type === 'minor') {
            return (
              <Minor
                style={{
                  left: tick.time * timelineScale,
                }}
                key={tick.time}
              ></Minor>
            );
          } else {
            return null;
          }
        })}
      </TickWrapper>
    </Main>
  );
});

const Main = styled.div`
  position: absolute;
  padding-top: 8px;
  padding-left: 14px;
  z-index: 1;
  height: 27px;
  overflow: hidden;
`;

const TimeMarkWrapper = styled.div`
  box-sizing: content-box;
  position: relative;
  height: 16px;
  color: rgb(97, 97, 97);
`;

const TimeMark = styled.div`
  position: absolute;
  font-size: 10px;
  font-weight: 600;
  text-wrap: nowrap;
  transform: translateX(-50%);
  color: #f2d093;
`;

const TickWrapper = styled.div`
  position: relative;
  padding-top: 2px;
  height: 3px;
`;

const Major = styled.div`
  position: absolute;
  width: 3px;
  height: 3px;
  border-radius: 100%;
  background-color: rgb(206, 206, 206);
  transform: translateX(-1.5px);
`;

const Minor = styled.div`
  position: absolute;
  width: 3px;
  height: 3px;
  border-radius: 100%;
  transform: translateX(-1.5px);
  background-color: rgb(132, 139, 151);
`;
