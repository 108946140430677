type Props = {
  width?: string;
  height?: string;
  viewBox?: string;
  strokeColor?: string;
};
const ShareIcon = (props: Props) => {
  const {
    width = '24',
    height = '24',
    viewBox = '0 0 19 18',
    strokeColor = '#03041A',
  } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
    >
      <path
        d="M9.5 3.40039H1.5V17.0004H15.1V9.00039"
        stroke={strokeColor}
        strokeWidth="2"
      />
      <path d="M7.90039 10.6L17.5004 1" stroke={strokeColor} strokeWidth="2" />
      <path d="M11.0996 1H17.4996V7.4" stroke={strokeColor} strokeWidth="2" />
    </svg>
  );
};

export default ShareIcon;
