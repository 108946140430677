import React, { useState, useRef, useEffect } from 'react';
import { useOutsideAlerter } from '../transcript/useClickOutside';
import styled, { css } from 'styled-components';
import HighlightIcon from '../../svgs/HighlightIcon';
import { KaraokeConfig } from '../../videoTranscriptionProcessor/types/karaokeTypes';
import { PropertyNumericalInput } from '../sidepanel/PropertyNumericalInput';
import ColorPicker from './ColorPicker';
import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';

const Colors: Record<string, string> = {
  white: 'rgba(255, 255, 255, 1)',
  black: 'rgba(0, 0, 0, 1)',
  red: 'rgba(255, 0, 0, 1)',
  green: 'rgba(0, 255, 0, 1)',
  orange: 'rgba(255, 165, 0, 1)',
  blue: 'rgba(0, 0, 255, 1)',
  yellow: 'rgba(255, 255, 0, 1)',
};

type Props = {
  action: (propertyMap: Partial<KaraokeConfig>) => void;
  defaultValue?: string | null;
  opacity: number;
};

const formatter = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  maximumFractionDigits: 1,
  minimumFractionDigits: 0,
});

const KaraokeBackground = (props: Props) => {
  const videoCreator = useVideoCreatorStore();
  const [openPicker, togglePicker] = useState<DOMRect | null>(null);

  const contentRef = useRef<HTMLDivElement>(null);
  useOutsideAlerter(contentRef, () => {
    togglePicker(null);
  });

  const pickerClose = videoCreator.textBrandingService.closeActiveState;
  useEffect(() => {
    if (pickerClose) {
      togglePicker(null);
      videoCreator.textBrandingService.closeActiveState = false;
    }
  }, [pickerClose]);

  let currColor = props.defaultValue ?? 'rgba(0, 0, 0, 1)';

  if (currColor in Colors) {
    currColor = Colors[currColor];
  }
  const rgbaArray = currColor?.match(/\d+(\.\d+)?/g)?.map(Number) || [];

  return (
    <Main ref={contentRef}>
      {openPicker && (
        <ColorPicker
          defaultValue={props.defaultValue}
          getValue={(colorValue) => {
            let color = colorValue;
            if (!colorValue) color = null;
            props.action({ background_color: color });
          }}
          pos={openPicker}
          onClose={() => {
            togglePicker(null);
          }}
        />
      )}
      <Content
        isSelected={!!props.defaultValue}
        onClick={(e) => {
          if (openPicker) return togglePicker(null);
          return togglePicker(e.currentTarget.getBoundingClientRect());
        }}
      >
        <Highlight>
          <HighlightIcon />
          {props.defaultValue && (
            <HighlightStroke selectedItem={props.defaultValue} />
          )}
        </Highlight>
      </Content>
      <HighlightDeg onClick={() => togglePicker(null)}>
        <PropertyNumericalInput
          defaultValue={formatter.format((props.opacity || 1) * 100)}
          unit="%"
          propertyName="opacity"
          manualKaraoke
          padding="12px 8px"
          customStyles="width: 60px;"
          getInputValue={(key: string, val: any) => {
            const newOpacity = parseFloat(val || 0) / 100;

            if (!isNaN(newOpacity) && newOpacity >= 0 && newOpacity <= 1) {
              if (rgbaArray.length === 3) {
                rgbaArray.push(newOpacity);
              } else if (rgbaArray.length === 4) {
                rgbaArray[3] = newOpacity;
              }

              const newColor = `rgba(${rgbaArray.join(', ')})`;
              props.action({ background_color: newColor });
            }
          }}
        />
      </HighlightDeg>
    </Main>
  );
};

export default KaraokeBackground;

const Content = styled.div<{ isSelected: boolean }>`
  position: relative;
  width: 40px;
  height: 40px;
  aspect-ratio: 1/1;
  border: 1px solid #f2d093;

  background-color: #f2d093;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
const Main = styled.div`
  display: flex;
  gap: 8px;
  position: relative;
  width: fit-content;
`;
const Dropdown = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #484848;
  border-radius: 8px;
  background-color: #03041a;
  box-shadow: 8px 16px 8px 0px rgba(0, 0, 0, 0.4);
  padding: 0 5px;
  padding-top: 5px;
  height: 230px;
  width: calc(100% - 10px);
  overflow: auto;
  position: absolute;
  top: 40px;

  cursor: pointer;
  z-index: 1;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
`;

const DropdownItem = styled.span<{
  item?: string | null;
}>`
  margin: 4px 0;
  height: 25px;

  ${(props) =>
    props.item &&
    css`
      background-color: ${props.item};
      border: ${props.item === 'rgba(0, 0, 0, 1)' && '1px solid #484848'};
    `}
  ${(props) =>
    !props.item &&
    css`
      background: linear-gradient(
        to left top,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 1) calc(50% - 0.8px),
        rgb(255, 0, 0) 50%,
        rgba(255, 255, 255, 1) calc(50% + 0.8px),
        rgba(255, 255, 255, 1) 100%
      );
    `}


  &:not(:last-child) {
    border-bottom: ${(props) => props.item !== 'black' && '1px solid #484848'};
  }
  &:hover {
    color: #f2d093;
  }
`;

const HighlightStroke = styled.div<{ selectedItem: string | null }>`
  background-color: ${(props) => props.selectedItem};
  width: 18px;
  height: 5px;
`;

const Highlight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  width: 100%;
`;

const HighlightDeg = styled.div`
  padding: 0px;
  max-width: 76px;
  input {
    box-sizing: border-box;
  }
`;
