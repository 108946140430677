import { useQuerySubscription } from 'react-datocms/use-query-subscription';
import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';

export const useQuerySharedContent = (ids: string[]) => {
  const videoCreator = useVideoCreatorStore();
  const { status, error, data } = useQuerySubscription({
    query: `query getSharedContents($ids: [ItemId]) {
        allSharedContents(filter: {id: {in: $ids}, postUrl: {isPresent: "true"}}) {
          id
          ayrId
          postUrl
        }
    }`,
    variables: { ids },
    environment: videoCreator.datoContext.environment,
    token: process.env.REACT_APP_DATOCMS_READ_API_TOKEN!,
  });

  return { status, error, data };
};
