import { useEffect } from 'react';
import { useQuerySubscription } from 'react-datocms/use-query-subscription';

import { ShowcaseArtifactsAiTitleUpdate } from '../types.ts/story';
import { SHOWCASES_ARTIFACT_AI_TITLE_QUERY } from '../gql/ai-prompts-gql';

import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';

export const useQueryShowcases = (
  ids: string[],
): {
  status: string;
  error: any;
  data: { allShowcases: ShowcaseArtifactsAiTitleUpdate[] } | undefined;
} => {
  const videoCreator = useVideoCreatorStore();

  const { status, error, data } = useQuerySubscription({
    query: SHOWCASES_ARTIFACT_AI_TITLE_QUERY,
    variables: { ids },
    environment: videoCreator.datoContext.environment,
    token: process.env.REACT_APP_DATOCMS_READ_API_TOKEN!,
  });

  return { status, error, data };
};

const OrganizationArtifactsAiTitleSubscription = (props: {
  organizationIds: string[];
  onDataReceived: (updates: ShowcaseArtifactsAiTitleUpdate[]) => void;
  onError: (error: any) => void;
}) => {
  const { organizationIds, onDataReceived, onError } = props;
  const { error, data } = useQueryShowcases(organizationIds);

  useEffect(() => {
    if (error) {
      onError(error);
    } else if (data?.allShowcases?.length) {
      onDataReceived(data.allShowcases);
    }
  }, [data, error]);

  return <></>;
};

export default OrganizationArtifactsAiTitleSubscription;
