import styled, { css } from 'styled-components';

import CreateIcon from '@src/svgs/DocumentIcon';
import GenerateIcon from '@src/svgs/CreateIcon';
import RotatingSquare from '@src/svgs/RotatingSquare';
import ShareIcon from '@src/svgs/ShareIcon';
import SaveIcon from '@src/svgs/SaveIcon';
import CopyIcon from '@src/svgs/CopyIcon';

type Props = {
  disabled?: boolean;
  onClick?: () => void;
  buttonLabel?: string;
  variant?: 'primary' | 'outlined';
  size?: 'sm' | 'lg';
  fullWidth?: boolean;
  icon?: 'create' | 'generate' | 'regenerate' | 'copy' | 'share' | 'save';
};

const ContentButton = (props: Props) => {
  const {
    disabled,
    onClick,
    variant = 'primary',
    size = 'sm',
    fullWidth = false,
    buttonLabel = 'Generate',
    icon: _icon,
  } = props;
  const iconProps = {
    width: '16',
    height: '16',
    strokeColor: variant === 'primary' ? '#03041A' : '#17c964',
  };
  const icon =
    _icon === 'create' ? (
      <CreateIcon {...iconProps} />
    ) : _icon === 'generate' ? (
      <GenerateIcon {...iconProps} />
    ) : _icon === 'regenerate' ? (
      <RotatingSquare {...iconProps} />
    ) : _icon === 'copy' ? (
      <CopyIcon {...iconProps} />
    ) : _icon === 'share' ? (
      <ShareIcon {...iconProps} />
    ) : _icon === 'save' ? (
      <SaveIcon {...iconProps} />
    ) : null;
  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      variant={variant}
      size={size}
      fullWidth={fullWidth}
    >
      {icon}
      {buttonLabel}
    </Button>
  );
};

const Button = styled.button<{
  variant: 'primary' | 'outlined';
  size: 'sm' | 'lg';
  fullWidth: boolean;
}>`
  display: flex;
  height: ${({ size }) => (size === 'lg' ? '48px' : '40px')};
  width: ${({ fullWidth, size }) =>
    fullWidth ? '100%' : size === 'lg' ? '240px' : '148px'};
  padding: 0 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  ${({ variant }) => {
    switch (variant) {
      case 'outlined':
        return css`
          background: none;
          color: #17c964;
          border: 1px solid #17c964;
        `;
      default:
        return css`
          background: #17c964;
          color: #03041a;
          border: none;
        `;
    }
  }}
  border-radius: 8px;
  outline: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  transition: 0.15s;
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export default ContentButton;
