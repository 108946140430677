type Props = {
  width?: string;
  height?: string;
  viewBox?: string;
  strokeColor?: string;
};

const SaveIcon = (props: Props) => {
  const {
    width = '24',
    height = '25',
    viewBox = '0 0 24 25',
    strokeColor = 'currentColor',
  } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
    >
      <path
        d="M20.4545 7.9091H12.7555L10.2727 5.42637C10.1381 5.29072 9.97795 5.18316 9.80144 5.10997C9.62493 5.03678 9.43563 4.9994 9.24454 5.00001H4.45454C4.06878 5.00001 3.69881 5.15325 3.42603 5.42603C3.15325 5.69881 3 6.06878 3 6.45455V19.6018C3.00048 19.9725 3.14794 20.3278 3.41005 20.59C3.67215 20.8521 4.02751 20.9995 4.39818 21H20.5354C20.8996 20.9995 21.2487 20.8546 21.5062 20.5971C21.7637 20.3396 21.9086 19.9905 21.9091 19.6264V9.36364C21.9091 8.97787 21.7558 8.6079 21.4831 8.33512C21.2103 8.06234 20.8403 7.9091 20.4545 7.9091ZM4.45454 6.45455H9.24454L10.6991 7.9091H4.45454V6.45455ZM20.4545 19.5455H4.45454V9.36364H20.4545V19.5455Z"
        fill={strokeColor}
      />
    </svg>
  );
};

export default SaveIcon;
