import { ElementState } from '../renderer/ElementState';
import { Renderer } from '../renderer/Renderer';

import {
  TranscriptChange,
  TranscriptClipboard,
  TranscriptElement,
} from '../types.ts/transcript';

import VideoToTranscriptionProcessor from './VideoToTranscriptionProcessor';
import TranscriptionToVideoProcessor from './TranscriptionToVideoProcessor';
import TranscriptionProcessor from './TranscriptionProcessor';
import { adjustTrackNumbersToStartFromOne } from './utils';
import VideoCreatorStore from '@src/stores/VideoCreatorStore';

export default class VideoTranscriptionProcessor {
  private videoToTranscriptionProcessor: VideoToTranscriptionProcessor;
  private transcriptionToVideoProcessor: TranscriptionToVideoProcessor;
  private transcriptionProcessor: TranscriptionProcessor;

  constructor(videoCreator: VideoCreatorStore) {
    this.transcriptionProcessor = new TranscriptionProcessor(videoCreator);
    this.videoToTranscriptionProcessor = new VideoToTranscriptionProcessor(
      videoCreator,
      this.transcriptionProcessor,
    );
    this.transcriptionToVideoProcessor = new TranscriptionToVideoProcessor(
      videoCreator,
      this.transcriptionProcessor,
    );
  }

  get transcriptClipboard(): TranscriptClipboard | undefined {
    return this.transcriptionToVideoProcessor.transcriptClipboard;
  }

  set onFinalTranscriptionElementsChange(
    onChangeFn: (elements: TranscriptElement[]) => void,
  ) {
    this.transcriptionProcessor.onFinalTranscriptionElementsChange = onChangeFn;
  }

  get finalTranscriptionElements() {
    return this.transcriptionProcessor.getFinalTranscriptionElements();
  }

  setRenderer(renderer: Renderer) {
    this.videoToTranscriptionProcessor.setRenderer(renderer);
    this.transcriptionToVideoProcessor.setRenderer(renderer);
  }

  setOriginalSource(source: Record<string, any>) {
    this.transcriptionToVideoProcessor.setOriginalSource(source);
  }

  setTranscriptionElements(transcriptionElements: TranscriptElement[]) {
    this.transcriptionProcessor.setTranscriptionElements(transcriptionElements);
  }

  setTranscriptionSnapshot(elements: TranscriptElement[]) {
    this.transcriptionProcessor.setTranscriptionSnapshot(elements);
  }

  setTranscriptionChanges(changes: TranscriptChange[]) {
    this.transcriptionProcessor.setTranscriptionChanges(changes);
  }

  getTranscriptionChanges() {
    return this.transcriptionProcessor.getTranscriptionChanges();
  }

  getFinalTranscriptionText() {
    return this.transcriptionProcessor.getFinalTranscriptionText();
  }

  applyChangesToOriginalTranscription(transcriptChanges: TranscriptChange[]) {
    this.transcriptionProcessor.applyChangesToOriginalTranscription(
      transcriptChanges,
    );
  }

  applyChangesToCurrentTranscription(transcriptChanges: TranscriptChange[]) {
    this.transcriptionProcessor.applyChangesToCurrentTranscription(
      transcriptChanges,
    );
  }

  /*
    Operations on video tracks reflected in transcription
  */
  async trimTrackStart(
    elementId: number,
    newStartTime: number,
    trimStart: number,
    newDuration: number,
  ) {
    return this.videoToTranscriptionProcessor.trimTrackStart(
      elementId,
      newStartTime,
      trimStart,
      newDuration,
    );
  }

  async moveElements(elementIds: string[], time: number) {
    return this.videoToTranscriptionProcessor.moveElements(elementIds, time);
  }

  async moveTrack(elementId: number, newStartTime: number) {
    return this.videoToTranscriptionProcessor.moveTrack(
      elementId,
      newStartTime,
    );
  }

  async trimTrackDuration(elementId: string, duration: number) {
    return this.videoToTranscriptionProcessor.trimTrackDuration(
      elementId,
      duration,
    );
  }

  /**
   * Removes a track from the video and transcription
   * @param elementId
   */
  async deleteOriginalVideoTrack(elementId: string) {
    return this.videoToTranscriptionProcessor.deleteOriginalVideoTrack(
      elementId,
    );
  }

  async untrimTextElements(
    fromTs: number,
    toTs: number,
    newTs: number,
    type: 'start' | 'end',
  ) {
    return this.videoToTranscriptionProcessor.untrimTextElements(
      fromTs,
      toTs,
      newTs,
      type,
    );
  }

  /** Operations on transcription reflected in video tracks */
  async pasteFromClipboard(intoPosition: number) {
    return this.transcriptionToVideoProcessor.pasteFromClipboard(intoPosition);
  }

  async removeTextElements(
    fromElement: number,
    toElement: number,
    toClipboard: boolean = false,
    autoCorrect: boolean = false,
  ) {
    return this.transcriptionToVideoProcessor.removeTextElements(
      fromElement,
      toElement,
      toClipboard,
      autoCorrect,
    );
  }

  async cropVideoToKeepTextElements(
    fromElement: number,
    toElement: number,
    noRendererOutput?: {
      duration: number;
      source: Record<string, any>;
    },
  ): Promise<void> {
    return this.transcriptionToVideoProcessor.cropVideoToKeepTextElements(
      fromElement,
      toElement,
      noRendererOutput,
    );
  }

  async cropVideoToKeepTextElementsInMultiplePlaces(
    ranges: { fromElement: number; toElement: number }[],
    noRendererOutput?: {
      duration: number;
      source: Record<string, any>;
    },
  ) {
    return this.transcriptionToVideoProcessor.cropVideoToKeepTextElementsInMultiplePlaces(
      ranges,
      noRendererOutput,
    );
  }

  async insertTextElements(
    fromElement: number,
    toElement: number,
    intoPosition: number,
  ) {
    return this.transcriptionToVideoProcessor.insertTextElements(
      fromElement,
      toElement,
      intoPosition,
    );
  }

  /** remove transcription elements between fromElement and toElement positions and place them next to afterElement */
  async moveTextElements(
    fromElement: number,
    toElement: number,
    afterElement: number,
  ) {
    return this.transcriptionToVideoProcessor.moveTextElements(
      fromElement,
      toElement,
      afterElement,
    );
  }

  replaceTextElement(startIndex: number, endIndex: number, newValue: string) {
    return this.transcriptionProcessor.replaceTextElement(
      startIndex,
      endIndex,
      newValue,
    );
  }

  replaceElementTs(
    startIndex: number,
    endIndex: number,
    newValueTs: number,
    newValueEndTs: number,
  ) {
    return this.transcriptionProcessor.replaceElementTs(
      startIndex,
      endIndex,
      newValueTs,
      newValueEndTs,
    );
  }

  hideKaraoke(
    boundaries: { startIndex: number; endIndex: number },
    isBatched: boolean = false,
  ) {
    return this.transcriptionProcessor.hideKaraoke(boundaries, isBatched);
  }

  restoreMutedTextElement(
    fromElement: number,
    toElement: number,
    isBatched: boolean = false,
  ) {
    return this.transcriptionProcessor.restoreMutedTextElement(
      fromElement,
      toElement,
      isBatched,
    );
  }

  muteTextElement(
    fromElement: number,
    toElement: number,
    isBatched: boolean = false,
  ) {
    return this.transcriptionProcessor.hideKaraoke(
      { startIndex: fromElement, endIndex: toElement },
      isBatched,
    );
  }

  async restoreTextElementsFromOriginal(
    fromElement: number,
    toElement: number,
  ) {
    return this.transcriptionToVideoProcessor.restoreTextElementsFromOriginal(
      fromElement,
      toElement,
    );
  }

  addPunctuation(
    code: 'Comma' | 'Period' | 'Space' | 'Enter',
    position: number,
    options: { karaoke_break?: boolean } = {},
  ) {
    return this.transcriptionProcessor.addPunctuation(code, position, options);
  }

  addKaraokeBreaks(positions: number[]) {
    return this.transcriptionProcessor.addKaraokeBreaks(positions);
  }

  removeKaraokeBreak(position: number) {
    return this.transcriptionProcessor.removeKaraokeBreak(position);
  }

  removeAllKaraokeBreaks() {
    return this.transcriptionProcessor.removeAllKaraokeBreaks();
  }

  removeKaraokeMutes() {
    return this.transcriptionProcessor.removeAllKaraokeMutes();
  }

  addPhotoHighlight(
    fromElement: number,
    toElement: number,
    photoHighlightId: string,
  ) {
    return this.transcriptionProcessor.addPhotoHighlight(
      fromElement,
      toElement,
      photoHighlightId,
    );
  }

  removeAllPhotoHighlights() {
    return this.transcriptionProcessor.removeAllPhotoHighlights();
    // todo onFinalTranscriptionElementsChange?
  }

  removeSinglePhotoHighlight(id: string) {
    return this.transcriptionProcessor.removeSinglePhotoHighlight(id);
    // this.onFinalTranscriptionElementsChange(this.finalTranscriptionElements!);
  }

  removeAllAutoPhotoHighlights() {
    return this.transcriptionProcessor.removeAllAutoPhotoHighlights();
    // this.onFinalTranscriptionElementsChange(this.finalTranscriptionElements!);
  }

  findClosestIndexToTimestamp(
    ts: number,
    time: 'ts' | 'end_ts' = 'ts',
    helperStrategy?: 'ts_lookbehind',
  ) {
    return this.transcriptionProcessor.findClosestIndexToTimestamp(
      ts,
      time,
      helperStrategy,
    );
  }

  async applyKaraokeElementPlacement(
    element: ElementState,
    placement: Pick<ElementState, 'duration' | 'globalTime' | 'trimStart'>,
    isSubtitles: boolean = false,
  ) {
    return this.videoToTranscriptionProcessor.applyKaraokeElementPlacement(
      element,
      placement,
      isSubtitles,
    );
  }

  adjustTrackNumbersToStartFromOne(source: any) {
    return adjustTrackNumbersToStartFromOne(source);
  }

  reapplyEdits(oldTranscriptElements: TranscriptElement[]) {
    this.transcriptionProcessor.reapplyEdits(oldTranscriptElements);
  }

  resetUndoRedo() {
    // todo: stub, remove
  }
}
