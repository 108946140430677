import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import CircleCheckIcon from '../../svgs/CircleCheckIcon';
import SendIcon from '../../svgs/SendIcon';
import CloseIcon from '../../svgs/CloseIcon';

import CircleExclammationIcon from '../../svgs/CircleExclammationIcon';
import { runInAction } from 'mobx';
import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';

type Props = {
  delay?: number;
};
const ToastNotification = (props: Props) => {
  const videoCreator = useVideoCreatorStore();
  const { state = 'success', message } = videoCreator.toastState!;
  const [slidingDown, setSlidingDown] = useState<boolean>(false);
  const { delay = 5000 } = props;

  useEffect(() => {
    let timer = setTimeout(() => {
      // runInAction(() => videoCreator.toastState = null)
      setSlidingDown(true);
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    if (!slidingDown) return;

    let timer = setTimeout(() => {
      runInAction(() => (videoCreator.toastState = null));
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [slidingDown]);

  const getBgColor = () => {
    if (state === 'success') {
      return '#17C964';
    } else if (state === 'publishing') {
      return '#F2D093';
    }

    return '#F178B6';
  };

  const getStatusIcon = () => {
    if (state === 'success') {
      return <CircleCheckIcon />;
    } else if (state === 'publishing') {
      return <SendIcon />;
    }

    return <CircleExclammationIcon />;
  };

  function handleClose(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.stopPropagation();
    videoCreator.toastState = null;
  }

  return (
    <Main bgColor={getBgColor()} slidingDown={slidingDown}>
      {getStatusIcon()}
      {message}
      <Close onClick={handleClose}>
        {' '}
        <CloseIcon strokeColor="currentColor" />{' '}
      </Close>
    </Main>
  );
};

export default ToastNotification;

const slideUp = keyframes`
  from {
    transform: translateY(100%) translateX(-50%);
  }
  to {
    transform: translateY(0) translateX(-50%);
  }
`;

const slideDown = keyframes`
  from {
    transform: translateY(0) translateX(-50%);
  }
  to {
    transform: translateY(100%) translateX(-50%);
  }
`;

const Main = styled.div<{ bgColor: string; slidingDown: boolean }>`
  padding: 0 15px;
  height: 60px;
  border-radius: 8px;
  gap: 10px;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 20px;
  background-color: ${(props) => props.bgColor};
  box-shadow: 8px 8px 16px 0px #00000066;
  color: #03041a;
  font-weight: 700;
  font-size: 14px;
  line-height: 16.94px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${(props) => (props.slidingDown ? slideDown : slideUp)} 0.5s
    ease-in-out;
  z-index: 10000001;
`;

const Close = styled.button`
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
`;
