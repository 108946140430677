import { getClosestTextIndexToLeft, getClosestTextIndexToRight } from './utils';
import {
  TranscriptElement,
  TranscriptChange,
  TranscriptTextElement,
} from '../types.ts/transcript';
import { ElementState } from '../renderer/ElementState';
import VideoCreatorStore from '@src/stores/VideoCreatorStore';

interface ClipItem {
  startTs: number;
  endTs: number;
  inTs: number;
  outTs: number;
  duration: number;
}

export const restoreTranscriptionFromVideoSource = (
  VideoCreator: VideoCreatorStore,
  videoElements: ElementState[],
  originalTranscription: { elements: TranscriptElement[] },
) => {
  const clipItems: ClipItem[] = videoElements.map((clip) => ({
    startTs: clip.globalTime,
    endTs: clip.globalTime + clip.duration,
    inTs: clip.trimStart || 0,
    outTs: (clip.trimStart || 0) + clip.duration,
    duration: clip.duration,
  }));
  const transcriptionChanges = generateTranscriptionChanges(
    VideoCreator,
    clipItems,
    originalTranscription.elements,
  );
  return transcriptionChanges;
};

const generateTranscriptionChanges = (
  videoCreator: VideoCreatorStore,
  clipItems: ClipItem[],
  originalElements: TranscriptElement[],
) => {
  if (!originalElements) throw new Error('No transcription elements loaded');
  // debugger;
  const restoreChanges: TranscriptChange[] = [];
  const firstTextElement = getClosestTextIndexToRight(0, originalElements);
  const lastTextElement = getClosestTextIndexToLeft(
    originalElements.length - 1,
    originalElements,
  );
  const firstElement = originalElements[
    firstTextElement
  ] as TranscriptTextElement;
  const lastElement = originalElements[
    lastTextElement
  ] as TranscriptTextElement;
  const timeBufferBefore = firstElement.ts;
  const timeBufferAfter =
    videoCreator.story!.originalVideo.video.duration - lastElement.end_ts;
  const removeAllChange: TranscriptChange = {
    type: 'remove',
    index: 0,
    count: originalElements.length,
    timeBufferBefore,
    timeBufferAfter,
    oldValue: 'all',
    newValue: null,
    datetime: new Date().toISOString(),
    version: 2,
  };

  let maxRestoredIndex = -1;
  let addedCounter = 0;

  for (let i = 0; i < clipItems.length; i++) {
    const clipItem = clipItems[i];
    const newTs = clipItem.startTs;
    const endTs = clipItem.endTs;
    const duration = endTs - newTs;

    const fromTs = clipItem.inTs;
    const fromElementIndex = originalElements.findIndex(
      (el) =>
        el.end_ts && el.end_ts >= fromTs && fromTs - el.ts < el.end_ts - fromTs,
    );

    const toTs = fromTs + duration;
    let toElementIndex = originalElements.findIndex(
      (el) => el.end_ts && el.end_ts >= toTs,
    );

    if (fromElementIndex >= 0) {
      if (toElementIndex === -1) {
        toElementIndex = lastTextElement;
      }
      if (
        originalElements[toElementIndex].ts &&
        originalElements[toElementIndex].end_ts &&
        toTs - originalElements[toElementIndex].ts! <
          originalElements[toElementIndex].end_ts! - toTs
      ) {
        toElementIndex = getClosestTextIndexToLeft(
          toElementIndex - 1,
          originalElements,
        );
      }

      if (toElementIndex >= fromElementIndex) {
        const timeBufferBefore =
          originalElements[fromElementIndex].ts! - fromTs;
        const timeBufferAfter = toTs - originalElements[toElementIndex].end_ts!;
        let nextTextElement = getClosestTextIndexToRight(
          toElementIndex + 1,
          originalElements,
        );
        if (nextTextElement === -1) {
          nextTextElement = originalElements.length;
        }
        if (fromElementIndex >= maxRestoredIndex) {
          restoreChanges.push({
            type: 'restore',
            index: fromElementIndex + addedCounter,
            count: nextTextElement - fromElementIndex, // include punctuation after
            newTs,
            timeBufferBefore,
            timeBufferAfter,
            datetime: new Date().toISOString(),
          });
          maxRestoredIndex = nextTextElement + addedCounter;
        } else {
          restoreChanges.push({
            type: 'insert_text',
            index: fromElementIndex,
            count: nextTextElement - fromElementIndex, // include punctuation after
            newTs,
            newIndex: maxRestoredIndex + addedCounter,
            timeBufferBefore,
            timeBufferAfter,
            datetime: new Date().toISOString(),
          });
          addedCounter += nextTextElement - fromElementIndex;
        }
      }
    }
  }

  return [removeAllChange, ...restoreChanges];
};
