import VideoCreatorStore from '@src/stores/VideoCreatorStore';
import { ElementState } from '../renderer/ElementState';

import { ExtraElementData, VolumeKeyPoint } from '../types.ts/story';

export const areVolumeKeyPointsSupportedBy = (
  element: ElementState,
): boolean => {
  return element.source?.type === 'audio';
};

export const getVolumeKeyPointsOf = (
  videoCreator: VideoCreatorStore,
  element: ElementState,
): VolumeKeyPoint[] | undefined => {
  let result = (
    videoCreator.currentVideo?.extraElementData[
      element.source?.id
    ] as ExtraElementData
  )?.volumeKeyPoints;
  if (!areVolumeKeyPointsSupportedBy(element)) {
    result = undefined;
  }
  return result;
};
