/* eslint-disable react/jsx-no-target-blank */
import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { ElementState } from '../../renderer/ElementState';
import { TextInput } from './TextInput';
import styled, { css } from 'styled-components';
import { KARAOKE_TRACK_NUMBER } from '../../stores/VideoCreatorStore';
import SliderSelect from './SliderSelect';
import UpDownIcon from '../../svgs/UpDownIcon';
import ItemDropdownSelect from '../common/ItemDropdownSelect';
import lodash from 'lodash';
import { KaraokeConfig } from '../../videoTranscriptionProcessor/types/karaokeTypes';
import KaraokeBackground from '../common/KaraokeBackground';
import { getMinMaxYPosition } from '../../videoTranscriptionProcessor/utils';
import { PropertyCaption } from './PropertyCaption';
import { PropertyNumericalInput } from './PropertyNumericalInput';
import { numericalInputStyles } from '../../styles/mainStyle';
import { observer } from 'mobx-react-lite';
import ColorDropdownSelect from '../common/ColorDropdownSelect';
import { FONT_SIZE_VALUES } from '../../utility/general';
import FontDropdownSelect from '../common/FontDropdownSelect';
import TextBrandingTemplates from '../common/TextBrandingTemplates';
import PrimaryActionButton from './PrimaryActionButton';
import { TextIcon } from '../common/icons';
import { TextAnimationSettings } from './TextAnimationSettings';
import SpinningLoading from '../SpinningLoading';
import SaveOrCancelTemplate from '../common/SaveOrCancelTemplate';
import { LowerThirdTemplate } from '../textProcessor/TextBrandingService';
import TextBoldAlignFormatting from '../common/TextBoldAlignFormatting';
import KaraokeShadow from '../common/KaraokeShadow';
import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';

interface TextSettingsProps {
  activeElement: ElementState | null;
}

enum Action {
  bold = 'Bold',
  italic = 'Italic',
  capitalize = 'Capitalize',
  align = 'Align',
  // bgColor = 'Background Color',
}

enum Align {
  leftAlign = 'Left Align',
  midAlign = 'Middle Align',
  rightAlign = 'Right Align',
}

export const AlignValues = {
  [Align.leftAlign]: '0%',
  [Align.midAlign]: '50%',
  [Align.rightAlign]: '100%',
};

enum Bold {
  normal = 'Normal',
  semiBold = 'Semi-Bold',
  bold = 'Bold',
  extraBold = 'Extra-Bold',
}

const BoldValues = {
  [Bold.normal]: 400,
  [Bold.semiBold]: 600,
  [Bold.bold]: 700,
  [Bold.extraBold]: 800,
};

enum Colors {
  black = 'black',
  white = 'white',
  red = 'red',
  green = 'green',
  orange = 'orange',
  blue = 'blue',
  yellow = 'yellow',
}

const formatter = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  maximumFractionDigits: 3,
  minimumFractionDigits: 0,
});

export const TextSettings = observer(
  forwardRef<any, TextSettingsProps>((props, ref) => {
    const videoCreator = useVideoCreatorStore();
    const { source } = props.activeElement || {};
    const config = videoCreator.textProducer.getTextConfig();
    const template = videoCreator.textBrandingService
      .selectedBrandingTemplate as LowerThirdTemplate;
    const textWrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (source && source.track !== KARAOKE_TRACK_NUMBER) {
        videoCreator.textProducer.setTextConfig(source);
      }
    }, [source, videoCreator.currentVideo?.aspectRatio]);

    useEffect(() => {
      if (!source) {
        videoCreator.textProducer.setTextConfig({
          ...config,
          time: videoCreator.time,
        });
      }
    }, [videoCreator.time, template]);

    useEffect(() => {
      if (videoCreator.textBrandingService.isLoading) {
        textWrapperRef.current?.scrollIntoView();
      }
    }, [videoCreator.textBrandingService.isLoading]);

    useEffect(() => {
      return () => {
        if (videoCreator.textBrandingService.isTemplateModified) {
          videoCreator.textBrandingService.isTemplateModified = false;
        }
      };
    }, []);

    const [addNewTemplate, toggleAddNewTemplate] = useState<boolean>(false);
    const [templateName, setTemplateName] = useState<string>();
    const [templateNameError, setTemplateNameError] = useState<boolean>(false);
    const [allowCustomTemplateSave, setAllowCustomTemplateSave] =
      useState<boolean>(false);

    const hasStickyBottom =
      addNewTemplate ||
      allowCustomTemplateSave ||
      videoCreator.textBrandingService.isTemplateModified;

    const x_alignment = config?.x_alignment || '0%';
    const getDefaultXAlign = () => {
      switch (x_alignment) {
        case '0%':
          return Align.leftAlign;
        case '50%':
          return Align.midAlign;
        case '100%':
          return Align.rightAlign;
        default:
          return Align.leftAlign;
      }
    };
    const getSelectedBold = () => {
      const weight = config?.font_weight;
      if (!weight) return Bold.normal;
      for (const key in BoldValues) {
        if (BoldValues[key as Bold] === Number(weight)) {
          return key as Bold;
        }
      }
      return Bold.normal;
    };

    const getDefaultActions = () => {
      let actions: Action[] = [];
      if (config?.font_style === 'italic') {
        actions.push(Action.italic);
      } else {
        actions = actions.filter((action) => action !== Action.italic);
      }

      if (config?.text_transform === 'uppercase') {
        actions.push(Action.capitalize);
      } else {
        actions = actions.filter((action) => action !== Action.capitalize);
      }

      if (config?.font_weight && config?.font_weight !== '400') {
        actions.push(Action.bold);
      } else {
        actions = actions.filter((action) => action !== Action.bold);
      }
      actions.push(Action.align);

      return actions;
    };

    useEffect(() => {
      setSelectedActions(getDefaultActions());
    }, [config]);

    const [selectedActions, setSelectedActions] =
      useState<Action[]>(getDefaultActions());

    const alignType = getDefaultXAlign();
    const selectedBold = getSelectedBold();
    const selectedFontFamily = config?.font_family;
    const selectFillColor = config?.fill_color;
    const selectedBackgroundColor = config?.background_color;
    const selectedBackgroundOpacity = parseFloat(
      !selectedBackgroundColor || selectedBackgroundColor === 'transparent'
        ? '0'
        : selectedBackgroundColor.split(',')[3]?.split(')')[0]?.trim() ?? '1',
    );
    const selectedShadow = useMemo(
      () =>
        config.shadow_color && config.shadow_color !== 'false'
          ? {
              color: config.shadow_color as string,
              blur: config.shadow_blur as number,
              x: config.shadow_x as number,
              y: config.shadow_y as number,
            }
          : undefined,
      [config],
    );
    const pos_y = config?.y || '50%';

    const font_size = config.font_size;

    const minMaxYPosition = getMinMaxYPosition(
      videoCreator.renderer?.getSource()?.height,
      font_size.toString(),
    );

    const modifyPropertyDebounced = lodash.debounce(
      (property) => videoCreator.textProducer.modifyProperty(property),
      250,
    );
    const isKaraokeText = props.activeElement?.track === KARAOKE_TRACK_NUMBER;

    const handleToggleAction = (
      action: Action,
      propertyName: keyof KaraokeConfig,
      normalState: string,
      requiredState: string,
    ) => {
      const isSelected = selectedActions.includes(action);

      const state = isSelected ? normalState : requiredState;
      videoCreator.textProducer.modifyProperty({ [propertyName]: state });
    };

    const handleToggleBold = (bold: Bold) => {
      const state = bold === selectedBold ? null : bold;

      let value = BoldValues[Bold.normal];
      switch (state) {
        case Bold.normal:
          value = BoldValues[Bold.normal];
          break;
        case Bold.semiBold:
          value = BoldValues[Bold.semiBold];
          break;
        case Bold.bold:
          value = BoldValues[Bold.bold];
          break;
        case Bold.extraBold:
          value = BoldValues[Bold.extraBold];
          break;
        default:
          value = BoldValues[Bold.normal];
      }

      videoCreator.textProducer.modifyProperty({
        font_weight: value.toString(),
      });
    };

    const renderFullTextSettings = () => {
      return (
        <>
          <HorizontalLine />
          {!isKaraokeText && (
            <Item style={{ display: 'flex', flexDirection: 'column' }}>
              <FieldName>Text</FieldName>
              <TextInput
                text={config?.text || ''}
                modifyElement={(text) => {
                  if (text !== config?.text || '') {
                    videoCreator.textProducer.modifyProperty({ text });
                  }
                }}
              />
            </Item>
          )}
          <TextBrandingTemplates
            ref={ref}
            hasStickyBottom={hasStickyBottom}
            addNewTemplate={addNewTemplate}
            toggleAddNewTemplate={toggleAddNewTemplate}
            setTemplateNameError={setTemplateNameError}
            templateNameError={templateNameError}
            setTemplateName={setTemplateName}
            hasActiveElement={
              !!source && !videoCreator.textProducer.creatingNewText
            }
            type={isKaraokeText ? 'karaoke' : 'basic'}
          />
          <FontColorGroup>
            <FontDropdownSelect
              ref={ref}
              hasStickyBottom={hasStickyBottom}
              action={(propertyName, value) =>
                videoCreator.textProducer.modifyProperty({
                  [propertyName]: value,
                })
              }
              propertyName="font_family"
              defaultValue={selectedFontFamily || 'Inter'}
              type="basic"
            />

            <ColorDropdownSelect
              action={(propertyName, value) =>
                videoCreator.textProducer.modifyProperty({
                  [propertyName]: value,
                })
              }
              propertyName="fill_color"
              defaultValue={selectFillColor || Colors.black}
            />
          </FontColorGroup>

          <SliderGroup>
            <Position>
              <SliderSelect
                title="Position"
                Icon={<UpDownIcon />}
                action={modifyPropertyDebounced}
                defaultValue={pos_y}
                unit="%"
                propertyName="y"
                reverse={true}
                min={100 - minMaxYPosition[1]}
                max={100 - minMaxYPosition[0]}
              />
            </Position>

            <FontSize>
              <ItemDropdownSelect
                ref={ref}
                hasStickyBottom={hasStickyBottom}
                action={(propertyName, value) => {
                  videoCreator.textProducer.modifyProperty({
                    [propertyName]: value,
                  });
                }}
                propertyName="font_size"
                defaultValue={Math.round(font_size || 32).toString()}
                values={FONT_SIZE_VALUES.map((value) => ({
                  caption: `${value}`,
                  value: `${value}`,
                }))}
              />
            </FontSize>
          </SliderGroup>
          <ActionGroup>
            <TextBoldAlignFormatting
              ref={ref}
              alignType={alignType}
              handleToggleAction={handleToggleAction}
              handleToggleBold={handleToggleBold}
              hasStickyBottom={hasStickyBottom}
              selectedBold={selectedBold}
              selectedActions={selectedActions}
              toggleAlign={(align) => {
                videoCreator.textProducer.modifyProperty({
                  x_alignment: AlignValues[align],
                });
              }}
            />
          </ActionGroup>
          <BackgroundShadowGroup>
            <KaraokeBackground
              action={(property) => {
                videoCreator.textProducer.modifyProperty({ ...property });
              }}
              defaultValue={selectedBackgroundColor}
              opacity={selectedBackgroundOpacity}
            />
            <KaraokeShadow
              type="basic"
              action={(property) => {
                videoCreator.textProducer.modifyProperty({ ...property });
              }}
              value={selectedShadow || undefined}
            />
          </BackgroundShadowGroup>
          <TextAnimationSettings />

          <PositionContent>
            <Item>
              <PropertyCaption>Start</PropertyCaption>
              <PropertyNumericalInput
                propertyName="time"
                defaultValue={config.time || 0}
                unit="s"
                customStyles={numericalInputStyles}
                getInputValue={async (property: string, value: string) => {
                  await videoCreator.textProducer.modifyProperty({
                    [property]: value,
                  });
                }}
              />
            </Item>
            <Item>
              <PropertyCaption>End</PropertyCaption>
              <PropertyNumericalInput
                propertyName="time"
                defaultValue={formatter.format(
                  (parseFloat(config.time) || 0) +
                    (parseFloat(config.duration) || 4),
                )}
                unit="s"
                customStyles={numericalInputStyles}
                getInputValue={async (property: string, value: string) => {
                  const duration = formatter.format(
                    parseFloat(value) - parseFloat(config.time) || 0,
                  );
                  await videoCreator.textProducer.modifyProperty({ duration });
                }}
              />
            </Item>
            <Item>
              <PropertyCaption>Length</PropertyCaption>
              <PropertyNumericalInput
                propertyName="duration"
                defaultValue={config.duration || 4}
                unit="s"
                customStyles={numericalInputStyles}
                getInputValue={async (property: string, value: string) => {
                  await videoCreator.textProducer.modifyProperty({
                    [property]: value,
                  });
                }}
              />
            </Item>
          </PositionContent>
          <BottomPadding></BottomPadding>
          <SaveOrCancelTemplate
            addNewTemplate={addNewTemplate}
            setTemplateName={setTemplateName}
            setTemplateNameError={setTemplateNameError}
            templateName={templateName}
            templateNameError={templateNameError}
            toggleAddNewTemplate={toggleAddNewTemplate}
            allowCustomTemplateSave={allowCustomTemplateSave}
            setAllowCustomTemplateSave={setAllowCustomTemplateSave}
            type={isKaraokeText ? 'karaoke' : 'basic'}
            setting="text-settings"
          />
        </>
      );
    };

    return (
      <Main>
        {videoCreator.textBrandingService.isLoading && (
          <SpinningLoading
            positionTop="20px"
            text={
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span>Saving template</span>
              </div>
            }
          />
        )}
        {videoCreator.textBrandingService.isPropsChanged && (
          <SpinningLoading
            positionTop="20px"
            text={
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span>Creating Custom Template</span>
              </div>
            }
          />
        )}
        <TextWrapper ref={textWrapperRef}>
          <AddTextButton
            type="button"
            onClick={() => {
              videoCreator.textProducer.produceText();
            }}
          >
            <TextIcon height="20" width="20" />
            Add New Text Element
          </AddTextButton>
        </TextWrapper>

        {props.activeElement && renderFullTextSettings()}
      </Main>
    );
  }),
);

const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;

  position: relative;
`;

const Position = styled.div`
  flex: 1;
`;
const FontSize = styled.div`
  flex: 1;
`;

const ActionGroup = styled.div`
  display: flex;
  gap: 8px;
  justify-content: space-between;
`;

const BackgroundShadowGroup = styled.div`
  display: flex;
  gap: 16px;
`;

const SliderGroup = styled.div`
  display: flex;
  gap: 10px;
`;

const FontColorGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 8px;
`;

const Item = styled.div``;

const PositionContent = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 10px;
`;

const AddTextButton = styled(PrimaryActionButton)`
  height: 40px;
  font-size: 14px;
  display: flex;
  cursor: pointer;
  svg {
    margin-right: 8px;
  }
`;

const TextWrapper = styled.div`
  z-index: 2;
  cursor: pointer;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 12px;
`;

const HorizontalLine = styled.div`
  background-color: #333;
  width: 100%;
  height: 1px;
`;

const FieldName = styled.span`
  color: #848484;
  font-family: Inter, san-serif;
  font-size: 10px;
  font-weight: 500;
`;

const BottomPadding = styled.div`
  width: 100%;
  height: 50px;
`;
