import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import Accordion from './Accordion';
import DeleteIcon from '../../svgs/DeleteIcon';

import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';

const BrandKitExternalLinks = observer(() => {
  const videoCreator = useVideoCreatorStore();
  const branding = videoCreator.organization?.branding;
  const externalLinks = branding?.externalLinks;
  const [value, setValue] = useState('');

  useEffect(() => {
    if (externalLinks) setValue(externalLinks);
  }, [Boolean(externalLinks)]);

  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const handleSave = async () => {
    setIsSaving(true);
    setIsSaved(false);
    const newValue = filterValues(value).trim();
    const organization = videoCreator.organization;
    organization!.branding = {
      ...organization!.branding!,
      externalLinks: newValue,
    };
    await videoCreator.albumRepository?.update(organization!);
    setValue(newValue);
    setIsSaving(false);
    setIsSaved(true);
  };

  const handleAdd = () => {
    setValue((prev) => `${prev},`);
  };

  const externalLinksList = value.split(',');
  const isChanged = filterValues(value) !== filterValues(externalLinks || '');
  const disabled = isSaving || !isChanged;

  return (
    <Accordion id="external-links" title="External Links" isSaved={isSaved}>
      <Container isSaving={isSaving}>
        {externalLinksList?.map((link, i) => (
          <ExternalLink
            key={i}
            value={link}
            onChange={(linkValue) =>
              setValue((curr) => {
                const list = curr.split(',');
                list[i] = linkValue;
                return list.join(',');
              })
            }
            onDelete={() => {
              setValue((curr) => {
                const list = curr.split(',');
                list.splice(i, 1);
                return list.join(',');
              });
            }}
          />
        ))}
        <div className="actions">
          <button className="add-btn" disabled={isSaving} onClick={handleAdd}>
            Add New
          </button>
          <button className="save-btn" disabled={disabled} onClick={handleSave}>
            {isSaving ? 'Saving...' : 'Save'}
          </button>
        </div>
      </Container>
    </Accordion>
  );
});

const ExternalLink = ({
  value,
  onChange,
  onDelete,
}: {
  value: string;
  onChange: (value: string) => void;
  onDelete: () => void;
}) => {
  return (
    <div className="input-wrapper">
      <input
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder="Add external link (e.g. Google Drive)"
      />
      <div role="button" className="delete-icon" onClick={onDelete}>
        <DeleteIcon width="20" height="20" />
      </div>
    </div>
  );
};

const filterValues = (value: string | string[]) => {
  if (Array.isArray(value)) return value.filter(Boolean).join(',');
  return value
    .split(',')
    .map((s) => s.trim())
    .filter(Boolean)
    .join(',');
};

const Container = styled.div<{ isSaving?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 24px;

  .input-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #484848;
    border-radius: 8px;
    padding-right: 24px;

    &:focus-within {
      border-color: #f3e9d7;
    }

    > input {
      flex-grow: 1;
      color: #f3e9d7;
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      background: transparent;
      outline: none;
      border: none;
      resize: none;
      padding: 24px;
      &::placeholder {
        color: #848484;
      }
    }

    > .delete-icon {
      flex-shrink: 0;
      cursor: pointer;
      & svg path {
        transition: 0.2s;
      }
      &:hover svg path {
        stroke: #ef5d6f;
      }
    }
  }

  .actions {
    display: flex;
    align-self: flex-end;
    gap: 24px;

    > button {
      display: block;
      height: 48px;
      padding: 12px 24px;
      border-radius: 8px;
      font-size: 14px;
      font-weight: 700;
      border: none;
      transition: 0.2s;
      opacity: ${({ isSaving }) => (isSaving ? 0.6 : 1)};
      cursor: ${({ isSaving }) => (isSaving ? 'not-allowed' : 'pointer')};
      &:hover:not(:disabled) {
        filter: brightness(1.2) saturate(1.2);
      }
      &.add-btn {
        background: transparent;
        border: 1px solid #17c964;
        color: #17c964;
      }
      &.save-btn {
        background: #17c964;
        color: #03041a;
      }
      &:disabled {
        color: #848484;
        border: 1px solid #848484;
        background: transparent;
        cursor: not-allowed;
      }
    }
  }
`;

export default BrandKitExternalLinks;
