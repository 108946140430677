import React, { useState, useRef, useEffect } from 'react';
import { useOutsideAlerter } from '../transcript/useClickOutside';
import styled, { css } from 'styled-components';
import ColorPicker from './ColorPicker';

import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';

type Props = {
  showBorderColor?: boolean;
  action: (propertyName: string, value: string | null) => void;
  propertyName: string;
  defaultValue: string | undefined | null;
  getValue?: (value: string) => void;
};

const ColorDropdownSelect = (props: Props) => {
  const videoCreator = useVideoCreatorStore();
  const [selectedItem, setSelectedItem] = useState<string | undefined | null>(
    props.defaultValue,
  );
  const [colorPicker, togglePicker] = useState<DOMRect | null>(null);
  const pickerClose = videoCreator.textBrandingService.closeActiveState;
  useEffect(() => {
    if (pickerClose) {
      togglePicker(null);
      videoCreator.textBrandingService.closeActiveState = false;
    }
  }, [pickerClose]);

  useEffect(() => {
    setSelectedItem(props.defaultValue);
  }, [props.defaultValue]);

  const contentRef = useRef<HTMLDivElement>(null);
  useOutsideAlerter(contentRef, () => {
    togglePicker(null);
  });

  return (
    <Main ref={contentRef}>
      <Selected
        onClick={(e) => {
          if (colorPicker) return togglePicker(null);
          return togglePicker(e.currentTarget.getBoundingClientRect());
        }}
      >
        <SelectedContent
          selectedItem={selectedItem}
          showBorderColor={props.showBorderColor}
        ></SelectedContent>{' '}
      </Selected>

      {colorPicker && (
        <ColorPicker
          defaultValue={props.defaultValue}
          getValue={(color) => {
            props.action(props.propertyName, color);
          }}
          solidDefaultAlpha
          pos={colorPicker}
          onClose={() => {
            togglePicker(null);
          }}
        />
      )}
    </Main>
  );
};

export default ColorDropdownSelect;

const Main = styled.div`
  position: relative;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
`;
const Selected = styled.div`
  border: 1px solid #484848;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 24px;
  cursor: pointer;

  padding: 8px 16px;
  border-radius: 8px;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
`;

const SelectedContent = styled.span<{
  selectedItem?: string | null;
  showBorderColor?: boolean;
}>`
  font-size: 14px;

  ${(props) =>
    !props.showBorderColor &&
    props.selectedItem &&
    css`
      width: 15px;
      height: 15px;
      background-color: ${props.selectedItem};
      border: ${(props.selectedItem === 'black' ||
        props.selectedItem === 'rgba(0, 0, 0, 1)') &&
      '1px solid #484848'};
      border-radius: 50%;
    `}

  ${(props) =>
    props.showBorderColor &&
    props.selectedItem &&
    css`
      width: 15px;
      height: 15px;
      border: 1px solid ${props.selectedItem};
      background: ${props.selectedItem === 'black' ||
      props.selectedItem === 'rgba(0, 0, 0, 1)'
        ? '#484848'
        : 'transparent'};
      border-radius: 50%;
    `}

    ${(props) =>
    props.showBorderColor &&
    (props.selectedItem === 'transparent' ||
      props.selectedItem === 'rgba(0, 0, 0, 0)') &&
    css`
      background: linear-gradient(
        to left top,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 1) calc(50% - 0.8px),
        rgb(255, 0, 0) 50%,
        rgba(255, 255, 255, 1) calc(50% + 0.8px),
        rgba(255, 255, 255, 1) 100%
      );
    `}
`;
