import VideoCreatorStore from '@src/stores/VideoCreatorStore';
import { ElementState } from '../renderer/ElementState';

type Overlay = {
  time: number;
  easing: string;
  value: string;
  id?: string;
};

export default class FadeProducer {
  private activeElement: ElementState | null;
  private videoCreator: VideoCreatorStore;

  constructor(
    videoCreator: VideoCreatorStore,
    activeElement: ElementState | null = null,
  ) {
    this.videoCreator = videoCreator;
    this.activeElement = activeElement;
  }

  public getOriginalVideoElements() {
    return this.videoCreator.renderer
      ?.getElements()
      .filter((e) => this.videoCreator.isOriginalVideoElement(e.source))!;
  }

  private getVideoOverlayDict(
    originalVideos = this.getOriginalVideoElements(),
  ) {
    const elementsWithOverlay = originalVideos.filter(
      (o) => o.source.color_overlay,
    );
    const overlayDict = {} as Record<string, Overlay[] | string>;
    for (let e of elementsWithOverlay) {
      overlayDict[`${e.source.id}.color_overlay`] = e.source.color_overlay;
    }
    return overlayDict;
  }

  private initializeVideoOverlayDict(
    originalVideos = this.getOriginalVideoElements(),
  ) {
    const elementsWithOverlay = originalVideos.filter(
      (o) => o.source.color_overlay,
    );
    const overlayDict = {} as Record<string, Overlay[] | string>;
    for (let e of elementsWithOverlay) {
      overlayDict[`${e.source.id}.color_overlay`] = '';
    }
    return overlayDict;
  }

  private filterOverlayDictValuesByEasing(
    easing: string,
    overlayDict = this.getVideoOverlayDict(),
  ) {
    // filter overlay dict by easing
    const filteredDict = {} as Record<string, Overlay[] | string>;
    for (let [k, v] of Object.entries(overlayDict)) {
      if (typeof v === 'string') {
        filteredDict[k] = v;
        continue;
      }
      const newVal = v.filter((o) => !o.easing.includes(easing));
      if (newVal.length) {
        filteredDict[k] = newVal.sort((a, b) => a.time - b.time);
      } else {
        filteredDict[k] = '';
      }
    }

    return filteredDict;
  }

  private getOverlayDurationByEasing(easingString: string) {
    const overlayDict = this.getVideoOverlayDict();
    for (let v of Object.values(overlayDict)) {
      if (typeof v === 'string') continue;
      const val = v.find((o) => o.easing.includes(easingString));
      if (val) {
        const stringValue = val.easing
          .replace(`${easingString}-inc.`, '')
          .replace(`${easingString}.`, '');

        return parseFloat(stringValue!);
      }
    }
  }

  public getFadeVariables = (type: 'enter' | 'exit') => {
    if (!this.activeElement) return;
    const { color_overlay } = this.activeElement.source;
    const overlayData = typeof color_overlay === 'string' ? [] : color_overlay;

    if (!Array.isArray(overlayData) || !overlayData.length) return null;

    const overlays = overlayData?.filter((c: any) => {
      if (type === 'enter')
        return c.easing === 'quadratic-in' || c.easing === 'ease-in';
      return (
        c.easing?.includes('quadratic-out') || c.easing?.includes('ease-out')
      );
    });

    if (!overlays.length) return null;

    const overlay = overlays.find((o: any) => o.time !== 0 && o.time !== 'end');

    if (!overlay) {
      const isDip = overlays[0]?.easing === 'quadratic-in';
      const durr = (overlayData || []).find(
        (o: any) => o.time !== 0 && o.time !== 'end',
      )?.time;

      let duration = 1;
      if (isDip && durr) {
        duration = parseFloat(durr);
      }

      if (!isDip && durr) {
        duration = parseFloat(durr) * 2;
      }

      return {
        exists: true,
        type: isDip ? 'dip' : 'crossfade',
        duration,
      };
    }

    if (overlay.easing === 'quadratic-in' || overlay.easing === 'ease-in') {
      return {
        exists: true,
        type: overlay.easing === 'quadratic-in' ? 'dip' : 'crossfade',
        duration:
          overlay.easing === 'ease-in'
            ? parseFloat(overlay.time) * 2
            : overlay.time,
      };
    } else {
      const duration =
        overlay.easing.replace('quadratic-out', '').replace('ease-out', '') ||
        1;
      return {
        exists: true,
        duration,
        type: overlay.easing.includes('quadratic-out') ? 'dip' : 'crossfade',
      };
    }
  };

  private getMatchingVideoIdAndTime(currTime: number) {
    const originalVideos = this.getOriginalVideoElements();
    for (let i = 0; i < originalVideos.length; i++) {
      const timeSpan = originalVideos[i].localTime + originalVideos[i].duration;
      const time = originalVideos[i].localTime;
      if (time <= currTime && timeSpan >= currTime) {
        return {
          id: originalVideos[i].source.id,
          localTime: Math.max(currTime - time, 0),
        };
      }
    }

    const lastVideo = originalVideos[originalVideos.length - 1];
    return {
      id: lastVideo.source.id,
      localTime: Math.max(currTime - lastVideo.localTime, 0),
    };
  }

  private addElementToOverlayDict(
    overlayDict: Record<string, Overlay[] | string>,
    time: number,
    value: string,
    easing: string,
    duration: number,
  ) {
    const id = this.getMatchingVideoIdAndTime(time).id;

    if (
      !overlayDict[`${id}.color_overlay`] ||
      overlayDict[`${id}.color_overlay`] === ''
    ) {
      overlayDict[`${id}.color_overlay`] = [
        {
          time: this.getMatchingVideoIdAndTime(time).localTime, //elementTime - (duration || 1) / 2,
          easing: `${easing}.${duration}`,
          value,
        },
      ];
    } else {
      (overlayDict[`${id}.color_overlay`] as Overlay[]).push({
        time: this.getMatchingVideoIdAndTime(time).localTime, //elementTime - (duration || 1) / 2,
        easing: `${easing}.${duration}`,
        value,
      });
    }
  }

  private getOverlayForDipStart(overlays: Overlay[], duration: number) {
    const existingOverlay = overlays.filter(
      (c) =>
        c.easing.includes('quadratic-out') || c.easing.includes('ease-out'),
    );

    const newVideoOverlaysDict = this.filterOverlayDictValuesByEasing(
      `${this.activeElement!.source.id}-cross-fade-start`,
    );

    const newOverlays = [
      {
        time: 0,
        easing: 'quadratic-in',
        value: 'rgba(0,0,0,1)',
      },
      {
        time: duration || 1,
        easing: 'quadratic-in',
        value: 'rgba(0,0,0,0)',
      },
      ...existingOverlay,
    ];
    return { newOverlays, newVideoOverlaysDict };
  }

  private getOverlayForDipEnd(overlays: Overlay[], duration: number) {
    const elementDuration = this.activeElement!.duration;
    const existingOverlay = overlays.filter(
      (c) => c.easing === 'quadratic-in' || c.easing === 'ease-in',
    );

    if (existingOverlay.length === 1) {
      const existingEndOverlay = overlays.find(
        (o) =>
          o.easing.includes('quadratic-out') || o.easing.includes('ease-out'),
      );
      if (existingEndOverlay) {
        existingOverlay.push({
          time: existingEndOverlay.time,
          easing: existingOverlay[0]?.easing,
          value: 'rgba(0,0,0,0)',
        });
      }
    }

    const newVideoOverlaysDict = this.filterOverlayDictValuesByEasing(
      `${this.activeElement!.source.id}-cross-fade-end`,
    );

    const newOverlays = [
      ...existingOverlay,
      {
        time: Math.min(
          Math.max(elementDuration - (duration || 1), 1),
          elementDuration,
        ),
        easing: `quadratic-out${duration || 1}`,
        value: 'rgba(0,0,0,0)',
      },
      {
        time: 'end',
        easing: `quadratic-out${duration || 1}`,
        value: 'rgba(0,0,0,1)',
      },
    ];

    return { newOverlays, newVideoOverlaysDict };
  }

  private buildEntryOverlayDict(
    overlayDict: Record<string, string | Overlay[]>,
    id: string,
    time: number,
    durr: number,
  ) {
    this.addElementToOverlayDict(
      overlayDict,
      time - (durr || 0.5),
      'rgba(0,0,0,0)',
      `${id}-cross-fade-start`,
      durr || 0.5,
    );

    this.addElementToOverlayDict(
      overlayDict,
      time,
      'rgba(0,0,0,1)',
      `${id}-cross-fade-start`,
      durr || 0.5,
    );

    this.addElementToOverlayDict(
      overlayDict,
      time + 0.1,
      'rgba(0,0,0,0)',
      `${id}-cross-fade-start-inc`,
      durr || 0.5,
    );
  }

  private buildExitOverlayDict(
    overlayDict: Record<string, string | Overlay[]>,
    id: string,
    time: number,
    element_duration: number,
    durr: number,
  ) {
    this.addElementToOverlayDict(
      overlayDict,
      time + element_duration - 0.1,
      'rgba(0,0,0,0)',
      `${id}-cross-fade-end-inc`,
      durr || 0.5,
    );

    this.addElementToOverlayDict(
      overlayDict,
      time + element_duration,
      'rgba(0,0,0,1)',
      `${id}-cross-fade-end`,
      durr || 0.5,
    );

    this.addElementToOverlayDict(
      overlayDict,
      time + element_duration + durr || 0.5,
      'rgba(0,0,0,0)',
      `${id}-cross-fade-end`,
      durr || 0.5,
    );
  }

  private getStartCrossFadeOverlay(overlays: Overlay[], duration: number) {
    const { time: elementTime, id } = this.activeElement!.source;
    const existingOverlay = overlays.filter(
      (c) =>
        c.easing?.includes('quadratic-out') || c.easing?.includes('ease-out'),
    );

    const videoOverlaysDict = this.filterOverlayDictValuesByEasing(
      `${this.activeElement!.source.id}-cross-fade-start`,
    );

    this.buildEntryOverlayDict(
      videoOverlaysDict,
      id,
      elementTime,
      (duration || 1) / 2,
    );

    const newOverlays = [
      {
        time: 0,
        easing: 'ease-in',
        value: 'rgba(0,0,0,1)',
      },
      {
        time: (duration || 1) / 2,
        easing: 'ease-in',
        value: 'rgba(0,0,0,0)',
      },
      ...existingOverlay,
    ];

    return {
      newOverlays,
      newVideoOverlaysDict: videoOverlaysDict,
    };
  }

  private getEndCrossFadeOverlay(overlays: Overlay[], duration: number) {
    // const videoOverlays = this.getVideoOverlays();
    const { time: elementTime, id } = this.activeElement!.source;
    const elementDuration = this.activeElement!.duration;
    const existingOverlay = overlays.filter(
      (c) => c.easing === 'quadratic-in' || c.easing === 'ease-in',
    );
    if (existingOverlay.length === 1) {
      const existingEndOverlay = overlays.find(
        (o) =>
          o.easing.includes('quadratic-out') || o.easing.includes('ease-out'),
      );
      if (existingEndOverlay) {
        existingOverlay.push({
          time: existingEndOverlay.time,
          easing: existingOverlay[0].easing,
          value: 'rgba(0,0,0,0)',
        });
      }
    }
    const startTime = elementDuration - (duration || 1);

    const newOverlays = [
      ...existingOverlay,
      {
        time: (startTime + elementDuration) / 2,
        easing: `ease-out${duration || 1}`,
        value: 'rgba(0,0,0,0)',
      },
      {
        time: 'end',
        easing: `ease-out${duration || 1}`,
        value: 'rgba(0,0,0,1)',
      },
    ];

    const videoOverlaysDict = this.filterOverlayDictValuesByEasing(
      `${id}-cross-fade-end`,
    );
    this.buildExitOverlayDict(
      videoOverlaysDict,
      id,
      elementTime,
      elementDuration,
      (duration || 1) / 2,
    );

    return {
      newOverlays,
      //   newVideoOverlays,
      newVideoOverlaysDict: videoOverlaysDict,
    };
  }

  private filterElementIfOverlayIsNotSelected(
    overlays: Overlay[],
    duration: number,
    time: string,
  ) {
    const { id } = this.activeElement!.source;
    let newOverlays = [...overlays];
    if (overlays.length === 3) {
      const middleOverlay = newOverlays.find(
        (o: any) => o.time !== 0 && o.time !== 'end',
      );

      if (
        middleOverlay?.easing === 'quadratic-in' ||
        middleOverlay?.easing === 'ease-in'
      ) {
        const endOverlay = newOverlays.find(
          (o) => o.time?.toString() === 'end',
        );
        const easing = endOverlay?.easing.includes('ease-out')
          ? `ease-out${duration || 1}`
          : `quadratic-out${duration || 1}`;

        newOverlays.splice(2, 0, {
          ...middleOverlay,
          easing,
        });
      } else if (
        middleOverlay?.easing?.includes('quadratic-out') ||
        middleOverlay?.easing.includes('ease-out')
      ) {
        const startOverlay = newOverlays.find((o) => o.time === 0);
        newOverlays.splice(1, 0, {
          ...middleOverlay,
          easing: startOverlay?.easing || 'quadratic-in',
        });
      }
    }

    newOverlays = newOverlays.filter((o: any) => {
      if (time === 'start')
        return (
          o.easing?.includes('quadratic-out') || o.easing?.includes('ease-out')
        );
      return o.easing?.includes('quadratic-in') || o.easing.includes('ease-in');
    });

    let filter_value = `${id}-cross-fade-end`;
    if (time === 'start') filter_value = `${id}-cross-fade-start`;

    const newVideoOverlaysDict =
      this.filterOverlayDictValuesByEasing(filter_value);

    return { newOverlays, newVideoOverlaysDict };
  }

  public async resetCrossfadeOnVideo(
    time: number,
    duration: number,
    applyChangesToOriginalTranscription = false,
  ) {
    if (!this.activeElement) return;
    // const originalVideos = this.getOriginalVideoElements();
    const element = this.activeElement;
    const id = element.source.id;
    if (this.videoCreator.isOriginalVideoElement(element.source)) return {};

    const elementOverlays = element?.source?.color_overlay || [];
    const elementExitValue = elementOverlays?.find(
      (o: any) => o.easing?.includes('ease-out'),
    );

    let newElementOverlays = elementOverlays?.filter(
      (o: any) => !o.easing?.includes('ease-out'),
    );

    const entryOverlayDuration = this.getOverlayDurationByEasing(
      `${id}-cross-fade-start`,
    );
    const exitOverlayDuration = this.getOverlayDurationByEasing(
      `${id}-cross-fade-end`,
    );

    const newVideoOverlayDict = this.filterOverlayDictValuesByEasing(
      `${id}-cross-fade`,
    );

    const videoOverlayDict = { ...newVideoOverlayDict };

    if (entryOverlayDuration) {
      //   newOverlays = [...entryOverlayValues, ...newOverlays];
      this.buildEntryOverlayDict(
        videoOverlayDict,
        id,
        time,
        entryOverlayDuration,
      );
    }

    if (exitOverlayDuration) {
      this.buildExitOverlayDict(
        videoOverlayDict,
        id,
        time,
        duration,
        exitOverlayDuration,
      );
    }

    if (elementExitValue?.easing) {
      const animationDuration = elementExitValue.easing.replace('ease-out', '');
      const startTime = duration - (parseFloat(animationDuration) || 1);

      newElementOverlays = [
        ...newElementOverlays,
        {
          time: (startTime + duration) / 2,
          easing: `ease-out${animationDuration || 1}`,
          value: 'rgba(0,0,0,0)',
        },
        {
          time: 'end',
          easing: `ease-out${animationDuration || 1}`,
          value: 'rgba(0,0,0,1)',
        },
      ];
    }
    console.log('resetCrossfade videoOverlayDict', {
      videoOverlayDict,
      newElementOverlays,
    });

    const modification = {
      ...videoOverlayDict,
      ...(newElementOverlays.length && {
        [`${id}.color_overlay`]: newElementOverlays,
      }),
    };

    if (applyChangesToOriginalTranscription) {
      await this.videoCreator.renderer?.applyModifications({ ...modification });
    }
    return modification;
  }

  public setOverlayAnimation(
    type: any,
    time: any,
    duration: number,
    overlays: Overlay[],
  ) {
    const isOriginalVideo = this.videoCreator.isOriginalVideoElement(
      this.activeElement!.source,
    );

    if (type === 'dip' && !isOriginalVideo) {
      if (time === 'start') {
        return this.getOverlayForDipStart(overlays, duration);
      } else if (time === 'end') {
        return this.getOverlayForDipEnd(overlays, duration);
      }
    } else if (type === 'crossfade' && !isOriginalVideo) {
      if (time === 'start') {
        return this.getStartCrossFadeOverlay(overlays, duration);
      } else if (time === 'end') {
        return this.getEndCrossFadeOverlay(overlays, duration);
      }
    }
    return this.filterElementIfOverlayIsNotSelected(overlays, duration, time);
  }

  public async removeElementOverlayOnVideo(id = this.activeElement!.source.id) {
    const overlayDict = this.filterOverlayDictValuesByEasing(
      `${id}-cross-fade`,
    );

    await this.videoCreator.renderer?.applyModifications({
      ...overlayDict,
    });
  }

  private handleElementFadeEffectOnCut(
    source: ElementState['source'],
    easing: string,
  ) {
    if (source.color_overlay && Array.isArray(source.color_overlay)) {
      source.color_overlay = source.color_overlay.map((c) => {
        if (c.easing.includes(easing) && c.time !== 'end') {
          const duration = parseFloat(c.easing.replace(easing, ''));
          return {
            ...c,
            time: source.duration - duration / 2,
          };
        }
        return c;
      });
    }
  }

  public processCrossfadeOnElementCut(
    head: ElementState['source'],
    tail: ElementState['source'],
    elements: ElementState['source'],
  ) {
    if (!this.activeElement) return;
    if (this.videoCreator.isOriginalVideoElement(this.activeElement.source))
      return;
    // Dip to black
    this.handleElementFadeEffectOnCut(head, 'quadratic-out');
    this.handleElementFadeEffectOnCut(tail, 'quadratic-out');

    // Crossfade end
    this.handleElementFadeEffectOnCut(head, 'ease-out');
    this.handleElementFadeEffectOnCut(tail, 'ease-out');

    const element_source = this.activeElement.source;
    const overlayDict = this.filterOverlayDictValuesByEasing(element_source.id);

    for (let el of [head, tail]) {
      const entry_overlays = (element_source.color_overlay || []).filter(
        (c: any) => c.easing.includes('ease-in'),
      );

      const exit_overlays = (element_source.color_overlay || []).filter(
        (c: any) => c.easing.includes('ease-out'),
      );

      if (entry_overlays.length) {
        const durr = entry_overlays.reduce(
          (acc: number, curr: Overlay) => Math.abs(curr.time - acc),
          0,
        );

        this.buildEntryOverlayDict(overlayDict, el.id, el.time, durr);
      }

      if (exit_overlays.length) {
        const durr =
          parseFloat(exit_overlays[0].easing.replace('ease-out', '')) / 2;

        this.buildExitOverlayDict(
          overlayDict,
          el.id,
          el.time,
          el.duration,
          durr,
        );
      }
    }

    elements = elements.map((el: any) => {
      const matching_overlay = overlayDict[`${el.id}.color_overlay`] || '';
      if (matching_overlay || matching_overlay === '') {
        return {
          ...el,
          color_overlay: matching_overlay,
        };
      }
      return el;
    });
  }

  private isCompositionElement(
    compositions: ElementState[],
    element: ElementState,
  ) {
    return compositions.some(
      (c) => c.elements?.some((e) => e.source.id === element.source.id),
    );
  }

  public async tidyOriginalVideoOverlay() {
    const overlayDict = this.initializeVideoOverlayDict();
    const rendererElements = this.videoCreator.renderer?.getElements();
    const elements =
      rendererElements?.filter(
        (e) =>
          !this.videoCreator.isOriginalVideoElement(e.source) &&
          e.source.color_overlay,
      ) || [];

    const imageCompositions =
      rendererElements?.filter((e) =>
        this.videoCreator.isImageElementComposition(e),
      ) || [];
    const modification_filter: Record<string, string> = {};

    for (let el of elements || []) {
      if (this.isCompositionElement(imageCompositions, el)) {
        modification_filter[`${el.source.id}.color_overlay`] = '';
        continue;
      }
      const entry_overlays = (el.source.color_overlay || []).filter((c: any) =>
        c.easing.includes('ease-in'),
      );
      const exit_overlays = (el.source.color_overlay || []).filter((c: any) =>
        c.easing.includes('ease-out'),
      );
      if (entry_overlays?.length) {
        const durr = entry_overlays.reduce(
          (acc: number, curr: Overlay) => Math.abs(curr.time - acc),
          0,
        );

        this.buildEntryOverlayDict(
          overlayDict,
          el.source.id,
          el.localTime,
          durr,
        );
      }

      if (exit_overlays?.length) {
        const durr =
          parseFloat(exit_overlays[0].easing.replace('ease-out', '')) / 2;

        this.buildExitOverlayDict(
          overlayDict,
          el.source.id,
          el.localTime,
          el.duration,
          durr,
        );
      }

      if (
        Object.keys(overlayDict).length ||
        Object.keys(modification_filter).length
      ) {
        await this.videoCreator.renderer?.applyModifications({
          ...overlayDict,
          ...modification_filter,
        });
      }
    }
  }

  public async processCrossfadeOnOriginalVideoCut() {
    if (!this.activeElement) return;
    if (!this.videoCreator.isOriginalVideoElement(this.activeElement.source))
      return;

    await this.tidyOriginalVideoOverlay();
  }
}
