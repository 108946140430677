import React, { useCallback } from 'react';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import throttle from 'lodash/throttle';
import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';

import { Draggable } from '../Draggable';

export const EndMarker: React.FC = observer(() => {
  const videoCreator = useVideoCreatorStore();
  const timelineScale = videoCreator.timelineScale;
  const duration = videoCreator.duration;

  const changeDuration = (time: number) => {
    runInAction(() => (videoCreator.isScrubbing = true));
    videoCreator
      .setDuration(time)
      .then(() => runInAction(() => (videoCreator.isScrubbing = false)));
  };

  // eslint-disable-next-line
  const throttledChangeDuration = useCallback(throttle(changeDuration, 15), []);

  return (
    <Draggable
      onStart={(e, data) => {
        return { startX: data.x, startTime: duration };
      }}
      onDrag={(e, data, context) => {
        const time = Math.max(
          context.startTime + (data.x - context.startX) / timelineScale,
          0,
        );
        throttledChangeDuration(time);
      }}
      onStop={() => {}}
    >
      {(ref, context) => (
        <Main ref={ref} style={{ left: duration * timelineScale + 8.5 }}>
          <Head>
            <Line />
          </Head>
        </Main>
      )}
    </Draggable>
  );
});

const Main = styled.div`
  position: absolute;
  z-index: 1;
  top: 20px;
`;

const Head = styled.div`
  box-sizing: content-box;
  padding: 4px;
  height: 8px;
  background: rgb(73, 128, 241);
  border-radius: 2px;
  cursor: ew-resize;
`;

const Line = styled.div`
  width: 1px;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
`;
