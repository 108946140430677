import React from 'react';
import { observer } from 'mobx-react-lite';
import { ElementState } from '../../renderer/ElementState';

import styled, { css } from 'styled-components';

import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';

interface PropertyNumericalInputProps {
  activeElement?: ElementState;
  propertyName: string;
  defaultValue: any;
  unit: string;
  getInputValue?: any;
  manualKaraoke?: boolean;
  padding?: string;
  customStyles?: string;
}

function byString(o: any, s: string) {
  s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  s = s.replace(/^\./, ''); // strip a leading dot
  var a = s.split('.');
  for (var i = 0, n = a.length; i < n; ++i) {
    var k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
}

export const PropertyNumericalInput: React.FC<PropertyNumericalInputProps> =
  observer((props) => {
    const videoCreator = useVideoCreatorStore();
    const elementByString = () => {
      if (!props.activeElement) return null;
      return byString(props.activeElement?.source, props.propertyName);
    };
    const [value, setValue] = React.useState(
      () => elementByString() ?? props.defaultValue,
    );
    const [changed, setChanged] = React.useState(false);
    const [editing, setEditing] = React.useState(false);
    const [badInput, setBadInput] = React.useState<string>('');
    const { unit } = props;

    React.useEffect(() => {
      let value = elementByString() ?? props.defaultValue;
      setValue(value);
      if (badInput) {
        doHandleSetBadInput(String(value));
      }
    }, [
      props.activeElement?.source,
      elementByString(),
      props.propertyName,
      props.defaultValue,
    ]);

    let displayValue = value;
    if (!isNaN(value) && !editing) {
      displayValue += ` ${unit}`;
    }

    const badInputStyle = `
      border: 1px solid #BF1818;
    `;
    const CUSTOM_STYLE = `${props.customStyles || ''} ${
      badInput ? badInputStyle : ''
    }`;

    const excludeUnit = (value: string): string => {
      if (value.endsWith(unit) && value.trim() !== unit) {
        value = value.slice(0, value.length - unit.length);
      }
      return value;
    };

    const handleSetBadInput = (e: React.ChangeEvent<HTMLInputElement>) => {
      return doHandleSetBadInput(e.target.value);
    };

    const doHandleSetBadInput = (value: string): boolean => {
      value = excludeUnit(value);
      if (isNaN(Number(value))) {
        setBadInput('Please enter a number');
        return true;
      } else if (Number(value) < 0) {
        setBadInput('Please enter a positive number');
        return true;
      }
      setBadInput('');
      return false;
    };

    return (
      <Container>
        <TextInput
          value={displayValue}
          padding={props.padding}
          manualKaraoke={props.manualKaraoke}
          customStyles={CUSTOM_STYLE}
          onFocus={(e) => {
            setEditing(true);
            e.target.select();
            setChanged(false);
          }}
          onChange={async (e) => {
            handleSetBadInput(e);
            setValue(e.target.value);
            setChanged(true);
          }}
          onBlur={async (e) => {
            const hasBadInput = handleSetBadInput(e);
            if (hasBadInput) return;
            if (changed && props.getInputValue) {
              setEditing(false);
              return await props.getInputValue(
                props.propertyName,
                e.target.value,
              );
            }
            if (
              changed &&
              props.activeElement &&
              e.target.value !== elementByString()
            ) {
              await videoCreator.applyVideoStateModifications(
                {
                  [`${props.activeElement?.source.id}.${props.propertyName}`]:
                    e.target.value,
                },
                true,
                `modifying ${props.activeElement.source.type} element`,
              );
            }
            setEditing(false);
          }}
        />
        {badInput && <ErrorText>{badInput}</ErrorText>}
      </Container>
    );
  });

const Container = styled.div`
  width: 100%;
`;

const TextInput = styled.input<{
  manualKaraoke?: boolean;
  padding?: string;
  customStyles?: string;
}>`
  display: flex;
  resize: none;
  padding: 10px 15px;
  margin: 10px 0;
  color: #fff;
  background: #2b3035;
  border: none;
  border-radius: 5px;
  font-family: Inter, sans-serif;
  font-size: 14px;

  &:focus {
    outline: 1px solid #2a85ff;
  }
  border-color: red;

  ${(props) =>
    props.manualKaraoke &&
    css`
      outline: none;
      border: 1px solid #484848;
      background: transparent;
      color: #f3e9d7;
      margin: 0;
      padding: ${props.padding ?? '14px 15px'};
      border-radius: 8px;
      width: inherit;

      &:focus {
        outline: 0;
      }
    `}

  ${(props) =>
    props.customStyles &&
    css`
      ${props.customStyles}
    `}
`;

const ErrorText = styled.div`
  display: flex;
  min-width: 80px;
  color: #bf1818;
  font-family: Inter;
  font-size: 10px;

  font-weight: 400;
`;
