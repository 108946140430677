import styled from 'styled-components';

export const OptionList = styled.div`
  display: flex;
  gap: 8px;
`;

export const OptionItem = styled.div.attrs(
  (props: { isCurrent: boolean }) => props,
)`
  padding: 16px;
  position: relative;
  border-radius: 8px;
  box-sizing: border-box;
  border: 1px solid transparent;
  cursor: pointer;
  color: #f3e9d7;
  flex: 1;
  border: 1px solid #848484;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  font-family: Inter;
  line-height: 18px;
  user-select: none;
  ${(props) =>
    props.isCurrent &&
    `
    color: #F2D093;
    border-color: #F2D093;
  `}

  &::after {
    content: '';
    position: absolute;
    width: 14px;
    height: 14px;
    border-radius: 14px;
    border: 1px solid #d7d7e1;
    right: 8px;
    top: 0;
    bottom: 0;
    margin: auto;
    ${(props) =>
      props.isCurrent &&
      `
      font-size: 9px;
      font-weight: 600;
      text-align: center;
      content: '✓';
      background: #4ad067;
      border-color: #F2D093;
    `}
  }
`;
