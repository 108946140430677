import { isEqual } from 'lodash';
import { Renderer } from '../renderer/Renderer';

import {
  getParts,
  removeFillerWords,
  capitalizeAfterPeriods,
} from './utils/textUtils';

import { KaraokeConfig } from './types/karaokeTypes';

import {
  MAX_GAP_DURATION,
  MAX_CHARS_INSTAGRAM,
  MAX_CHARS_DEFAULT,
  DEFAULT_KARAOKE_CONFIG,
} from './constants/karaokeConstants';

import KaraokeClip from './lib/KaraokeClip';
import KaraokeSegmenter from './lib/KaraokeSegmenter';
import KaraokeTranscriptMuter from './lib/KaraokeTranscriptMuter';
import TimelineClip from './lib/TimelineClip';

import VideoCreatorStore, {
  KARAOKE_TRACK_NUMBER,
} from '../stores/VideoCreatorStore';
import {
  convertFromPixels,
  getClosestElementIndexToLeftByFilter,
  getClosestElementIndexToRightByFilter,
  getClosestNotRemovedElementIndexToLeft,
  getClosestNotRemovedElementIndexToRight,
  getClosestNotRemovedTextIndexToLeft,
  getClosestNotRemovedTextIndexToRight,
} from './utils';

import { TranscriptElement } from '../types.ts/transcript';
import { v4 as uuid } from 'uuid';
import { AspectRatio } from '../types.ts/video';
import { makeAutoObservable } from 'mobx';

export default class KaraokeProducer {
  private videoCreator: VideoCreatorStore;
  private transcriptionElements?: TranscriptElement[];
  private subtitleElements?: TranscriptElement[];
  private renderer?: Renderer;
  private karaokeClips?: KaraokeClip[];
  private karaokeConfig: KaraokeConfig = DEFAULT_KARAOKE_CONFIG;

  constructor(videoCreator: VideoCreatorStore) {
    makeAutoObservable<
      KaraokeProducer,
      | 'videoCreator'
      | 'transcriptionElements'
      | 'subtitleElements'
      | 'subtitleElements'
      | 'renderer'
      | 'karaokeClips'
      | 'karaokeConfig'
    >(this, {
      videoCreator: false,
      transcriptionElements: false,
      subtitleElements: false,
      renderer: false,
      karaokeClips: false,
      karaokeConfig: true,
    });
    this.videoCreator = videoCreator;
  }

  hasElements() {
    return Boolean(this.karaokeClips && this.karaokeClips.length > 0);
  }

  setRenderer(renderer: Renderer) {
    this.renderer = renderer;
  }

  getKaraokeConfig() {
    return this.karaokeConfig;
  }

  getKaraokeClips(): KaraokeClip[] {
    return this.karaokeClips ?? [];
  }

  setTranscriptionElements(transcriptionElements: TranscriptElement[]) {
    // Ensure elements have current_index refreshed for Karaoke Production
    this.transcriptionElements = transcriptionElements.map((element, i) => {
      element.current_index = i;
      return element;
    });
  }

  setSubtitleElements(subtitleElements?: TranscriptElement[]) {
    // Ensure elements have current_index refreshed for Karaoke Production
    this.subtitleElements = subtitleElements?.map((element, i) => {
      element.current_index = i;
      return element;
    });
  }

  getKaraokeTextSettingByAspectRatio(
    aspectRatio: AspectRatio = this.videoCreator.currentVideo?.aspectRatio ||
      AspectRatio.AR_16_9,
  ) {
    switch (aspectRatio) {
      case AspectRatio.AR_16_9:
        return {
          font_size: '36',
          y: '86%',
          width: '76%',
        };
      case AspectRatio.AR_1_1:
        return {
          font_size: '30',
          y: '84%',
          width: '76%',
        };
      case AspectRatio.AR_9_16:
        return {
          font_size: '24',
          y: '75%',
          width: '82%',
        };
      default:
        return {
          font_size: '36',
          y: '86%',
          width: '76%',
        };
    }
  }

  async deleteKaraokeClips() {
    const source = this.renderer!.getSource();
    source.elements = source.elements.filter(
      (el: any) => el.track < KARAOKE_TRACK_NUMBER,
    );
    await this.renderer!.setSource(source);
    this.karaokeClips = [];
  }

  public karaokeElement() {
    const source = this.renderer!.getSource();
    const element = source?.elements?.find(
      (el: any) => el.track === KARAOKE_TRACK_NUMBER,
    );
    return element;
  }

  createKaraokeBreaksFromExistingElements() {
    const karaokeClips = this.karaokeClips;
    const transcriptElements = this.transcriptionElements!;
    if (!karaokeClips) return;
    const transcriptPositionsToBreak: number[] = [];
    let currentTranscriptElement = getClosestNotRemovedTextIndexToRight(
      0,
      transcriptElements,
    );
    for (let i = 0; i < karaokeClips.length; i++) {
      const karaokeClip = karaokeClips[i];
      const firstElementAfterStartTs = getClosestElementIndexToRightByFilter(
        currentTranscriptElement,
        transcriptElements,
        (transcriptEl) =>
          Boolean(
            transcriptEl.state !== 'removed' &&
              transcriptEl.state !== 'cut' &&
              transcriptEl.state !== 'muted' &&
              transcriptEl.value &&
              transcriptEl.ts &&
              transcriptEl.ts > karaokeClip.startTs - 1,
          ),
      );
      const lastElementAfterEndTs = getClosestElementIndexToRightByFilter(
        currentTranscriptElement,
        transcriptElements,
        (transcriptEl) =>
          Boolean(
            transcriptEl.state !== 'removed' &&
              transcriptEl.state !== 'cut' &&
              transcriptEl.state !== 'muted' &&
              transcriptEl.value &&
              transcriptEl.end_ts &&
              transcriptEl.end_ts > karaokeClip.endTs + 1,
          ),
      );
      const slice = transcriptElements.slice(
        firstElementAfterStartTs,
        lastElementAfterEndTs + 1,
      );
      const karaokeLineParts = karaokeClip.text.match(/(\w+(?:('|-)\w+)*)/g);
      if (!karaokeLineParts || karaokeLineParts.length < 2) continue;

      const start = slice.findIndex(
        (transcriptEl, index) =>
          karaokeLineParts[0].toLowerCase() ===
            transcriptEl.value?.toLowerCase() &&
          slice[
            getClosestNotRemovedTextIndexToRight(index + 1, slice)
          ].value?.toLowerCase() === karaokeLineParts[1].toLowerCase(),
      );

      //@ts-ignore
      let end = slice.findLastIndex(
        (transcriptEl: TranscriptElement, index: number) =>
          karaokeLineParts.at(-1)!.toLowerCase() ===
            transcriptEl.value?.toLowerCase() &&
          slice[
            getClosestNotRemovedTextIndexToLeft(index - 1, slice)
          ].value?.toLowerCase() === karaokeLineParts.at(-2)!.toLowerCase(),
      );

      if (start !== -1 && transcriptPositionsToBreak.length > 0) {
        const newBreak = getClosestNotRemovedElementIndexToLeft(
          firstElementAfterStartTs + start - 1,
          transcriptElements,
        );
        if (newBreak > 0 && transcriptPositionsToBreak.at(-1) !== newBreak) {
          transcriptPositionsToBreak.push(newBreak);
        }
      }

      if (end !== -1) {
        const nextElem = getClosestNotRemovedTextIndexToRight(
          firstElementAfterStartTs + end + 1,
          transcriptElements,
        );
        end =
          nextElem > 0
            ? getClosestNotRemovedElementIndexToLeft(
                nextElem - 1,
                transcriptElements,
              )
            : firstElementAfterStartTs + end;
        transcriptPositionsToBreak.push(end);
      }

      currentTranscriptElement = firstElementAfterStartTs;
    }
    this.videoCreator.removeAllKaraokeBreaks();
    this.videoCreator.addKaraokeBreaks(transcriptPositionsToBreak);
  }

  private getCompositionElementDuration(creatomateEl: any): number {
    return creatomateEl.elements.reduce(
      (acc: number, el: any) =>
        Math.max(acc, parseFloat(el.time) + parseFloat(el.duration)),
      0,
    );
  }

  private createTimelineClip(creatomateEl: any): TimelineClip {
    const duration =
      creatomateEl.type !== 'composition'
        ? creatomateEl.duration
        : this.getCompositionElementDuration(creatomateEl);

    return new TimelineClip({
      track: creatomateEl.track,
      localTime: creatomateEl.time,
      globalTime: creatomateEl.time,
      duration,
    });
  }

  private assembleKaraokeClips(
    timelineClips: TimelineClip[],
    onlyOnTranscriptBreaks: boolean,
    cleanOldKaraoke = false,
  ): KaraokeClip[] {
    const allTranscriptElements = timelineClips.flatMap(
      (timelineClip: TimelineClip) =>
        timelineClip.getTranscriptElements(
          this.getTextElementsToUse(this.isSubtitles()),
        ),
    );

    const muter = new KaraokeTranscriptMuter(
      allTranscriptElements,
      this.karaokeConfig,
    );
    const processedElements = muter.getProcessedElements();

    if (timelineClips.length === 0) {
      return [];
    }

    const startTime = timelineClips[0].startTime();
    const endTime = timelineClips[timelineClips.length - 1].endTime();

    const segmenter = new KaraokeSegmenter(
      processedElements,
      startTime,
      endTime,
      onlyOnTranscriptBreaks,
    );
    const karaokeSegments = segmenter.splitTextIntoSegments();

    // Remove legacy Karaoke Breaks from spaces.
    // When running through editor (not onload), we need to clean up old karaoke breaks.
    if (cleanOldKaraoke) {
      allTranscriptElements.forEach((el) => {
        if (el.karaoke_break) {
          delete el.karaoke_break;
        }
      });
    }

    return karaokeSegments.map(
      (segment) =>
        new KaraokeClip(uuid(), segment.startTs, segment.endTs, segment.words),
    );
  }

  setKaraokeClipsFromSource(source: any) {
    // Early return if no source elements for karaoke track are found
    const sourceElements = source?.elements?.filter(
      (creatomateEl: any) =>
        parseInt(creatomateEl.track) === KARAOKE_TRACK_NUMBER,
    );

    if (!sourceElements?.length) {
      this.karaokeClips = [];
      return;
    }

    if (process.env.REACT_APP_NEW_KARAOKE == 'true') {
      const track = source?.elements?.find((creatomateEl: any) =>
        this.videoCreator.isOriginalVideoElement(creatomateEl),
      )?.track;
      const sourceElements = source?.elements?.filter(
        (creatomateEl: any) => parseInt(creatomateEl.track) === parseInt(track),
      );
      if (!sourceElements?.length) {
        this.karaokeClips = [];
        return;
      }
      const timelineClips = sourceElements.map((el: any) =>
        this.createTimelineClip(el),
      );
      this.karaokeClips = this.assembleKaraokeClips(timelineClips, true);
    } else {
      this.karaokeClips = sourceElements.flatMap((creatomateEl: any) => {
        switch (creatomateEl.type) {
          case 'composition':
            return this.parseCompositionElement(creatomateEl);
          case 'text':
            return this.parseTextElement(creatomateEl);
        }
      });
    }
  }

  // Helper method to parse composition elements
  parseCompositionElement(creatomateEl: any): KaraokeClip[] {
    const compositionTs = parseFloat(creatomateEl.time);
    return creatomateEl.elements.map(
      (subCreatomateEl: any) =>
        new KaraokeClip(
          subCreatomateEl.id,
          compositionTs + parseFloat(subCreatomateEl.time),
          compositionTs +
            parseFloat(subCreatomateEl.time) +
            parseFloat(subCreatomateEl.duration),
          subCreatomateEl.text,
        ),
    );
  }

  // Helper method to parse text elements
  parseTextElement(creatomateEl: any): KaraokeClip {
    return new KaraokeClip(
      creatomateEl.id,
      parseFloat(creatomateEl.time),
      parseFloat(creatomateEl.time) + parseFloat(creatomateEl.duration),
      creatomateEl.text,
    );
  }

  setConfig(config: Partial<KaraokeConfig>) {
    this.karaokeConfig = {
      ...DEFAULT_KARAOKE_CONFIG,
      ...this.getKaraokeTextSettingByAspectRatio(),
      ...config,
    };
  }

  async rerenderWithNewConfig(newConfig: Partial<KaraokeConfig>) {
    // console.trace('rerenderWithNewConfig', newConfig, this.karaokeConfig);
    if (isEqual(newConfig, this.karaokeConfig)) return;

    const oldConfig = this.karaokeConfig;
    const isInstagram = !!newConfig.instagramEffect;
    const wasInstagram = !!oldConfig.instagramEffect;

    this.videoCreator.saveVideoStateSnapshot(undefined, 'modifying karaoke');
    this.videoCreator.karaokeLoading = true;

    this.setConfig(newConfig);

    if (oldConfig.language !== newConfig.language) {
      await this.produceKaraoke();
    } else if (isInstagram !== wasInstagram) {
      this.karaokeClips = [];
      this.videoCreator.removeAllKaraokeBreaks();
      await this.produceKaraoke();
    } else if (!isInstagram) {
      await this.renderKaraokeClips();
    } else {
      await this.renderInstagramElements();
    }

    this.videoCreator.karaokeLoading = false;
  }

  async produceKaraoke(
    config?: KaraokeConfig,
    addBreaksForRange?: { fromIndex: number; toIndex: number },
  ) {
    if (config) {
      this.videoCreator.saveVideoStateSnapshot(undefined, 'producing karaoke');
    }

    this.videoCreator.karaokeLoading = true;

    const originalLanguage = this.videoCreator.originalTranscription?.language;
    this.karaokeConfig = config || this.karaokeConfig;

    if (!this.karaokeConfig) {
      throw new Error('No config provided');
    }

    if (
      !originalLanguage?.includes('en') &&
      this.karaokeConfig.language.includes('en') &&
      !this.subtitleElements
    ) {
      await this.videoCreator.requestSubtitlesForCurrentVideo();
    }

    const onlyOnTranscriptBreaks = this.hasElements();

    if (process.env.REACT_APP_NEW_KARAOKE === 'true') {
      // Remove all karaoke markup from transcript before producing new karaoke if
      // we're starting fresh.
      if (!onlyOnTranscriptBreaks) {
        this.videoCreator.videoTranscriptionProcessor.removeAllKaraokeBreaks();

        this.setTranscriptionElements(
          this.videoCreator.finalTranscriptionElements || [],
        );
      }

      this.newProduceKaraokeClips(
        this.karaokeConfig.instagramEffect
          ? MAX_CHARS_INSTAGRAM
          : MAX_CHARS_DEFAULT,
        onlyOnTranscriptBreaks,
        addBreaksForRange,
      );
    } else {
      this.produceKaraokeClips(
        this.karaokeConfig.instagramEffect
          ? MAX_CHARS_INSTAGRAM
          : MAX_CHARS_DEFAULT,
        onlyOnTranscriptBreaks,
        addBreaksForRange,
      );
    }

    if (this.karaokeConfig.instagramEffect) {
      await this.renderInstagramElements();
    } else {
      await this.renderKaraokeClips();
    }

    this.videoCreator.karaokeLoading = false;
  }

  private shouldBreakOnElement(
    transcriptEl: TranscriptElement,
    part: Pick<TranscriptElement, 'type' | 'value'>,
    currentKaraokeClip: KaraokeClip,
    maxCharsInLine: number,
    isStartOfSentence: boolean,
    isAfterComma: boolean,
    onlyOnTranscriptBreaks: boolean,
  ) {
    return onlyOnTranscriptBreaks
      ? Boolean(isStartOfSentence)
      : part.type === 'text' &&
          part.value &&
          currentKaraokeClip.text.trim().length > 0 &&
          (currentKaraokeClip.text.length + part.value!.length >
            maxCharsInLine ||
            transcriptEl.ts! - currentKaraokeClip.endTs >
              2 * MAX_GAP_DURATION ||
            isStartOfSentence ||
            (isAfterComma &&
              currentKaraokeClip.text.length >
                Math.round(0.7 * maxCharsInLine)));
  }

  // Helper functions
  private initializeKaraokeClip(): KaraokeClip {
    return new KaraokeClip(uuid(), -1, -1, []);
  }

  private updateBreakIndexes(
    isSubtitles: boolean,
    addBreaksForRange: { fromIndex: number; toIndex: number },
    elementsToUse: any[],
  ) {
    let previousBreak = getClosestElementIndexToLeftByFilter(
      addBreaksForRange.fromIndex,
      elementsToUse,
      (el) => !!el.karaoke_break,
    );
    if (previousBreak > 0) {
      this.removeKaraokeBreak(isSubtitles, previousBreak);
      previousBreak = getClosestElementIndexToLeftByFilter(
        previousBreak - 1,
        elementsToUse,
        (el) => !!el.karaoke_break,
      );
    }
    addBreaksForRange.fromIndex = previousBreak > 0 ? previousBreak : 0;

    let nextBreak = getClosestElementIndexToRightByFilter(
      addBreaksForRange.toIndex,
      elementsToUse,
      (el) => !!el.karaoke_break,
    );
    if (nextBreak > 0) {
      this.removeKaraokeBreak(isSubtitles, nextBreak);
      nextBreak = getClosestElementIndexToRightByFilter(
        nextBreak + 1,
        elementsToUse,
        (el) => !!el.karaoke_break,
      );
    }
    addBreaksForRange.toIndex =
      nextBreak > 0 ? nextBreak : elementsToUse.length - 1;
  }

  private removeKaraokeBreak(isSubtitles: boolean, index: number) {
    if (isSubtitles) {
      this.videoCreator.subtitlesProcessor.removeKaraokeBreak(index);
    } else {
      this.videoCreator.videoTranscriptionProcessor.removeKaraokeBreak(index);
    }
  }

  private finalizeKaraokeClip(
    currentKaraokeClip: KaraokeClip,
    resultedKaraokeClips: KaraokeClip[],
    lastAddedElementIndex: number,
    allowBreaks: boolean,
    transcriptPositionsToBreak: number[],
  ) {
    currentKaraokeClip.text = currentKaraokeClip.text.trim();
    if (currentKaraokeClip.startTs > currentKaraokeClip.endTs) {
      // temp hotfix
      const endTs = currentKaraokeClip.startTs;
      currentKaraokeClip.endTs = currentKaraokeClip.startTs;
      currentKaraokeClip.startTs = endTs;
    }
    resultedKaraokeClips.push(currentKaraokeClip);
    if (allowBreaks) {
      transcriptPositionsToBreak.push(lastAddedElementIndex);
    }
  }

  private shouldUpdateStartOfSentence(
    part: any,
    allowBreaks: boolean,
    element: any,
    isStartOfSentence: boolean,
  ): boolean {
    return !allowBreaks
      ? Boolean(part.karaoke_break)
      : part.karaoke_break ||
          element.value === '.' ||
          element.value === '?' ||
          element.value === '!' ||
          (isStartOfSentence && element.type !== 'text');
  }

  private newProduceKaraokeClips(
    maxCharsInLine: number = 18,
    onlyOnTranscriptBreaks: boolean,
    addBreaksForRange?: { fromIndex: number; toIndex: number },
  ) {
    const rendererElements = this.renderer!.state?.elements;

    const track = this.renderer
      ?.getSource()
      .elements.find((el: any) => this.videoCreator.isOriginalVideoElement(el))
      .track;

    const timelineClips = (rendererElements || [])
      .filter((el: any) => el.track == track)
      .map((e: any) => new TimelineClip(e));

    // Reuse the utility function to assemble karaoke clips
    this.karaokeClips = this.assembleKaraokeClips(
      timelineClips,
      onlyOnTranscriptBreaks,
      true,
    );

    // Filter empty clips.
    this.karaokeClips = this.karaokeClips.filter(
      (clip) => clip.text.trim().length > 0,
    );

    if (this.karaokeClips.length > 0) {
      const breakpoints = this.karaokeClips.map((clip: KaraokeClip) => {
        const lastEl = clip.getElements().at(-1);

        return getClosestNotRemovedElementIndexToRight(
          lastEl?.current_index ?? 0,
          this.getTextElementsToUse(this.isSubtitles()),
        );
      });

      if (!this.isSubtitles()) {
        this.videoCreator.videoTranscriptionProcessor.addKaraokeBreaks(
          breakpoints,
        );
      } else {
        this.videoCreator.subtitlesProcessor.addKaraokeBreaks(breakpoints);
      }
    }
  }

  private isSubtitles(): boolean {
    const originalLanguage = this.videoCreator.originalTranscription?.language;
    return (
      !originalLanguage?.includes('en') &&
      this.karaokeConfig.language.includes('en')
    );
  }

  // Refactored produceKaraokeClips
  private produceKaraokeClips(
    maxCharsInLine: number = 18,
    onlyOnTranscriptBreaks: boolean,
    addBreaksForRange?: { fromIndex: number; toIndex: number },
  ) {
    const resultedKaraokeClips: KaraokeClip[] = [];
    let currentKaraokeClip: KaraokeClip = this.initializeKaraokeClip();
    let isStartOfSentence = true;
    let isAfterComma = false;
    let lastAddedElementIndex = -1;
    let karaokeBreakStartTsDiff = 0;
    let allowBreaks = !onlyOnTranscriptBreaks;

    let elementsToUse = this.getTextElementsToUse(this.isSubtitles());

    if (addBreaksForRange) {
      this.updateBreakIndexes(
        this.isSubtitles(),
        addBreaksForRange,
        elementsToUse,
      );
    }

    this.muteFillersInTranscript(this.isSubtitles());
    elementsToUse = this.getTextElementsToUse(this.isSubtitles());

    const transcriptPositionsToBreak: number[] = [];

    for (let i = 0; i < elementsToUse.length; i++) {
      const element = elementsToUse[i];

      if (addBreaksForRange) {
        allowBreaks =
          i >= addBreaksForRange.fromIndex && i < addBreaksForRange.toIndex;
      }

      if (
        element.state === 'removed' ||
        element.state === 'cut' ||
        (!onlyOnTranscriptBreaks &&
          (element.state === 'muted' || !element.value))
      ) {
        continue;
      }

      const parts = getParts(element);

      for (const part of parts) {
        let shouldBreak = this.shouldBreakOnElement(
          element,
          part,
          currentKaraokeClip,
          maxCharsInLine,
          isStartOfSentence,
          isAfterComma,
          !allowBreaks,
        );

        if (shouldBreak) {
          this.finalizeKaraokeClip(
            currentKaraokeClip,
            resultedKaraokeClips,
            lastAddedElementIndex,
            allowBreaks,
            transcriptPositionsToBreak,
          );
          currentKaraokeClip = this.initializeKaraokeClip();
          karaokeBreakStartTsDiff = 0;
        }

        isStartOfSentence = this.shouldUpdateStartOfSentence(
          part,
          allowBreaks,
          element,
          isStartOfSentence,
        );
        isAfterComma =
          element.value === ',' || (isAfterComma && element.type !== 'text');

        if (element.state !== 'muted' && element.value) {
          currentKaraokeClip.text += part.value;
          lastAddedElementIndex = i;
        }

        if (part.type === 'text') {
          currentKaraokeClip.startTs =
            currentKaraokeClip.startTs < 0
              ? part.ts! + karaokeBreakStartTsDiff
              : currentKaraokeClip.startTs;
          currentKaraokeClip.endTs = part.end_ts!;
        }
        if (part.karaoke_break_start_ts_diff != null) {
          if (currentKaraokeClip.startTs < 0) {
            karaokeBreakStartTsDiff = part.karaoke_break_start_ts_diff;
          } else {
            currentKaraokeClip.startTs =
              currentKaraokeClip.startTs + part.karaoke_break_start_ts_diff;
          }
        }

        if (
          currentKaraokeClip.endTs &&
          part.karaoke_break_end_ts_diff != null
        ) {
          currentKaraokeClip.endTs =
            currentKaraokeClip.endTs + part.karaoke_break_end_ts_diff;
        }
      }
    }

    if (currentKaraokeClip.text.trim().length > 0) {
      this.finalizeKaraokeClip(
        currentKaraokeClip,
        resultedKaraokeClips,
        lastAddedElementIndex,
        allowBreaks,
        transcriptPositionsToBreak,
      );
    }

    if (transcriptPositionsToBreak.length > 0) {
      this.videoCreator.addKaraokeBreaks(
        transcriptPositionsToBreak,
        this.isSubtitles(),
      );
    }

    this.karaokeClips = resultedKaraokeClips.filter(
      (karaokeClip) =>
        karaokeClip.startTs >= 0 && karaokeClip.text.trim().length > 0,
    );
  }

  private getTextElementsToUse(isSubtitles: boolean): TranscriptElement[] {
    return isSubtitles ? this.subtitleElements! : this.transcriptionElements!;
  }

  private muteFillersInTranscript(isSubtitles: boolean): void {
    if (!this.karaokeConfig.temp_muteFillers) {
      return;
    }
    const elementsToUse = this.getTextElementsToUse(isSubtitles);
    const batchedKaraokeMutes: { startIndex: number; endIndex: number }[] = [];
    const batchedKaraokeMuteUndos: number[] = [];
    for (let i = 0; i < elementsToUse.length; i++) {
      const element = elementsToUse[i];
      if (
        element.state !== 'removed' &&
        element.state !== 'cut' &&
        element.state !== 'muted' &&
        element.value?.trim() &&
        this.karaokeConfig.hideFillers &&
        !removeFillerWords(element.value).trim()
      ) {
        // go to the left until something other than ',' or ' ' is found
        // but also, we need to keep 1 whitespace unmuted to match removeFillerWords logic
        let startIndex = i;
        let nonMutableWsIdx = -1;
        for (let j = i - 1; j >= 0; j--) {
          if (
            elementsToUse[j].state !== 'removed' &&
            elementsToUse[j].state !== 'cut' &&
            elementsToUse[j].state !== 'muted' &&
            (elementsToUse[j].value === ' ' || elementsToUse[j].value === ',')
          ) {
            startIndex--;
            if (elementsToUse[j].value === ' ' && nonMutableWsIdx === -1) {
              nonMutableWsIdx = j;
            } else {
              elementsToUse[j].muted_by_hideFillers = true;
            }
          } else {
            break;
          }
        }
        if (nonMutableWsIdx !== -1) {
          // do nothing if only single whitespace was found on the left
          if (nonMutableWsIdx === startIndex) {
            startIndex = i;
          } else {
            batchedKaraokeMutes.push({
              startIndex,
              endIndex: nonMutableWsIdx - 1,
            });
            startIndex = nonMutableWsIdx + 1;
          }
        }
        let endIndex = i;
        for (let j = i + 1; j < elementsToUse.length; j++) {
          if (
            elementsToUse[j].state !== 'removed' &&
            elementsToUse[j].state !== 'cut' &&
            elementsToUse[j].state !== 'muted' &&
            (elementsToUse[j].value === ' ' || elementsToUse[j].value === ',')
          ) {
            endIndex++;
            elementsToUse[j].muted_by_hideFillers = true;
          } else {
            break;
          }
        }
        batchedKaraokeMutes.push({
          startIndex,
          endIndex,
        });
        element.muted_by_hideFillers = true;
      }

      if (
        element.state === 'muted' &&
        !this.karaokeConfig.hideFillers &&
        element.muted_by_hideFillers
      ) {
        batchedKaraokeMuteUndos.push(i);
        delete element.muted_by_hideFillers;
      }
    }

    for (let i = 0; i < batchedKaraokeMutes.length; i++) {
      this.videoCreator.videoTranscriptionProcessor.hideKaraoke(
        batchedKaraokeMutes[i],
        i !== batchedKaraokeMutes.length - 1,
      );
    }
    for (let i = 0; i < batchedKaraokeMuteUndos.length; i++) {
      this.videoCreator.videoTranscriptionProcessor.restoreMutedTextElement(
        batchedKaraokeMuteUndos[i],
        batchedKaraokeMuteUndos[i] + 1,
        i !== batchedKaraokeMuteUndos.length - 1,
      );
    }
    delete this.karaokeConfig.temp_muteFillers;
  }

  estimateTextWidth(text: string, fontSize: number) {
    return text.length * fontSize * 0.6;
  }

  private sanitizedText(rawText: string, config: KaraokeConfig) {
    let text = rawText;
    if (config.hideFillers) {
      text = capitalizeAfterPeriods(removeFillerWords(text).trim());
    }

    if (config.hideComma) {
      text = text.replaceAll(',', '');
    }
    if (config.hidePeriod) {
      text = text.replaceAll('.', '');
    }
    return text;
  }

  private transformToInstagramElements(
    karaokeClips: KaraokeClip[],
    config: KaraokeConfig,
  ) {
    const source = this.renderer!.getSource();
    const videoWidth = source.width;
    const videoHeight = source.height;
    let avgFontSize = parseFloat(config.font_size);
    let fontSizeUnits = config.font_size.match(/[a-z]+/)?.[0] || 'px';
    const lines = Number(config.instagramLines) || 5;
    const largeLines = Math.floor((2 * lines) / 5);

    if (fontSizeUnits === 'px') {
      avgFontSize = convertFromPixels(avgFontSize, 'vh', {
        width: videoWidth,
        height: videoHeight,
      });
      fontSizeUnits = 'vh';
    }

    const instagramKaraokeClips: (KaraokeClip & {
      fontSize?: number;
      size?: 'large' | 'small';
    })[][] = [];

    let currentInstagramClip = [];
    for (let i = 0; i < karaokeClips.length; i++) {
      const karaokeClip = karaokeClips[i];

      if (
        currentInstagramClip.length === lines ||
        (i > 0 &&
          karaokeClip.startTs - karaokeClips[i - 1].endTs > MAX_GAP_DURATION)
      ) {
        instagramKaraokeClips.push(currentInstagramClip);
        currentInstagramClip = [];
      }

      currentInstagramClip.push(karaokeClip);
    }
    // last one
    if (currentInstagramClip.length > 0) {
      instagramKaraokeClips.push(currentInstagramClip);
    }

    const resultedKaraokeClips = [];
    for (const instagramKaraokeClip of instagramKaraokeClips) {
      const karaokeLines = [];
      const elementTs = instagramKaraokeClip[0].startTs;
      const elementEndTs =
        instagramKaraokeClip[instagramKaraokeClip.length - 1].endTs;

      const secondsPerCharInLine = instagramKaraokeClip.map(
        (karaokeClip, index) => ({
          index,
          tsPerChar:
            (karaokeClip.endTs - karaokeClip.startTs) / karaokeClip.text.length,
        }),
      );

      secondsPerCharInLine.sort((a, b) => b.tsPerChar - a.tsPerChar);
      secondsPerCharInLine.slice(0, largeLines).forEach((el) => {
        instagramKaraokeClip[el.index].fontSize = Math.round(
          1.15 * avgFontSize * (1 + Math.random() * 0.1),
        );
        instagramKaraokeClip[el.index].size = 'large';
      });
      secondsPerCharInLine.slice(largeLines).forEach((el) => {
        instagramKaraokeClip[el.index].fontSize = Math.round(
          0.75 * avgFontSize * (1 + Math.random() * 0.1),
        );
        instagramKaraokeClip[el.index].size = 'small';
      });

      // TODO replace with width and height params
      // + composition position
      let linePosY = parseFloat(config.y); // Math.round((parseFloat(config.y) * videoHeight) / 100);
      let linePosX = videoWidth / 2;

      for (let k = 0; k < instagramKaraokeClip.length; k++) {
        const karaokeLine = instagramKaraokeClip[k];
        const nextLine = instagramKaraokeClip[k + 1];
        const time = Math.max(elementTs, karaokeLine.startTs - 0.2);
        const duration = elementEndTs - time;
        karaokeLines.push({
          id: uuid(),
          time,
          duration,
          type: 'text',
          text: this.sanitizedText(karaokeLine.text, config),
          background_y_padding: '5%',
          background_x_padding:
            Math.round((avgFontSize / karaokeLine.fontSize!) * 16) + '%',
          ...config,
          // IMPORTANT: values below override config
          text_transform: 'uppercase',
          font_size: null, //currentWordElement.fontSize!,
          height: `${Math.ceil(1.15 * karaokeLine.fontSize!)} ${fontSizeUnits}`,
          y: `${linePosY} vh`,
          x: linePosX,
          animations: config.animations.map((anim: any) => ({
            ...anim,
            duration: 0.1, // Math.max(karaokeLine.endTs! - karaokeLine.ts!, 0.1),
            fade: false,
            background_effect: 'animated',
          })),
        });

        linePosY += Math.ceil(
          0.55 * karaokeLine.fontSize! + 0.55 * (nextLine?.fontSize || 0),
        );
      }
      resultedKaraokeClips.push(karaokeLines);
    }

    return resultedKaraokeClips;
  }

  private getCompositionElement(elements: any[]): {
    time: any;
    type: string;
    track: number;
    elements: any[];
    [key: string]: any; // Index signature
  } {
    const relativeTime = elements[0].time;
    return {
      time: relativeTime,
      type: 'composition',
      track: KARAOKE_TRACK_NUMBER,
      elements: elements.map((el, index) => {
        el.time -= relativeTime;
        el.track = index + 1; // track inside composition
        return el;
      }),
    };
  }

  private async renderInstagramElements() {
    if (!this.karaokeConfig) throw new Error('No karaoke config');

    const {
      shadowEffect,
      shadow_color,
      shadow_blur,
      shadow_x,
      shadow_y,
      ...config
    } = this.karaokeConfig;
    const source = this.renderer!.getSource();
    let track = KARAOKE_TRACK_NUMBER; //karaoke is fixed on top track

    source.elements = source.elements.filter((el: any) => el.track < track);
    source.elements = [
      ...source.elements,
      ...this.transformToInstagramElements(this.karaokeClips!, config).map(
        (karaokeElement: any[]) => {
          let composition = this.getCompositionElement(karaokeElement);
          composition.x = config!.x;
          return composition;
        },
      ),
    ];
    await this.renderer!.setSource(source);
  }

  private async renderKaraokeClips() {
    const _config = this.karaokeConfig;
    if (!_config) throw new Error('No karaoke config');
    const {
      shadowEffect,
      shadow_color,
      shadow_blur,
      shadow_x,
      shadow_y,
      ...config
    } = _config;
    const source = this.renderer!.getSource();
    // check if top track is free
    let track = KARAOKE_TRACK_NUMBER; //fix karaoke on top track
    source.elements = source.elements.filter((el: any) => el.track < track);

    let fontSize = parseFloat(config.font_size);
    let fontSizeUnits = config.font_size.match(/[a-z]+/)?.[0] || 'px';

    if (fontSizeUnits === 'px') {
      fontSize = convertFromPixels(fontSize, 'vh', {
        width: source.width,
        height: source.height,
      });
      fontSizeUnits = 'vh';
    }

    for (let i = 0; i < this.karaokeClips!.length; i++) {
      const karaokeClip = this.karaokeClips![i];

      // Params to improve timing of word appearance.
      const bezierParams =
        process.env.REACT_APP_NEW_KARAOKE == 'true'
          ? karaokeClip.calculateCubicBezierForWords().join(',')
          : '0,0,1,1'; // Linear

      const duration =
        karaokeClip.endTs - karaokeClip.startTs > 0
          ? karaokeClip.endTs - karaokeClip.startTs
          : 0.1; //MIN DURATION
      source.elements.push({
        id: karaokeClip.id,

        track: track,
        time: karaokeClip.startTs,
        duration,
        type: 'text',
        text: karaokeClip.text,
        ...config,
        font_size: `${fontSize} ${fontSizeUnits}`,

        ...(shadowEffect && {
          shadow_color,
          shadow_blur,
          shadow_x,
          shadow_y,
        }),

        animations: config.animations.map((anim: any) => ({
          ...anim,
          duration: Math.max(0.1, duration - 0.2),
          fade: false,
          easing: `cubic-bezier(${bezierParams})`,
          background_effect: 'animated',
        })),
      });
    }

    await this.renderer!.setSource(source);
  }
}
