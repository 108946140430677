import styled from 'styled-components';
import Modal from '../../common/Modal';
import { Story } from '../../../types.ts/story';
import { useStoryCreator, Step } from './useStoryCreator';
import { StepOne, StepTwo, StepThree, StepFour } from './steps';
import { useState } from 'react';
import StatusMessageModal from '../../common/StatusMessageModal';
import AddStoriesModalBanner from './AddStoriesModalBanner';
import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';

const AddStoriesModal = ({
  closeModal,
  onStoryInitialized,
  onStoryCreated,
  onStoryProgress,
  modalId,
  setBannerHeight,
}: {
  closeModal: (modalId: string) => void;
  onStoryInitialized: (s: Story) => void;
  onStoryCreated: (s: Story, modalId: string) => void;
  onStoryProgress: (percent: number, storyId?: string) => void;
  modalId: string;
  setBannerHeight: (height: number | undefined) => void;
}) => {
  const videoCreator = useVideoCreatorStore();
  const [isStatusMessageModalOpen, setIsStatusMessageModalOpen] =
    useState<boolean>(false);
  const [percent, setPercent] = useState<number>(0);
  const handleStoryProgress = (percent: number, storyId?: string) => {
    setPercent(percent);
    console.log('onStoryProgress', percent);
    onStoryProgress(percent, storyId);
  };
  const {
    step,
    goToNextStep,
    canGoToStep,
    handleStoryVideoUpload,
    handleStoryBRollUpload,
    storyName,
    setStoryName,
    storyType,
    setStoryType,
    videoMusicStrategy,
    setVideoMusicStrategy,
    storyArtifactsFiles,
    storyArtifactsVideoFiles,
    storyArtifacts,
    storyArtifactsVideo,
    saveArtifactNotes,
    setArtifactNotes,
    deleteArtifact,
    saveArtifactVideoNotes,
    setArtifactVideoNotes,
    deleteArtifactVideo,
  } = useStoryCreator({
    videoCreator,
    onStoryInitialized,
    onStoryCreated: (s) => onStoryCreated(s, modalId),
    onStoryProgress: handleStoryProgress,
    logError: (...data: any[]) => {
      console.error(...data);
      setIsStatusMessageModalOpen(true);
      handleStoryProgress(100);
    },
    logWarning: console.warn,
  });

  const renderStep = () => {
    switch (step) {
      case Step.one:
        return (
          <StepOne
            goToNextStep={goToNextStep}
            canGoToStep={canGoToStep(Step.two)}
            handleStoryVideoUpload={handleStoryVideoUpload}
          />
        );
      case Step.two:
        return (
          <StepTwo
            goToNextStep={goToNextStep}
            canGoToStep={canGoToStep(Step.three)}
            storyType={storyType}
            setStoryType={setStoryType}
            videoMusicStrategy={videoMusicStrategy}
            setVideoMusicStrategy={setVideoMusicStrategy}
          />
        );
      case Step.three:
        return (
          <StepThree
            goToNextStep={goToNextStep}
            canGoToStep={canGoToStep(Step.four)}
            storyName={storyName}
            setStoryName={setStoryName}
          />
        );

      case Step.four:
        return (
          <StepFour
            goToNextStep={goToNextStep}
            handleStoryBRollUpload={handleStoryBRollUpload}
            storyArtifactsFiles={storyArtifactsFiles}
            storyArtifactsVideoFiles={storyArtifactsVideoFiles}
            storyArtifacts={storyArtifacts}
            storyArtifactsVideo={storyArtifactsVideo}
            saveArtifactNotes={saveArtifactNotes}
            setArtifactNotes={setArtifactNotes}
            deleteArtifact={deleteArtifact}
            saveArtifactVideoNotes={saveArtifactVideoNotes}
            setArtifactVideoNotes={setArtifactVideoNotes}
            deleteArtifactVideo={deleteArtifactVideo}
          />
        );
      default:
        return null;
    }
  };

  const handleCloseModal = () => {
    closeModal(modalId);
  };

  if (isStatusMessageModalOpen) {
    return (
      <StatusMessageModal
        enabled
        variant="user-upload-failed"
        onClose={handleCloseModal}
      />
    );
  }

  if (step === Step.five) {
    return null;
  }

  return (
    <>
      <Modal isOpen closeModal={handleCloseModal}>
        <Wrapper>
          <Heading>
            Story producer (Step {step} of {Step.five - 1})
          </Heading>
          {renderStep()}
        </Wrapper>
      </Modal>
      {percent > 0 && percent < 100 && (
        <AddStoriesModalBanner
          setBannerHeight={setBannerHeight}
          percent={percent}
        />
      )}
    </>
  );
};

const Wrapper = styled.div`
  width: 440px;
  border-radius: 16px;
  border: 1px solid #484848;
  padding: 48px 24px;
  box-sizing: content-box;
  background: #03041a;
`;

const Heading = styled.div`
  color: #45d483;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.92px;
  text-transform: uppercase;
  margin-bottom: 24px;
`;

export default AddStoriesModal;
