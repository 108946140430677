import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Music, Story } from '../../types.ts/story';

import MusicSongItem from './MusicSongItem';
import { AIProducerCard, SidebarOption } from '../../types.ts/general';
import AIGenerateCard from '../common/AIGenerateCard';
import SongCollection, {
  AddSongToTrackOptions,
  FIT_SONG_TO_VIDEO_DEFAULT,
} from './SongCollection';
import ChatGPTService from '../../services/ChatGPTService';
import { observer } from 'mobx-react-lite';
import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';
import { analytics } from '@src/utility/analytics';
import PrimaryActionButton from './PrimaryActionButton';
import { deepClone } from '@src/utility/deepClone';

const MUSIC_SECTION_WIDTH_PX = 300;
const SONG_LIST_LENGTH_STEP = 5;

const isOnFirstPage = (songListLength: number): boolean => {
  return songListLength <= SONG_LIST_LENGTH_STEP;
};

type Props = {
  story?: Story;
  music?: Music[];
  selectedCard?: AIProducerCard;
};
const AIProducer = observer((props: Props) => {
  const videoCreator = useVideoCreatorStore();
  const gptService = new ChatGPTService(videoCreator);
  const [currentSong, setCurrentSong] = useState('');
  const [selectedSongs, setSelectedSongs] = useState<Music['collection']>();
  const [songListLength, setSongListLength] = useState(SONG_LIST_LENGTH_STEP);

  const audioRef = useRef<HTMLAudioElement | null>(null);
  const songList = selectedSongs || videoCreator.musicOptions;

  const preloadAudio = (url: string) => {
    const audio = new Audio(url);
    audio.preload = 'auto';
    audio.load();
  };

  const [isSelectedGenreLoading, setIsSelectedGenreLoading] =
    useState<boolean>(false);
  const callbackNameRef = useRef<string>();

  useEffect(() => {
    if (!isSelectedGenreLoading && callbackNameRef.current) {
      videoCreator.musicProducerLoading = false;
      callByName(callbackNameRef.current);
      callbackNameRef.current = undefined;
    }
  }, [isSelectedGenreLoading]);

  useEffect(() => {
    if (!songList) return;
    for (const song of songList) {
      preloadAudio(song.url);
      if (!videoCreator.audioTracksData[song.url]) {
        videoCreator.loadWaveformForSource({
          source: song.url,
          name: song.title,
        });
      }
    }
  }, [songList]);

  useEffect(() => {
    if (!isOnFirstPage(songListLength)) {
      selectSongsByMood();
    }
  }, [songListLength]);

  // const maxSongsDuration =
  //   songList?.reduce((acc, song) => {
  //     return Math.max(acc, Number(song.customData.duration.split(' ')[0]));
  //   }, 0) || 100;

  const getVideoDuration = () => {
    return videoCreator.duration;
  };

  const getAIMood = () => {
    const response =
      videoCreator?.contentStudioGeneratedContent?.['Music Selection']?.content
        ?.response || '';
    return (Array.isArray(response) ? response[0] : response) as string;
  };

  const selectedGenre: string = getAIMood().trim();

  const delayAndStopLoading = (
    callback: () => void,
    timeoutOuter: number = 3000,
    timeoutInner: number = 1000,
  ) => {
    setTimeout(() => {
      callback();
      setTimeout(() => {
        videoCreator.musicProducerLoading = false;
      }, timeoutInner);
    }, timeoutOuter);
  };

  const getMusicCollection = (): {
    storyLength: number;
    sortedCollection: Music['collection'];
    collection: Music['collection'];
  } => {
    const music = props.music?.find(
      (m) => m.genre.toLowerCase() === selectedGenre.toLowerCase(),
    );
    const collection = music?.collection || [];

    const storyLength = Number(getVideoDuration());

    const sortedCollection = deepClone(collection).sort(
      (a, b) => getDuration(b) - getDuration(a),
    );
    return { storyLength, sortedCollection, collection };
  };

  const hasMoreSongs = (): boolean => {
    const { collection } = getMusicCollection();
    return collection.length > songListLength && (songList?.length || 0) > 1;
  };

  const loadMoreSongs = () => {
    setSongListLength(songListLength + SONG_LIST_LENGTH_STEP);
  };

  const selectSongByMood = async () => {
    const { storyLength, sortedCollection } = getMusicCollection();
    if (!sortedCollection?.length) {
      videoCreator.musicOptions = [];
      setSelectedSongs([]);
      return;
    }

    let song = sortedCollection[0];
    videoCreator.musicProducerLoading = true;

    const last = sortedCollection[sortedCollection.length - 1];
    if (getDuration(last) >= storyLength) {
      delayAndStopLoading(() => {
        videoCreator.musicOptions = [last];
        setSelectedSongs([last]);
        handleAddToTrack(
          last.url,
          last.customData.songName,
          last.customData.duration,
          {
            fitSongToVideo: FIT_SONG_TO_VIDEO_DEFAULT,
          },
        );
      });
      return;
    }

    if (getDuration(sortedCollection[0]) > storyLength) {
      for (let i = 1; i < sortedCollection.length; i++) {
        if (getDuration(sortedCollection[i]) < storyLength) {
          song = sortedCollection[i - 1];
          break;
        }
      }
    }

    delayAndStopLoading(() => {
      videoCreator.musicOptions = [song];
      setSelectedSongs([song]);
      handleAddToTrack(
        song.url,
        song.customData.songName,
        song.customData.duration,
        {
          fitSongToVideo: FIT_SONG_TO_VIDEO_DEFAULT,
        },
      );
    });
  };

  const selectSongsByMood = async () => {
    const { collection } = getMusicCollection();

    if (!collection?.length) {
      videoCreator.musicOptions = [];
      setSelectedSongs([]);
      return;
    }

    videoCreator.musicProducerLoading = true;

    const songs = collection.slice(0, songListLength);

    delayAndStopLoading(
      () => {
        videoCreator.musicOptions = deepClone(songs);
        setSelectedSongs(deepClone(songs));
      },
      isOnFirstPage(songListLength) ? undefined : 500,
      isOnFirstPage(songListLength) ? undefined : 500,
    );
  };

  const callByName = (callbackName: string) => {
    switch (callbackName) {
      case 'selectSongByMood':
        selectSongByMood();
        break;
      case 'selectSongsByMood':
        selectSongsByMood();
        break;
      default:
        break;
    }
  };

  const getDuration = (collection: Music['collection'][0]) =>
    Number(collection?.customData?.duration?.split(' ')[0]) || 0;

  const handleAddToTrack = async (
    url: string,
    songName: string,
    mediaDuration: string,
    options: AddSongToTrackOptions,
  ) => {
    const songDuration = parseFloat(mediaDuration);
    const freeTrack = videoCreator.getFreeMediaTrack('audio', songDuration, 0);
    const elementSource: Record<string, any> = {
      name: songName.replace(/\b\w/g, (match) => match.toUpperCase()),
      type: 'audio',
      source: url,
      autoplay: true,
      time: 0,
      ...(freeTrack && { track: freeTrack }),
      volume: 10,
    };

    if (options?.fitSongToVideo) {
      const availableDuration = videoCreator.getFreeMediaDuration(
        songDuration,
        0,
      );
      if (Math.round(availableDuration) < 1) {
        videoCreator.toastState = {
          state: 'error',
          message: 'Song addition failed, please adjust playhead',
        };
        return;
      }
      elementSource.duration = availableDuration;
    }

    await videoCreator.createElement(
      elementSource,
      true,
      freeTrack ? undefined : videoCreator.pickOriginalVideoTrack,
    );

    videoCreator.sidebarOptions = SidebarOption.editing;
  };

  const callAfterSelectedGenreLoaded = async (callbackName: string) => {
    try {
      analytics.track('generate_music', {
        storyId: videoCreator.story?.id,
        storyTitle: videoCreator.story?.title,
        videoId: videoCreator.currentVideo?.id,
        videoTitle: videoCreator.currentVideo?.title,
      });
      setIsSelectedGenreLoading(true);
      videoCreator.musicProducerLoading = true;
      callbackNameRef.current = callbackName;
      clearGeneratedMusicSelection();
      setSongListLength(SONG_LIST_LENGTH_STEP);
      await gptService.regenerateStreamResponse({
        key: 'Music Selection',
        setLoading: setIsSelectedGenreLoading,
      });
    } catch (error) {
      console.error("Couldn't generate mood for the story: ", error);
      callbackNameRef.current = undefined;
      videoCreator.musicProducerLoading = false;
      setIsSelectedGenreLoading(false);
    }
  };

  const clearGeneratedMusicSelection = () => {
    if (
      videoCreator?.contentStudioGeneratedContent?.['Music Selection']
        ?.hasBeenGenerated
    ) {
      videoCreator.contentStudioGeneratedContent[
        'Music Selection'
      ].hasBeenGenerated = false;
      videoCreator.contentStudioGeneratedContent['Music Selection'].content =
        null;
    }
  };

  const renderMusicGenerateOptions = () => (
    <>
      <audio ref={audioRef} preload="auto">
        <source src="" />
      </audio>

      <AIGenerateCard
        handleClick={() => callAfterSelectedGenreLoaded('selectSongByMood')}
        text="Generate"
      />
      <AIGenerateCard
        handleClick={() => callAfterSelectedGenreLoaded('selectSongsByMood')}
        text="Identify Music Options"
      />
      {renderGeneratedOptions()}
    </>
  );

  const renderCustomDescription = () => (
    <>
      <ContentDescription>
        <p className="description">
          <span className="bolded">
            Recommended Music based on length and emotion
          </span>
        </p>
        <div className="emotions">
          <span>Emotions identified</span>
          <div className="mood">
            {selectedGenre && <MoodTag>{selectedGenre}</MoodTag>}
          </div>
        </div>
      </ContentDescription>

      <HorizontalLine height="2" viewBox="0 0 300 2" fill="none">
        <path d="M0 1L300 1.00002" stroke="#333333" />
      </HorizontalLine>
    </>
  );

  const renderGeneratedOptions = () => (
    <Content>
      {songList && (
        <>
          {renderCustomDescription()}
          {songList?.length ? (
            <SongCollection onAddToTrack={handleAddToTrack}>
              {({ handleSongSelect, isSongSelected }) => (
                <>
                  {songList.map((song) => (
                    <MusicSongItem
                      key={song.id}
                      audioRef={audioRef}
                      song={song}
                      currentSong={currentSong}
                      setCurrentSong={setCurrentSong}
                      mood={selectedGenre}
                      onSongClick={handleSongSelect}
                      customTimelineScale={
                        (MUSIC_SECTION_WIDTH_PX /
                          parseFloat(song.customData.duration)) *
                        1
                      }
                      isHighlighted={isSongSelected(song)}
                    />
                  ))}
                  {hasMoreSongs() && (
                    <LoadMoreSongsButton onClick={loadMoreSongs} />
                  )}
                </>
              )}
            </SongCollection>
          ) : (
            <div>No matching song in the album</div>
          )}
        </>
      )}
    </Content>
  );

  return <Main>{renderMusicGenerateOptions()}</Main>;
});

export default AIProducer;

const Main = styled.div``;

const Content = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
`;

const ContentDescription = styled.div`
  box-sizing: border-box;
  font-size: 12px;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  width: 320px;
  padding: 4px 10px 8px;

  gap: 10px;
  .description {
    margin: 6px 0;
    font-weight: 700;
  }

  .emotions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;

    .mood {
      display: flex;
      gap: 5px;
    }
  }
`;

const MoodTag = styled.span`
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #f2d093;
  color: #f2d093;
  font-size: 10px;
  font-weight: 500;
`;

const HorizontalLine = styled.svg`
  width: 100%;
`;

const LoadMoreButton = styled(PrimaryActionButton)`
  max-height: 40px;
  font-size: 14px;
  border: 1px solid #17c964;
  color: #17c964;
  background-color: #03041a;
  margin: 0 0 6px;
`;

// separate component to make sure disabled state is up-to-date
const LoadMoreSongsButton = observer(({ onClick }: { onClick: () => void }) => {
  const videoCreator = useVideoCreatorStore();
  return (
    <LoadMoreButton
      disabled={videoCreator.musicProducerLoading}
      onClick={onClick}
    >
      Find More Songs
    </LoadMoreButton>
  );
});
