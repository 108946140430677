export type NaturalDimensions = {
  width: number;
  height: number;
} | null;

export async function getImageNaturalDimensions(
  src: string,
): Promise<NaturalDimensions> {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      resolve({ width: img.naturalWidth, height: img.naturalHeight });
    };
    img.onerror = () => {
      console.error('Failed to load the image for src', src);
      resolve(null);
    };
  });
}

export async function getVideoNaturalDimensions(
  src: string,
): Promise<NaturalDimensions> {
  return new Promise((resolve) => {
    const video = document.createElement('video');
    video.src = src;
    video.preload = 'metadata'; // Only load metadata, not the entire video
    video.onloadedmetadata = () => {
      resolve({
        width: video.videoWidth,
        height: video.videoHeight,
      });
    };
    video.onerror = () => {
      console.error('Failed to load video metadata for element', src);
      resolve(null);
    };
  });
}
